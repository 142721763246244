import { Component, Injectable } from '@angular/core';
// TODO didn't we get a piece of code from iTransition we can use for the ToastController?

@Injectable({providedIn: 'root'})
@Component({
  selector: 'app-tutorial-details',
  template: '<div></div>'
})
export class NavParams {
  data: any;
  constructor() {}
  create(data:any) {
    console.log('TODO if needed create toast',data);
  }
  present() {
    console.log('present toast');
  }
  openSnackBar(data:any) {
    console.log('snackbar: ' + data);
    /*
    this.snackBar.open('This is a toast message', 'Close', {
      duration: 3000,  
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
    */
  }
}
