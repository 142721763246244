import { Component, Input } from "@angular/core";

@Component({
  selector: "upsell-wait",
  templateUrl: "upsell-wait.html",
  styleUrls: ['upsell-wait.scss'],
})

export class UpsellWaitComponent {

  @Input() iconWait: string = 'https://cdn.gundrymd.com/images/palm_wait.svg';
  @Input() iconText: string = 'wait!';
  @Input() isFull: boolean = false;
  @Input() title: string = '';
  @Input() description: string = '';

  constructor() {}
}
