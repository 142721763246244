import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

import { Content } from '../models/content';

const API_URL: string = (window as any)['process_env'].API_URL;

interface CmsReplacements {
  [key: string]: any;
}
const cmsReplacements: CmsReplacements = {
  'https://{{FUNNEL_URL}}': (window as any)['process_env'].FUNNEL_URL,
  'https://{{CART_URL}}': (window as any)['process_env'].CART_URL,
  'https://{{AFFILIATE_URL}}': (window as any)['process_env'].AFFILIATE_URL,
};

const GET_CONTENT_BY_NAME: string = 'content/byname/';
const GET_CONTENT_BY_SFID: string = 'content/sfid/';

@Injectable({providedIn: 'root'})
export class TouchcrApiContentProvider {
  contentsMap: Map<string, Content> = new Map();

  constructor(
    public http: HttpClient,
  ) {}

  getHomeContent(): Observable<Content> {
    return this.getPageContent('home');
  }

  getSubscriptionsContent(): Observable<Content> {
    return this.getPageContent('subscriptions');
  }

  getPageContent(contentName: string): Observable<Content> {
    if (this.contentsMap.get(contentName)) {
      return Observable.of(<Content>this.contentsMap.get(contentName));
    }
    return this.http.get<Array<Content>>(`${API_URL}${GET_CONTENT_BY_NAME}${contentName}`)
      .map(([contentObj]: Array<Content>) => {
        if (!contentObj) return;

        const contentObjCopy = JSON.parse(JSON.stringify(contentObj));

        Object.keys(cmsReplacements).forEach(key => {
          contentObjCopy.content = contentObjCopy.content.replace(new RegExp(key, 'g'), cmsReplacements[key]);
        });

        this.contentsMap.set(contentName, contentObjCopy);
        return contentObjCopy;
      })
      .catch((error) => {
        return Observable.of(error && error.error && error.error.error);
      });
  }

  getPageContentBySFId(id: string = ''): Observable<Content | null> {
    if (!id) {
      return Observable.of(null);
    }
    return this.http.get<Content>(`${API_URL}${GET_CONTENT_BY_SFID}${id}`)
      .map((response) => {
        if (!response || !response.content) return null;
        try {
          return JSON.parse(response.content);
        } catch (e) {
          return null;
        }
      })
      .catch((err) => {
        return Observable.of(null);
      });
  }
}
