import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralProvider } from './general';
import { Storage } from '@ionic/storage-angular';
import { deepLinkConfig } from '../../app/app.module';
import { StateProvider } from './state';
import { NavControllerExt } from '../extensions/nav-controller-extension';
import { UrlsProvider } from './urls';
import { TouchcrApiContentProvider } from './touchcr-api-content';


const API_URL = (window as any)['process_env'].API_URL;
const FUNNEL_CONFS = (window as any)['funnel_confs'] ? (window as any)['funnel_confs'].funnels : null;
const DESTINATIONS: any = (window as any)['funnel_confs'] ? (window as any)['funnel_confs'].destinations : null;
export const UNIQUE_IDENTIFIER = 'uid';
export const DESTINATION_ID = 'dsid';
export const CONTENT_ID = 'pcid';

let SESSION_ID: string;
let UID: string;
let SESSION_ID_GENERATED: boolean = false;
let DESTINATION_ID_VALUE: string;
let CONTENT_ID_VALUE: string = '';

@Injectable({providedIn: 'root'})
export class FunnelSettingsProvider {
  private STATS: string = 'stats';

  constructor(
    private http: HttpClient,
    private generalProvider: GeneralProvider,
    public storage: Storage,
    public stateProvider: StateProvider,
    public navCtrl: NavControllerExt,
    public urls: UrlsProvider,
    public contentProvider: TouchcrApiContentProvider,
  ) {
    this.storage = new Storage({
        name: '__ghdb'
        });
      this.storage.create();
    // [TOUD-5477]
    if (!(window as any)['loggedMainFunnel']) this.logMainFunnelByUID();
    (window as any)['loggedMainFunnel'] = true;
  }

  async getFunnelFromOfferById(funnelId: string = '') {
    const mainFunnel = this.getMainFunnelBySFID(funnelId);

    if (!mainFunnel || !mainFunnel.defaultFunnels) return;
    let { defaultFunnels } = mainFunnel;
    if (!defaultFunnels) return;
    const mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
    if (!mDefaultFunnels) return;
    const nextDefaultFunnel = mDefaultFunnels[0];
    if (!nextDefaultFunnel) return;
    const orderForm = mainFunnel.orderForm;
    const parType = nextDefaultFunnel.type;
    const parDefaultFunnel = nextDefaultFunnel;
    const parMainFunnelID = mainFunnel.sfid;
    const parUid = mainFunnel.uniqueIdentifier;

    let nextPage = await this.calculateNextPage(parDefaultFunnel, parDefaultFunnel.variants, parMainFunnelID);
    console.log("NextPage: ", nextPage);
    if (!nextPage) return;
    let { url, contentId, variantId } = nextPage;
    this.setContentIdToQuery(contentId);
    const component = await this.getComponent(url);
    if (!component) return;
    this.saveFunnelHistory(parMainFunnelID, parDefaultFunnel, url, variantId);
    let splitTestingId = null;
    mainFunnel.defaultFunnels.forEach((e: { splitTesting: string | any[]; }) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });

    let params = {
      component: component,
      orderForm: orderForm,
      type: parType,
      defaultFunnel: parDefaultFunnel,
      mainFunnelID: parMainFunnelID,
      url: url,
      uid: parUid,
      splitTestingPageId: splitTestingId,
      eventType: 'Page View',
    };
    if (!params) return;
    this.sendStatsToAPI(params as any);
    if (!this.urls.getParameterFromUrl({ url: window.location.search, parameter: UNIQUE_IDENTIFIER })) {
      this.setuId(params.uid);
    }
    if (params.defaultFunnel && params.defaultFunnel.step) this.setNextStep(params.defaultFunnel.step);
    return params;
  }

  async getNextPageByDestination(dstGenericUrl = '') {
    if (!dstGenericUrl) return;
    const result = this.nextStepDestination(dstGenericUrl);
    if (!result || !result.mainFunnel || !result.destinationId) {
      return;
    }
    const { mainFunnel, destinationId } = result;
    const firsStep = (<any>mainFunnel).defaultFunnels[0];
    const genericUrlOfFirstStep = firsStep.genericUrl;
    let newQuery:any = this.urls.getQueryParams();

    if (this.urls.getParameterFromUrl({ url: newQuery, parameter: UNIQUE_IDENTIFIER })) {
      newQuery = this.urls.removeParamFromQuery(newQuery, UNIQUE_IDENTIFIER);
    }
    if (this.urls.getParameterFromUrl({ url: newQuery, parameter: DESTINATION_ID })) {
      newQuery = this.urls.removeParamFromQuery(newQuery, DESTINATION_ID);
    }
    newQuery = this.urls.addParamToQuery(newQuery, UNIQUE_IDENTIFIER, (<any>mainFunnel).uniqueIdentifier);
    this.urls.setQueryParams(newQuery);
    this.setDestinationIdValue(destinationId);
    const params = await this.getNextPage(genericUrlOfFirstStep);
    if (!params) return;
    this.navCtrl.streamEvent('GenericUrlFunnel', {}, 'gu/' + params.defaultFunnel.genericUrl);
    return params;
  }

  nextStepDestination(genericUrl: string) {
    if (!DESTINATIONS || !genericUrl) return;

    const destination = DESTINATIONS.find((e: { genericUrl: any; }) => e.genericUrl === genericUrl);
    if (!destination) return;
    let mainFunnel: { sfid: any; };
    const defaultMainFunnel = this.getMainFunnelBySFID(destination.defaultFunnel);

    if (!destination.splitTestEnabled || (destination.splitTestEnabled && destination.percentage === 100)) {
      mainFunnel = defaultMainFunnel;
      if (!mainFunnel) return;
    } else {
      const mainFunnels = this.getMainFunnelsByDestinationId(destination.sfid);
      if (!mainFunnels || !mainFunnels.length || mainFunnels.length === 0) return;
      const mDefaultMainFunnel = JSON.parse(JSON.stringify(defaultMainFunnel));
      mDefaultMainFunnel.percentage = destination.percentage;
      mainFunnels.push(mDefaultMainFunnel);
      mainFunnel = this.getWithProbability(mainFunnels as any);
    }
    const splitTestingId = (
      destination.splitTesting &&
      destination.splitTesting.length &&
      destination.splitTesting.length > 0) ? destination.splitTesting[0].sfid : null;

      this.sendStatsToAPIDest({
        funnelSTFId: mainFunnel.sfid,
        destinationId: destination.sfid,
        splitTestingId: splitTestingId,
        eventType: 'Destination' as any,
      });
    
    let destinationId = '';
    if (destination) {
      destinationId = destination.sfid
    }
    return { mainFunnel, destinationId };
  }

  async sendStatsToAPIDest({
    funnelSTFId = null,
    destinationId = null,
    splitTestingId = null,
    eventType = null as string | null,
  } = {}) {
    const sessionIdGenerated = this.getSessionIDGenerated();
    try { // [TOUC-4669]
      if (!window.sessionStorage.getItem("fecnt")) {
        window.sessionStorage.setItem("fecnt", "0");
      }
      window.sessionStorage.setItem("fecnt", (parseInt((window as any).sessionStorage.getItem("fecnt")) + 1).toString());
      if ((parseInt((window as any).sessionStorage.getItem("fecnt")) > 300)) {
        console.log("[GH] detected 300 events in session already ");
        return;
      }
    } catch (e) {
      if (!(window as any)['fecnt']) {
        (window as any)['fecnt'] = 0;
      }
      (window as any)['fecnt'] = ((window as any)['fecnt'] || 0) + 1;
      if ((parseInt((window as any)['fecnt']) > 300)) {
        console.log("[GH] detected 300 events on window already ");
        return;
      }
    }

    if (sessionIdGenerated === true) {

        this.setSessionIDGenerated(false);
        this.sendStatsToAPIDest({
          funnelSTFId,
          destinationId,
          splitTestingId,
          eventType: 'New Session' as any,
        });

    }

    if (this.urls.getParameterFromUrl({ url: document.location.href, parameter: "preload" }) == 'true'
      && this.urls.getParameterFromUrl({ url: document.location.href, parameter: "preloadorigin" }) != '') {
      try {
        const tmp = {
          funnelSTFId: funnelSTFId,
          sessionId: this.urls.getParameterFromUrl({ url: document.location.href, parameter: "sessionid" }),
          destinationId: destinationId,
          splitTestingId: splitTestingId,
          eventType: eventType,
        };

        console.log('[GH] Experimental preload event1 ' + tmp.eventType, tmp);
        var eventClip = document.createElement('script');
        if (typeof (window as any)['btoa'] == 'function')
          eventClip.src = '/proxy/funnel/stats/save/?event=' + (window as any)['btoa'](JSON.stringify(tmp));
        else
          eventClip.src = '/proxy/funnel/stats/save/?event=' + encodeURIComponent(JSON.stringify(tmp));
        eventClip.type = 'text/javascript';
        document.body.appendChild(eventClip);
        return new Promise(resolve => {
          resolve(null);
        });
      } catch (e) {
        console.log('[GH] Experimental preload error', e);
      }
    }

    if (!funnelSTFId || !destinationId) return;
    const tmp = {
      funnelSTFId: funnelSTFId,
      sessionId: this.getSessionId(),
      destinationId: destinationId,
      splitTestingId: splitTestingId,
      eventType: eventType,
    };
    console.log('%c[GH] sendStatsToAPIDest ' + eventType, "color:lightblue;", tmp);

    return this.http.post(`${API_URL}funnel/stats/save`, tmp).toPromise()
      .catch(() => { });
  }

  wait(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async getNextPage(genericUrl = '', button: boolean = false, type: string = '', isPageFail = false) {
    await this.wait(300); //wait 300ms to ensure we are populating the correct step number from url parameter

    if (button || type || isPageFail) {
      return await this.getNextPageUpsell(button, type, isPageFail);
    } else {
      return await this.getNextPageV2(genericUrl);
    }
  }

  filterFunnelConfiguration(defaultFunnels = []) {
    if (defaultFunnels.length === 0) return;
    const mDefaultFunnels = JSON.parse(JSON.stringify(defaultFunnels));
    const isMobile = this.generalProvider.isMobile();
    const currentUrl = this.getCurrentUrl();
    let platform = isMobile ? 'Mobile only' : 'Desktop only';
    return mDefaultFunnels
      .map((e: { variants: any[]; }) => {
        if (e.variants) {
          e.variants = e.variants
            .map((e1: { splitTestEnabled: any; visibility: string; url: string; }) =>
              !(
                !e1.splitTestEnabled &&
                e1.visibility !== 'All' &&
                e1.visibility !== platform &&
                e1.url !== currentUrl
              )
                ? e1
                : false,
            )
            .filter((e1: any) => e1);
        }
        return e;
      })
      .filter((e: { step: any; variants: string | any[]; }) => e.step && e.variants && e.variants.length !== 0)
      .sort((a: { step: number; }, b: { step: number; }) => a.step - b.step);
  }

  async getNextPageV2(genericUrl = '') {
    let params: any = '';
    let orderForm: String;
    let parDefaultFunnel: any = '';
    let parType: String;
    let parMainFunnelID = '';
    let parUid: String;
    let currentmDefaultFunnels: any = '';
    let currentDefaultFunnel: any = '';
    let currentDefaultFunnelId = '';
    let mainFunnel: any = '';
    if (!FUNNEL_CONFS) return;
    if (genericUrl) {
      mainFunnel = this.getMainFunnelByGenericUrl(genericUrl);
      if (!mainFunnel || !mainFunnel.defaultFunnels) return;

      let { defaultFunnels } = mainFunnel;
      if (!defaultFunnels) return;
      let mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
      if (!mDefaultFunnels) return;

      let defaultFunnel = mDefaultFunnels.find((e: { genericUrl: string; }) => e.genericUrl === genericUrl);
      if (!defaultFunnel) [defaultFunnel] = mDefaultFunnels;
      const { variants } = defaultFunnel;
      if (!variants) return;
      orderForm = mainFunnel.orderForm;
      parType = defaultFunnel.type;
      parDefaultFunnel = defaultFunnel;
      parMainFunnelID = mainFunnel.sfid;
      parUid = mainFunnel.uniqueIdentifier;
    } else {
      let isSearchCurrentUrl = false;
      mainFunnel = this.getMainFunnelByUID();
      if (!mainFunnel || !mainFunnel.defaultFunnels) isSearchCurrentUrl = true;
      if (!isSearchCurrentUrl) {
        let { defaultFunnels } = mainFunnel;
        if (!defaultFunnels) return;
        defaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
        if (!defaultFunnels) return;
        currentmDefaultFunnels = defaultFunnels;
        if (this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' })) {
          const currentStep = Number(this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' }));
          currentDefaultFunnelId = '';
          let numUrlInFunnel = 0;
          currentDefaultFunnel = defaultFunnels.find((e: { variants: any[]; step: number; }, i: string) => {
            currentDefaultFunnelId = i;
            let defVariant = e.variants.find((e1: { url: string; }, i1: any) => {
              if (e1.url === this.getCurrentUrl()) {
                numUrlInFunnel = e.step;
              }
            });
            if (numUrlInFunnel > 0 && numUrlInFunnel < currentStep) {
              return true;
            }
            return e.step === currentStep;
          });
        }
        if (!currentDefaultFunnel || !currentDefaultFunnel.step) isSearchCurrentUrl = true;
      }

      if (isSearchCurrentUrl) {
        const currentUrl = this.getCurrentUrl();
        if (!currentUrl) return;
        mainFunnel = this.getMainFunnelByCurrentUrl();
        if (!mainFunnel || !mainFunnel.defaultFunnels) return;
        let { defaultFunnels } = mainFunnel;
        if (!defaultFunnels) return;
        let mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
        if (!mDefaultFunnels) return;
        currentmDefaultFunnels = mDefaultFunnels;
        currentDefaultFunnelId = '';
        currentDefaultFunnel = mDefaultFunnels.find((e: { url: string; variants: any[]; }, i: string) => {
          currentDefaultFunnelId = i;
          return e.url === currentUrl || (e.variants && e.variants.find((e1: { url: string; }) => e1.url === currentUrl));
        });
        if (!currentDefaultFunnel || !currentDefaultFunnel.step) return;
        const currentVariant =
          currentDefaultFunnel.url === currentUrl
            ? currentDefaultFunnel
            : currentDefaultFunnel.variants &&
            currentDefaultFunnel.variants.find((e: { url: string; }) => e.url === currentUrl);
        if (!currentVariant) return;
      }

      const nextDefaultFunnel = currentmDefaultFunnels[currentDefaultFunnelId + 1];
      if (!nextDefaultFunnel) return;
      orderForm = nextDefaultFunnel.upsell || nextDefaultFunnel.downsell || mainFunnel.orderForm;
      parType = nextDefaultFunnel.type;
      parDefaultFunnel = nextDefaultFunnel;
      parMainFunnelID = mainFunnel.sfid;
      parUid = mainFunnel.uniqueIdentifier;
    }

    let nextPage = await this.calculateNextPage(parDefaultFunnel, parDefaultFunnel.variants, parMainFunnelID);
    if (!nextPage) return;
    let { url, contentId, variantId } = nextPage;
    this.setContentIdToQuery(contentId);
    const component = await this.getComponent(url);
    if (!component) return;
    this.saveFunnelHistory(parMainFunnelID, parDefaultFunnel, url, variantId);
    let splitTestingId = null;
    mainFunnel.defaultFunnels.forEach((e: { splitTesting: string | any[]; }) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });
    

    params = {
      component: component,
      orderForm: orderForm,
      type: parType,
      defaultFunnel: parDefaultFunnel,
      mainFunnelID: parMainFunnelID,
      url: url,
      uid: parUid,
      splitTestingPageId: splitTestingId,
      eventType: 'Page View',
    };
    if (!params) return;


    this.sendStatsToAPI(params);


    if (!this.urls.getParameterFromUrl({ url: window.location.search, parameter: UNIQUE_IDENTIFIER })) {
      this.setuId(params.uid);
    }
    if (params.defaultFunnel && params.defaultFunnel.step) this.setNextStep(params.defaultFunnel.step);
    return params;
  }

  getCurrentUrl() {
    return window.location.pathname.split('/')[1];
  }

  async saveUserStats(stats: any = {}) {
    if (!stats) return;
    stats.deviceInfo = await this.generalProvider.getDeviceInfo();
    await this.storage.set(this.STATS, stats);
  }

  getUserStats() {
    return this.storage.get(this.STATS);
  }

  async saveFunnelHistory(mainFunnelId = '', defaultFunnel: any = '', url: any = '', variantId = '') {
    if (!mainFunnelId || !defaultFunnel || !defaultFunnel.genericUrl || !url) return;
    if (!this.storage) {
      this.storage = new Storage({
        name: '__ghdb'
        });
      await this.storage.create();
    }
    let stats = await this.getUserStats();
    if (!stats) stats = {};
    if (!stats.funnels) stats.funnels = {};
    if (!stats.funnels[mainFunnelId]) stats.funnels[mainFunnelId] = [];
    const spId = defaultFunnel.splitTesting[0] && defaultFunnel.splitTesting[0].sfid ? defaultFunnel.splitTesting[0].sfid : null;
    const obj = {
      step: defaultFunnel.step,
      genericUrl: defaultFunnel.genericUrl,
      url: url,
      spId: spId,
      variantId: variantId,
    };
    const isExist = stats.funnels[mainFunnelId].find(
      (e: { genericUrl: any; }) => e.genericUrl === defaultFunnel.genericUrl,
    );
    if (!isExist) {
      stats.funnels[mainFunnelId].push(obj);
    } else {
      stats.funnels[mainFunnelId] = stats.funnels[mainFunnelId].map((e: { genericUrl: any; }) => {
        if (e.genericUrl === defaultFunnel.genericUrl) {
          return obj;
        }
        return e;
      });
    }
    stats.funnels[mainFunnelId].sort((a: { step: number; }, b: { step: number; }) => a.step - b.step);
    await this.saveUserStats(stats);
  }

  getComponent(url: any = '') {
    if (!url) return;
    const component: any = deepLinkConfig.links.find((e) => e.segment === url);
    if (!component) return;
    return component;
  }

  async calculateNextPage(defaultFunnel: any = '', variants: any = '', mainFunnelId = '') {
    if (!defaultFunnel.splitTestEnabled) {
      let defVariant = variants.find((e: { default: any; }) => e.default);
      if (!defVariant) return;
      return {
        url: defVariant.url,
        contentId: defVariant.pageContent,
        variantId: defVariant.sfid,
      };
    }
    const isPageViewed = await this.checkIfPageViewed(defaultFunnel, mainFunnelId);
    if (isPageViewed) {
      const { url, variantId } = isPageViewed;
      const currentVariant = variants.find((e: { sfid: any; }) => e.sfid === variantId);
      if (currentVariant) {
        return {
          url: url,
          contentId: currentVariant.pageContent,
          variantId: currentVariant.sfid,
        };
      }
    }
    const calcObj = this.getWithProbability(variants);
    if (!calcObj) return {
      url: null,
      contentId: null,
      variantId: null,
    };
    return {
      url: calcObj.url,
      contentId: calcObj.pageContent,
      variantId: calcObj.sfid,
    };
  }

  async checkIfPageViewed(defaultFunnel: any = '', mainFunnelId: any = '') {
    if (!defaultFunnel || !mainFunnelId || !defaultFunnel.variants) return;
    const stats = await this.getUserStats();
    if (!stats || !stats.funnels) return;
    const mainFunnel = stats.funnels[mainFunnelId];
    if (!mainFunnel || !mainFunnel.length || mainFunnel.length === 0) return;
    const savedFunnel = mainFunnel.find((e: { genericUrl: any; }) => e.genericUrl === defaultFunnel.genericUrl);
    if (!savedFunnel) return;
    if (defaultFunnel.splitTestEnabled) {
      if (defaultFunnel.splitTesting[0] && defaultFunnel.splitTesting[0].sfid != savedFunnel.spId) {
        return;
      }
    }
    const component = this.getComponent(savedFunnel.url);
    if (!component) return;
    const ifExistInConfiguration = defaultFunnel.variants.find((e: { url: any; }) => e.url === savedFunnel.url);
    if (!ifExistInConfiguration) return;
    return {
      url: savedFunnel.url,
      variantId: savedFunnel.variantId,
    };
  }

  shuffleArray(arr: any[]) {
    const newArr = [...arr];
    for (let i = newArr.length - 1; i > 0; i--) {
      const rand = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr;
  }

  getWithProbability(array = []) {
    if (array.length === 0) return;
    if (array.length === 1) return array[0];
    let tmpArr: any[] = [];
    array.forEach((e:any) => {
      if (e.percentage) tmpArr.push(...Array(Math.round(+e.percentage)).fill(e));
    });
    tmpArr = this.shuffleArray(tmpArr);
    return tmpArr[Math.floor(Math.random() * (tmpArr.length - 1))];
  }

  async getOrderFormByCurrentUrl() {
    if (!FUNNEL_CONFS) return;
    const currentUrl = this.getCurrentUrl();
    if (!currentUrl) return;
    const mainFunnel = this.getMainFunnelByCurrentUrl();
    if (!mainFunnel || !mainFunnel.orderForm) return;
    return mainFunnel.orderForm;
  }

  async sendVideoStats(videoPercentage:any) {
    return;
  }

  async sendCustomStats(customEventType: any, customPayLoad: any) {
    const customPayLoad1:any = {};
    const customPayLoad2:any = {};

    if (customPayLoad) {
      const keysCP = Object.keys(customPayLoad);

      keysCP.forEach((e) => {
        if (Object.keys(customPayLoad1).length < keysCP.length / 2) {
          customPayLoad1[e] = customPayLoad[e];
        } else {
          customPayLoad2[e] = customPayLoad[e];
        }
      });
    }

    let funnelInfo = this.getCurrentFunnelInfo();
    if(this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origmainFunnelIdOrig' }) !='') {
      funnelInfo = this.getMainFunnelByUID();
      let currentUrl = null;
      const mainFunnel = this.getMainFunnelBySFID(this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origmainFunnelIdOrig' }));
      let defaultFunnel1 = null;
      try {
        defaultFunnel1 = (window as any)['funnel_confs']['funnels'][this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origmainFunnelIdOrig' })].defaultFunnels[0];
        if(defaultFunnel1) currentUrl = defaultFunnel1.name;
      } catch (e) {
        currentUrl = '';
      }
      const defaultFunnel = defaultFunnel1;
      const splitTestingId:any = this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origsplitTestingFunnelIdOrig' });
      funnelInfo = { defaultFunnel, mainFunnel, currentUrl, splitTestingId }
    }
    if (!funnelInfo) {
      console.log('[GH] missing funnelInfo for stats ');
      return;
    }
    const { defaultFunnel, mainFunnel, currentUrl, splitTestingId } = funnelInfo;
    if (!defaultFunnel || !mainFunnel || !currentUrl) {
      if(!defaultFunnel) console.log('[GH] missing defaultFunnel for stats ');
      if(!mainFunnel) console.log('[GH] missing mainFunnel for stats ');
      if(!currentUrl) console.log('[GH] missing currentUrl for stats ');
      return;
    }

      this.sendStatsToAPI({
        defaultFunnel: defaultFunnel,
        mainFunnelID: mainFunnel.sfid,
        url: currentUrl as any,
        splitTestingPageId: splitTestingId,
        customPayLoad1: JSON.stringify(customPayLoad1) || null as any,
        customPayLoad2: JSON.stringify(customPayLoad2) || null as any,
        eventType: customEventType || null,
      });
  

  }

  async sendStatsToAPI({
    component = null,
    orderForm = null,
    type = null,
    defaultFunnel = null,
    mainFunnelID = null,
    url = null,
    uid = null,
    splitTestingFunnelId = null,
    splitTestingPageId = null,
    videoPercentage = null,
    customPayLoad1 = null,
    customPayLoad2 = null,
    eventType = null,
  } = {}) {
    const sessionIdGenerated = this.getSessionIDGenerated();

    try { // [TOUC-4669]
      if (!window.sessionStorage.getItem("fecnt")) {
        window.sessionStorage.setItem("fecnt", "0");
      }
      window.sessionStorage.setItem("fecnt", (parseInt((window as any).sessionStorage.getItem("fecnt")) + 1).toString());
      if ((parseInt((window as any).sessionStorage.getItem("fecnt")) > 300)) {
        console.log("[GH] detected 300 events in session already ");
        return;
      }
    } catch (e) {
      if (!(window as any)['fecnt']) {
        (window as any)['fecnt'] = 0;
      }
      (window as any)['fecnt'] = ((window as any)['fecnt'] || 0) + 1;
      if ((parseInt((window as any)['fecnt']) > 300)) {
        console.log("[GH] detected 300 events on window already ");
        return;
      }
    }


    if (!defaultFunnel || !mainFunnelID || !url) {
      if(!defaultFunnel) console.log("[GH] returning from missing defaultFunnel ", defaultFunnel);
      if(!mainFunnelID) console.log("[GH] returning from missing mainFunnelID ", mainFunnelID);
      if(!url) console.log("[GH] returning from missing url ", url);
      return;
    }
    let funnelId = '';
    const { variants } = defaultFunnel as any;
    if (variants) {
      const currentVariant = variants.find((e: { url: any; }) => e.url === url);
      if (currentVariant) funnelId = currentVariant.sfid;
    }
    let destinationId = this.getDestinationIdValue() ? this.getDestinationIdValue() : null;
    if(typeof (window as any)['sendorigdsidOrig'] == 'boolean' && (window as any)['sendorigdsidOrig'] ) {
      destinationId = this.urls.getParameterFromUrl({ url: window.location.search, parameter: 'origdsidOrig' });
      delete (window as any)['sendorigdsidOrig'];
    }
    const stFunnelId = this.getSPFId();
    const tmp = {
      funnelSTPId: funnelId,
      url: url,
      mainFunnelId: mainFunnelID,
      sessionId: SESSION_ID,
      splitTestingFunnelId: stFunnelId,
      splitTestingPageId: splitTestingPageId,
      videoPercentage: videoPercentage,
      customPayLoad1: customPayLoad1,
      customPayLoad2: customPayLoad2,
      eventType: eventType,
      destinationId: destinationId,
    };

    if (this.urls.getParameterFromUrl({ url: document.location.href, parameter: "preload" }) == 'true'
      && this.urls.getParameterFromUrl({ url: document.location.href, parameter: "preloadorigin" }) != '' ) {
      try {
        tmp.sessionId =this.urls.getParameterFromUrl({ url: document.location.href, parameter: "sessionid" });
        tmp.splitTestingFunnelId = this.urls.getParameterFromUrl({ url: document.location.href, parameter: "origsplitTestingFunnelIdOrig" });
        //console.log('[GH] Experimental preload event2 '+tmp.eventType, tmp);
        var eventClip = document.createElement('script');
        if (typeof (window as any)['btoa'] == 'function')
          eventClip.src = '/proxy/funnel/stats/save/?event=' + (window as any)['btoa'](JSON.stringify(tmp));
        else
          eventClip.src = '/proxy/funnel/stats/save/?event=' + encodeURIComponent(JSON.stringify(tmp));
        eventClip.type = 'text/javascript';
        document.body.appendChild(eventClip);
        return new Promise(resolve => {
          resolve(null);
        });
      } catch (e) {
        console.log('[GH] Experimental preload error', e);
      }
    }
    console.log('%c[GH] sendStatsToAPI ' + eventType, 'color:lightblue;', tmp);

    return this.http
      .post(`${API_URL}funnel/stats/save`, tmp)
      .toPromise()
      .catch((e) => { });
  }

  async getNextPageUpsell(button: boolean = false, type: string = '', isPageFail = false) {
    if (!FUNNEL_CONFS) return;

    let currentDefaultFunnelId = '';
    let currentDefaultFunnel: any;
    let isSearchCurrentUrl = false;
    let mainFunnel = this.getMainFunnelByUID();
    let defaultFunnels: any;
    if (!mainFunnel || !mainFunnel.defaultFunnels) isSearchCurrentUrl = true;
    else {
      defaultFunnels = mainFunnel.defaultFunnels;
      if (!defaultFunnels) return;
      defaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
      if (this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' })) {
        if (!defaultFunnels) return;
        const currentStep = Number(this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' }));
        currentDefaultFunnel = defaultFunnels.find((e: { step: number; }, i: string) => {
          currentDefaultFunnelId = i;
          return e.step === currentStep;
        });
      }
      if (!currentDefaultFunnel || !currentDefaultFunnel.step) isSearchCurrentUrl = true;
    }
    if (isSearchCurrentUrl) {
      const currentUrl = this.getCurrentUrl();
      if (!currentUrl) return;
      mainFunnel = this.getMainFunnelByCurrentUrl();
      if (!mainFunnel || !mainFunnel.defaultFunnels) return;
      defaultFunnels = mainFunnel.defaultFunnels;
      if (!defaultFunnels) return;
      defaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
      if (!defaultFunnels) return;
      currentDefaultFunnelId = '';
      currentDefaultFunnel = defaultFunnels.find((e: { url: string; variants: any[]; }, i: string) => {
        currentDefaultFunnelId = i;
        return e.url === currentUrl || (e.variants && e.variants.find((e1: { url: string; }) => e1.url === currentUrl));
      });
    }

    let url = '';
    let contentId = '';
    let variantId = '';
    let nextDefaultFunnel: any = '';
    if (!currentDefaultFunnel || !currentDefaultFunnel.step) return;
    if (isPageFail) return await this.getPageFail(defaultFunnels);
    if (currentDefaultFunnel.overrideYes && button) {
      const arr = await this.getFunnelBySfid(defaultFunnels, currentDefaultFunnel.overrideYes);
      if (arr && arr.length > 0) {
        [nextDefaultFunnel] = arr;
        url = arr[1].url;
        contentId = arr[1].pageContent;
        variantId = arr[1].sfid;
      }
    } else if (currentDefaultFunnel.overrideNo && !button) {
      const arr = await this.getFunnelBySfid(defaultFunnels, currentDefaultFunnel.overrideNo);
      if (arr && arr.length > 0) {
        [nextDefaultFunnel] = arr;
        url = arr[1].url;
        contentId = arr[1].pageContent;
        variantId = arr[1].sfid;
      }
    }
    if (!url || !nextDefaultFunnel) {
      const arr = defaultFunnels.slice(currentDefaultFunnelId + 1);
      nextDefaultFunnel =
        button || type === 'Downsell'
          ? await this.getNextUpsellFunnel(arr)
          : await this.getNextDownsellFunnel(arr);
      if (!nextDefaultFunnel) {
        nextDefaultFunnel = defaultFunnels
          .slice(currentDefaultFunnelId)
          .find(
            (e: { type: string; }) =>
              e.type && e.type.toLowerCase() !== 'upsell' && e.type.toLowerCase() !== 'downsell',
          );
      }
      if (!nextDefaultFunnel || !nextDefaultFunnel.variants) return;
      const nextPage = await this.calculateNextPage(
        nextDefaultFunnel,
        nextDefaultFunnel.variants,
        mainFunnel.sfid,
      );
      if (nextPage) {
        ({ url, contentId, variantId } = nextPage);
      }
    }
    const component = await this.getComponent(url);
    if (!component) return;
    this.saveFunnelHistory(mainFunnel.sfid, nextDefaultFunnel, url, variantId);
    let splitTestingId = null;
    mainFunnel.defaultFunnels.forEach((e: { splitTesting: string | any[]; }) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });

    const params = {
      component: component,
      orderForm: nextDefaultFunnel.upsell || nextDefaultFunnel.downsell || mainFunnel.orderForm,
      type: nextDefaultFunnel.type,
      defaultFunnel: nextDefaultFunnel,
      mainFunnelID: mainFunnel.sfid,
      url: url,
      splitTestingPageId: splitTestingId,
      eventType: 'Page View',
    };
    this.sendStatsToAPI(params as any);
    this.setContentIdToQuery(contentId);
    if (params.defaultFunnel && params.defaultFunnel.step) this.setNextStep(params.defaultFunnel.step);
    return params;
  }

  async getNextUpsellFunnel(defaultFunnels = []) {
    if (defaultFunnels.length === 0) return;
    const upsellFunnel = defaultFunnels.find((e:any) => e.type && e.type.toLowerCase() === 'upsell');
    if (!upsellFunnel) return;
    return upsellFunnel;
  }

  async getNextDownsellFunnel(defaultFunnels = []) {
    if (defaultFunnels.length === 0) return;
    const downsellFunnel = defaultFunnels[0] as any;
    if (
      !downsellFunnel ||
      (downsellFunnel.type && downsellFunnel.type.toLowerCase() !== 'downsell')
    ) {
      return await this.getNextUpsellFunnel(defaultFunnels);
    }
    return downsellFunnel;
  }

  async getPageFailFunnel(defaultFunnels = []) {
    if (defaultFunnels.length === 0) return;
    const currentUrl = this.getCurrentUrl();
    const currentDefaultFunnel = defaultFunnels.find(
      (e:any) => e && e.variants && e.variants.find((e1: { url: string; }) => e1.url === currentUrl),
    ) as any;
    if (!currentDefaultFunnel) return;
    if (currentDefaultFunnel.nextPageFail) {
      const nextPageFailFunnel = defaultFunnels.find(
        (e:any) => e.sfid === currentDefaultFunnel.nextPageFail,
      );
      if (nextPageFailFunnel) return nextPageFailFunnel;
    }
    const lastFunnel = defaultFunnels[defaultFunnels.length - 1];
    if (!lastFunnel) return;
    return lastFunnel;
  }

  async getPageFail(defaultFunnels = []) {
    if (defaultFunnels.length === 0) return;
    const pageFailFunnel = await this.getPageFailFunnel(defaultFunnels) as any;
    if (!pageFailFunnel || !pageFailFunnel.variants) return;
    const { variants } = pageFailFunnel;
    const variant = pageFailFunnel.splitTestEnabled
      ? this.getWithProbability(variants)
      : variants.find((e: { default: any; }) => e.default);
    if (!variant) return;
    const component = this.getComponent(variant.url);
    if (!component) return;
    return {
      component,
    };
  }

  async getFunnelBySfid(funnels = [], sfid = '') {
    if (funnels.length === 0 || !sfid) return;
    const defaultFunnel = funnels.find(
      (e:any) => e.variants && e.variants.find((e1: { sfid: string; }) => e1.sfid === sfid),
    ) as any;
    if (!defaultFunnel) return;
    const variantFunnel = defaultFunnel.variants.find((e: { sfid: string; }) => e.sfid === sfid);
    if (!variantFunnel) return;
    return [defaultFunnel, variantFunnel];
  }

  getSessionId() {
    return SESSION_ID;
  }

  getDestinationId() {
    const destinationid = this.urls.getParameterFromUrl({
      url: window.location.href,
      parameter: DESTINATION_ID,
    });
    if (destinationid) {
      return destinationid;
    } else {
      return null;
    }
  }

  getSessionIDGenerated() {
    return SESSION_ID_GENERATED;
  }

  setSessionIDGenerated(value: boolean = false) {
    if (typeof value !== 'boolean') {
      return;
    }
    SESSION_ID_GENERATED = value;
  }

  setSessionId({ sessionId = '', isCreated = false }) {
    SESSION_ID = sessionId;
    this.setSessionIDGenerated(isCreated);
    let newQueryParam = window.location.search;
    newQueryParam = this.urls.addParamToQuery(newQueryParam, 'sessionid', sessionId);
    this.urls.changeState(newQueryParam);
  }

  setNextStep(nextStep: string) {
    if ((window as any)['stepGateClosed'] == true) { // prevent multiple step changes when handling broken configuraions (that auto-repair by finding a replacement offer)
      console.log('step gate closed');
      return;
    }
    (window as any)['stepGateClosed'] = true;
    // moved this to the setRootExt function
  }

  setuId(uid: string) {
    UID = uid;
    console.log('[GH] setting uid', UID);
    let newQueryParam = this.urls.getQueryParams();
    newQueryParam = this.urls.addParamToQuery(newQueryParam, 'uid', UID);
    this.urls.setQueryParams(newQueryParam);
  }

  getDestinationIdValue() {
    return DESTINATION_ID_VALUE;
  }

  setDestinationIdValue(destinationId: string = '') {
    if (!destinationId) return;
    DESTINATION_ID_VALUE = destinationId;
    let newQueryParam = this.urls.getQueryParams();
    newQueryParam = this.urls.addParamToQuery(newQueryParam, DESTINATION_ID, destinationId);
    this.urls.setQueryParams(newQueryParam);
  }

  async getBumpOffers() {
    const currentUrl = this.getCurrentUrl();
    if (!currentUrl || !FUNNEL_CONFS) return [];
    const mainFunnel = this.getMainFunnelByCurrentUrl();
    if (mainFunnel.bumpOffers && mainFunnel.bumpOffers.length) return mainFunnel.bumpOffers;
    return [];
  }

  getMainFunnelByGenericUrl(genericUrl: string) {
    if (!genericUrl) return;
    return Object.keys(FUNNEL_CONFS)
      .map((key) => {
        return FUNNEL_CONFS[key];
      })
      .find((e) => e.defaultFunnels && e.defaultFunnels.find((e: { genericUrl: any; }) => e.genericUrl === genericUrl));
  }

  getMainFunnelByCurrentUrl() {
    if (!FUNNEL_CONFS) return;
    const currentUrl = this.getCurrentUrl();
    const mainFunnel = this.getMainFunnelByUID();
    if (mainFunnel) return mainFunnel;
    return Object.keys(FUNNEL_CONFS)
      .map((key) => {
        return FUNNEL_CONFS[key];
      })
      .find(
        (e) =>
          e.defaultFunnels &&
          e.defaultFunnels.find(
            (e1: { url: string; variants: any[]; }) =>
              e1.url === currentUrl ||
              (e1.variants && e1.variants.find((e2: { url: string; }) => e2.url === currentUrl)),
          ),
      );
  }

  isUIDExist() {
    return ~window.location.search.indexOf(UNIQUE_IDENTIFIER);
  }

  isDSIDExist() {
    return ~window.location.search.indexOf(DESTINATION_ID);
  }

  getSPFId() {
    let destinationId = this.getDestinationIdValue();
    let destinationSplit = DESTINATIONS.find((e: { sfid: string; splitTestEnabled: any; }) => {
      return e.sfid === destinationId && e.splitTestEnabled;
    });
    if (
      destinationSplit &&
      destinationSplit.splitTesting &&
      destinationSplit.splitTesting[0] &&
      destinationSplit.splitTesting[0].sfid
    ) {
      return destinationSplit.splitTesting[0].sfid;
    }
    return null;
  }

  getSPPId() {
    if (!FUNNEL_CONFS) return;
    const currentUrl = this.getCurrentUrl();
    if (!currentUrl) return;
    const mainFunnel = this.getMainFunnelByCurrentUrl();
    if (!mainFunnel || !mainFunnel.defaultFunnels) return;
    let { defaultFunnels } = mainFunnel;
    if (!defaultFunnels) return;
    let splitTestingId = '';
    mainFunnel.defaultFunnels.forEach((e: { splitTesting: string | any[]; }) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });
    return splitTestingId;
  }

  getMainFunnelByUID() {
    if (this.isUIDExist()) {
      const uniqueIdentifier = this.urls.getParameterFromUrl({
        url: window.location.href,
        parameter: UNIQUE_IDENTIFIER,
      });
      if (uniqueIdentifier) {
        const mainFunnel = Object.keys(FUNNEL_CONFS)
          .map((key) => {
            return FUNNEL_CONFS[key];
          })
          .find((e) => e.uniqueIdentifier === uniqueIdentifier);
        if (mainFunnel) return mainFunnel;
      }
    }
  }
  logMainFunnelByUID() {
    try {
      if (this.isUIDExist() && localStorage.getItem('debugMode') == 'true') {
        const uniqueIdentifier = this.urls.getParameterFromUrl({
          url: window.location.href,
          parameter: UNIQUE_IDENTIFIER,
        });
        const dsid = this.urls.getParameterFromUrl({
          url: window.location.href,
          parameter: "dsid",
        });
        const cstp = this.urls.getParameterFromUrl({
          url: window.location.href,
          parameter: "step",
        });
        const sid = this.urls.getParameterFromUrl({
          url: window.location.href,
          parameter: "sessionid",
        });
        if (uniqueIdentifier) {
          const mainFunnel = Object.keys(FUNNEL_CONFS)
            .map((key) => {
              return FUNNEL_CONFS[key];
            })
            .find((e) => e.uniqueIdentifier === uniqueIdentifier);
          console.log("FUNNEL_CONFS FOR UID " + uniqueIdentifier, mainFunnel);
          (window as any)['funnelConfsForUUID'] = mainFunnel;
          let stp = 1;
          let foundDynamicId = false;
          if (mainFunnel && mainFunnel.defaultFunnels) {
            mainFunnel.defaultFunnels.forEach((item: { step: any; variants: any[]; }) => {
              let itm = item.step;
              item.variants.forEach((variant: { sfid: string; url: string; }) => {
                let srch = document.location.search;
                if (cstp)
                  srch = srch.replace("step=" + cstp, ("step=" + itm)); // change the step 
                else srch += "&step=" + itm;
                if (dsid) srch = srch.replace(dsid, variant.sfid);
                else srch += "&dsid=" + variant.sfid;
                srch = srch.replace("&sessionid=" + sid, ""); // remove the session id
                console.log(window.location.origin + '/' + variant.url + srch + "&pseudoState=true");
                stp++;
              });
            });
          }
        }
      } 
      if(localStorage.getItem('debugMode') == 'true') {
        console.log("ALL ACTUAL PAGES - not all these links will work");
        let srch = document.location.search;
        srch = srch.replace("step=1", "step=2"); // change the step ;
        for (var prop in deepLinkConfig.links) {
          console.log(window.location.origin + '/' + deepLinkConfig.links[prop].segment + srch + "&pseudoState=true&debugMode=true");
        }

      }
    } catch (e) {
      console.log("Error logging UID data " + e);
    }
  }

  getOrderFormByGenericURL(genericUrl: string) {
    if (!genericUrl) return;
    const mainFunnel = this.getMainFunnelByGenericUrl(genericUrl);
    if (!mainFunnel) return;
    if (mainFunnel.orderForm) return mainFunnel.orderForm;
  }

  getMainFunnelBySFID(sfid: string = '') {
    if (!sfid || !FUNNEL_CONFS) return;
    return FUNNEL_CONFS[sfid];
  }

  getMainFunnelsByDestinationId(sfid: string = '') {
    if (!sfid || !FUNNEL_CONFS) return;

    let mainFunnels: any[] = [];
    Object.keys(FUNNEL_CONFS)
      .map((key) => {
        return FUNNEL_CONFS[key];
      })
      .forEach((e) => {
        if (e.destinationId === sfid) mainFunnels.push(e);
      });

    return mainFunnels;
  }

  getUpsellByCurrentUrl() {
    const currentUrl = this.getCurrentUrl();
    const mainFunnel = this.getMainFunnelByCurrentUrl();
    if (!mainFunnel || !mainFunnel.defaultFunnels) return;
    const currentDefaultFunnel =
      mainFunnel.defaultFunnels.find((e: { variants: any[]; }) => e.variants.find((el: { url: string; }) => el.url === currentUrl));
    if (!currentDefaultFunnel || !currentDefaultFunnel.upsell) return;
    return [currentDefaultFunnel.upsell];
  }

  getCurrentFunnelInfo() {
    if (!FUNNEL_CONFS) return;
    const currentUrl = this.getCurrentUrl();
    if (!currentUrl) return;
    const mainFunnel = this.getMainFunnelByCurrentUrl();
    if (!mainFunnel || !mainFunnel.defaultFunnels) return;
    let { defaultFunnels } = mainFunnel;
    if (!defaultFunnels) return;
    let mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
    if (!mDefaultFunnels) return;
    const currentStep = Number(
      this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' }),
    );
    const defaultFunnel = mDefaultFunnels.find(
      (e: { url: string; variants: any[]; step: number; }) => (
        e.url === currentUrl
        || (e.variants && e.variants.find((e1: { url: string; }) => e1.url === currentUrl))
      ) && (
          currentStep ? e.step === currentStep : true
        )
    );
    if (!defaultFunnel) return;
    let splitTestingId = null;
    mainFunnel.defaultFunnels.forEach((e: { splitTesting: string | any[]; }) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });
    return {
      defaultFunnel,
      mainFunnel,
      currentUrl,
      splitTestingId,
    };
  }

  getStats() {
    const funnelInfo = this.getCurrentFunnelInfo();
    if (!funnelInfo) return;
    const { defaultFunnel, mainFunnel, currentUrl } = funnelInfo;
    if (!defaultFunnel || !mainFunnel || !currentUrl) return;
    let funnelSTPId = '';
    const { variants } = defaultFunnel;
    if (variants) {
      const currentVariant = variants.find((e:any) => e.url === currentUrl);
      if (currentVariant) funnelSTPId = currentVariant.sfid;
    }
    return {
      funnelSTPId,
      url: this.getCurrentUrl(),
      mainFunnelId: mainFunnel.sfid,
      sessionId: this.getSessionId(),
      splitTestingFunnelId: this.getSPFId(),
      splitTestingPageId: this.getSPPId(),
      destinationId: this.getDestinationId(),
    };
  }

  getContentId() {
    return CONTENT_ID_VALUE;
  }

  setContentId(id = '') {
    CONTENT_ID_VALUE = id;
  }

  async setContentIdToQuery(id = '') {
    // no need to run any of this as we don't use the content id from the old TCR CMS
    return;
/*     let newQueryParams = this.urls.removeParamFromQuery(this.urls.getQueryParams(), CONTENT_ID) as any;
    if (id) {
      newQueryParams = this.urls.addParamToQuery(newQueryParams, CONTENT_ID, id);
    }
    this.setContentId(id);
    await this.urls.setQueryParams(newQueryParams); */
  }

  async getAndApplyContentPage(context: {} | undefined) {
    const contentPage = await this.getContentPage() as any;
    this.parseContentPage(contentPage, context);
    return;
  }

  async getContentPage() {
    const contentId = this.getContentId();
    if (contentId) {
      return await this.contentProvider.getPageContentBySFId(contentId).toPromise();
    }
    return null;
  }

  parseContentPage(contentPage:any = {}, context:any = {}) {
    if (!contentPage || !context) return;
    Object.keys(contentPage).forEach((e) => {
      context[e] = contentPage[e];
    });
  }
  //Method for get page from current funnel settings by chosen step
  //Method get step in format 'first', 'last' or step number as integer.
  async getPageByStep(step: any = 'first') {
    let params: any = '';
    let orderForm: String;
    let parDefaultFunnel: any = '';
    let parType: String;
    let parMainFunnelID = '';
    let parUid: String;
    let mainFunnel: any = '';
    if (!FUNNEL_CONFS) return;
    let isSearchCurrentUrl = false;
    let defaultFunnels;
    let mDefaultFunnels;
    mainFunnel = this.getMainFunnelByUID();
    console.log('[GH] getPageByStep mainFunnel', mainFunnel);
    if (!mainFunnel || !mainFunnel.defaultFunnels) isSearchCurrentUrl = true;
    if (!isSearchCurrentUrl) {
      defaultFunnels = mainFunnel.defaultFunnels;
      if (!defaultFunnels) return;
      mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
      if (!mDefaultFunnels) return;
    }

    if (isSearchCurrentUrl) {
      const currentUrl = this.getCurrentUrl();
      if (!currentUrl) return;
      mainFunnel = this.getMainFunnelByCurrentUrl();
      if (!mainFunnel || !mainFunnel.defaultFunnels) return;
      defaultFunnels = mainFunnel.defaultFunnels;
      if (!defaultFunnels) return;
      mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
      if (!mDefaultFunnels) return;
    }

    if (step === 'first') {
      parDefaultFunnel = mDefaultFunnels[0];
    } else if (step === 'last') {
      parDefaultFunnel = mDefaultFunnels[mDefaultFunnels.length - 1];
    } else {
      const filtredDefaultFunnels = mDefaultFunnels.filter((e: { step: any; }) => e.step == step);
      parDefaultFunnel = filtredDefaultFunnels ? filtredDefaultFunnels[0] : '';
    }
    if (!parDefaultFunnel) return;

    orderForm = parDefaultFunnel.upsell || parDefaultFunnel.downsell || mainFunnel.orderForm;
    parType = parDefaultFunnel.type;
    parMainFunnelID = mainFunnel.sfid;
    parUid = mainFunnel.uniqueIdentifier;

    const nextPage = await this.calculateNextPage(parDefaultFunnel, parDefaultFunnel.variants, parMainFunnelID);
    if (!nextPage) return;

    let { url, contentId, variantId } = nextPage;
    this.setContentIdToQuery(contentId);
    const component = await this.getComponent(url);
    console.log('component', component);
    if (!component) return;

    this.saveFunnelHistory(parMainFunnelID, parDefaultFunnel, url, variantId);

    let splitTestingId = null;
    mainFunnel.defaultFunnels.forEach((e: { splitTesting: string | any[]; }) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });

    params = {
      component: component,
      orderForm: orderForm,
      type: parType,
      defaultFunnel: parDefaultFunnel,
      mainFunnelID: parMainFunnelID,
      url: url,
      uid: parUid,
      splitTestingPageId: splitTestingId,
      eventType: 'Page View',
    };
    if (!params) return;

    this.sendStatsToAPI(params);
    if (!this.urls.getParameterFromUrl({ url: window.location.search, parameter: UNIQUE_IDENTIFIER })) {
      this.setuId(params.uid);
    }
    if(step != 'first') this.setNextStep(params.defaultFunnel.step);

    return params;
  }
}