import { Component, ViewChild } from '@angular/core';
/*
import {
  Content,
  IonicPage,
  LoadingController,
  NavParams,
  ToastController
} from 'ionic-angular';
*/
import { ToastController } from 'src/app/components/ionic-replacements/ToastController';
import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { TwoStepOrderFormBase } from '../two-step-order-form/two-step-order-form-base';
import { DomSanitizer } from '@angular/platform-browser';
import { OrderProviderExtender } from 'src/app/providers/order/order-extender';
import { TouchcrApiOrderformProvider } from "src/app/providers/touchcr-api-orderform/touchcr-api-orderform";

import { CartProvider } from 'src/app/providers/cart/cart';
import { TouchcrApiGeneralProvider } from 'src/app/providers/touchcr-api-general/touchcr-api-general';
import { TouchcrApiOrderProvider } from 'src/app/providers/touchcr-api-order/touchcr-api-order';
import { TouchcrApiRemoteServicesProvider } from 'src/app/providers/touchcr-api-remoteservices';
import { EventStreamerProvider } from 'src/app/providers/event-stream';
import { TouchcrApiLeadProvider } from 'src/app/providers/touchcr-api-lead/touchcr-api-lead';
import { TouchcrApiCouponProvider } from 'src/app/providers/touchcr-api-coupon/touchcr-api-coupon';
import { OrderDetailsProvider } from 'src/app/providers/order-details/order-details';
import { PaypalPaymentProvider } from 'src/app/providers/paypal/paypal';

import { NgZone } from '@angular/core';
import { TouchcrApiShippingProvider } from 'src/app/providers/TouchcrApiShippingProvider';
import { CalculateProvider } from 'src/app/providers/calculate/calculate';
import { UrlsProvider } from 'src/app/providers/urls';
import { OrderProvider } from 'src/app/providers/order/order';
import { StateProvider } from 'src/app/providers/state';
import { GeneralProvider } from 'src/app/providers/general';
import { FunnelSettingsProvider } from 'src/app/providers/funnel-settings';
import { NgForm } from "@angular/forms";
import { Address } from "src/app/models/address";
import { Country } from "src/app/models/country";
import { User } from "src/app/models/user";
import { SpinnerProvider } from "src/app/providers/spinner/spinner";
import { Storage } from '@ionic/storage';

const ROUTE = (window as any)["process_env"].ROUTE;
const TAX_ERROR_MESSAGE = "Sorry, but we couldn't calculate the tax. Your tax amount is $0.";
const ADDRESS_ERROR_MESSAGE = "Entered address is not valid.";
const COUNTRY_ERROR = "Sorry, but we don't ship to this address";
const SHIP_FROM_COUNTRY = (window as any)['process_env'].SHIP_FROM_COUNTRY;
import { Router } from '@angular/router';


@Component({
  selector: "paypal-nvp",
  templateUrl: "paypal-nvp.html"
})
export class PayPalNVP extends TwoStepOrderFormBase {
 // @ViewChild(Content) mContent: Content;

  firstName: string = "";
  lastName: string = "";
  city: string = "";
  shippingCountryCode: string = "";
  shippingStateCode: string = "";
  shippingCity: string = "";
  shippingStreet: string = "";
  shippingPostalCode: string = "";
  differentAddressStatesList: Array<any> = [];
  address: Address = {} as Address;
  countryChosen: Country = {} as Country;
  differentAddressCountryChosen: Country = {} as Country;
  phone: string = "";
  emailToConfirm: string = "";
  lastLeadCreated: string = "";
  emailPattern: string = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";
  textPattern = /^[a-zA-Z][a-zA-Z .,\-]*$/;
  numberPattern = /^[\d]+$/;
  mixedPattern = /^[a-zA-Z\d][a-zA-Z\d .,\-]*$/;
  override totalAmount: number = 0;
  cardType: string = "";
  cardName: string = "";
  shippingAddress: Address = {} as Address;
  shipToName: string = "";
  autocomplete: boolean = (window as any)["process_env"].AUTOCOMPLETE === true;
  warningImg: string =
    "/assets/img/icon-warning.gif";
  warnPointerImg: string =
    "/assets/img/icon-warning-pointer.gif";
  headerBanner: string =
    "https://images.thenewgutfix.com/Gundry_cartheader.png";
  breadcrumbArrow: string = "https://images.thenewgutfix.com/icon-arrow.gif";
  padlockImg: string = "https://images.thenewgutfix.com/icon-padlock.gif";
  comodoLogo: string =
    "";
  creditCardImg: string =
    "https://images.thenewgutfix.com/payment-tab-cc-on.gif";

  submitted = false;
  couponValidation: boolean = true;
  isCountryInvalid: boolean = true;
  isLeadCreated: boolean = false;
  isTaxCalculated: boolean = false;

  cardBrands: string[] = ["Visa", "Mastercard", "Discover", "Amex"];
  isSelectedCB2: boolean = false;
  cvvMinDigits: number = 3;
  cvvMaxDigits: number = 4;
  cardNumberMinLength: number = 15;
  cardNumberMaxLength: number = 16;

  token: string = '';
  payerId: string = '';
  isPayPalBillingAgreementApproved: boolean = false;
  choosedShippingRate: number = 0;
  standardShipping: number = 0;
  isPayPalSubscription: boolean = false;

  // Transaction Fee // must be calculated in the end (after shipping and taxes)
  override grandTotalPrice: number = 0;
  subscriptionSecondPaymentSubtotal: number = 0;  // without transaction fee
  override orderCardNicn: any = '';
  oldBillingZipCode: any = '';
  oldBillingConutry: any = '';

  errorMessage: string = '';

  constructor(
    public override navCtrl: NavControllerExt,
    public override sanitizer: DomSanitizer,
    public override tcrApiOrderform: TouchcrApiOrderformProvider,
    public override cart: CartProvider,
    public override tcrApi: TouchcrApiGeneralProvider,
    public override tcrApiOrder: TouchcrApiOrderProvider,
    public override tcrApiRemoteServices: TouchcrApiRemoteServicesProvider,
    public override toastCtrl: ToastController,
    public override eventStreamer: EventStreamerProvider,
    public override tcrApiLead: TouchcrApiLeadProvider,
    public override tcrApiCoupon: TouchcrApiCouponProvider,
    public override orderDetails: OrderDetailsProvider,
    public override paypalPayment: PaypalPaymentProvider,
    public override ngZone: NgZone,
    public override shippingProvider: TouchcrApiShippingProvider,
    public override calculateHelper: CalculateProvider,
    public override urlsHelper: UrlsProvider,
    public override orderHelper: OrderProvider,
    public orderHelperExtender: OrderProviderExtender,
    public storeParamsProvider: StateProvider,
    public override general: GeneralProvider,
    public override funnelProvider: FunnelSettingsProvider,
    public override spinner: SpinnerProvider,
    public override router: Router,
    public storage?: Storage,
  ) {
    super(
      navCtrl,
      //navParams,
      sanitizer,
      tcrApiOrderform,
      cart,
      tcrApi,
      //loadingCtrl,
      tcrApiOrder,
      tcrApiRemoteServices,
      toastCtrl,
      eventStreamer,
      tcrApiLead,
      tcrApiCoupon,
      orderDetails,
      paypalPayment,
      ngZone,
      //ApplePayWeb,
      //GooglePayWeb,
      shippingProvider,
      calculateHelper,
      urlsHelper,
      orderHelper,
      storeParamsProvider,
      general,
      funnelProvider,
      spinner,
      router,
    );

    this.storage = new Storage({
      name: '__ghdb'
      });
    this.storage.create();   
  }

  override ngOnInit() {
  //  this.general.enableJSScroll(this.mContent);
  }


  parseCountriesInfo(countries:any) {
    this.countriesList = countries;
    for (let i = 0; i < this.countriesList.length; i++) {
      this.countriesList[i].label = this.decodeLabel(this.countriesList[i].label);

      let states = this.countriesList[i].states;
      for (let key in states) {
        states[key] = this.decodeLabel(states[key]);
      }
    }

    this.countriesList.forEach(country => {
      (this.countries as any)[country.label as any] = country;
    });
  }

  scrollToErrorBox(boxId:any) {
    let errorBox:any = document.getElementById(boxId);
    errorBox.scrollIntoView({ behavior : "smooth" });
  }

  changeStateCB2() {
    console.log('changeStateCB2');
    this.isSelectedCB2 = !this.isSelectedCB2;

    if (this.isSelectedCB2) {
      this.validateAddressAndCalculateTaxes("shipping");
    } else {
      this.validateAddressAndCalculateTaxes("billing");
    }
  }

  validateAddressAndCalculateTaxes(address:any) {
    let taxAddress = {};
    let statesNumber = 0;
    const country = this.address.country ? this.calculateHelper.getCountryISOCode((this.countries as any), this.address.country) :
      this.calculateHelper.getCountryISOCode((this.countries as any), this.guest.country as any);
    this.shippingCost = country === SHIP_FROM_COUNTRY ? (this.funnel.shippingCost  as any)+ this.calculatedShippingCost :
      (this.funnel.internationalShippingCost as any) + this.calculatedShippingCost;
    if (address === "billing") {
      this.checkCountry(true);
      statesNumber = this.statesList.length;
      if (!this.isSelectedCB2) {
        taxAddress = {
          country: this.guest.country,
          state: this.guest.state,
          street: this.guest.street,
          city: this.guest.city,
          postalCode: this.guest.zipPostalCode
        };
      }
    } else if (address === "shipping" && this.isSelectedCB2) {
      this.checkDifferentCountry(true);
      statesNumber = this.differentAddressStatesList.length;
      taxAddress = {
        country: this.shippingAddress.country,
        state: this.shippingAddress.state,
        street: this.shippingAddress.street,
        city: this.shippingAddress.city,
        postalCode: this.shippingAddress.postal
      };
    }

    if (this.validateAddressFields(taxAddress, statesNumber)) {
      this.setAddressForTaxCalculation(taxAddress);
    }
    this.calculateTransactionFee();
  }

  validateAddressFields(address:any, statesNumber:any) {
    return address.country && (address.state || statesNumber === 0)  &&
      this.validateMixedInput(address.city) &&
      this.validateMixedInput(address.postalCode) &&
      this.validateMixedInput(address.street);
  }

  setAddressForTaxCalculation(addressDetails:any) {
    this.address.country = addressDetails.country;
    this.address.state = addressDetails.state;
    this.address.street = addressDetails.street;
    this.address.city = addressDetails.city;
    this.address.postal = addressDetails.postalCode;
    this.calculate(true);
  }

  override onChooseAddress(addressDetails:any) {
    if (addressDetails) {
      this.guest.country = addressDetails.country;
      this.guest.state = addressDetails.state;
      this.guest.street = addressDetails.street;
      this.guest.city = addressDetails.city;
      this.guest.zipPostalCode = addressDetails.postalCode;

      this.checkCountry(false);

      addressDetails.country = this.guest.country;
      addressDetails.state = this.guest.state;
      addressDetails.street = this.guest.street;
      addressDetails.city = this.guest.city;
      addressDetails.postalCode = this.guest.zipPostalCode;

      if (!this.isSelectedCB2 && this.validateAddressFields(addressDetails, this.statesList.length)) {
        this.setAddressForTaxCalculation(addressDetails);
      }
    }

    if (this.autocomplete) {
      this.synchronizeAutocompleteForms("accountForm", addressDetails);
    }
  }

  checkCountry(clearState:any) {
    if (this.countryChosen.label !== this.guest.country) {
      if (this.guest.country && (this.countries as any)[this.guest.country]) {
        this.countryChosen = (this.countries as any)[this.guest.country];
      } else {
        if (!this.guest.country) {
          this.isCountryInvalid = false;
        }

        this.countryChosen = this.getCountryByCode("US");
        this.guest.country = this.countryChosen.label;
        this.guest.state = "";
        this.guest.street = "";
        this.guest.zipPostalCode = "";
        this.guest.city = "";
        this.showCountryErrorToast();
      }

      this.statesList = this.getStates(this.countryChosen);
      if (this.statesList.length === 0 || clearState) {
        this.guest.state = "";
      }
    }
  }

  onChooseDifferentAddress(addressDetails:any) {
    if (addressDetails) {
      this.shippingAddress.country = addressDetails.country;
      this.shippingAddress.state = addressDetails.state;
      this.shippingAddress.street = addressDetails.street;
      this.shippingAddress.city = addressDetails.city;
      this.shippingAddress.postal = addressDetails.postalCode;

      this.checkDifferentCountry(false);

      addressDetails.country = this.shippingAddress.country;
      addressDetails.state = this.shippingAddress.state;
      addressDetails.street = this.shippingAddress.street;
      addressDetails.city = this.shippingAddress.city;
      addressDetails.postalCode = this.shippingAddress.postal;

      if (this.validateAddressFields(addressDetails, this.differentAddressStatesList.length)) {
        this.setAddressForTaxCalculation(addressDetails);
      }
    }

    if (this.autocomplete) {
      this.synchronizeAutocompleteForms("diff-address", addressDetails);
    }
  }

  checkDifferentCountry(clearState:any) {
    if (this.differentAddressCountryChosen.label !== this.shippingAddress.country) {
      if (this.shippingAddress.country && (this.countries as any)[this.shippingAddress.country]) {
        this.differentAddressCountryChosen = (this.countries as any)[this.shippingAddress.country];
      } else {
        if (!this.shippingAddress.country) {
          this.isCountryInvalid = false;
        }

        this.differentAddressCountryChosen = this.getCountryByCode("US");
        (this.shippingAddress.country as any) = this.differentAddressCountryChosen.label;
        this.shippingAddress.state = "";
        this.shippingAddress.street = "";
        this.shippingAddress.postal = "";
        this.shippingAddress.city = "";
        this.showCountryErrorToast();
      }

      this.differentAddressStatesList = this.getStates(this.differentAddressCountryChosen);
      if (this.differentAddressStatesList.length === 0 || clearState) {
        this.shippingAddress.state = "";
      }
    }
  }

  showCountryErrorToast() {
    if (this.isCountryInvalid) {
      this.toastCtrl
        .create({
          message: COUNTRY_ERROR,
          position: "top",
          showCloseButton: true,
          cssClass: "errorToast"
        })
        //.present();
    }

    this.isCountryInvalid = true;
  }

  showTaxErrorToast(message:any) {
    this.toastCtrl
      .create({
        message: message,
        position: "top",
        showCloseButton: true,
        cssClass: "errorToast"
      })
      //.present();
  }

  synchronizeAutocompleteForms(formClass:any, address:any) {
    let autocompleteForms = document.getElementsByClassName(formClass);
    for (let i = 0; i < autocompleteForms.length; i++) {
      let autocomplete = autocompleteForms[i].getElementsByTagName("autocompleteforpaypalnvp");
      autocomplete[0].getElementsByTagName("input")[0].value = address.street ? address.street : "";
    }
  }

  validateEmail(email:any) {
    if (email) {
      return email.match(this.emailPattern);
    }

    return false;
  }

  confirmEmail() {
    if (this.validateEmail(this.guest.email) &&
      this.validateEmail(this.emailToConfirm)) {
      return this.guest.email.toLowerCase() === this.emailToConfirm.toLowerCase();
    }

    return false;
  }

  validateMonth() {
    if (this.cardDetails.expmonth) {
      return this.monthArray.indexOf(this.cardDetails.expmonth * 1) > -1;
    }

    return false;
  }

  validateYear() {
    if (this.cardDetails.expyear) {
      return this.yearArray.indexOf(this.cardDetails.expyear * 1) > -1;
    }

    return false;
  }

  validateDate() {
    if (this.validateMonth() && this.validateYear()) {
      let todayDate = new Date();
      let year = todayDate.getFullYear();

      if ((this.cardDetails.expyear as any) * 1 > year) {
        return true;
      } else if ((this.cardDetails.expyear as any) * 1 === year) {
        let month = todayDate.getMonth() + 1;
        return (this.cardDetails.expmonth as any) * 1 >= month;
      }
    }

    return false;
  }

  validateCVV() {
    if (this.cardDetails.cardccv) {
      let maxDigits = this.cardType === "Amex" ? this.cvvMaxDigits : this.cvvMinDigits;
      let cvvInString = this.cardDetails.cardccv.toString();

      if (cvvInString.length === maxDigits) {
        return this.validateNumberInput(cvvInString);
      }
    }

    return false;
  }

  validateCardNumber() {
    if (this.cardDetails.card &&
      ((this.cardDetails.card.length === this.cardNumberMaxLength && this.cardType !== "Amex") ||
        (this.cardDetails.card.length === this.cardNumberMinLength && this.cardType === "Amex")) &&
      this.validateNumberInput(this.cardDetails.card)) {
      let sum = 0;
      let digits = this.cardDetails.card;

      for (let i = 0; i < digits.length; i++) {
        let cardNumber = parseInt(digits[i]);

        if ((digits.length - i) % 2 === 0) {
          cardNumber = cardNumber * 2;

          if (cardNumber > 9) {
            cardNumber = cardNumber - 9;
          }
        }

        sum += cardNumber;
      }

      return sum % 10 === 0;
    }

    return false;
  }

  getStates(country: Country) {
    let states = [];
    let foundStates = country.states;

    for (let stateCode in foundStates) {
      let state = {};
      (state as any)["value"] = stateCode;
      (state as any)["label"] = foundStates[stateCode];
      states.push(state);
    }

    states.reverse();
    return states;
  }

  getCountryByCode(countryCode:any) {
    let foundCountry = {} as Country;
    for (let country of this.countriesList) {
      if (country.value === countryCode) {
        foundCountry = country;
        break;
      }
    }

    return foundCountry;
  }

  updateBrand() {
    this.cardDetails.cardccv = undefined;
    this.cardDetails.card = undefined;
  }

  ionViewDidLoad() {
    let titleId = document.getElementsByTagName("title");
    titleId[0].innerHTML = "Funnel TouchCR";
  }

  async readyToCreateLead() {
    if (this.confirmEmail()) {
      await this.createNewLead();
    }
  }

  createNewLead() {
    if (!this.isLeadCreated ||
      (this.lastLeadCreated !== this.guest.email && this.guest.email)) {
      this.isLeadCreated = true;
      this.lastLeadCreated = this.guest.email;

      return this.tcrApiLead.createLead({
        firstName: this.guest.firstName,
        lastName: this.guest.lastName,
        email: this.guest.email,
        phone: this.guest.phone,
        country: this.guest.country,
        state: this.guest.state,
        postalCode: this.guest.zipPostalCode,
        city: this.guest.city,
        street: this.guest.street
      });
    }
    return;
  };

  override calculate(getShipping: Boolean) {
    const spinnerMessage = !this.transactionFeeEnabled ? 'Calculating taxes.' : 'Calculating.';
    this.spinner.enable(spinnerMessage + "<br> Please Wait");
    this.isTaxCalculated = true;
    this.isProcessRemoteServices = true;
    this.bumpOffersInCart = [];
    this.orderFormProduct = [];

    const shippingAddress = {
      country: this.address.country || this.guest.country,
      state: this.address.state || this.guest.state,
      postal: this.address.postal || this.guest.zipPostalCode,
      city: this.address.city || this.guest.city,
      street: this.address.street || this.guest.street
    };

    if (this.isFreeChecked) {
      if (this.funnelOffersData && (this.funnelOffersData[0].isFreeFirstShipping === true) && this.selectedDeliveryDay && this.selectedDeliveryDay >= 1) {
        this.funnelOffersData[0].isFreeFirstShipping = false;
      } else if (this.funnelOffersData && (this.funnelOffersData[0].isFreeFirstShipping === false) && this.selectedDeliveryDay && this.selectedDeliveryDay == 'Free') {
        this.funnelOffersData[0].isFreeFirstShipping = true;
      }
    }

    let funnelOffers: any = [];

    if (this.funnelOffersData && this.funnelOffersData.length) {
      const funnelArray = this.createFunnelBody(
        this.funnelOffersData,
        this.selectedProduct
      );
      if (funnelArray.length) {
        funnelOffers = funnelArray;
      }
    }
    if (
      this.funnel &&
      this.funnel.orderFormProduct &&
      this.funnel.orderFormProduct.length
    ) {
      const funnelArray = this.createFunnelProductBody(
        this.funnel,
        this.funnel.orderFormProduct,
        this.selectedProduct
      );
      if (funnelArray.length) {
        funnelOffers = funnelArray;
      }
    }

    if (this.isShippingAvailable && getShipping) {
      this.isShowShippingRates = false;
      this.isCalculatingShippingRates = true;
      this.disablePaymentDueToShipping = true;
      this.isShippingValid = true;
      this.shippingRates = [];
    }

    if (this.taxServiceEnabled) {
      this.isTaxValid = true;
      this.taxServiceOnPocess = true;
      this.showTaxes = false;
    }

    if (this.bumpOffers) {
      for (let i = 0; i < this.bumpOffers.length; i++) {
        if (this.bumpOffers[i].isInCart) {
          this.bumpOffersInCart.push(this.bumpOffers[i]);
        }
      }
    }

    this.calculateHelper.amountsRecalculation({
      funnelOffersData: funnelOffers,
      bumpOffers: this.bumpOffers,
      coupon: this.coupon,
      calculateTax: this.taxServiceEnabled,
      shippingAddress: shippingAddress,
      countries: this.countries,
      calculatedShippingCost: +this.calculatedShippingCost,
      accInfoForShipping: this.getAccountInfoForShipping(this.guest),
      productsForShipping: this.getProductsForShipping(),
      getShipping: this.isShippingAvailable && getShipping,
      isSubscription: this.isSubscription,
    })
      .then(({totalPrice, funnelPrice, bumpPrice, shippingCost, couponDiscount, totalSubscriptionPrice, taxCost, taxData, taxError, error, shippingRates, shippingError, subscriptionTax}) => {

        const country = this.address.country ? this.calculateHelper.getCountryISOCode((this.countries as any), this.address.country) :
          this.calculateHelper.getCountryISOCode((this.countries as any), this.guest.country as any);

        this.grandTotalPrice = totalPrice;
        this.totalPrice = totalPrice;
        this.funnelPrice = funnelPrice;
        this.bumpPrice = bumpPrice;
        this.shippingCost = shippingCost;
        (this.standardShipping as any) = country === SHIP_FROM_COUNTRY ? this.funnel.shippingCost : this.funnel.internationalShippingCost;
        this.couponDiscount = couponDiscount;
        this.totalSubscriptionPrice = totalSubscriptionPrice;
        this.subscriptionTax = subscriptionTax;

        this.totalTaxforBody = taxCost;
        this.totalTax = taxCost;
        this.taxData.dataForTax = taxData;
        this.showTaxes = true;
        this.taxServiceOnPocess = false;

        this.spinner.disable();

        if (this.isShippingAvailable) {
          this.isCalculatingShippingRates = false;
          this.disablePaymentDueToShipping = false;
          if (shippingError) {
            this.isTaxCalculated = false;
            this.isShippingValid = false;
            this.shippingCost = this.standardShipping;
            this.showTaxErrorToast(ADDRESS_ERROR_MESSAGE);
          } else {
            this.isShowShippingRates = true;
            if (shippingRates && shippingRates.length) {
              this.shippingRates = shippingRates;
              this.applyShippingRate();
            }
          }
        }

        if (taxError) {
          this.isTaxValid = false;
          this.isTaxCalculated = false;
          this.showTaxErrorToast(TAX_ERROR_MESSAGE);
        }
        if (error !== "") {
          this.toastCtrl
            .create({
              message: error,
              position: "top",
              showCloseButton: true,
              cssClass: "errorToast"
            })
            //.present();
          this.isTaxCalculated = false;
        }
        this.isProcessRemoteServices = false;
        this.calculateTransactionFee();
      }).catch((reason) => {
      console.log(reason);
    });
  }

  override getAccountInfoForShipping(guest:any) {
    return {
      firstName: guest.firstName,
      lastName: guest.lastName,
      shippingCountryCode: this.address.country || this.guest.country,
      shippingStateCode: this.address.state || this.guest.state,
      shippingCity: this.address.city || this.guest.city,
      shippingStreet: this.address.street || this.guest.street,
      shippingPostalCode: this.address.postal || this.guest.zipPostalCode,
      phone: guest.phone
    };
  }

  override saveShippingInformation() {
    if (this.isShippingValid) {
      return this.stateProvider.setShippingInfo({
        productsForShipping: this.getProductsForShipping(),
        accountInfoForShipping: {
          firstName: this.guest.firstName,
          lastName: this.guest.lastName,
          phone: this.guest.phone,
          shippingCity: this.address.city,
          shippingCountryCode: this.address.country,
          shippingPostalCode: this.address.postal,
          shippingStateCode: this.address.state,
          shippingStreet: this.address.street,
          shippingApartment: this.shippingAddress && this.isSelectedCB2 ? this.guest.apartment : this.guest.billingApartment,
          additionalShippingAddressLine: this.shippingAddress && this.isSelectedCB2 ? this.guest.additionalAddressLine : this.guest.additionalBillingAddressLine,
          billingApartment: this.guest.billingApartment,
          additionalBillingAddressLine: this.guest.additionalBillingAddressLine
        },
        selectedDeliveryDay: this.selectedDeliveryDay,
        isValid: true
      });
    } else {
      return this.stateProvider.setShippingInfo({
        isValid: false
      });
    }
  }

  validateTextInput(string:any) {
    if (string) {
      return this.textPattern.test(string);
    } else {
      return false;
    }
  }

  validateNumberInput(string:any) {
    if (string) {
      return this.numberPattern.test(string);
    } else {
      return false;
    }
  }

  validateMixedInput(string:any) {
    if (string) {
      return this.mixedPattern.test(string);
    } else {
      return false;
    }
  }

  decodeLabel(label:any) {
    return decodeURIComponent(label);
  }

  getCoupon() {
    this.spinner.enable("Executing coupon.<br> Please Wait");
    if (this.isSubscription) {
      this.spinner.disable();
      this.toastCtrl
        .create({
          message: 'Coupon is not available for Subscription!',
          position: "top",
          showCloseButton: true,
          cssClass: "errorToast"
        })
        //.present();
    }
    else {
      return this.tcrApiCoupon
        .checkCoupon({couponCode: this.couponCode})
        .toPromise()
        .then(
          result => {
            this.spinner.disable();
            if (result.discountAmount) {
              (this.productsForCoupon as any) = result.products;
              this.couponProductsOption = result.couponProductsOption;
              if (this.checkValidCoupon()) {
                this.couponValidation = true;
                this.coupon = result;
                this.coupon.price = 0;
                this.coupon.count = 1;
                this.coupon.couponId = result.sfid;
                this.calculate(false);
                this.stateProvider.setCoupon(this.coupon);
              }
            } else {
              this.couponValidation = false;
              this.resetCoupon();
            }
          },
          error => {
            this.toastCtrl
              .create({
                message: ` Coupon '${this.couponCode}' Error! ${
                  error.error.message
                  }`,
                position: "top",
                showCloseButton: true,
                cssClass: "errorToast"
              })
              //.present();
            this.resetCoupon();
            this.spinner.disable();
          }
        );
    }
    return;
  }

  updateShippingCost(event:any) {
    this.shippingRateIndex = event.target.selectedIndex;
    this.applyShippingRate();
  }

  checkoutPayPalNVP() {
    this.errorMessage = '';
    this.spinner.enable('Redirecting to PayPal<br> Please Wait');
    return this.paypalPayment.setExpressCheckout({
      orderType: this.isSubscription ? 'Subscription' : 'Standard',
      offersData: this.funnelOffersData[0],
      coupon: this.coupon,
      shipToCountry: this.calculateHelper.getCountryISOCode((this.countries as any), this.guest.country as any),
      isUpsell: false,
    })
      .then((result: any) => {
        window.open(result.url, '_self', 'location=no');
      }, (error) => {
        this.spinner.disable();
        const message = error && error.message ? error.message : 'Something went wrong';
        this.toastCtrl.create({ message: `Error! ${message}`, duration: 3000, position: 'top' })//.present();
      })
  }

  getPayPalPayerDetailsNVP(data:any) {
    this.token = data.token;
    this.payerId = data.PayerID;
    this.isPayPalSubscription = this.isSubscription;
    return this.paypalPayment.getPayPalPayerDetailsNVP({
        paramData: {
          TOKEN: data.token
        }
      }
    )
      .then((response: any) => {
        return {
          firstName: response['firstName'],
          lastName: response['lastName'],
          email: response['email'],
          phone: '',
          zipPostalCode: response['zipPostalCode'],
          street: response['street'],
          city: response['city'],
          country: response['country'],
          state: response['state'],
          apartment: ''
        } as User;
      }, (error) => {
        const message = error && error.message ? error.message : 'Something went wrong';
        this.toastCtrl.create({ message: `Error! ${message}`, duration: 3000, position: 'top' })//.present();
        console.log(error);
        return {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          zipPostalCode: '',
          street: '',
          city: '',
          country: '',
          state: '',
          apartment: ''
        } as User;
      });
  }

  submitPayPalOrder(
    accountForm: NgForm,
    diffAddress: NgForm,
    boxId: string
  ) {
    this.errorMessage = '';
    this.submittedAccountForm = true;
    this.submittedPaymentForm = false;
    this.couponValidation = true;
    const country = this.address.country ? (this.countries as any)[this.address.country].value  : (this.countries as any)[this.guest.country as any].value;
    if (
      accountForm.invalid || !this.confirmEmail() || !this.guest.street || (this.guest.street ? !this.guest.street.trim() : true) ||
      ((this.guest.state as any).length === 0 && this.statesList.length > 0) || !this.guest.zipPostalCode || (this.guest.zipPostalCode ? !this.guest.zipPostalCode.trim() : true) ||
      !this.validateTextInput(this.guest.firstName) || !this.validateTextInput(this.guest.lastName) || !this.validateNumberInput(this.guest.phone) ||
      !this.guest.city || (this.guest.city ? !this.guest.city.trim() : true) ||
      (this.isSelectedCB2 &&
        (diffAddress.invalid || !this.shippingAddress.street || (this.shippingAddress.street ? !this.shippingAddress.street.trim() : true) ||
          ((this.shippingAddress as any).state.length === 0 && this.differentAddressStatesList.length > 0) ||
          !this.shippingAddress.postal || (this.shippingAddress.postal ? !this.shippingAddress.postal.trim() : true) ||
          !this.validateTextInput(this.shipToName) || !this.shippingAddress.city || (this.shippingAddress.city ? !this.shippingAddress.city.trim() : true)))
    ) {
      this.scrollToErrorBox(boxId);
    }
    else {
      this.spinner.enable('Executing order.<br> Please Wait');
      let accDetails = this.orderHelper.getAccountDetailsFromGuest(this.guest);
      const shippingAddress = {
        country: this.address.country || this.guest.country,
        state: this.address.state || this.guest.state,
        postal: this.address.postal || this.guest.zipPostalCode,
        city: this.address.city || this.guest.city,
        street: this.address.street || this.guest.street
      };
      let executeBody = {
        totalPrice: (!this.funnelOffersData[0].isFreeFirstShipping && this.isSubscription) ? +this.totalSubscriptionPrice.toFixed(2) : +this.totalPrice.toFixed(2),
        accDetails: accDetails,
        orderType: this.isSubscription ? 'Subscription' : 'Standard',
        OffersData: this.funnelOffersData,
        totalTax: (!this.funnelOffersData[0].isFreeFirstShipping && this.isSubscription) ? +this.subscriptionTax.toFixed(2) : +this.totalTaxforBody.toFixed(2),
        subscriptionTax: +this.subscriptionTax.toFixed(2),
        calculatedShippingCost: +(+this.calculatedShippingCost).toFixed(2),
        shippingType: this.selectedDeliveryDay,
        accDetailsForPayPal: this.calculateHelper.generateAccDataForTax(accDetails.shippingAddress, this.countries),
        token: this.token,
        payerId: this.payerId,
        shippingAddress: shippingAddress,
        isInternationalShipping: country != SHIP_FROM_COUNTRY,
      };
      if (this.taxServiceEnabled) {
        (executeBody as any)['dataForTax'] = this.taxData.dataForTax;
      }
      let executeFunction;
      if (this.isSubscription) {
        executeFunction = this.paypalPayment.createRecurringPaymentsProfile(executeBody);
      }
      else {
        (executeBody as any)['coupon'] = this.coupon;
        executeFunction = this.paypalPayment.doExpressCheckout(executeBody);
      }
      return executeFunction
        .then((result: any) => {
          result.order.paymentMethod = 'PayPal';
          this.stateProvider.setOrderDetails({order: result.order})
            .then(async (state) => {
              this.state = state;
              // Save shipping information to storage
              if (this.shippingProvider.isShippingAvailable()) {
                await this.saveShippingInformation();
              }
              await this.stateProvider.setTaxData(this.taxData.dataForTax);
              this.cart.cleanCart();
              this.toastCtrl.create({message: `SUCCESS!`, duration: 2000, position: 'top'})//.present();
              this.spinner.disable();
              result.order.subscribed = this.isSubscription;
              result.order.authorized = false;
              this.eventStreamer.streamEvent('purchase', {
                customTitle: 'PayPal Subscription',
                data: result.order,
              });
              let orderDetailsInfowCopy = JSON.parse(JSON.stringify(this.state.orderDetails)) || {};
              orderDetailsInfowCopy.order = result.order;
              orderDetailsInfowCopy.paymentMethod = 'PayPal';
              return this.stateProvider.setPageNameAndOrder(this.stateProvider.UPSELL, orderDetailsInfowCopy)
                .then(() => {
                  this.navCtrl.setRootExt(this.stateProvider.UPSELL, {name: 'ONE TIME OFFER SPECIAL', isUpsell: true}, { taken: true});
                })
            });
        }, (error) => {
          this.isPayPalBillingAgreementApproved = false;
          this.spinner.disable();
          this.errorMessage = error && error.message ? error.message : 'Something went wrong';
          this.scrollToErrorBox(boxId);
        })
    }
    return;
  }


  // callout to the API for calculate Transaction Fee
  override calculateTransactionFee() {
    this.subscriptionSecondPaymentSubtotal = +(this.isSubscription && this.funnel.subscriptionPrice != 0 && this.totalSubscriptionPrice == 0 ? (this.funnel.subscriptionPrice as any)  + this.shippingCost : this.totalSubscriptionPrice).toFixed(2);
    this.subscriptionSecondPaymentSubtotal = this.isSubscription && this.funnel.isFreeFirstShipping && this.totalSubscriptionPrice != 0 ? this.subscriptionSecondPaymentSubtotal + this.shippingCost : this.subscriptionSecondPaymentSubtotal;
    if (!this.transactionFeeEnabled) {
      this.grandTotalPrice = +this.totalPrice.toFixed(2);
      return;
    }

    const cardNicn = this.cardDetails.card ? '' + this.cardDetails.card.split(' ').join('').substring(0, 8) : '';

    if (cardNicn.length < 8 || !this.guest.country || !this.guest.zipPostalCode || this.guest.zipPostalCode.length < 5) {
      this.transactionFeeAmount = 0;
      this.oldBillingZipCode = this.guest.zipPostalCode;
      return;
    }

    // modified logic from page
    const orderTotalWithoutFee = this.totalPrice <= 0
      ? +((this.funnel.offerPrice as any) + ((this.funnel.isFreeFirstShipping && this.selectedDeliveryDay == 'Free') ? 0 : this.shippingCost) + this.couponDiscount).toFixed(2)
      : this.coupon.discountType === 'Free Shipping' ? +((this.funnel.offerPrice as any)).toFixed(2)
      : this.totalPrice;

    const isNichNotChanged = this.orderCardNicn === cardNicn;
    const isZipNotChanged = this.oldBillingZipCode === this.guest.zipPostalCode;
    const isCountryNotChanged = this.oldBillingConutry === this.guest.country;
    const isTotalPriceNotChanged = this.oldTotalPriceWithoutFee.toFixed(2) === orderTotalWithoutFee.toFixed(2);

    if (isNichNotChanged && isZipNotChanged && isCountryNotChanged && isTotalPriceNotChanged) {
      return;
    }
    this.spinner.enable("Calculating.<br> Please Wait");

    this.orderCardNicn = cardNicn;
    this.oldBillingZipCode = this.guest.zipPostalCode;
    this.oldBillingConutry = this.guest.country;

    this.oldTotalPriceWithoutFee = +(orderTotalWithoutFee).toFixed(2);
    const accountInfo = {
      billingPostalCode: this.guest.zipPostalCode,
      billingCountryCode: this.guest.country,
    };

    this.orderIdTemp = !this.orderIdTemp || this.orderIdTemp === ''
    ? `_${Math.random().toString(36).substr(2, 11)}` : this.orderIdTemp;
    this.orderIdTempSubscription = !this.orderIdTempSubscription || this.orderIdTempSubscription === ''
    ? `_${Math.random().toString(36).substr(2, 11)}` : this.orderIdTempSubscription;

    this.tcrApiRemoteServices.requestDataForTrFee({
      brand: (window as any)['process_env'].BRAND,
      account: accountInfo,
      orderTotalWithoutFee: this.oldTotalPriceWithoutFee,
      orderCardNicn: cardNicn,
      orderIdTemp: this.orderIdTemp,
      orderSTxId: this.orderSTxId,
      orderTotalWithoutFeeSubscription: this.subscriptionSecondPaymentSubtotal,
      orderIdTempSubscription: this.orderIdTempSubscription,
      orderSTxIdSubscription: this.orderSTxIdSubscription,
    }
    ).subscribe((res) => {
      const calculationResponse = res.trFeeResponse[this.orderIdTemp] ? res.trFeeResponse[this.orderIdTemp] : {};
      this.transactionFeeAmount = calculationResponse.transactionFee ? calculationResponse.transactionFee : 0;
      this.grandTotalPrice = orderTotalWithoutFee + this.transactionFeeAmount;
      this.orderSTxId = calculationResponse.sTxId ? calculationResponse.sTxId : '';
      // response also contains: are calculation is valid in 'calculationResponse.isSuccess'
      this.disableCardPaymentDueToTransactionFee = false;
      // calculation of the 'Transaction Fee' for the second subscription payment
      const calculationResponseSubscription = res.trFeeResponse[this.orderIdTempSubscription]
        ? res.trFeeResponse[this.orderIdTempSubscription] : {};
      this.subscriptionTransactionFee = calculationResponseSubscription.transactionFee
        ? calculationResponseSubscription.transactionFee : 0;
      this.orderSTxIdSubscription = calculationResponseSubscription.sTxId
        ? calculationResponseSubscription.sTxId : '';
      this.spinner.disable();
    }, (err) => {
      this.transactionFeeAmount = 0;
      this.grandTotalPrice = orderTotalWithoutFee;
      this.disableCardPaymentDueToTransactionFee = false;
      this.orderSTxId = '';
      this.subscriptionTransactionFee = 0;
      this.orderSTxIdSubscription = '';
      this.spinner.disable();

      console.log('Error on transaction fee in Сalculate Transaction Fee: ', err);
      this.toastCtrl.create({ message: `Error transaction fee calculating!`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
    })
  }

}
