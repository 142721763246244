export const PARAM = "n";
export const OBTCR = "obtcr";
export const TBATCR = "tbatcr";
export const FBTCR = "fbtcr";
export const DBTCR = "dbtcr";
export const REVTCR = "revtcr";
export const ADV = "Advertorial";
export const ADVERT = "Advertisement";
export const OPT = 'opt';
export const OBNATCR = 'obnatcr';
export const MSNTCR = 'msntcr';
export const OB = 'ob';
export const AOL = 'aol';
export const AOLTCR = 'aoltcr';
export const GDN = 'gdn';
export const GDNTCR = 'gdntcr';
export const AYAHTCR = 'ayahtcr';
export const AYAHC = 'ayahc';
export const AYAHLG = 'ayahlg';
export const ATBATCR = 'atbatcr';
export const VMXGUN = 'vmxgun';
export const RMGEM = 'rm-gem';

export const OOYALA_CLASS = "ooyala-player-Gundry-MD";


export const VIDEO_SCRIPTS = [
  "//player.ooyala.com/static/v4/stable/4.24.9/core.min.js",
  "//player.ooyala.com/static/v4/stable/4.24.9/video-plugin/main_html5.min.js",
  "//player.ooyala.com/static/v4/stable/4.24.9/skin-plugin/html5-skin.min.js"];
export const VIDEO_STYLE_REL = "stylesheet";
export const VIDEO_STYLE_HREF = "//player.ooyala.com/static/v4/stable/4.24.9/skin-plugin/html5-skin.min.css";

export const fbParam = ".fbParam {\n\tdisplay: initial !important;;\n}";
export const tbaParam = ".tbaParam {\n\tdisplay: initial !important;;\n}";
export const obParam = ".obParam {\n\tdisplay: initial !important;;\n}";
export const msnParam = ".msnParam {\n\tdisplay: initial !important;;\n}";
export const revParam = ".revParam {\n\tdisplay: initial !important;;\n}";

export const BRIGHTCOVE_SCRIPTS = [
  "https://players.brightcove.net/6058004206001/default_default/index.min.js"];


// DebugProvider data
export const UPSELL_DATA = {

};
export const ORDER_SUCCESS_DATA = {

};
// Countries back up array
export const COUNTRIES_BACKUP = [{
  "value": "US",
  "states": {
    "WY": "Wyoming",
    "WI": "Wisconsin",
    "WV": "West Virginia",
    "WA": "Washington",
    "VA": "Virginia",
    "VT": "Vermont",
    "UT": "Utah",
    "TX": "Texas",
    "TN": "Tennessee",
    "SD": "South Dakota",
    "SC": "South Carolina",
    "RI": "Rhode Island",
    "PA": "Pennsylvania",
    "OR": "Oregon",
    "OK": "Oklahoma",
    "OH": "Ohio",
    "ND": "North Dakota",
    "NC": "North Carolina",
    "NY": "New York",
    "NM": "New Mexico",
    "NJ": "New Jersey",
    "NH": "New Hampshire",
    "NV": "Nevada",
    "NE": "Nebraska",
    "MT": "Montana",
    "MO": "Missouri",
    "MS": "Mississippi",
    "MN": "Minnesota",
    "MI": "Michigan",
    "MA": "Massachusetts",
    "MD": "Maryland",
    "ME": "Maine",
    "LA": "Louisiana",
    "KY": "Kentucky",
    "KS": "Kansas",
    "IA": "Iowa",
    "IN": "Indiana",
    "IL": "Illinois",
    "ID": "Idaho",
    "HI": "Hawaii",
    "GA": "Georgia",
    "FL": "Florida",
    "DC": "District of Columbia",
    "DE": "Delaware",
    "CT": "Connecticut",
    "CO": "Colorado",
    "CA": "California",
    "AR": "Arkansas",
    "AZ": "Arizona",
    "AK": "Alaska",
    "AL": "Alabama"
  },
  "label": "United States"
}, {
  "value": "CA",
  "states": {
    "YT": "Yukon Territories",
    "SK": "Saskatchewan",
    "QC": "Quebec",
    "PE": "Prince Edward Island",
    "ON": "Ontario",
    "NU": "Nunavut",
    "NS": "Nova Scotia",
    "NT": "Northwest Territories",
    "NL": "Newfoundland and Labrador",
    "NB": "New Brunswick",
    "MB": "Manitoba",
    "BC": "British Columbia",
    "AB": "Alberta"
  },
  "label": "Canada"
}];



export const PAYMENT_LABEL: any = {
  'United States': {
    nameCard: 'Name on Card',
    cardType: 'Card Type',
    cardNumber: 'Card Number',
    expirationDate: 'Expiration Date',
    monthDate: 'Month',
    yearDate: 'Year',
    expirationDateLabel: '(MM/YYYY)',
    expirationDateLabelBT: '(MM/YY)',
    cvv: 'CVV2',
    whatsThis: 'What is this?',
  },
  'Germany': {
    nameCard: 'Name auf der Karte',
    cardType: 'Speicherkarten-Typ',
    cardNumber: 'Kartennummer',
    expirationDate: 'Verfallsdatum',
    monthDate: 'Monat',
    yearDate: 'Jahr',
    expirationDateLabel: '(MM/JJJJ)',
    expirationDateLabelBT: '(MM/JJ)',
    cvv: 'CVV2',
    whatsThis: 'Was ist das?',
  },
  'Mexico': {
    nameCard: 'Nombre en la tarjeta',
    cardType: 'Tipo de tarjeta',
    cardNumber: 'Número de tarjeta',
    expirationDate: 'Fecha de caducidad',
    monthDate: 'Mes',
    yearDate: 'Año',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: '¿Qué es esto?',
  },
  'Russia': {
    nameCard: 'Имя на карте',
    cardType: 'Тип карты',
    cardNumber: 'Номер карты  ',
    expirationDate: 'Срок действия',
    monthDate: 'Месяц',
    yearDate: 'Год',
    expirationDateLabel: '(ММ/ГГГГ)',
    expirationDateLabelBT: '(ММ/ГГ)',
    cvv: 'CVV2',
    whatsThis: 'Что это?',
  },
  'China': {
    nameCard: '卡上的名字',
    cardType: '卡类型',
    cardNumber: '卡号',
    expirationDate: '有效期',
    monthDate: '月',
    yearDate: '年',
    expirationDateLabel: '(MM/YYYY)',
    expirationDateLabelBT: '(MM/YY)',
    cvv: 'CVV2',
    whatsThis: '这是什么？',
  },
  'Austria': {
    nameCard: 'Name auf der Karte',
    cardType: 'Kartentyp',
    cardNumber: 'Kartennummer',
    expirationDate: 'Ablaufdatum',
    monthDate: 'Monat',
    yearDate: 'Jahr',
    expirationDateLabel: '(MM/JJJJ)',
    expirationDateLabelBT: '(MM/JJ)',
    cvv: 'CVV2',
    whatsThis: 'Was ist das?',
  },
  'Belgium': {
    nameCard: 'Naam op kaart',
    cardType: 'Kaarttype',
    cardNumber: 'Kaartnummer',
    expirationDate: 'Vervaldatum',
    monthDate: 'Maand',
    yearDate: 'Jaar',
    expirationDateLabel: '(MM/JJJJ)',
    expirationDateLabelBT: '(MM/JJ)',
    cvv: 'CVV2',
    whatsThis: 'Wat is dit?',
  },
  'Bulgaria': {
    nameCard: 'Име на картата',
    cardType: 'Тип на картата',
    cardNumber: 'Номер на картата',
    expirationDate: 'Срок на годност',
    monthDate: 'Месец',
    yearDate: 'Година',
    expirationDateLabel: '(ММ/ГГГГ)',
    expirationDateLabelBT: '(ММ/ГГ)',
    cvv: 'CVV2',
    whatsThis: 'Какво е това?',
  },
  'Croatia': {
    nameCard: 'Ime na kartici',
    cardType: 'Vrsta kartice',
    cardNumber: 'Broj kart  ',
    expirationDate: 'Datum isteka',
    monthDate: 'Mjesec',
    yearDate: 'Godina',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: 'Što je ovo?',
  },
  'Cyprus': {
    nameCard: 'Όνομα στην κάρτα',
    cardType: 'Τύπος κάρτας',
    cardNumber: 'Αριθμός κάρτας',
    expirationDate: 'Ημερομηνία λήξης',
    monthDate: 'Μήνας',
    yearDate: 'Έτος',
    expirationDateLabel: '(ΜΜ/ΕΕΕΕ)',
    expirationDateLabelBT: '(ΜΜ/ΕΕ)',
    cvv: 'CVV2',
    whatsThis: 'Τι είναι αυτό;',
  },
  'Czech Republic': {
    nameCard: 'Jméno na kartě',
    cardType: 'Typ karty',
    cardNumber: 'Číslo karty',
    expirationDate: 'Datum expirace',
    monthDate: 'Měsíc',
    yearDate: 'Rok',
    expirationDateLabel: '(MM/RRRR)',
    expirationDateLabelBT: '(MM/RR)',
    cvv: 'CVV2',
    whatsThis: 'Co je to?',
  },
  'Denmark': {
    nameCard: 'Navn på kort',
    cardType: 'Korttype',
    cardNumber: 'Kortnummer',
    expirationDate: 'Udløbsdato',
    monthDate: 'Måned',
    yearDate: 'År',
    expirationDateLabel: '(MM/ÅÅÅÅ)',
    expirationDateLabelBT: '(MM/ÅÅ)',
    cvv: 'CVV2',
    whatsThis: 'Hvad er dette?',
  },
  'Estonia': {
    nameCard: 'Nimi kaardil',
    cardType: 'Kaarditüüp',
    cardNumber: 'Kaardi number',
    expirationDate: 'Aegumiskuupäev',
    monthDate: 'Kuu',
    yearDate: 'Aasta',
    expirationDateLabel: '(KK/AAAA)',
    expirationDateLabelBT: '(KK/AA)',
    cvv: 'CVV2',
    whatsThis: 'Mis see on?',
  },
  'Finland': {
    nameCard: 'Nimi kortissa',
    cardType: 'Kortin tyyppi',
    cardNumber: 'Kortin numero',
    expirationDate: 'Vanhenemispäivä',
    monthDate: 'Kuukausi',
    yearDate: 'Vuosi',
    expirationDateLabel: '(KK/VVVV)',
    expirationDateLabelBT: '(KK/VV)',
    cvv: 'CVV2',
    whatsThis: 'Mikä tämä on?',
  },
  'France': {
    nameCard: 'Nom sur la carte',
    cardType: 'Type de carte',
    cardNumber: 'Numéro de carte',
    expirationDate: 'Date d\'expiration',
    monthDate: 'Mois',
    yearDate: 'Année',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: 'Qu\'est-ce que c\'est?',
  },
  'Greece': {
    nameCard: 'Όνομα στην κάρτα',
    cardType: 'Τύπος κάρτας',
    cardNumber: 'Αριθμός κάρτας',
    expirationDate: 'Ημερομηνία λήξης',
    monthDate: 'Μήνας',
    yearDate: 'Έτος',
    expirationDateLabel: '(ΜΜ/ΕΕΕΕ)',
    expirationDateLabelBT: '(ΜΜ/ΕΕ)',
    cvv: 'CVV2',
    whatsThis: 'Τι είναι αυτό;',
  },
  'Hungary': {
    nameCard: 'Név a kártyán',
    cardType: 'Kártyatípus',
    cardNumber: 'Kártyaszám',
    expirationDate: 'Lejárati dátum',
    monthDate: 'Hónap',
    yearDate: 'Év',
    expirationDateLabel: '(HH/ÉÉÉÉ)',
    expirationDateLabelBT: '(HH/ÉÉ)',
    cvv: 'CVV2',
    whatsThis: 'Mi ez?',
  },
  'Italy': {
    nameCard: 'Nome sulla carta',
    cardType: 'Tipo di carta',
    cardNumber: 'Numero di carta',
    expirationDate: 'Data di scadenza',
    monthDate: 'Mese',
    yearDate: 'Anno',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: 'Cos\'è questo?',
  },
  'Latvia': {
    nameCard: 'Vārds uz kartes',
    cardType: 'Kartes tips',
    cardNumber: 'Kartes numurs',
    expirationDate: 'Derīguma termiņš',
    monthDate: 'Mēnesis',
    yearDate: 'Gads',
    expirationDateLabel: '(MM/GGGG)',
    expirationDateLabelBT: '(MM/GG)',
    cvv: 'CVV2',
    whatsThis: 'Kas tas ir?',
  },
  'Lithuania': {
    nameCard: 'Vardas kortelėje',
    cardType: 'Kortelės tipas',
    cardNumber: 'Kortelės numeris',
    expirationDate: 'Galiojimo data',
    monthDate: 'Mėnuo',
    yearDate: 'Metai',
    expirationDateLabel: '(MM/GGGG)',
    expirationDateLabelBT: '(MM/GG)',
    cvv: 'CVV2',
    whatsThis: 'Kas tai?',
  },
  'Luxembourg': {
    nameCard: 'Nom sur la carte',
    cardType: 'Type de carte',
    cardNumber: 'Numéro de carte',
    expirationDate: 'Date d\'expiration',
    monthDate: 'Mois',
    yearDate: 'Année',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: 'Qu\'est-ce que c\'est?',
  },
  'Malta': {
    nameCard: 'Isem fuq il-Karta',
    cardType: 'Tip ta \'Karta',
    cardNumber: 'Numru tal-Karta',
    expirationDate: 'Data ta \'Skadenza',
    monthDate: 'Xahar',
    yearDate: 'Sena',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: 'X\'inhuma?',
  },
  'The Netherlands': {
    nameCard: 'Naam op kaart',
    cardType: 'Kaarttype',
    cardNumber: 'Kaartnummer',
    expirationDate: 'Vervaldatum',
    monthDate: 'Maand',
    yearDate: 'Jaar',
    expirationDateLabel: '(MM/JJJJ)',
    expirationDateLabelBT: '(MM/JJ)',
    cvv: 'CVV2',
    whatsThis: 'Wat is dit?',
  },
  'Poland': {
    nameCard: 'Nazwa na karcie',
    cardType: 'Typ karty',
    cardNumber: 'Numer karty  ',
    expirationDate: 'Data ważności',
    monthDate: 'Miesiąc',
    yearDate: 'Rok',
    expirationDateLabel: '(MM/RRRR)',
    expirationDateLabelBT: '(MM/RR)',
    cvv: 'CVV2',
    whatsThis: 'Co to jest?',
  },
  'Portugal': {
    nameCard: 'Nome no cartão',
    cardType: 'Tipo de cartão',
    cardNumber: 'Número do cartão',
    expirationDate: 'Data de validade',
    monthDate: 'Mês',
    yearDate: 'Ano',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: 'O que é isso?',
  },
  'Romania': {
    nameCard: 'Nume pe card',
    cardType: 'Tip de card',
    cardNumber: 'Număr de card',
    expirationDate: 'Data de expirare',
    monthDate: 'Luna',
    yearDate: 'An',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: 'Ce este asta?',
  },
  'Slovakia': {
    nameCard: 'Meno na karte',
    cardType: 'Typ karty',
    cardNumber: 'Číslo karty',
    expirationDate: 'Dátum exspirácie',
    monthDate: 'Mesiac',
    yearDate: 'Rok',
    expirationDateLabel: '(MM/RRRR)',
    expirationDateLabelBT: '(MM/RR)',

    cvv: 'CVV2',
    whatsThis: 'Čo je to?',
  },
  'Slovenia': {
    nameCard: 'Ime na kartici',
    cardType: 'Vrsta kartice',
    cardNumber: 'Broj kart',
    expirationDate: 'Datum isteka',
    monthDate: 'Mjesec',
    yearDate: 'Godina',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: 'Što je ovo?',
  },
  'Spain': {
    nameCard: 'Nombre en la tarjeta',
    cardType: 'Tipo de tarjeta',
    cardNumber: 'Número de tarjeta',
    expirationDate: 'Fecha de caducidad',
    monthDate: 'Mes',
    yearDate: 'Año',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: '¿Qué es esto?',
  },
  'Sweden': {
    nameCard: 'Namn på kortet',
    cardType: 'Korttyp',
    cardNumber: 'Kortnummer',
    expirationDate: 'Utgångsdatum',
    monthDate: 'Månad',
    yearDate: 'År',
    expirationDateLabel: '(MM/ÅÅÅÅ)',
    expirationDateLabelBT: '(MM/ÅÅ)',
    cvv: 'CVV2',
    whatsThis: 'Vad är detta?',
  },
  'Japan': {
    nameCard: 'カード上の名前',
    cardType: 'カードの種類',
    cardNumber: 'カード番号',
    expirationDate: '有効期限',
    monthDate: '月',
    yearDate: '年',
    expirationDateLabel: '(MM/YYYY)',
    expirationDateLabelBT: '(MM/YY)',
    cvv: 'CVV2',
    whatsThis: 'これは何ですか？',
  },
  'Switzerland': {
    nameCard: 'Name auf Karte',
    cardType: 'Kartentyp',
    cardNumber: 'Kartennummer',
    expirationDate: 'Ablaufdatum',
    monthDate: 'Monat',
    yearDate: 'Jahr',
    expirationDateLabel: '(MM/JJJJ)',
    expirationDateLabelBT: '(MM/JJ)',
    cvv: 'CVV2',
    whatsThis: 'Was ist das?',
  },
  'Hong Kong': {
    nameCard: '卡上的名字',
    cardType: '卡类型',
    cardNumber: '卡号',
    expirationDate: '有效期',
    monthDate: '月',
    yearDate: '年',
    expirationDateLabel: '(MM/YYYY)',
    expirationDateLabelBT: '(MM/YY)',
    cvv: 'CVV2',
    whatsThis: '这是什么？',
  },
  'Taiwan': {
    nameCard: '卡上的名字',
    cardType: '卡类型',
    cardNumber: '卡号',
    expirationDate: '有效期',
    monthDate: '月',
    yearDate: '年',
    expirationDateLabel: '(MM/YYYY)',
    expirationDateLabelBT: '(MM/YY)',
    cvv: 'CVV2',
    whatsThis: '这是什么？',
  },
  'Iceland': {
    nameCard: 'Nafn á korti',
    cardType: 'Kortategund',
    cardNumber: 'Kortanúmer',
    expirationDate: 'Gildistími',
    monthDate: 'Mánuður',
    yearDate: 'Ár',
    expirationDateLabel: '(MM/ÁÁÁÁ)',
    expirationDateLabelBT: '(MM/ÁÁ)',
    cvv: 'CVV2',
    whatsThis: 'Hvað er þetta?',
  },
  'Portgual': {
    nameCard: 'Nome no cartão',
    cardType: 'Tipo de cartão',
    cardNumber: 'Número do cartão',
    expirationDate: 'Data de validade',
    monthDate: 'Mês',
    yearDate: 'Ano',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: 'O que é isto?',
  },
  'Brazil': {
    nameCard: 'Nome no cartão',
    cardType: 'Tipo de cartão',
    cardNumber: 'Número do cartão',
    expirationDate: 'Data de validade',
    monthDate: 'Mês',
    yearDate: 'Ano',
    expirationDateLabel: '(MM/AAAA)',
    expirationDateLabelBT: '(MM/AA)',
    cvv: 'CVV2',
    whatsThis: 'O que é isso?',
  },
}

export const SHIPPING_LABEL: any = {
  'United States': {
    shipToName: 'Ship to Name',
    address: 'Address',
    addressConfirm: 'Address 2',
    city: 'City',
    zip: 'ZIP/Postal Code',
    country: 'Country',
    state: 'State',
    stateLabel: 'Choose a State'
  },
  'Germany': {
    shipToName: 'Empfängername',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Stadt',
    zip: 'Postleitzahl',
    country: 'Land',
    state: 'Zustand',
    stateLabel: 'Wählen Sie einen Staat',
    'United States': 'Vereinigte Staaten',
    'United Kingdom': 'Großbritannien',
    'Canada': 'Kanada',
    'Australia': 'Australien',
    'Argentina': 'Argentinien',
    'Chile': 'Chile',
    'Colombia': 'Kolumbien',
    'Denmark': 'Dänemark',
    'France': 'Frankreich',
    'Germany': 'Deutschland',
    'Mexico': 'Mexiko',
    'New Zeland': 'Neuseeland',
    'Peru': 'Peru',
    'Puerto Rico': 'Puerto Rico',
    'Singapore': 'Singapur',
    'Spain': 'Spanien',
    'Virgin Islands, British': 'Virgin Inseln, Britisch',
  },
  'Mexico': {
    shipToName: 'Nombre de envío',
    address: 'Dirección',
    addressConfirm: 'Dirección 2',
    city: 'Ciudad',
    zip: 'Código postal',
    country: 'País',
    state: 'Estado',
    stateLabel: 'Elige un estado'
  },
  'Russia': {
    shipToName: 'Имя получателя',
    address: 'Адрес',
    addressConfirm: 'Адрес 2',
    city: 'Город',
    zip: 'Почтовый индекс',
    country: 'Страна',
    state: 'Область',
    stateLabel: 'Выберите область'
  },
  'China': {
    shipToName: '收件人姓名',
    address: '地址',
    addressConfirm: '地址 2',
    city: '市',
    zip: '邮政编码',
    country: '国家',
    state: '州',
    stateLabel: '选择一个州'
  },
  'Greece': {
    shipToName: 'Όνομα παραλήπτη',
    address: 'Διεύθυνση ',
    addressConfirm: 'Διεύθυνση 2',
    city: 'Πόλη',
    zip: 'Ταχυδρομικός κώδικας',
    country: 'Χώρα',
    state: 'Πολιτεία',
    stateLabel: 'Επιλέξτε μια κατάσταση'
  },
  'Austria': {
    shipToName: 'Name des Empfängers',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Stadt',
    zip: 'Postleitzahl',
    country: 'Land',
    state: 'Zustand',
    stateLabel: 'Wählen Sie einen Staat'
  },
  'Belgium': {
    shipToName: 'Nom du destinataire',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Ville',
    zip: 'Code postal',
    country: 'Pays',
    state: 'État',
    stateLabel: 'Choisissez un état'
  },
  'Bulgaria': {
    shipToName: 'Име на получателя',
    address: 'Адрес',
    addressConfirm: 'Адрес 2',
    city: 'Град',
    zip: 'Пощенски код',
    country: 'Страна',
    state: 'Област',
    stateLabel: 'Изберете област'
  },
  'Croatia': {
    shipToName: 'Ime primatelja',
    address: 'Adresa',
    addressConfirm: 'Adresa 2',
    city: 'Grad',
    zip: 'Poštanski broj',
    country: 'Zemlja',
    state: 'Država',
    stateLabel: 'Odaberite državu'
  },
  'Cyprus': {
    shipToName: 'Όνομα παραλήπτη',
    address: 'Διεύθυνση ',
    addressConfirm: 'Διεύθυνση 2',
    city: 'Πόλη',
    zip: 'Ταχυδρομικός κώδικας',
    country: 'Χώρα',
    state: 'Πολιτεία',
    stateLabel: 'Επιλέξτε μια κατάσταση'
  },
  'Czech Republic': {
    shipToName: 'Jméno příjemce',
    address: 'Adresa',
    addressConfirm: 'Adresa 2',
    city: 'Město',
    zip: 'PSČ',
    country: 'Země',
    state: 'Stát',
    stateLabel: 'Vyberte stát'
  },
  'Denmark': {
    shipToName: 'Modtagernavn',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'By',
    zip: 'Postnummer',
    country: 'Land',
    state: 'Stat',
    stateLabel: 'Vælg en stat'
  },
  'Estonia': {
    shipToName: 'Saaja nimi',
    address: 'Aadress',
    addressConfirm: 'Aadress 2',
    city: 'Linn',
    zip: 'Postiindeks',
    country: 'Riik',
    state: 'Osariik',
    stateLabel: 'Valige osariik'
  },
  'Finland': {
    shipToName: 'Vastaanottajan nimi',
    address: 'Osoite',
    addressConfirm: 'Osoite 2',
    city: 'Kaupunki',
    zip: 'Postinumero',
    country: 'Maa',
    state: 'Osavaltio',
    stateLabel: 'Valitse osavaltio'
  },
  'France': {
    shipToName: 'Nom du destinataire',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Ville',
    zip: 'Code postal',
    country: 'Pays',
    state: 'État',
    stateLabel: 'Choisissez un état'
  },
  'Hungary': {
    shipToName: 'Címzett neve',
    address: 'Cím',
    addressConfirm: 'Cím 2',
    city: 'Város',
    zip: 'Irányítószám',
    country: 'Ország',
    state: 'Állam',
    stateLabel: 'Válassz államot'
  },
  'Italy': {
    shipToName: 'Nome del destinatario',
    address: 'Indirizzo',
    addressConfirm: 'Indirizzo 2',
    city: 'Città',
    zip: 'CAP',
    country: 'Paese',
    state: 'Stato',
    stateLabel: 'Scegli uno stato'
  },
  'Latvia': {
    shipToName: 'Saņēmēja vārds',
    address: 'Adrese',
    addressConfirm: 'Adrese 2',
    city: 'Pilsēta',
    zip: 'Pasta indekss',
    country: 'Valsts',
    state: 'Valsts',
    stateLabel: 'Izvēlieties valsti'
  },
  'Lithuania': {
    shipToName: 'Gavėjo vardas',
    address: 'Adresas',
    addressConfirm: 'Adresas 2',
    city: 'Miestas',
    zip: 'Pašto kodas',
    country: 'Šalis',
    state: 'Valstybė',
    stateLabel: 'Pasirinkite valstiją'
  },
  'Luxembourg': {
    shipToName: 'Nom du destinataire',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Ville',
    zip: 'Code postal',
    country: 'Pays',
    state: 'État',
    stateLabel: 'Choisissez un état'
  },
  'Malta': {
    shipToName: 'Isem tal-Ġwanni',
    address: 'Indirizz',
    addressConfirm: 'Indirizz 2',
    city: 'Belt',
    zip: 'Kodiċi Postali',
    country: 'Pajjiż',
    state: 'Stat',
    stateLabel: 'Agħżel stat'
  },
  'The Netherlands': {
    shipToName: 'Naam ontvanger',
    address: 'Adres',
    addressConfirm: 'Adres 2',
    city: 'Stad',
    zip: 'Postcode',
    country: 'Land',
    state: 'Staat',
    stateLabel: 'Kies een staat'
  },
  'Poland': {
    shipToName: 'Nazwa odbiorcy',
    address: 'Adres',
    addressConfirm: 'Adres 2',
    city: 'Miasto',
    zip: 'Kod pocztowy',
    country: 'Kraj',
    state: 'Stan',
    stateLabel: 'Wybierz stan'
  },
  'Portugal': {
    shipToName: 'Nome do destinatário',
    address: 'Endereço',
    addressConfirm: 'Endereço 2',
    city: 'Cidade',
    zip: 'Código postal',
    country: 'País',
    state: 'Estado',
    stateLabel: 'Escolha um estado'
  },
  'Romania': {
    shipToName: 'Numele destinatarului',
    address: 'Adresa',
    addressConfirm: 'Adresa 2',
    city: 'Oraș',
    zip: 'Cod poștal',
    country: 'Țară',
    state: 'Stat',
    stateLabel: 'Alegeți un stat'
  },
  'Slovakia': {
    shipToName: 'Meno príjemcu',
    address: 'Adresa',
    addressConfirm: 'Adresa 2',
    city: 'Mesto',
    zip: 'PSČ',
    country: 'Krajina',
    state: 'Štát',
    stateLabel: 'Vyberte štát'
  },
  'Slovenia': {
    shipToName: 'Ime prejemnika',
    address: 'Naslov',
    addressConfirm: 'Naslov 2',
    city: 'Mesto',
    zip: 'Poštna številka',
    country: 'Država',
    state: 'Država',
    stateLabel: 'Izberite državo'
  },
  'Spain': {
    shipToName: 'Nombre del destinatario',
    address: 'Dirección',
    addressConfirm: 'Dirección 2',
    city: 'Ciudad',
    zip: 'Código postal',
    country: 'País',
    state: 'Estado',
    stateLabel: 'Elige un estado'
  },
  'Sweden': {
    shipToName: 'Namn på mottagaren',
    address: 'Adress',
    addressConfirm: 'Adress 2',
    city: 'Stad',
    zip: 'Postnummer',
    country: 'Land',
    state: 'Stat',
    stateLabel: 'Välj en stat'
  },
  'Japan': {
    shipToName: '受信者名',
    address: '住所',
    addressConfirm: '住所 2',
    city: '市',
    zip: '郵便番号',
    country: '国',
    state: '州',
    stateLabel: '州を選択'
  },
  'Switzerland': {
    shipToName: 'Name des Empfängers',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Stadt',
    zip: 'Postleitzahl',
    country: 'Land',
    state: 'Zustand',
    stateLabel: 'Wählen Sie einen Staat'
  },
  'Hong Kong': {
    shipToName: '收件人姓名',
    address: '地址',
    addressConfirm: '地址 2',
    city: '市',
    zip: '郵政編碼',
    country: '國家',
    state: '州',
    stateLabel: '選擇一個州'
  },
  'Taiwan': {
    shipToName: '收件人姓名',
    address: '地址',
    addressConfirm: '地址 2',
    city: '市',
    zip: '郵政編碼',
    country: '國家',
    state: '州',
    stateLabel: '選擇一個州'
  },
  'Iceland': {
    shipToName: 'Nafn móttakanda',
    address: 'Heimilisfang',
    addressConfirm: 'Heimilisfang 2',
    city: 'Borg',
    zip: 'Póstnúmer',
    country: 'Land',
    state: 'Ríki',
    stateLabel: 'Veldu ríki'
  },
  'Brazil': {
    shipToName: 'Nome do destinatário',
    address: 'Endereço',
    addressConfirm: 'Endereço 2',
    city: 'Cidade',
    zip: 'Código postal',
    country: 'País',
    state: 'Estado',
    stateLabel: 'Escolha um estado'
  },

}

export const CHECKOUT_LABEL: any = {
  'United States': {
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    email: 'E-mail',
    confirmEmail: 'Confirm Email',
    address: 'Address',
    addressConfirm: 'Address 2',
    city: 'City',
    zip: 'ZIP/Postal Code',
    country: 'Country',
    state: 'State',
    stateLabel: 'Choose a State',
    'Cart Contents': 'Cart Contents',
    Checkout: 'Checkout',
    Confirmation: 'Confirmation',
    'Regular Price': 'Regular Price',
    Savings: 'Savings',
    'Sales Tax': 'Sales Tax',
    Shipping: 'Shipping',
    Total: 'Total',
    'Coupon discount': 'Coupon discount',
    couponMessage: 'Coupon code',
    optional: '(optional)',
    Apply: 'Apply',
    diffAddress: 'Ship to a different address?',
    required: 'Required fields are in <strong>bold</strong>.',
    billinginformation: '<span class="form__title_underline">B</span>illing Information',
    safesecure: 'Your order is safe and secure',
    isrequired: ' is a required field',
    pleaseselect: 'Please select a payment method',
    pleasecheck: 'Please check the following errors',
    allrightsreserved: 'All Rights Reserved',
    couponinvalid: 'Sorry, the <strong>coupon code</strong> you have entered is invalid. Please check the code and try again.',
    containsinvalid: 'contains invalid characters',
    shippingLabel: '<span class="form__title"><span class="form__title_underline">S</span>hipping</span>',
    paymentInformation: '<span class="form__title"><span  class="form__title_underline">P</span>ayment Information</span>',
    cc: 'Credit Card',
    'address is invalid': 'address is invalid',
    'choose a state': '-Choose a State-',
    problem: 'There was a problem when placing your order.  Pease update the payment information and also wait a minute before trying again.',


  },
  'Germany': {
    firstName: 'Vorname',
    lastName: 'Familienname, Nachname',
    phone: 'Telefon',
    email: 'E-mail-Adresse',
    confirmEmail: 'E-Mail-Bestätigen',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Stadt',
    zip: 'Postleitzahl',
    country: 'Land',
    state: 'Zustand',
    stateLabel: 'Wählen Sie einen Staat',
    'Cart Contents': 'Warenkorb',
    Checkout: 'Auschecken',
    Confirmation: 'Bestätigung',
    'Regular Price': 'Normaler Preis',
    Savings: 'Ersparnisse',
    'Sales Tax': 'Umsatzsteuer',
    Shipping: 'Versand',
    Total: 'Gesamt',
    'Coupon discount': 'Gutscheinrabatt',
    couponMessage: 'Gutscheincode',
    optional: '(optional)',
    Apply: 'Anwenden',
    diffAddress: 'An eine andere Adresse versenden?',
    required: 'Erf orderliche Felder sind in <strong>fett</strong>.',
    billinginformation: '<span class="underline">R</span>echnungsdaten',
    safesecure: 'Ihre Bestellung ist sicher und geschützt',
    orderdetail: 'Bestelldetails',
    item: 'Artikel',
    price: 'Preis',
    subscription: 'Abonnement',
    remember: 'Merken Sie sich meine Informationen',
    onetimepurchase: 'Einmaliger Kauf',
    submitorder: 'Bestellung abschicken',
    isrequired: ' ist ein Pflichtfeld',
    pleaseselect: 'Bitte wählen Sie eine Zahlungsmethode',
    pleasecheck: 'Bitte überprüfen Sie die folgenden Fehler',
    allrightsreserved: 'Alle Rechte vorbehalten',
    couponinvalid: 'Entschuldigung, der <strong>Gutscheincode</strong>, den Sie eingegeben haben, ist ungültig. Bitte überprüfen Sie den Code und versuchen Sie es erneut.',
    containsinvalid: 'enthält ungültige Zeichen',
    shippingLabel: '<span class="form__title"><span class="form__title_underline">V</span>ersand</span>',
    paymentInformation: '<span class="form__title"><span  class="form__title_underline">Z</span>ahlungsinformationen</span>',
    cc: 'Kreditkarte',
    'United States': 'Vereinigte Staaten',
    'United Kingdom': 'Großbritannien',
    'Canada': 'Kanada',
    'Australia': 'Australien',
    'Argentina': 'Argentinien',
    'Chile': 'Chile',
    'Colombia': 'Kolumbien',
    'Denmark': 'Dänemark',
    'France': 'Frankreich',
    'Germany': 'Deutschland',
    'Mexico': 'Mexiko',
    'New Zeland': 'Neuseeland',
    'Peru': 'Peru',
    'Puerto Rico': 'Puerto Rico',
    'Singapore': 'Singapur',
    'Spain': 'Spanien',
    'Virgin Islands, British': 'Virgin Inseln, Britisch',
    'address is invalid': 'Adresse ist ungültig',
    'choose a state': '-Wählen Sie einen Staat-',
    problem: 'Es gab ein Problem beim Platzieren Ihrer Bestellung. Bitte aktualisieren Sie die Zahlungsinformationen und warten Sie auch eine Minute, bevor Sie es erneut versuchen.',

  },
  'Mexico': {
    firstName: 'Nombre de pila',
    lastName: 'Apellido',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    confirmEmail: 'Confirmar correo electrónico',
    address: 'Dirección',
    addressConfirm: 'Dirección 2',
    city: 'Ciudad',
    zip: 'Código postal',
    country: 'País',
    state: 'Estado',
    stateLabel: 'Elige un estado',
    'Cart Contents': 'Contenido del carrito',
    Checkout: 'Finalizar compra',
    Confirmation: 'Confirmación',
    'Regular Price': 'Precio normal',
    Savings: 'Ahorros',
    'Sales Tax': 'Impuesto sobre las ventas',
    Shipping: 'Envío',
    Total: 'Total',
    'Coupon discount': 'Descuento de cupón',
    couponMessage: `Código de cupón`,
    optional: '(opcional)',
    Apply: 'Aplicar',
    diffAddress: 'Enviar a una dirección diferente?',
    required: 'Los campos obligatorios están en <strong>negrita</strong>.',
    billinginformation: '<span class="underline">I</span>nformación de facturación',
    safesecure: 'Tu pedido es seguro y protegido',
    orderdetail: 'Detalles del pedido',
    item: 'Artículo',
    price: 'Precio',
    subscription: 'Suscripción',
    remember: 'Recuerda mi información',
    onetimepurchase: 'Compra única',
    submitorder: 'Enviar pedido',
    isrequired: ' es un campo obligatorio',
    pleaseselect: 'Por favor seleccione un método de pago',
    pleasecheck: 'Por favor, compruebe los siguientes errores',
    allrightsreserved: 'Todos los derechos reservados',
    couponinvalid: 'Lo siento, el <strong>código de cupón</strong> que has ingresado no es válido. Por favor, comprueba el código e inténtalo de nuevo.',
    containsinvalid: 'contiene caracteres no válidos',
    shippingLabel: '<span class="form__title"><span class="form__title_underline">E</span>nviar</span>',
    paymentInformation: '<span class="form__title"><span  class="form__title_underline">I</span>nformación de pago</span>',
    cc: 'Tarjeta de crédito',
    'address is invalid': 'la dirección es inválida',
    'choose a state': '-Elige un estado-',
    problem: 'Hubo un problema al realizar su pedido. Actualice la información de pago y también espere un minuto antes de intentarlo nuevamente.',


  },
  'France': {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    phone: 'Téléphone',
    email: 'E-mail',
    confirmEmail: 'Confirmer l\'e-mail',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Ville',
    zip: 'Code postal',
    country: 'Pays',
    state: 'État',
    stateLabel: 'Choisissez un état',
    'Cart Contents': 'Contenu du panier',
    Checkout: 'Check-out',
    Confirmation: 'Confirmation',
    'Regular Price': 'Prix normal',
    Savings: 'Économies',
    'Sales Tax': 'Taxe de vente',
    Shipping: 'Expédition',
    Total: 'Total',
    'Coupon discount': 'Réduction de coupon',
    couponMessage: 'Code de coupon',
    optional: '(facultatif)',
    Apply: 'Appliquer',
    diffAddress: 'Expédier à une adresse différente?',
    required: 'Les champs obligatoires sont en <strong>gras</strong>.',
    billinginformation: '<span class="underline">I</span>nformations de facturation',
    safesecure: 'Votre commande est sûre et sécurisée',
    orderdetail: 'Détails de la commande',
    item: 'Article',
    price: 'Prix',
    subscription: 'Abonnement',
    remember: 'Rappelez-vous mes informations',
    onetimepurchase: 'Achat unique',
    submitorder: 'Passer la commande',
    isrequired: ' est un champ obligatoire',
    pleaseselect: 'Veuillez sélectionner un mode de paiement',
    pleasecheck: 'Veuillez vérifier les erreurs suivantes',
    allrightsreserved: 'Tous droits réservés',
    couponinvalid: 'Désolé, le <strong>code de coupon</strong> que vous avez saisi n\'est pas valide. Veuillez vérifier le code et réessayer.',
    containsinvalid: 'contient des caractères non valides',
    'address is invalid': 'l\'adresse est invalide',
    'choose a state': '-Choisissez un état-',
    problem: 'Il y a eu un problème lors de la passation de votre commande. Veuillez mettre à jour les informations de paiement et attendre également une minute avant de réessayer.',


  },
  'Russia': {
    firstName: 'Имя',
    lastName: 'Фамилия',
    phone: 'Телефон',
    email: 'Электронная почта',
    confirmEmail: 'Подтвердите адрес электронной почты',
    address: 'Адрес',
    addressConfirm: 'Адрес 2',
    city: 'Город',
    zip: 'Почтовый индекс',
    country: 'Страна',
    state: 'Область',
    stateLabel: 'Выберите область',
    'Cart Contents': 'Содержимое корзины',
    Checkout: 'Проверять, выписываться',
    Confirmation: 'Подтверждение',
    'Regular Price': 'Обычная цена',
    Savings: 'Сбережения',
    'Sales Tax': 'Налог на продажу',
    Shipping: 'Доставка',
    Total: 'Всего',
    'Coupon discount': 'Скидка на купон',
    couponMessage: 'Код купона',
    optional: '(необязательно)',
    Apply: 'Применять',
    diffAddress: 'Отправить на другой адрес?',
    required: 'Обязательные поля выделены <strong>жирным</strong>.',
    billinginformation: '<span class="underline">И</span>нформация о платеже',
    safesecure: 'Ваш заказ защищен и безопасен',
    orderdetail: 'Детали заказа',
    onetimepurchase: 'Одноразовая покупка',
    submitorder: 'Отправить заказ',
    isrequired: ' является обязательным полем',
    subscription: 'Подписка',
    item: 'Пункт',
    price: 'Цена',
    pleaseselect: 'Пожалуйста, выберите способ оплаты',
    pleasecheck: 'Пожалуйста, проверьте следующие ошибки',
    allrightsreserved: 'Все права за',
    couponinvalid: 'Извините, <strong>код купона</strong>, который вы ввели, недействителен. Пожалуйста, проверьте код и попробуйте снова.',
    containsinvalid: 'содержит недопустимые символы',
    'address is invalid': 'адрес недействителен',
    'choose a state': '-Выберите область-',
    'problem': 'При размещении вашего заказа возникла проблема. Пожалуйста, обновите информацию об оплате и также подождите минуту, прежде чем попробовать снова.',


  },
  'China': {
    firstName: '名字',
    lastName: '姓',
    phone: '电话',
    email: '电子邮件',
    confirmEmail: '确认电子邮件',
    address: '地址',
    addressConfirm: '地址 2',
    city: '市',
    zip: '邮政编码',
    country: '国家',
    state: '州',
    stateLabel: '选择一个州',
    'Cart Contents': '购物车内容',
    Checkout: '查看',
    Confirmation: '确认',
    'Regular Price': '普通价格',
    Savings: '节省',
    'Sales Tax': '销售税',
    Shipping: '运费',
    Total: '总',
    'Coupon discount': '优惠券折扣',
    couponMessage: '优惠券代码',
    optional: '(可选)',
    Apply: '应用',
    diffAddress: '发送到不同的地址？',
    required: '必填字段为<strong>粗体</strong>。',
    billinginformation: '<span class="underline">账</span>单信息',
    safesecure: '您的订单是安全的',
    orderdetail: '订单详情',
    onetimepurchase: '一次性购买',
    submitorder: '提交订单',
    isrequired: ' 是必填字段',
    subscription: '订阅',
    item: '项目',
    price: '价格',
    pleaseselect: '请选择付款方式',
    pleasecheck: '请检查以下错误',
    allrightsreserved: '保留所有权利',
    couponinvalid: '抱歉，您输入的<strong>优惠券代码</strong>无效。请检查代码并重试。',
    containsinvalid: '包含无效字符',
    'address is invalid': '地址无效',
    'choose a state': '-选择一个州-',
    'problem': '下订单时出现问题。 请更新付款信息，并在再次尝试之前等待一分钟。',

  },
  'Greece': {
    firstName: 'Όνομα',
    lastName: 'Επίθετο',
    phone: 'Τηλέφωνο',
    email: 'Ηλεκτρονικό ταχυδρομείο',
    confirmEmail: 'Επιβεβαιώστε το email',
    address: 'Διεύθυνση',
    addressConfirm: 'Διεύθυνση 2',
    city: 'Πόλη',
    zip: 'Ταχυδρομικός κώδικας',
    country: 'Χώρα',
    state: 'Πολιτεία',
    stateLabel: 'Επιλέξτε μια κατάσταση',
    'Cart Contents': 'Περιεχόμενα καλαθιού',
    Checkout: 'Αποχωρήστε',
    Confirmation: 'Επιβεβαίωση',
    'Regular Price': 'Κανονική τιμή',
    Savings: 'Εξοικονομήσεις',
    'Sales Tax': 'Φόρος πωλήσεων',
    Shipping: 'Αποστολή',
    Total: 'Σύνολο',
    'Coupon discount': 'Έκπτωση κουπονιού',
    couponMessage: 'Κωδικός κουπονιού',
    optional: '(προαιρετικό)',
    Apply: 'Ισχύει',
    diffAddress: 'Αποστολή σε διαφορετική διεύθυνση;',
    required: 'Τα υποχρεωτικά πεδία είναι σε <strong>έντονη</strong>.',
    billinginformation: '<span class="underline">Π</span>ληροφορίες πληρωμής',
    safesecure: 'Η παραγγελία σας είναι ασφαλής και ασφαλής',
    orderdetail: 'Λεπτομέρειες παραγγελίας',
    item: 'Είδος',
    price: 'Τιμή',
    subscription: 'Συνδρομή',
    remember: 'Θυμήσου τις πληροφορίες μου',
    onetimepurchase: 'Μια φορά αγορά',
    submitorder: 'Υποβολή παραγγελίας',
    isrequired: ' είναι υποχρεωτικό πεδίο',
    pleaseselect: 'Παρακαλώ επιλέξτε μέθοδο πληρωμής',
    pleasecheck: 'Παρακαλώ ελέγξτε τα παρακάτω σφάλματα',
    allrightsreserved: 'Με επιφύλαξη παντός δικαιώματος',
    couponinvalid: 'Λυπούμαστε, ο <strong>κωδικός κουπονιού</strong> που εισαγάγατε δεν είναι έγκυρος. Ελέγξτε τον κωδικό και δοκιμάστε ξανά.',
    containsinvalid: 'περιέχει μη έγκυρους χαρακτήρες',
    'address is invalid': 'η διεύθυνση δεν είναι έγκυρη',
    'choose a state': '-Επιλέξτε μια κατάσταση-',
    'problem': 'Υπήρξε πρόβλημα κατά την τοποθέτηση της παραγγελίας σας. Ενημερώστε τις πληροφορίες πληρωμής και περιμένετε επίσης ένα λεπτό πριν δοκιμάσετε ξανά.',


  },
  'Austria': {
    firstName: 'Vorname',
    lastName: 'Familienname, Nachname',
    phone: 'Telefon',
    email: 'E-mail-Adresse',
    confirmEmail: 'E-Mail-Bestätigen',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Stadt',
    zip: 'Postleitzahl',
    country: 'Land',
    state: 'Zustand',
    stateLabel: 'Wählen Sie einen Staat',
    'Cart Contents': 'Warenkorb',
    Checkout: 'Auschecken',
    Confirmation: 'Bestätigung',
    'Regular Price': 'Normaler Preis',
    Savings: 'Ersparnisse',
    'Sales Tax': 'Umsatzsteuer',
    Shipping: 'Versand',
    Total: 'Gesamt',
    'Coupon discount': 'Gutscheinrabatt',
    couponMessage: 'Gutscheincode',
    optional: '(optional)',
    Apply: 'Anwenden',
    diffAddress: 'An eine andere Adresse versenden?',
    required: 'Erf orderliche Felder sind in <strong>fett</strong>.',
    billinginformation: '<span class="underline">R</span>echnungsdaten',
    safesecure: 'Ihre Bestellung ist sicher und geschützt',
    orderdetail: 'Bestelldetails',
    item: 'Artikel',
    price: 'Preis',
    subscription: 'Abonnement',
    remember: 'Merken Sie sich meine Informationen',
    onetimepurchase: 'Einmaliger Kauf',
    submitorder: 'Bestellung abschicken',
    isrequired: ' ist ein Pflichtfeld',
    pleaseselect: 'Bitte wählen Sie eine Zahlungsmethode',
    pleasecheck: 'Bitte überprüfen Sie die folgenden Fehler',
    allrightsreserved: 'Alle Rechte vorbehalten',
    couponinvalid: 'Entschuldigung, der <strong>Gutscheincode</strong>, den Sie eingegeben haben, ist ungültig. Bitte überprüfen Sie den Code und versuchen Sie es erneut.',
    containsinvalid: 'enthält ungültige Zeichen',
    'address is invalid': 'Adresse ist ungültig',
    'choose a state': '-Wählen Sie einen Staat-',
    problem: 'Es gab ein Problem beim Platzieren Ihrer Bestellung. Bitte aktualisieren Sie die Zahlungsinformationen und warten Sie auch eine Minute, bevor Sie es erneut versuchen.',


  },
  'Belgium': {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    phone: 'Téléphone',
    email: 'E-mail',
    confirmEmail: 'Confirmer l\'e-mail',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Ville',
    zip: 'Code postal',
    country: 'Pays',
    state: 'État',
    stateLabel: 'Choisissez un état',
    'Cart Contents': 'Contenu du panier',
    Checkout: 'Check-out',
    Confirmation: 'Confirmation',
    'Regular Price': 'Prix normal',
    Savings: 'Économies',
    'Sales Tax': 'Taxe de vente',
    Shipping: 'Expédition',
    Total: 'Total',
    'Coupon discount': 'Réduction de coupon',
    couponMessage: 'Code de coupon',
    optional: '(facultatif)',
    Apply: 'Appliquer',
    diffAddress: 'Expédier à une adresse différente?',
    required: 'Les champs obligatoires sont en <strong>gras</strong>.',
    billinginformation: '<span class="underline">I</span>nformations de facturation',
    safesecure: 'Votre commande est sûre et sécurisée',
    orderdetail: 'Détails de la commande',
    item: 'Article',
    price: 'Prix',
    subscription: 'Abonnement',
    remember: 'Rappelez-vous mes informations',
    onetimepurchase: 'Achat unique',
    submitorder: 'Passer la commande',
    isrequired: ' est un champ obligatoire',
    pleaseselect: 'Veuillez sélectionner un mode de paiement',
    pleasecheck: 'Veuillez vérifier les erreurs suivantes',
    allrightsreserved: 'Tous droits réservés',
    couponinvalid: 'Désolé, le <strong>code de coupon</strong>, que vous avez saisi n\'est pas valide. Veuillez vérifier le code et réessayer.',
    containsinvalid: 'contient des caractères non valides',
    'address is invalid': 'l\'adresse est invalide',
    'choose a state': '-Choisissez un état-',
    problem: 'Il y a eu un problème lors de la passation de votre commande. Veuillez mettre à jour les informations de paiement et attendre également une minute avant de réessayer.',


  },
  'Bulgaria': {
    firstName: 'Име',
    lastName: 'Фамилия',
    phone: 'Телефон',
    email: 'Имейл',
    confirmEmail: 'Потвърдете имейла',
    address: 'Адрес',
    addressConfirm: 'Адрес 2',
    city: 'Град',
    zip: 'Пощенски код',
    country: 'Страна',
    state: 'Област',
    stateLabel: 'Изберете област',
    'Cart Contents': 'Съдържание на количката',
    Checkout: 'Проверка',
    Confirmation: 'Потвърждение',
    'Regular Price': 'Редовна цена',
    Savings: 'Спестявания',
    'Sales Tax': 'Данък върху продажбите',
    Shipping: 'Доставка',
    Total: 'Общо',
    'Coupon discount': 'Купон за отстъпка',
    couponMessage: 'Код на купона',
    optional: '(по избор)',
    Apply: 'Приложете',
    diffAddress: 'Изпратете на различен адрес?',
    required: 'Задължителните полета са в <strong>получер</strong>.',
    billinginformation: '<span class="underline">П</span>латежна информация',
    safesecure: 'Вашата поръчка е безопасна и защитена',
    orderdetail: 'Детайли за поръчката',
    item: 'Пункт',
    price: 'Цена',
    subscription: 'Абонамент',
    remember: 'Запомни моята информация',
    onetimepurchase: 'Еднократна покупка',
    submitorder: 'Подайте поръчка',
    isrequired: ' е задължително поле',
    pleaseselect: 'Моля, изберете начин на плащане',
    pleasecheck: 'Моля, проверете след грешки',
    allrightsreserved: 'Всички права запазени',
    couponinvalid: 'Съжаляваме, <strong>кода на купона</strong>, който сте въвели, е невалиден. Моля, проверете кода и опитайте отново.',
    containsinvalid: 'съдържа невалидни символи',
    'address is invalid': 'адресът е невалиден',
    'choose a state': '-Изберете област-',
    problem: 'Възникна проблем при плащането на вашата поръчка. Моля, актуализирайте информацията за плащане и изчакайте минута, преди да опитате отново.',


  },
  'Croatia': {
    firstName: 'Ime',
    lastName: 'Prezime',
    phone: 'Telefon',
    email: 'E-mail',
    confirmEmail: 'Potvrdite e-mail',
    address: 'Adresa',
    addressConfirm: 'Adresa 2',
    city: 'Grad',
    zip: 'Poštanski broj',
    country: 'Zemlja',
    state: 'Država',
    stateLabel: 'Odaberite državu',
    'Cart Contents': 'Sadržaj košarice',
    Checkout: 'Provjeri',
    Confirmation: 'Potvrda',
    'Regular Price': 'Redovna cijena',
    Savings: 'Ušteda',
    'Sales Tax': 'Porez na promet',
    Shipping: 'Isporuka',
    Total: 'Ukupno',
    'Coupon discount': 'Popust na kupon',
    couponMessage: 'Kod kupona',
    optional: '(neobavezno)',
    Apply: 'Primijeni',
    diffAddress: 'Pošalji na drugu adresu?',
    required: 'Obavezna polja su podebljana.',
    billinginformation: '<span class="underline">P</span>odaci o naplati',
    safesecure: 'Vaša narudžba je sigurna i zaštićena',
    orderdetail: 'Detalji narudžbe',
    item: 'Artikl',
    price: 'Cijena',
    subscription: 'Pretplata',
    remember: 'Zapamti moje podatke',
    onetimepurchase: 'Jednokratna kupnja',
    submitorder: 'Podnesi narudžbu',
    isrequired: ' je obavezno polje',
    pleaseselect: 'Molimo odaberite način plaćanja',
    pleasecheck: 'Molimo provjerite sljedeće pogreške',
    allrightsreserved: 'Sva prava pridržana',
    couponinvalid: 'Žao nam je, <strong>kôd kupona</strong> koji ste unijeli nije valjan. Provjerite kôd i pokušajte ponovno.',
    containsinvalid: 'sadrži nevažeće znakove',
    'address is invalid': 'adresa je nevažeća',
    'choose a state': '-Odaberite državu-',
    problem: 'Došlo je do problema prilikom postavljanja vaše narudžbe. Ažurirajte podatke o plaćanju i pričekajte i minutu prije nego što pokušate ponovno.',


  },
  'Cyprus': {
    firstName: 'Όνομα',
    lastName: 'Επώνυμο',
    phone: 'Τηλέφωνο',
    email: 'Ηλεκτρονικό ταχυδρομείο',
    confirmEmail: 'Επιβεβαιώστε το email',
    address: 'Διεύθυνση',
    addressConfirm: 'Διεύθυνση 2',
    city: 'Πόλη',
    zip: 'Ταχυδρομικός κώδικας',
    country: 'Χώρα',
    state: 'Πολιτεία',
    stateLabel: 'Επιλέξτε μια κατάσταση',
    'Cart Contents': 'Περιεχόμενα καλαθιού',
    Checkout: 'Αποχωρήστε',
    Confirmation: 'Επιβεβαίωση',
    'Regular Price': 'Κανονική τιμή',
    Savings: 'Εξοικονομήσεις',
    'Sales Tax': 'Φόρος πωλήσεων',
    Shipping: 'Αποστολή',
    Total: 'Σύνολο',
    'Coupon discount': 'Έκπτωση κουπονιού',
    couponMessage: 'Κωδικός κουπονιού',
    optional: '(προαιρετικό)',
    Apply: 'Ισχύει',
    diffAddress: 'Αποστολή σε διαφορετική διεύθυνση;',
    required: 'Τα υποχρεωτικά πεδία είναι σε <strong>έντονη</strong>.',
    billinginformation: '<span class="underline">Π</span>ληροφορίες πληρωμής',
    safesecure: 'Η παραγγελία σας είναι ασφαλής και ασφαλής',
    orderdetail: 'Λεπτομέρειες παραγγελίας',
    item: 'Είδος',
    price: 'Τιμή',
    subscription: 'Συνδρομή',
    remember: 'Θυμήσου τις πληροφορίες μου',
    onetimepurchase: 'Μια φορά αγορά',
    submitorder: 'Υποβολή παραγγελίας',
    isrequired: ' είναι υποχρεωτικό πεδίο',
    pleaseselect: 'Παρακαλώ επιλέξτε μέθοδο πληρωμής',
    pleasecheck: 'Παρακαλώ ελέγξτε τα παρακάτω σφάλματα',
    allrightsreserved: 'Με επιφύλαξη παντός δικαιώματος',
    couponinvalid: 'Λυπούμαστε, <strong>κωδικός κουπονιού</strong>, που εισαγάγατε δεν είναι έγκυρος. Παρακαλώ ελέγξτε τον κωδικό και δοκιμάστε ξανά.',
    containsinvalid: 'περιέχει μη έγκυρους χαρακτήρες',
    'address is invalid': 'η διεύθυνση είναι άκυρη',
    'choose a state': '-Επιλέξτε μια κατάσταση-',
    problem: 'Προέκυψε πρόβλημα κατά την τοποθέτηση της παραγγελίας σας. Παρακαλώ ενημερώστε τις πληροφορίες πληρωμής και περιμένετε επίσης ένα λεπτό πριν δοκιμάσετε ξανά.',



  },
  'Czech Republic': {
    firstName: 'Jméno',
    lastName: 'Příjmení',
    phone: 'Telefon',
    email: 'E-mail',
    confirmEmail: 'Potvrďte e-mail',
    address: 'Adresa',
    addressConfirm: 'Adresa 2',
    city: 'Město',
    zip: 'PSČ',
    country: 'Země',
    state: 'Stát',
    stateLabel: 'Vyberte stát',
    'Cart Contents': 'Obsah košíku',
    Checkout: 'Pokladna',
    Confirmation: 'Potvrzení',
    'Regular Price': 'Běžná cena',
    Savings: 'Úspory',
    'Sales Tax': 'Daň z prodeje',
    Shipping: 'Doprava',
    Total: 'Celkem',
    'Coupon discount': 'Sleva na kupón',
    couponMessage: 'Kód kupónu',
    optional: '(volitelné)',
    Apply: 'Použít',
    diffAddress: 'Odeslat na jinou adresu?',
    required: 'Povinné položky jsou tučné.',
    billinginformation: '<span class="underline">F</span>akturační údaje',
    safesecure: 'Vaše objednávka je bezpečná a zabezpečená',
    orderdetail: 'Podrobnosti objednávky',
    item: 'Položka',
    price: 'Cena',
    subscription: 'Předplatné',
    remember: 'Zapamatujte si mé informace',
    onetimepurchase: 'Jednorázový nákup',
    submitorder: 'Odeslat objednávku',
    isrequired: ' je povinné pole',
    pleaseselect: 'Vyberte prosím způsob platby',
    pleasecheck: 'Zkontrolujte prosím následující chyby',
    allrightsreserved: 'Všechna prá va vyhrazena',
    couponinvalid: 'Omlouváme se, <strong>kód kupónu</strong>, který jste zadali, není platný. Zkontrolujte kód a zkuste to znovu.',
    containsinvalid: 'obsahuje neplatné znaky',
    'address is invalid': 'adresa je neplatná',
    'choose a state': '-Vyberte stát-',


  },
  'Denmark': {
    firstName: 'Fornavn',
    lastName: 'Efternavn',
    phone: 'Telefon',
    email: 'E-mail',
    confirmEmail: 'Bekræft e-mail',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'By',
    zip: 'Postnummer',
    country: 'Land',
    state: 'Stat',
    stateLabel: 'Vælg en stat',
    'Cart Contents': 'Indhold af indkøbskurv',
    Checkout: 'Kassen',
    Confirmation: 'Bekræftelse',
    'Regular Price': 'Normal pris',
    Savings: 'Besparelser',
    'Sales Tax': 'Moms',
    Shipping: 'Forsendelse',
    Total: 'Total',
    'Coupon discount': 'Kuponrabat',
    couponMessage: 'Kuponkode',
    optional: '(valgfri)',
    Apply: 'Anvende',
    diffAddress: 'Send til en anden adresse?',
    required: 'Obligatoriske felter er i <strong>fet</strong>.',
    billinginformation: '<span class="underline">F</span>aktureringsoplysninger',
    safesecure: 'Din ordre er sikker og sikker',
    orderdetail: 'Ordredetaljer',
    item: 'Vare',
    price: 'Pris',
    subscription: 'Abonnement',
    remember: 'Husk mine oplysninger',
    onetimepurchase: 'Engangskøb',
    submitorder: 'Indsend ordre',
    isrequired: ' er et obligatorisk felt',
    pleaseselect: 'Vælg venligst en betalingsmetode',
    pleasecheck: 'Kontroller venligst følgende fejl',
    allrightsreserved: 'Alle rettigheder forbeholdes',
    couponinvalid: 'Beklager, den <strong>kuponkode</strong>, du har indtastet, er ugyldig. Kontroller koden og prøv igen.',
    containsinvalid: 'indeholder ugyldige tegn',
    'address is invalid': 'adressen er ugyldig',
    'choose a state': '-Vælg en stat-',

  },
  'Estonia': {
    firstName: 'Eesnimi',
    lastName: 'Perekonnanimi',
    phone: 'Telefon',
    email: 'E-post',
    confirmEmail: 'Kinnita e-post',
    address: 'Aadress',
    addressConfirm: 'Aadress 2',
    city: 'Linn',
    zip: 'Postiindeks',
    country: 'Riik',
    state: 'Osariik',
    stateLabel: 'Valige osariik',
    'Cart Contents': 'Korvi sisu',
    Checkout: 'Kassasse',
    Confirmation: 'Kinnitus',
    'Regular Price': 'Tavahind',
    Savings: 'Säästud',
    'Sales Tax': 'Müügimaks',
    Shipping: 'Kohaletoimetamine',
    Total: 'Kokku',
    'Coupon discount': 'Kupongi allahindlus',
    couponMessage: 'Kupongikood',
    optional: '(valikuline)',
    Apply: 'Kohaldatakse',
    diffAddress: 'Saada teisele aadressile?',
    required: 'Kohustuslikud väljad on <strong>rasvased</strong>.',
    billinginformation: '<span class="underline">A</span>ruandlus',
    safesecure: 'Teie tellimus on turvaline ja kaitstud',
    orderdetail: 'Tellimuse üksikasjad',
    item: 'Toode',
    price: 'Hind',
    subscription: 'Tellimus',
    remember: 'Mäleta minu andmeid',
    onetimepurchase: 'Ühekordne ost',
    submitorder: 'Esita tellimus',
    isrequired: ' on kohustuslik väli',
    pleaseselect: 'Palun valige makseviis',
    pleasecheck: 'Palun kontrollige järgmisi vigu',
    allrightsreserved: 'Kõik õigused kaitstud',
    couponinvalid: 'Vabandust, <strong>kupongikood</strong>, mille sisestasite, ei kehti. Kontrollige koodi ja proovige uuesti.',
    containsinvalid: 'sisaldab kehtetuid märke',
    'address is invalid': 'aadress on kehtetu',
    'choose a state': '-Valige osariik-',


  },
  'Finland': {
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    phone: 'Puhelin',
    email: 'Sähköposti',
    confirmEmail: 'Vahvista sähköposti',
    address: 'Osoite',
    addressConfirm: 'Osoite 2',
    city: 'Kaupunki',
    zip: 'Postinumero',
    country: 'Maa',
    state: 'Osavaltio',
    stateLabel: 'Valitse osavaltio',
    'Cart Contents': 'Korin sisältö',
    Checkout: 'Kassalle',
    Confirmation: 'Vahvistus',
    'Regular Price': 'Normaali hinta',
    Savings: 'Säästöt',
    'Sales Tax': 'Myynnin vero',
    Shipping: 'Lähetys',
    Total: 'Kaikki yhteensä',
    'Coupon discount': 'Kuponkialennus',
    couponMessage: 'Kuponkikoodi',
    optional: '(valinnainen)',
    Apply: 'Käytä',
    diffAddress: 'Lähetä eri osoitteeseen?',
    required: 'Pakolliset kentät ovat <strong>lihavoituja</strong>.',
    billinginformation: '<span class="underline">L</span>askutustiedot',
    safesecure: 'Tilaus on turvallinen ja suojattu',
    orderdetail: 'Tilauksen tiedot',
    item: 'Tuote',
    price: 'Hinta',
    subscription: 'Tilaus',
    remember: 'Muista tietoni',
    onetimepurchase: 'Yksittäinen ostos',
    submitorder: 'Lähetä tilaus',
    isrequired: ' on pakollinen kenttä',
    pleaseselect: 'Valitse maksutapa',
    pleasecheck: 'Tarkista seuraavat virheet',
    allrightsreserved: 'Kaikki oikeudet pidätetään',
    couponinvalid: 'Anteeksi, <strong>kuponkikoodi</strong>, jonka olet syöttänyt, ei kelpaa. Tarkista koodi ja yritä uudelleen.',
    containsinvalid: 'sisältää virheellisiä merkkejä',
    'address is invalid': 'osoite on virheellinen',
    'choose a state': '-Valitse osavaltio-',

  },
  'Hungary': {
    firstName: 'Keresztnév',
    lastName: 'Vezetéknév',
    phone: 'Telefon',
    email: 'E-mail',
    confirmEmail: 'E-mail megerősítése',
    address: 'Cím',
    addressConfirm: 'Cím 2',
    city: 'Város',
    zip: 'Irányítószám',
    country: 'Ország',
    state: 'Állam',
    stateLabel: 'Válassz államot',
    'Cart Contents': 'Kosár tartalma',
    Checkout: 'Pénztár',
    Confirmation: 'Megerősítés',
    'Regular Price': 'Normál ár',
    Savings: 'Megtakarítás',
    'Sales Tax': 'ÁFA',
    Shipping: 'Szállítás',
    Total: 'Teljes',
    'Coupon discount': 'Kuponkedvezmény',
    couponMessage: 'Kuponkód',
    optional: '(választható)',
    Apply: 'Alkalmaz',
    diffAddress: 'Küldjön más címre?',
    required: 'A kötelező mezők <strong>félkövéren</strong> szerepelnek.',
    billinginformation: '<span class="underline">S</span>zámlázási információk',
    safesecure: 'A rendelés biztonságos és védett',
    orderdetail: 'Rendelési részletek',
    item: 'Tétel',
    price: 'Ár',
    subscription: 'Előfizetés',
    remember: 'Emlékezzen az adataimra',
    onetimepurchase: 'Egyszeri vásárlás',
    submitorder: 'Rendelés elküldése',
    isrequired: ' kötelező mező',
    pleaseselect: 'Kérjük, válasszon fizetési módot',
    pleasecheck: 'Kérjük, ellenőrizze a következő hibákat',
    allrightsreserved: 'Minden jog fenntartva',
    couponinvalid: 'Sajnáljuk, a <strong>kuponkód</strong>, amelyet megadott, érvénytelen. Ellenőrizze a kódot, majd próbálja újra.',
    containsinvalid: 'érvénytelen karaktereket tartalmaz',
    'address is invalid': 'a cím érvénytelen',
    'choose a state': '-Válassz államot-',

  },
  'Italy': {
    firstName: 'Nome',
    lastName: 'Cognome',
    phone: 'Telefono',
    email: 'E-mail',
    confirmEmail: 'Conferma e-mail',
    address: 'Indirizzo',
    addressConfirm: 'Indirizzo 2',
    city: 'Città',
    zip: 'CAP',
    country: 'Paese',
    state: 'Stato',
    stateLabel: 'Scegli uno stato',
    'Cart Contents': 'Contenuto del carrello',
    Checkout: 'Check-out',
    Confirmation: 'Conferma',
    'Regular Price': 'Prezzo normale',
    Savings: 'Risparmi',
    'Sales Tax': 'Imposta sulle vendite',
    Shipping: 'Spedizione',
    Total: 'Totale',
    'Coupon discount': 'Sconto coupon',
    couponMessage: 'Codice coupon',
    optional: '(opzionale)',
    Apply: 'Applicare',
    diffAddress: 'Spedisci a un altro indirizzo?',
    required: 'I campi obbligatori sono in <strong>grassetto</strong>.',
    billinginformation: '<span class="underline">I</span>nformazioni di fatturazione',
    safesecure: 'Il tuo ordine è sicuro e protetto',
    orderdetail: 'Dettagli dell\'ordine',
    item: 'Articolo',
    price: 'Prezzo',
    subscription: 'Abbonamento',
    remember: 'Ricorda le mie informazioni',
    onetimepurchase: 'Acquisto singolo',
    submitorder: 'Invia ordine',
    isrequired: ' è un campo obbligatorio',
    pleaseselect: 'Si prega di selezionare un metodo di pagamento',
    pleasecheck: 'Si prega di controllare gli errori seguenti',
    allrightsreserved: 'Tutti i diritti riservati',
    couponinvalid: 'Ci dispiace, il <strong>codice coupon</strong> che hai inserito non è valido. Controlla il codice e riprova.',
    containsinvalid: 'contiene caratteri non validi',
    'address is invalid': 'l\'indirizzo non è valido',
    'choose a state': '-Scegli uno stato-',

  },
  'Latvia': {
    firstName: 'Vārds',
    lastName: 'Uzvārds',
    phone: 'Telefons',
    email: 'E-pasts',
    confirmEmail: 'Apstipriniet e-pastu',
    address: 'Adrese',
    addressConfirm: 'Adrese 2',
    city: 'Pilsēta',
    zip: 'Pasta indekss',
    country: 'Valsts',
    state: 'Valsts',
    stateLabel: 'Izvēlieties valsti',
    'Cart Contents': 'Groza saturs',
    Checkout: 'Izrakstīties',
    Confirmation: 'Apstiprinājums',
    'Regular Price': 'Parasts cena',
    Savings: 'Ietaupījumi',
    'Sales Tax': 'Pārdošanas nodoklis',
    Shipping: 'Piegāde',
    Total: 'Kopā',
    'Coupon discount': 'Kupona atlaide',
    couponMessage: 'Kupona kods',
    optional: '(pēc izvēles)',
    Apply: 'Pieteikties',
    diffAddress: 'Nosūtīt uz citu adresi?',
    required: 'Obligātie lauki ir <strong>pustreknē</strong>.',
    billinginformation: '<span class="underline">A</span>pmaksas informācija',
    safesecure: 'Jūsu pasūtījums ir drošs un aizsargāts',
    orderdetail: 'Pasūtījuma detaļas',
    item: 'Prece',
    price: 'Cena',
    subscription: 'Abonements',
    remember: 'Atcerēties manu informāciju',
    onetimepurchase: 'Vienreizēja pirkšana',
    submitorder: 'Iesniegt pasūtījumu',
    isrequired: ' ir obligāts lauks',
    pleaseselect: 'Lūdzu, izvēlieties maksājuma veidu',
    pleasecheck: 'Lūdzu, pārbaudiet sekojošas kļūdas',
    allrightsreserved: 'Visas tiesības aizsargātas',
    couponinvalid: 'Atvainojiet, <strong>kupona kods</strong>, ko ievadījāt, nav derīgs. Pārbaudiet kodu un mēģiniet vēlreiz.',
    containsinvalid: 'satur nederīgus simbolus',
    'address is invalid': 'adrese nav derīga',
    'choose a state': '-Izvēlieties valsti-',

  },
  'Lithuania': {
    firstName: 'Gavėjo vardas',
    lastName: 'Pavardė',
    phone: 'Telefonas',
    email: 'El. paštas',
    confirmEmail: 'Patvirtinkite el. paštą',
    address: 'Adresas',
    addressConfirm: 'Adresas 2',
    city: 'Miestas',
    zip: 'Pašto kodas',
    country: 'Šalis',
    state: 'Valstybė',
    stateLabel: 'Pasirinkite valstiją',
    'Cart Contents': 'Krepšelio turinys',
    Checkout: 'Atsiskaitymas',
    Confirmation: 'Patvirtinimas',
    'Regular Price': 'Įprasta kaina',
    Savings: 'Taupymas',
    'Sales Tax': 'PVM',
    Shipping: 'Pristatymas',
    Total: 'Viso',
    'Coupon discount': 'Kupono nuolaida',
    couponMessage: 'Kupono kodas',
    optional: '(neprivaloma)',
    Apply: 'Taikyti',
    diffAddress: 'Pristatyti į kitą adresą?',
    required: 'Privalomi laukai yra <strong>paryškinti</strong>.',
    billinginformation: '<span class="underline">A</span>pmokėjimo informacija',
    safesecure: 'Jūsų užsakymas yra saugus ir apsaugotas',
    orderdetail: 'Užsakymo informacija',
    item: 'Prekė',
    price: 'Kaina',
    subscription: 'Prenumerata',
    remember: 'Prisiminti mano informaciją',
    onetimepurchase: 'Vienkartinis pirkimas',
    submitorder: 'Pateikti užsakymą',
    isrequired: ' yra privalomas laukas',
    pleaseselect: 'Prašome pasirinkti mokėjimo būdą',
    pleasecheck: 'Prašome patikrinti šias klaidas',
    allrightsreserved: 'Visos teisės saugomos',
    couponinvalid: 'Atsiprašome, <strong>kupono kodas</strong>, kurį įvedėte, yra neteisingas. Patikrinkite kodą ir bandykite iš naujo.',
    containsinvalid: 'turi netinkamus simbolius',
    'address is invalid': 'adresas yra neteisingas',
    'choose a state': '-Pasirinkite valstiją-',

  },
  'Luxembourg': {
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    phone: 'Téléphone',
    email: 'E-mail',
    confirmEmail: 'Confirmer l\'e-mail',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Ville',
    zip: 'Code postal',
    country: 'Pays',
    state: 'État',
    stateLabel: 'Choisissez un état',
    'Cart Contents': 'Contenu du panier',
    Checkout: 'Check-out',
    Confirmation: 'Confirmation',
    'Regular Price': 'Prix normal',
    Savings: 'Économies',
    'Sales Tax': 'Taxe de vente',
    Shipping: 'Expédition',
    Total: 'Total',
    'Coupon discount': 'Réduction de coupon',
    couponMessage: 'Code de coupon',
    optional: '(facultatif)',
    Apply: 'Appliquer',
    diffAddress: 'Expédier à une adresse différente?',
    required: 'Les champs obligatoires sont en <strong>gras</strong>.',
    billinginformation: '<span class="underline">I</span>nformations de facturation',
    safesecure: 'Votre commande est sûre et sécurisée',
    orderdetail: 'Détails de la commande',
    item: 'Article',
    price: 'Prix',
    subscription: 'Abonnement',
    remember: 'Rappelez-vous mes informations',
    onetimepurchase: 'Achat unique',
    submitorder: 'Passer la commande',
    isrequired: ' est un champ obligatoire',
    pleaseselect: 'Veuillez sélectionner un mode de paiement',
    pleasecheck: 'Veuillez vérifier les erreurs suivantes',
    allrightsreserved: 'Tous droits réservés',
    couponinvalid: 'Désolé, le <strong>code coupon</strong> que vous avez saisi n\'est pas valide. Veuillez vérifier le code et réessayer.',
    containsinvalid: 'contient des caractères non valides',
    'address is invalid': 'l\'adresse est invalide',
    'choose a state': '-Choisissez un état-',

  },
  'Malta': {
    firstName: 'Isem',
    lastName: 'Isem tal-familja',
    phone: 'Telefon',
    email: 'E-mail',
    confirmEmail: 'Ikkonferma l-email',
    address: 'Indirizz',
    addressConfirm: 'Indirizz 2',
    city: 'Belt',
    zip: 'Kodiċi postali',
    country: 'Pajjiż',
    state: 'Stat',
    stateLabel: 'Agħżel stat',
    'Cart Contents': 'Kontenut tal-karozza',
    Checkout: 'Checkout',
    Confirmation: 'Konferma',
    'Regular Price': 'Prezz regolari',
    Savings: 'Tispiża',
    'Sales Tax': 'Taxxa fuq il-bejgħ',
    Shipping: 'Trasport',
    Total: 'Total',
    'Coupon discount': 'Diskont tal-kupun',
    couponMessage: 'Kodiċi tal-kupun',
    optional: '(opzjonali)',
    Apply: 'Applika',
    diffAddress: 'Ibgħat għal indirizz differenti?',
    required: 'Il-qamħ obbligatorju huwa <strong>bold</strong>.',
    billinginformation: '<span class="underline">I</span>nformazzjoni tal-fatturazzjoni',
    safesecure: 'It-tella tiegħek hija sikura u protetta',
    orderdetail: 'Dettalji tal-ordni',
    item: 'Artiklu',
    price: 'Prezz',
    subscription: 'Sottoskritt',
    remember: 'Remind informazzjoni tiegħi',
    onetimepurchase: 'Xiri wieħed darba',
    submitorder: 'Issottometti l-ordni',
    isrequired: ' huwa kamp obbligatorju',
    pleaseselect: 'Jekk jogħġbok agħżel mod ta \'pagament',
    pleasecheck: 'Jekk jogħġbok iċċekkja l-ħsarat li ġejjin',
    allrightsreserved: 'Kull drittijiet riservati',
    couponinvalid: 'Ci dispiace, il <strong>codice coupon</strong> che hai inserito non è valido. Controlla il codice e riprova.',
    containsinvalid: 'contiene caratteri non validi',
    'address is invalid': 'l\'indirizz huwa invalidu',

  },
  'The Netherlands': {
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    phone: 'Telefoon',
    email: 'E-mail',
    confirmEmail: 'Bevestig e-mail',
    address: 'Adres',
    addressConfirm: 'Adres 2',
    city: 'Stad',
    zip: 'Postcode',
    country: 'Land',
    state: 'Staat',
    stateLabel: 'Kies een staat',
    'Cart Contents': 'Winkelwagen inhoud',
    Checkout: 'Afrekenen',
    Confirmation: 'Bevestiging',
    'Regular Price': 'Normale prijs',
    Savings: 'Besparingen',
    'Sales Tax': 'Omzetbelasting',
    Shipping: 'Verzending',
    Total: 'Totaal',
    'Coupon discount': 'Couponkorting',
    couponMessage: 'Couponcode',
    optional: '(optioneel)',
    Apply: 'Toepassen',
    diffAddress: 'Verzenden naar een ander adres?',
    required: 'Verplichte velden zijn in <strong>vetgedrukt</strong>.',
    billinginformation: '<span class="underline">F</span>actuurgegevens',
    safesecure: 'Uw bestelling is veilig en beveiligd',
    orderdetail: 'Bestellingsdetails',
    item: 'Artikel',
    price: 'Prijs',
    subscription: 'Abonnement',
    remember: 'Onthoud mijn gegevens',
    onetimepurchase: 'Eenmalige aankoop',
    submitorder: 'Bestelling plaatsen',
    isrequired: ' is een verplicht veld',
    pleaseselect: 'Selecteer een betaalmethode',
    pleasecheck: 'Controleer de volgende fouten',
    allrightsreserved: 'Alle rechten voorbehouden',
    couponinvalid: 'Sorry, de <strong>couponcode</strong> die u heeft ingevoerd, is ongeldig. Controleer de code en probeer het opnieuw.',
    containsinvalid: 'bevat ongeldige tekens',
    'address is invalid': 'het adres is ongeldig',
    'choose a state': '-Kies een staat-',


  },
  'Poland': {
    firstName: 'Imię',
    lastName: 'Nazwisko',
    phone: 'Telefon',
    email: 'E-mail',
    confirmEmail: 'Potwierdź e-mail',
    address: 'Adres',
    addressConfirm: 'Adres 2',
    city: 'Miasto',
    zip: 'Kod pocztowy',
    country: 'Kraj',
    state: 'Stan',
    stateLabel: 'Wybierz stan',
    'Cart Contents': 'Zawartość koszyka',
    Checkout: 'Zamówienie',
    Confirmation: 'Potwierdzenie',
    'Regular Price': 'Cena regularna',
    Savings: 'Oszczędności',
    'Sales Tax': 'Podatek od sprzedaży',
    Shipping: 'Wysyłka',
    Total: 'Całkowity',
    'Coupon discount': 'Kupon rabatowy',
    couponMessage: 'Kod kuponu',
    optional: '(opcjonalny)',
    Apply: 'Zastosować',
    diffAddress: 'Wysłać na inny adres?',
    required: 'Wymagane pola są <strong>pogrubione</strong>.',
    billinginformation: '<span class="underline">I</span>nformacje do faktury',
    safesecure: 'Twoje zamówienie jest bezpieczne i chronione',
    orderdetail: 'Szczegóły zamówienia',
    item: 'Przedmiot',
    price: 'Cena',
    subscription: 'Subskrypcja',
    remember: 'Zapamiętaj moje dane',
    onetimepurchase: 'Jednorazowy zakup',
    submitorder: 'Prześlij zamówienie',
    isrequired: ' jest wymagane pole',
    pleaseselect: 'Wybierz metodę płatności',
    pleasecheck: 'Sprawdź następujące błędy',
    allrightsreserved: 'Wszelkie prawa zastrzeżone',
    couponinvalid: 'Przepraszamy, <strong>kod kuponu</strong>, który wprowadziłeś, jest nieprawidłowy. Sprawdź kod i spróbuj ponownie.',
    containsinvalid: 'zawiera nieprawidłowe znaki',
    'address is invalid': 'adres jest nieprawidłowy',
    'choose a state': '-Wybierz stan-',

  },
  'Portugal': {
    firstName: 'Nome',
    lastName: 'Sobrenome',
    phone: 'Telefone',
    email: 'E-mail',
    confirmEmail: 'Confirmar e-mail',
    address: 'Endereço',
    addressConfirm: 'Endereço 2',
    city: 'Cidade',
    zip: 'Código postal',
    country: 'País',
    state: 'Estado',
    stateLabel: 'Escolha um estado',
    'Cart Contents': 'Conteúdo do carrinho',
    Checkout: 'Confira',
    Confirmation: 'Confirmação',
    'Regular Price': 'Preço normal',
    Savings: 'Poupança',
    'Sales Tax': 'Imposto sobre vendas',
    Shipping: 'Envio',
    Total: 'Total',
    'Coupon discount': 'Desconto de cupom',
    couponMessage: 'Código do cupom',
    optional: '(opcional)',
    Apply: 'Aplicar',
    diffAddress: 'Enviar para um endereço diferente?',
    required: 'Os campos obrigatórios estão em <strong>negrito</strong>.',
    billinginformation: '<span class="underline">I</span>nformações de faturamento',
    safesecure: 'Seu pedido é seguro e protegido',
    orderdetail: 'Detalhes do pedido',
    item: 'Item',
    price: 'Preço',
    subscription: 'Assinatura',
    remember: 'Lembre-se das minhas informações',
    onetimepurchase: 'Compra única',
    submitorder: 'Enviar pedido',
    isrequired: ' é um campo obrigatório',
    pleaseselect: 'Por favor, selecione um método de pagamento',
    pleasecheck: 'Por favor, verifique os seguintes erros',
    allrightsreserved: 'Todos os direitos reservados',
    couponinvalid: 'Desculpe, o <strong>código do cupom</strong> que você inseriu não é válido. Verifique o código e tente novamente.',
    containsinvalid: 'contém caracteres inválidos',
    'address is invalid': 'o endereço é inválido',
    'choose a state': '-Escolha um estado-',

  },
  'Romania': {
    firstName: 'Prenume',
    lastName: 'Nume de familie',
    phone: 'Telefon',
    email: 'E-mail',
    confirmEmail: 'Confirmați e-mailul',
    address: 'Adresă',
    addressConfirm: 'Adresă 2',
    city: 'Oraș',
    zip: 'Cod poștal',
    country: 'Țară',
    state: 'Stat',
    stateLabel: 'Alegeți un stat',
    'Cart Contents': 'Conținutul coșului',
    Checkout: 'Check-out',
    Confirmation: 'Confirmare',
    'Regular Price': 'Preț normal',
    Savings: 'Economii',
    'Sales Tax': 'Taxa pe vânzări',
    Shipping: 'Transport',
    Total: 'Total',
    'Coupon discount': 'Reducere cupon',
    couponMessage: 'Codul cuponului',
    optional: '(opțional)',
    Apply: 'Aplica',
    diffAddress: 'Trimiteți la o altă adresă?',
    required: 'Câmpurile obligatorii sunt în <strong>bold</strong>.',
    billinginformation: '<span class="underline">I</span>nformații de facturare',
    safesecure: 'Comanda dvs. este sigură și protejată',
    orderdetail: 'Detalii comandă',
    item: 'Articol',
    price: 'Preț',
    subscription: 'Abonament',
    remember: 'Amintește-ți informațiile',
    onetimepurchase: 'Achiziție unică',
    submitorder: 'Trimite comanda',
    isrequired: ' este un câmp obligatoriu',
    pleaseselect: 'Vă rugăm să selectați o metodă de plată',
    pleasecheck: 'Vă rugăm să verificați următoarele erori',
    allrightsreserved: 'Toate drepturile rezervate',
    couponinvalid: 'Ne pare rău, <strong>codul cuponului</strong> pe care l-ați introdus nu este valid. Verificați codul și încercați din nou.',
    containsinvalid: 'conține caractere nevalide',
    'address is invalid': 'adresa este invalidă',
    'choose a state': '-Alegeți un stat-',

  },
  'Slovakia': {
    firstName: 'Meno',
    lastName: 'Priezvisko',
    phone: 'Telefón',
    email: 'E-mail',
    confirmEmail: 'Potvrďte e-mail',
    address: 'Adresa',
    addressConfirm: 'Adresa 2',
    city: 'Mesto',
    zip: 'PSČ',
    country: 'Krajina',
    state: 'Štát',
    stateLabel: 'Vyberte štát',
    'Cart Contents': 'Obsah košíka',
    Checkout: 'Odhlásiť sa',
    Confirmation: 'Potvrdenie',
    'Regular Price': 'Bežná cena',
    Savings: 'Úspory',
    'Sales Tax': 'Daň z predaja',
    Shipping: 'Doprava',
    Total: 'Celkom',
    'Coupon discount': 'Zľava na kupón',
    couponMessage: 'Kód kupónu',
    optional: '(voliteľné)',
    Apply: 'Použiť',
    diffAddress: 'Poslať na inú adresu?',
    required: 'Povinné polia sú tučné.',
    billinginformation: '<span class="underline">F</span>akturačné údaje',
    safesecure: 'Vaša objednávka je bezpečná a zabezpečená',
    orderdetail: 'Podrobnosti objednávky',
    item: 'Položka',
    price: 'Cena',
    subscription: 'Predplatné',
    remember: 'Zapamätajte si moje informácie',
    onetimepurchase: 'Jednorazový nákup',
    submitorder: 'Odoslať objednávku',
    isrequired: ' je povinné pole',
    pleaseselect: 'Vyberte prosím spôsob platby',
    pleasecheck: 'Skontrolujte prosím nasledujúce chyby',
    allrightsreserved: 'Všetky práva vyhradené',
    couponinvalid: 'Prepáčte, <strong>kód kupónu</strong>, ktorý ste zadali, nie je platný. Skontrolujte kód a skúste to znova.',
    containsinvalid: 'obsahuje neplatné znaky',
    'address is invalid': 'adresa je neplatná',
    'choose a state': '-Vyberte štát-',

  },
  'Slovenia': {
    firstName: 'Ime',
    lastName: 'Priimek',
    phone: 'Telefon',
    email: 'E-pošta',
    confirmEmail: 'Potrdite e-pošto',
    address: 'Naslov',
    addressConfirm: 'Naslov 2',
    city: 'Mesto',
    zip: 'Poštna številka',
    country: 'Država',
    state: 'Država',
    stateLabel: 'Izberite državo',
    'Cart Contents': 'Vsebina vozička',
    Checkout: 'Preveri',
    Confirmation: 'Potrditev',
    'Regular Price': 'Redna cena',
    Savings: 'Prihranki',
    'Sales Tax': 'Davek na prodajo',
    Shipping: 'Dostava',
    Total: 'Skupaj',
    'Coupon discount': 'Popust na kupon',
    couponMessage: 'Koda kupona',
    optional: '(izbirno)',
    Apply: 'Uporabi',
    diffAddress: 'Pošlji na drug naslov?',
    required: 'Obvezna polja so <strong>krepka</strong>.',
    billinginformation: '<span class="underline">P</span>lačilni podatki',
    safesecure: 'Vaše naročilo je varno in zaščiteno',
    orderdetail: 'Podrobnosti naročila',
    item: 'Postavka',
    price: 'Cena',
    subscription: 'Naročnina',
    remember: 'Zapomni si moje podatke',
    onetimepurchase: 'Enkratni nakup',
    submitorder: 'Pošlji naročilo',
    isrequired: ' je obvezno polje',
    pleaseselect: 'Izberite način plačila',
    pleasecheck: 'Preverite naslednje napake',
    allrightsreserved: 'Vse pravice pridržane',
    couponinvalid: 'Oprostite, <strong>koda kupona</strong>, ki ste jo vnesli, ni veljavna. Preverite kodo in poskusite znova.',
    containsinvalid: 'vsebuje neveljavne znake',
    'address is invalid': 'naslov je neveljaven',
    'choose a state': '-Izberite državo-',

  },
  'Spain': {
    firstName: 'Nombre',
    lastName: 'Apellido',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    confirmEmail: 'Confirmar correo electrónico',
    address: 'Dirección',
    addressConfirm: 'Dirección 2',
    city: 'Ciudad',
    zip: 'Código postal',
    country: 'País',
    state: 'Estado',
    stateLabel: 'Elija un estado',
    'Cart Contents': 'Contenido del carrito',
    Checkout: 'Revisa',
    Confirmation: 'Confirmación',
    'Regular Price': 'Precio regular',
    Savings: 'Ahorros',
    'Sales Tax': 'Impuesto sobre ventas',
    Shipping: 'Envío',
    Total: 'Total',
    'Coupon discount': 'Descuento de cupón',
    couponMessage: 'Código de cupón',
    optional: '(opcional)',
    Apply: 'Aplicar',
    diffAddress: 'Enviar a una dirección diferente?',
    required: 'Los campos obligatorios están en <strong>negrita</strong>.',
    billinginformation: '<span class="underline">I</span>nformación de facturación',
    safesecure: 'Su pedido es seguro y protegido',
    orderdetail: 'Detalles del pedido',
    item: 'Artículo',
    price: 'Precio',
    subscription: 'Suscripción',
    remember: 'Recuerda mis datos',
    onetimepurchase: 'Compra única',
    submitorder: 'Enviar pedido',
    isrequired: ' es un campo obligatorio',
    pleaseselect: 'Seleccione un método de pago',
    pleasecheck: 'Por favor, compruebe los siguientes errores',
    allrightsreserved: 'Todos los derechos reservados',
    couponinvalid: 'Lo siento, el <strong>código de cupón</strong> que has introducido no es válido. Comprueba el código y vuelve a intentarlo.',
    containsinvalid: 'contiene caracteres no válidos',
    'address is invalid': 'la dirección no es válida',
    'choose a state': '-Elija un estado-',

  },
  'Sweden': {
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    phone: 'Telefon',
    email: 'E-post',
    confirmEmail: 'Bekräfta e-post',
    address: 'Adress',
    addressConfirm: 'Adress 2',
    city: 'Stad',
    zip: 'Postnummer',
    country: 'Land',
    state: 'Stat',
    stateLabel: 'Välj en stat',
    'Cart Contents': 'Varukorgsinnehåll',
    Checkout: 'Kassan',
    Confirmation: 'Bekräftelse',
    'Regular Price': 'Ordinarie pris',
    Savings: 'Besparingar',
    'Sales Tax': 'Moms',
    Shipping: 'Frakt',
    Total: 'Total',
    'Coupon discount': 'Kupongrabatt',
    couponMessage: 'Kupongkod',
    optional: '(valfritt)',
    Apply: 'Tillämpa',
    diffAddress: 'Skicka till en annan adress?',
    required: 'Obligatoriska fält är <strong>fetstilta</strong>.',
    billinginformation: '<span class="underline">F</span>aktureringsinformation',
    safesecure: 'Din beställning är säker och skyddad',
    orderdetail: 'Orderdetaljer',
    item: 'Artikel',
    price: 'Pris',
    subscription: 'Prenumeration',
    remember: 'Kom ihåg mina uppgifter',
    onetimepurchase: 'Engångsköp',
    submitorder: 'Skicka beställning',
    isrequired: ' är ett obligatoriskt fält',
    pleaseselect: 'Vänligen välj en betalni0`ngsmetod',
    pleasecheck: 'Vänligen kontrollera följande fel',
    allrightsreserved: 'Alla rättigheter förbehållna',
    couponinvalid: 'Tyvärr är den <strong>rabattkod</strong> du angav ogiltig. Kontrollera koden och försök igen.',
    containsinvalid: 'innehåller ogiltiga tecken',
    'address is invalid': 'adressen är ogiltig',
    'choose a state': '-Välj en stat-',

  },
  'Japan': {
    firstName: '名',
    lastName: '姓',
    phone: '電話',
    email: 'Eメール',
    confirmEmail: 'Eメールを確認',
    address: '住所',
    addressConfirm: '住所2',
    city: '市',
    zip: '郵便番号',
    country: '国',
    state: '州',
    stateLabel: '州を選択',
    'Cart Contents': 'カートの内容',
    Checkout: 'チェックアウト',
    Confirmation: '確認',
    'Regular Price': '通常価格',
    Savings: '節約',
    'Sales Tax': '消費税',
    Shipping: '出荷',
    Total: '合計',
    'Coupon discount': 'クーポン割引',
    couponMessage: 'クーポンコード',
    optional: '(オプション)',
    Apply: '適用',
    diffAddress: '別の住所に送信しますか？',
    required: '必須項目は<strong>太字</strong>で表示されます。',
    billinginformation: '<span class="underline">請求情報</span>',
    safesecure: 'ご注文は安全で保護されています',
    orderdetail: '注文の詳細',
    item: 'アイテム',
    price: '価格',
    subscription: 'サブスクリプション',
    remember: '私の情報を覚えておく',
    onetimepurchase: '一回の購入',
    submitorder: '注文を送信',
    isrequired: 'は必須項目です',
    pleaseselect: 'お支払い方法を選択してください',
    pleasecheck: '次のエラーを確認してください',
    allrightsreserved: '全著作権所有',
    couponinvalid: '申し訳ありませんが、入力した<strong>クーポンコード</strong>は無効です。コードを確認してもう一度お試しください。',
    containsinvalid: '無効な文字が含まれています',
    'address is invalid': '住所が無効です',
      'choose a state': '-州を選択-',

  },
  'Switzerland': {
    firstName: 'Vorname',
    lastName: 'Nachname',
    phone: 'Telefon',
    email: 'E-Mail',
    confirmEmail: 'E-Mail bestätigen',
    address: 'Adresse',
    addressConfirm: 'Adresse 2',
    city: 'Stadt',
    zip: 'Postleitzahl',
    country: 'Land',
    state: 'Staat',
    stateLabel: 'Wählen Sie einen Staat',
    'Cart Contents': 'Warenkorb Inhalt',
    Checkout: 'Kasse',
    Confirmation: 'Bestätigung',
    'Regular Price': 'Normaler Preis',
    Savings: 'Ersparnisse',
    'Sales Tax': 'Umsatzsteuer',
    Shipping: 'Versand',
    Total: 'Gesamt',
    'Coupon discount': 'Gutscheinrabatt',
    couponMessage: 'Gutscheincode',
    optional: '(optional)',
    Apply: 'Anwenden',
    diffAddress: 'An eine andere Adresse senden?',
    required: 'Pflichtfelder sind <strong>fett</strong>.',
    billinginformation: '<span class="underline">R</span>echnungsinformationen',
    safesecure: 'Ihre Bestellung ist sicher und geschützt',
    orderdetail: 'Bestelldetails',
    item: 'Artikel',
    price: 'Preis',
    subscription: 'Abonnement',
    remember: 'Meine Daten merken',
    onetimepurchase: 'Einmaliger Kauf',
    submitorder: 'Bestellung absenden',
    isrequired: ' ist ein Pflichtfeld',
    pleaseselect: 'Bitte wählen Sie eine Zahlungsmethode',
    pleasecheck: 'Bitte überprüfen Sie die folgenden Fehler',
    allrightsreserved: 'Alle Rechte vorbehalten',
    couponinvalid: 'Entschuldigung, der <strong>Gutscheincode</strong>, den Sie eingegeben haben, ist ungültig. Überprüfen Sie den Code und versuchen Sie es erneut.',
    containsinvalid: 'enthält ungültige Zeichen',
    'address is invalid': 'die Adresse ist ungültig',
    'choose a state': '-Wählen Sie einen Staat-',

  },
  'Hong Kong': {
    firstName: '名字',
    lastName: '姓氏',
    phone: '電話',
    email: '電子郵件',
    confirmEmail: '確認電子郵件',
    address: '地址',
    addressConfirm: '地址 2',
    city: '城市',
    zip: '郵政編碼',
    country: '國家',
    state: '州',
    stateLabel: '選擇一個州',
    'Cart Contents': '購物車內容',
    Checkout: '結帳',
    Confirmation: '確認',
    'Regular Price': '普通價格',
    Savings: '節省',
    'Sales Tax': '銷售稅',
    Shipping: '運輸',
    Total: '總',
    'Coupon discount': '優惠券折扣',
    couponMessage: '優惠券代碼',
    optional: '(可選)',
    Apply: '應用',
    diffAddress: '發送到其他地址？',
    required: '必填字段以<strong>粗體</strong>顯示。',
    billinginformation: '<span class="underline">帳單信息</span>',
    safesecure: '您的訂單是安全的並且受保護',
    orderdetail: '訂單詳情',
    item: '物品',
    price: '價錢',
    subscription: '訂閱',
    remember: '記住我的信息',
    onetimepurchase: '一次性購買',
    submitorder: '提交訂單',
    isrequired: ' 是必填字段',
    pleaseselect: '請選擇付款方式',
    pleasecheck: '請檢查以下錯誤',
    allrightsreserved: '保留所有權利',
    couponinvalid: '對不起，您輸入的<strong>優惠券代碼</strong>無效。請檢查代碼並重試。',
    containsinvalid: '包含無效字符',
    'address is invalid': '地址無效',
    'choose a state': '-選擇一個州-',

  },
  'Taiwan': {
    firstName: '名字',
    lastName: '姓氏',
    phone: '電話',
    email: '電子郵件',
    confirmEmail: '確認電子郵件',
    address: '地址',
    addressConfirm: '地址 2',
    city: '城市',
    zip: '郵政編碼',
    country: '國家',
    state: '州',
    stateLabel: '選擇一個州',
    'Cart Contents': '購物車內容',
    Checkout: '結帳',
    Confirmation: '確認',
    'Regular Price': '普通價格',
    Savings: '節省',
    'Sales Tax': '銷售稅',
    Shipping: '運輸',
    Total: '總',
    'Coupon discount': '優惠券折扣',
    couponMessage: '優惠券代碼',
    optional: '(可選)',
    Apply: '應用',
    diffAddress: '發送到其他地址？',
    required: '必填字段以<strong>粗體</strong>顯示。',
    billinginformation: '<span class="underline">帳單信息</span>',
    safesecure: '您的訂單是安全的並且受保護',
    orderdetail: '訂單詳情',
    item: '物品',
    price: '價錢',
    subscription: '訂閱',
    remember: '記住我的信息',
    onetimepurchase: '一次性購買',
    submitorder: '提交訂單',
    isrequired: ' 是必填字段',
    pleaseselect: '請選擇付款方式',
    pleasecheck: '請檢查以下錯誤',
    allrightsreserved: '保留所有權利',
    couponinvalid: '對不起，您輸入的<strong>優惠券代碼</strong>無效。請檢查代碼並重試。',
    containsinvalid: '包含無效字符',
    'address is invalid': '地址無效',
    'choose a state': '-選擇一個州-',

  },
  'Iceland': {
    firstName: 'Fornafn',
    lastName: 'Eftirnafn',
    phone: 'Sími',
    email: 'Tölvupóstur',
    confirmEmail: 'Staðfestu tölvupóst',
    address: 'Heimilisfang',
    addressConfirm: 'Heimilisfang 2',
    city: 'Borg',
    zip: 'Póstnúmer',
    country: 'Land',
    state: 'Ríki',
    stateLabel: 'Veldu ríki',
    'Cart Contents': 'Karfa innihald',
    Checkout: 'Útskrift',
    Confirmation: 'Staðfesting',
    'Regular Price': 'Venjulegt verð',
    Savings: 'Sparnaður',
    'Sales Tax': 'Söluskattur',
    Shipping: 'Sending',
    Total: 'Samtals',
    'Coupon discount': 'Afsláttur á afsláttarmiða',
    couponMessage: 'Afsláttarmiði',
    optional: '(valfrjálst)',
    Apply: 'Sækja',
    diffAddress: 'Senda á annað heimilisfang?',
    required: 'Skyldumerkt reit eru í <strong>feitletrað</strong>.',
    billinginformation: '<span class="underline">Reikningsupplýsingar</span>',
    safesecure: 'Pöntunin þín er örugg og örugg',
    orderdetail: 'Pöntunarupplýsingar',
    item: 'Vara',
    price: 'Verð',
    subscription: 'Áskrift',
    remember: 'Mundu upplýsingar mínar',
    onetimepurchase: 'Einu sinni kaup',
    submitorder: 'Senda pöntun',
    isrequired: ' er skyldumerkt reitur',
    pleaseselect: 'Vinsamlegast veldu greiðslumáta',
    pleasecheck: 'Vinsamlegast athugaðu eftirfarandi villur',
    allrightsreserved: 'Öll réttindi áskilin',
    couponinvalid: 'Afsakið, <strong>   afsláttarmiði</strong> sem þú hefur slegið inn er ógilt. Athugaðu kóðann og reyndu aftur.',
    containsinvalid: 'inniheldur ógild tákn',
    'address is invalid': 'heimilisfangið er ógilt',
    'choose a state': '-Veldu ríki-',

  },
  'Portgual': {
    firstName: 'Nome',
    lastName: 'Apelido',
    phone: 'Telefone',
    email: 'E-mail',
    confirmEmail: 'Confirmar e-mail',
    address: 'Endereço',
    addressConfirm: 'Endereço 2',
    city: 'Cidade',
    zip: 'Código postal',
    country: 'País',
    state: 'Estado',
    stateLabel: 'Escolha um estado',
    'Cart Contents': 'Conteúdo do carrinho',
    Checkout: 'Confira',
    Confirmation: 'Confirmação',
    'Regular Price': 'Preço normal',
    Savings: 'Poupança',
    'Sales Tax': 'Imposto sobre vendas',
    Shipping: 'Envio',
    Total: 'Total',
    'Coupon discount': 'Desconto de cupom',
    couponMessage: 'Código do cupom',
    optional: '(opcional)',
    Apply: 'Aplicar',
    diffAddress: 'Enviar para um endereço diferente?',
    required: 'Os campos obrigatórios estão em <strong>negrito</strong>.',
    billinginformation: '<span class="underline">I</span>nformações de faturação',
    safesecure: 'Seu pedido é seguro e protegido',
    orderdetail: 'Detalhes do pedido',
    item: 'Item',
    price: 'Preço',
    subscription: 'Assinatura',
    remember: 'Lembre-se das minhas informações',
    onetimepurchase: 'Compra única',
    submitorder: 'Enviar pedido',
    isrequired: ' é um campo obrigatório',
    pleaseselect: 'Por favor, selecione um método de pagamento',
    pleasecheck: 'Por favor, verifique os seguintes erros',
    allrightsreserved: 'Todos os direitos reservados',
    couponinvalid: 'Desculpe, o <strong>coupon code</strong> que você inseriu não é válido. Verifique o código e tente novamente.',
    containsinvalid: 'contém caracteres inválidos',
    'address is invalid': 'o endereço é inválido',
    'choose a state': '-Escolha um estado-',

  },
  'Brazil': {
    firstName: 'Nome',
    lastName: 'Sobrenome',
    phone: 'Telefone',
    email: 'E-mail',
    confirmEmail: 'Confirmar e-mail',
    address: 'Endereço',
    addressConfirm: 'Endereço 2',
    city: 'Cidade',
    zip: 'CEP',
    country: 'País',
    state: 'Estado',
    stateLabel: 'Escolha um estado',
    'Cart Contents': 'Conteúdo do carrinho',
    Checkout: 'Confira',
    Confirmation: 'Confirmação',
    'Regular Price': 'Preço normal',
    Savings: 'Economia',
    'Sales Tax': 'Imposto sobre vendas',
    Shipping: 'Envio',
    Total: 'Total',
    'Coupon discount': 'Desconto de cupom',
    couponMessage: 'Código do cupom',
    optional: '(opcional)',
    Apply: 'Aplicar',
    diffAddress: 'Enviar para um endereço diferente?',
    required: 'Os campos obrigatórios estão em <strong>negrito</strong>.',
    billinginformation: '<span class="underline">I</span>nformações de faturamento',
    safesecure: 'Seu pedido é seguro e protegido',
    orderdetail: 'Detalhes do pedido',
    item: 'Item',
    price: 'Preço',
    subscription: 'Assinatura',
    remember: 'Lembre-se das minhas informações',
    onetimepurchase: 'Compra única',
    submitorder: 'Enviar pedido',
    isrequired: ' é um campo obrigatório',
    pleaseselect: 'Por favor, selecione um método de pagamento',
    pleasecheck: 'Por favor, verifique os seguintes erros',
    allrightsreserved: 'Todos os direitos reservados',
    couponinvalid: 'Desculpe, o <strong>coupon code</strong> que você inseriu não é válido. Verifique o código e tente novamente.',
    containsinvalid: 'contém caracteres inválidos',
    'address is invalid': 'o endereço é inválido',
    'choose a state': '-Escolha um estado-',

  },

}
