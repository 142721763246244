import { Injectable } from "@angular/core";

import { OrderDetailsProvider } from '../order-details/order-details';
import { TouchcrApiRemoteServicesProvider } from '../touchcr-api-remoteservices';
import { UrlsProvider } from '../urls';
import { CalculateProvider } from '../calculate/calculate';
import { PaypalPaymentProvider } from "./paypal";
import { TouchcrApiOrderExtenderProvider } from "../touchcr-api-order/touchcr-api-order-extender";
import { StateExtenderProvider } from "../state-extender";
import { FunnelSettingsProvider } from "../funnel-settings";

@Injectable({providedIn: 'root'})
export class PaypalPaymentExtenderProvider extends PaypalPaymentProvider {
  isStorageAvailable: boolean = true;

  constructor(
    public override tcrApiOrder: TouchcrApiOrderExtenderProvider,
    public override orderDetails: OrderDetailsProvider,
    public override tcrApiRemoteServices: TouchcrApiRemoteServicesProvider,
    public override stateProvider: StateExtenderProvider,
    public override funnelProvider: FunnelSettingsProvider,
    public override urls: UrlsProvider,
    public override calculateHelper: CalculateProvider,
  ) {
    super(
      tcrApiOrder,
      orderDetails,
      tcrApiRemoteServices,
      stateProvider,
      funnelProvider,
      urls,
      calculateHelper
    );

    //Check if storage is unavailable - TCR
    try {
      sessionStorage.setItem('StorageTest', '');
      sessionStorage.removeItem('StorageTest');
    } catch (e) {
      this.isStorageAvailable = false;
    }
  }


  override getOrderBody({
                 orderFormProducts, OffersData, bumpOffers, totalPrice, accDetails, orderType, coupon, totalTax, calculatedShippingCost, shippingType,
                 subscriptionTax, isPayPalSubscription, isInternationalShipping
               } : any) {
    let createOrderBody:any = {
      products: [],
      variants: [],
      bumpoffers: [],
      funnels: [],
      orderFormProduct: [],
      paymentMethod: 'PayPal',
      namedAgent: 'PayPal',
      cardDetails: {},
      orderTotal: 0,
      totalTax: totalTax,
      authorize: false,
      coupons: coupon && Object.keys(coupon).length ? [coupon] : [],
      definedShippingCost: calculatedShippingCost,
      shippingType: shippingType,
      subscriptionTax: subscriptionTax,
      isPayPalSubscription: isPayPalSubscription ? isPayPalSubscription : false,
      isInternationalShipping: isInternationalShipping,
      isGuest: true,
    };

    // Add order form (funnels) in the body if there is no order form products
    if (!orderFormProducts || !orderFormProducts.length) {
      createOrderBody.funnels.push(...OffersData.map((elem:any) => {
        createOrderBody.authorize = elem.isSubscriptionAvailable && !!elem.subscriptionDaysPostpone;
        return {
          orderFormId: elem.sfid,
          count: 1,
        };
      }));
    }

    // Add bump offers in the body
    if (bumpOffers && bumpOffers.length) {
      createOrderBody.bumpoffers.push(...bumpOffers.map((elem:any) => {
        return {
          orderFormId: elem.sfid,
          count: 1,
        };
      }));
    }

    // Add order form products in the body
    if (orderFormProducts && orderFormProducts.length) {
      createOrderBody.orderFormProduct.push(...orderFormProducts.map((elem:any) => {
        createOrderBody.authorize = (elem.isSubscriptionAvailable) && !!elem.subscriptionDaysPostpone;
        return {
          orderFormProductId: elem.sfid,
          count: 1,
        };
      }));
    }
    // alert(orderType);
    createOrderBody.orderTotal = totalPrice;
    createOrderBody['accDetails'] = accDetails;
    createOrderBody['orderSource'] = 'Funnel';
    createOrderBody['orderType'] = orderType;
    createOrderBody['customerIp'] = this.isStorageAvailable ? sessionStorage.getItem('ip') : (window as any)['gh'].ip;
    if (coupon) {
      createOrderBody['couponDiscount'] = coupon.couponDiscount;
    }
    createOrderBody['statsInfo'] = this.funnelProvider.getStats();
    return createOrderBody;
  }
}
