import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import {Coupon} from "../../models/coupon";

const API_URL = (window as any)["process_env"].API_URL;

const GET_COUPON = 'coupon/check';

@Injectable({providedIn: 'root'})
export class TouchcrApiCouponProvider {

  constructor(
    public http: HttpClient,
  ) {

  }

  checkCoupon(body:any): Observable<Coupon> {
    return this.http.post<Coupon>(`${API_URL}${GET_COUPON}`, body)
    .map((response: Coupon) => {
      return response;
    })
    .catch((error) => {
      return Observable.throw(error);
    });
  }
}
