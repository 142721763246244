import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';

const API_URL = (window as any)["process_env"].API_URL;

const ADD_CARD = 'payments/add';
const REMOVE_CARD = 'payments/remove';

@Injectable({providedIn: 'root'})
export class TouchcrApiPaymentProvider {

  constructor(
    public http: HttpClient,
  ) {
  }

  addCard(body:any): Observable<any> {
    return this.http.post(`${API_URL}${ADD_CARD}`, { body })
      .catch((error) => {
        console.log('addCard error: ', error);
        return throwError(error && error.error && error.error.error);
      });
  }

  removeCard(body:any): Observable<any> {
    return this.http.post(`${API_URL}${REMOVE_CARD}`, { body })
      .catch((error) => {
        console.log('removeCard error: ', error);
        return throwError(error && error.error && error.error.error);
      });
  }
}
