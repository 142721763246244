import { Component, Injectable } from '@angular/core';
// TODO didn't we get a piece of code from iTransition we can use for the ToastController?
@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-tutorial-details',
  templateUrl: './LoadingController.html',
  styleUrls: ['./LoadingController.css'],
})
export class LoadingController {
  constructor() {}
  create(data:any) {
    console.log('spinner',data);
  }
  present() {
    console.log('present spinner');
  }
  openSnackBar(data:any) {
    console.log('spinner snackbar: ' + data);
    /*
    this.snackBar.open('This is a toast message', 'Close', {
      duration: 3000,  
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
    */
  }
}
