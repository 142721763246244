import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

const API_URL = (window as any)["process_env"].API_URL;

const AUTOCOMPLETE_URL = 'autocomplete';

@Injectable({providedIn: 'root'})
export class AutocompleteProvider {

  constructor(
    public http: HttpClient,
  ) {}

  getAddresses(query: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_URL}${AUTOCOMPLETE_URL}?query=${query}`);
  }

  getDetails(placeId: string): Observable<any> {
    return this.http.post<any>(`${API_URL}${AUTOCOMPLETE_URL}/details`, { placeId });
  }
}

