import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { UrlsProvider } from 'src/app/providers/urls';
import { Buffer } from 'buffer';

import { TouchcrApiAffiliateComponent } from "../touchcr-api-affiliate/touchcr-api-affiliate.component";

const { API_URL, TOKEN, BRAND, NODE_ENV, PUBLIC_KEY } = (window as any)['process_env'];
const MAX_CHUNK_SIZE = 86;

@Injectable({providedIn: 'root'})
export class HttpInterceptorProvider implements HttpInterceptor {
  isStorageAvailable: boolean = true;

  constructor(
    public urls?: UrlsProvider,
    public tcrApiAffiliate?: TouchcrApiAffiliateComponent,
  ) {

    //Check if storage is unavailable - TCR
    try {
      window.sessionStorage.setItem("StorageTest", "");
      window.sessionStorage.removeItem("StorageTest");
    } catch (e) {
      this.isStorageAvailable = false;
    }
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    if (BRAND && req.url.includes(API_URL)) {
      let url = req.url;
      let body = req.body;
      // Use proxy only if production);
      if (body) {
        let affParameters;
        if (this.isStorageAvailable) {
          affParameters = JSON.parse((window as any).sessionStorage.getItem('affiliateInfo'));
        } else {
          affParameters = (window as any)['gh']['affiliateInfo'];
        }

        if (!affParameters) {
          affParameters = this.urls!.getAffParamsFromUrl(window.location.search);
          this.tcrApiAffiliate!.saveAffParamsToSessionStorage(affParameters);
        }
        body.affParameters = affParameters;
      }
      if (NODE_ENV === 'production') {
        url = '/proxy/' + req.url.split(API_URL)[1];
        //if (body) {
        //  body = HttpInterceptorProvider.encryptBody(body);
       // }
      }
      let mHeaders = req.headers.set('X-Brand', BRAND);
      // [TOUC-5682]
      if (typeof (window as any)['getCookie'] === 'function') {
        mHeaders = mHeaders.append('XSRF-TOKEN', (window as any)['getCookie']('XSRF-TOKEN'));
      }
      if (TOKEN) {
        mHeaders = mHeaders.append('Authorization', TOKEN);
      }
      const clonedRequest = req.clone({
        url: url,
        headers: mHeaders,
        body: body,
        withCredentials: true,
      });
      return next.handle(clonedRequest)
        .map((response: any) => {
          if (response && response.body && response.body.error === 'UNSUCCESSFUL_DECRYPTION' ) {
            const errorMessage = 'The page has been updated, please click OK to reload';
            const result = confirm(errorMessage);
            if (result) {
              location.reload();
            }
          }
          return response;
        })
    }
    return next.handle(req);
  }
  
  // Method for encrypt body
  static encryptBody(body: any) {
    // Broke stringified body on array of chunks with MAX_CHUNK_SIZE size
    const bodyChunks = JSON.stringify(body).match(new RegExp(`.{1,${MAX_CHUNK_SIZE}}`, 'g')) || [];
    let bodyEncrypted: any[] = [];
    // Encrypt each chunk and push in body array
    bodyChunks.forEach((bodyChunk) => {
      const bodyChunkBuffer = Buffer.from(bodyChunk);
      //const bodyChunkEncrypted = crypto.publicEncrypt(PUBLIC_KEY, bodyChunkBuffer);
      const bodyChunkEncrypted = bodyChunkBuffer; /// TODO not used but needs to be reviewed
      bodyEncrypted.push(bodyChunkEncrypted.toString('base64'));
    });
    return bodyEncrypted;
  }
}
