import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

import { UrlsProvider } from '../urls';
import { TouchcrApiOrderProvider } from './touchcr-api-order';
import { EventStreamerProvider } from '../event-stream';
import { FunnelSettingsProvider } from '../funnel-settings';

const API_URL = (window as any)['process_env'].API_URL;

const PAY_WITHOUT_LOGIN = 'orders/createguest';
const PAY_WITH_LOGIN = 'orders/create';
const CREATE_WITH_LOGIN = 'orders/createorder';

@Injectable({providedIn: 'root'})
export class TouchcrApiOrderExtenderProvider extends TouchcrApiOrderProvider {
  isStorageAvailable: boolean = true;

  constructor(
    public override http: HttpClient,
    public override eventStreamer: EventStreamerProvider,
    public override funnelProvider: FunnelSettingsProvider,
    public override urls: UrlsProvider,
  ) {
    super(
      http,
      eventStreamer,
      funnelProvider,
      urls
    );

    //Check if storage is unavailable - TCR
    try {
      sessionStorage.setItem('StorageTest', '');
      sessionStorage.removeItem('StorageTest');
    } catch (e) {
      this.isStorageAvailable = false;
    }
  }

  override payUpsells(order:any, upsells:any, type:any, totalTax:any, dataForTax:any) {
    return this.http.post(`${API_URL}${PAY_WITHOUT_LOGIN}`, {
      accId: order.accountId,
      orderType: type,
      totalTax: totalTax,
      parent: order.id,
      orderSource: 'Funnel',
      customerIp: this.isStorageAvailable ? sessionStorage.getItem('ip') : (window as any)['gh'].ip,
      upsells: upsells,
      dataForTax: dataForTax,
      paymentOptionId: order.paymentOptionId,
      orderTransactionFee: order.transactionFeeAmount,
      transactionFeeDocumentId: order.transactionFeeDocumentId,
      statsInfo: this.funnelProvider.getStats(),
    })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  override payUpsellGoogleAndApplepay(order:any, upsells:any, type:any, token:any, paymentMethod:any) {
    return this.http.post(`${API_URL}${PAY_WITH_LOGIN}`, {
      accId: order.accountId,
      orderType: type,
      parent: order.id,
      orderSource: 'App',
      customerIp: this.isStorageAvailable ? sessionStorage.getItem('ip') : (window as any)['gh'].ip,
      upsells: upsells,
      paymentMethod: paymentMethod,
      cardDetails: {
        cardId: token
      },
      statsInfo: this.funnelProvider.getStats(),
    })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  override setUpsells(order:any, upsells:any, type:any, paymentMethod:any, totalTax:any) {
    return this.http.post(`${API_URL}${CREATE_WITH_LOGIN}`, {
      accId: order.accountId,
      orderType: type,
      parent: order.id,
      orderSource: 'Funnel',
      customerIp: this.isStorageAvailable ? sessionStorage.getItem('ip') : (window as any)['gh'].ip,
      upsells: upsells,
      paymentMethod: paymentMethod,
      namedAgent: paymentMethod,
      cardDetails: {},
      totalTax: totalTax,
      statsInfo: this.funnelProvider.getStats(),
    })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }
}
