import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class CurrencyConversionProvider {
  private static countriesToCurrencies = { // only GBP setup with Braintree
    AU: 'AUD',
    CA: 'CAD',
    US: 'USD',
    GB: 'GBP'
  };
  private static currenciesToSymbols = { // only GBP setup with Braintree
    AU: '$', // TODO AU$ ???
    CA: '$', // TODO CA$ ???
    US: '$',
    GB: '£'
  };
  constructor(private _client: HttpClient) {}

  static currencyCodeFromCountryCode(countryCode: string): string {
    const currencyCode = this.countriesToCurrencies[countryCode as keyof typeof this.countriesToCurrencies];
    if (!currencyCode) {
      throw new Error('Country code not found!');
    }
    return currencyCode;
  }

  getLocalCurrencyRate(currencyCode: string): Observable<number> {
    return this._client.get('/currency?format=raw&code=' + currencyCode.toUpperCase())
      .map((rates: any) => rates[currencyCode.toLowerCase()].rate);
  }

  getCurrencySymbol(country: string): string {
    const currencySymbol = CurrencyConversionProvider.currenciesToSymbols[country as keyof typeof CurrencyConversionProvider.currenciesToSymbols];
    if (!currencySymbol) {
      throw new Error('Currency code not found!');
    }
    return currencySymbol;
  }
}