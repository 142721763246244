<div class="upsell-steps-div">
  <div margin-vertical justify-content-center>
    <div col-12 padding class="step_wrapper">
      <div row class="step_content">
        <div col-12 col-lg-4 no-padding text-uppercase
            *ngFor="let step of steps; index as i;"
            class="step_item"
            [ngClass]="{'active' : step.active }">
            <svg *ngIf="step.showCheck" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <polygon points="78.5,216.75 170.67,308.93 433.51,46.09 512,124.58 249.16,387.42 170.67,465.91 92.17,387.42 0,295.25 "/>
            </svg>
            <b>{{stepLabel}} {{ i + 1 }}</b> {{ step.label }}
        </div>
      </div>
    </div>
  </div>
</div>
