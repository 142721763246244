import { Injectable } from "@angular/core";
import { UPSELL_DATA, ORDER_SUCCESS_DATA } from '../../constants/constants'

@Injectable({providedIn: 'root'})
export class DebugProvider {

  constructor(
    
  ) {}

  getUpsellDebugData(): any {
    this.showDebugWarning();
    return UPSELL_DATA;
  }

  getOrderSuccessData(): any {
    this.showDebugWarning();
    return ORDER_SUCCESS_DATA;
  }

  showDebugWarning() {
    /*
    this.toastCtrl
      .create({
        message: `Warning! You are in a Preview mode. This mode is intended for template debug only!`,
        position: 'top',
        showCloseButton: true,
        duration: 5000,
        cssClass: 'errorToast',
      })
      .present();*/
  }
}
