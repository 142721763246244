<div class="content" nopadding>
  <p>
    The <strong>Card Code</strong>, sometimes called CVV2, CVC2, CID or CVN,
    is a <strong>3-digit</strong> code located on the back of your credit card,
    on the signature strip just to the right of the card number.
  </p>
  <div class="row">
    <div class="custom-col text-center">
      <img src="{{ creditCardImg }}">
    </div>
  </div>
  <p>
    On <strong> American Express </strong>cards,
    the 4-digit code is on the front of the card,
    just above and to the right of the embossed card number.
  </p>
</div>
