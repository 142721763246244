<div class="upsell-product-box">
  <div class="upsell-product-box-wrapper"justify-content-center padding-horizontal>
    <div col-12 class="product-box_wrapper">
      <div *ngIf="showTitle">
        <div col-12 no-padding>
            <h4 [innerHTML]="title"></h4>
        </div>
      </div>
      <div class="upsell-product-box-row">
        <div col-12 col-md-6 no-padding class="image_wrapper">
            <img src="{{ image }}" data-qa="product-image" alt="{{ imageAlt}}">
            <img src="{{ auxImg }}" alt="{{ auxImgAlt }}" *ngIf="auxImg" class="auxImage">
        </div>
        <div col-12 col-md-6 no-padding padding-left class="description_wrapper" [ngClass]="{'hasTitle': showTitle}">
            <p class="subtitle" [innerHTML]="subTitle"></p>
            <p class="regularPrice notranslate">{{regularText}}: <span class="regularPriceText" data-qa="regular-price">{{ regularPrice * braintreeCurrencyConversion | currency: currency:"symbol":".2-2" }}</span></p>
            <p class="instantSavings notranslate"><span >{{instantSavingsText}}: <span data-qa="savings">{{ instantSavings * braintreeCurrencyConversion | currency: currency:"symbol":".2-2" }}</span></span></p>
            <p class="yourPrice notranslate">{{yourPriceText}}: <span data-qa="price">{{ yourPrice * braintreeCurrencyConversion | currency: currency:"symbol":".2-2" }}</span></p>
        </div>
      </div>
    </div>
  </div>
</div>
