import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class CheckoutLanguageProvider {

  static languages: any = {
    'english': {
      breadCrumbsTabsCartContents: `Cart Contents`,
      breadCrumbsTabsCheckout: `Checkout`,
      breadCrumbsTabsConfirmation: `Confirmation`,
      requiredFieldsInstructions: `Required fields are in <strong>bold</strong>.`,
      safeOrderAnchorText: `Your order is safe and secure`,
      safeOrderAnchorLink: `../safe-order`,
      sectionTitleBillingInformation: `<span class="form__title_underline">B</span>illing Information`,
      sectionTitleShipping: `<span class="form__title_underline">S</span>hipping`,
      sectionTitlePaymentInformation: `<span class="form__title_underline">P</span>ayment Information`,
      sectionTitleComments: `<span class="form__title_underline">C</span>omments`,
      inputLabelFirstName: 'First Name',
      inputLabelLastName: 'Last Name',
      inputLabelPhone: 'Phone',
      inputLabelEmail: 'Email',
      inputLabelConfirmEmail: 'Confirm email',
      inputLabelAddress: 'Address',
      inputLabelAddress2: 'Address 2',
      inputLabelCity: 'City',
      inputLabelZip: 'ZIP/Postal Code',
      inputLabelCountry: 'Country',
      inputLabelState: 'State',
      inputLabelShipToName: 'Ship to Name',
      inputLabelDefaultState: '-Choose a State-',
      inputLabelShipToDifferentAddress: `Ship to a different address?`,
      shippingMethodDefaultOption: `-Choose a Shipping Method-`,
      shippingMethodUSPSOption: `Standard USPS (2-7 Business Days) - `,
      priceSectionSubtotal: `Subtotal`,
      priceSectionSavings:`Savings`,
      priceSectionSalesTax: `Sales Tax`,
      priceSectionShipping: `Shipping:`,
      priceSectionCouponDiscount: `Coupon discount`,
      priceSectionTotal: `Total`,
      couponSectionInputLabel: `Coupon code <i>(optional)</i>`,
      couponApplyButtonText: `Apply`,
      amazonContainer1CheckboxLabel: `Required: check box below`,
      amazonContainer2LogOut: `Amazon logout`,
      inputLabelRememberInfo: `Remember my Information`,
      paymentTabTitle: 'Credit card',
      inputLabelPaymentCardName: 'Name on Card',
      inputLabelPaymentCardType: 'Card Type',
      inputLabelPaymentCardNumber: 'Card Number',
      inputLabelPaymentCardExpiration: 'Expiration Date',
      inputLabelPaymentCardExpirationMonth: 'Month',
      inputLabelPaymentCardExpirationYear: 'Year',
      inputLabelPaymentCardCVV2: 'CVV2',
      inputLabelPaymentCardWhatIsCVV2: 'What is this?',
      inputLabelPaymentCardWhatIsCVV2Link: '../what-is-this',
      commentsSectionText1: `I authorize Gundry MD to charge me for the order total. I further affirm that the name and personal information provided on this form are true and correct. I further declare that I have read, understand and accept Gundry MD's`,
      termsConditionsAnchorText: `Terms and Conditions`,
      termsConditionsLink: `terms`,
      commentsSectionAdditionalText1: `as published on their website. By pressing the Submit Order button below, I agree to pay Gundry MD`,
      commentsSectionText2: `By clicking 'Submit Order', you agree that Nucific has permission to utilize automated technology to call you about our products/services at the phone number(s) above; including any wireless numbers provided. Consent is not mandatory to purchase, and you may place product orders by calling our Customer Support at 888-679-5520 instead of clicking the submit button.`,
      inputLabelSubmitButton: `Submit Order`,
      errorsCheckCouponError: `Sorry, the <strong>coupon code</strong> you have entered is invalid. Please check the code and try again.`,
      errorsCheckErrorsText: `Please сheck the following errors`,
      errorsFirstNameRequired: `<strong>First Name</strong> is a required field.`,
      errorsFirstNameInvalid: `<strong>First Name</strong> contains invalid characters.`,
      errorsLastNameRequired: `<strong>Last Name</strong> is a required field.`,
      errorsLastNameInvalid: `<strong>Last Name</strong> contains invalid characters.`,
      errorsPhoneRequired: `<strong>Phone</strong> is a required field.`,
      errorsPhoneInvalid: `<strong>Phone</strong> contains invalid characters.`,
      errorsEmailRequired: `<strong>Email</strong> is a required field.`,
      errorsEmailInvalid: `<strong>Email</strong> address is invalid.`,
      errorsConfirmEmailRequired: `<strong>Confirm Email</strong> is a required field.`,
      errorsConfirmEmailInvalid: `<strong>Confirm Email</strong> address is invalid.`,
      errorsConfirmEmailNotMatching: `<strong>Confirm Email</strong> does not match Email.`,
      errorsAddressRequired: `<strong>Address</strong> is a required field.`,
      errorsAddressInvalid: `<strong>Address</strong> contains invalid characters.`,
      errorsCityRequired: `<strong>City</strong> is a required field.`,
      errorsCityInvalid: `<strong>City</strong> contains invalid characters.`,
      errorsZipRequired: `<strong>ZIP/Postal Code</strong> is a required field.`,
      errorsZipInvalid: `<strong>ZIP/Postal Code</strong> contains invalid characters.`,
      errorsCountryRequired: `<strong>Country</strong> is a required field.`,
      errorsStateRequired: `<strong>State</strong> is a required field.`,
      errorsShipToNameRequired: `<strong>Ship to Name</strong> is a required field.`,
      errorsShipToNameInvalid: `<strong>Ship to Name</strong> contains invalid characters.`,
      errorsShippingAddressRequired: `<strong>Address</strong> is a required field.`,
      errorsShippingAddressInvalid: `<strong>Address</strong> contains invalid characters.`,
      errorsShippingCityRequired: `<strong>City</strong> is a required field.`,
      errorsShippingCityInvalid: `<strong>City</strong> contains invalid characters.`,
      errorsShippingZipRequired: `<strong>ZIP/Postal Code</strong> is a required field.`,
      errorsShippingZipInvalid: `<strong>ZIP/Postal Code</strong> contains invalid characters.`,
      errorsShippingCountryRequired: `<strong>Country</strong> is a required field.`,
      errorsShippingStateRequired: `<strong>State</strong> is a required field.`,
      errorsPaymentNameRequired: `<strong>Name on Card</strong> is a required field.`,
      errorsPaymentNameInvalid: `<strong>Name on Card</strong> contains invalid characters.`,
      errorsPaymentCardRequired: `<strong>Card Number</strong> is a required field.`,
      errorsPaymentCardInvalid: `Invalid <strong>credit card number</strong>.`,
      errorsPaymentExpMonthRequired: `<strong>Expiration Month</strong> is a required field.`,
      errorsPaymentExpYearRequired: `<strong>Expiration Year</strong> is a required field.`,
      errorsPaymentCardExpired: `<strong>Expiration Date</strong> is invalid. Card has expired.`,
      errorsPaymentCCV2Required: `<strong>CVV2</strong> is a required field.`,
      errorsPaymentCCV2Invalid: `<strong>CVV2</strong> is invalid.`,


    },
    'spanish': {
      breadCrumbsTabsCartContents: `Contenido del carrito`,
      breadCrumbsTabsCheckout: `Finalizar compra`,
      breadCrumbsTabsConfirmation: `Confirmación`,
      requiredFieldsInstructions: `Los campos obligatorios están en <strong>negrita</strong>.`,
      safeOrderAnchorText: `Su pedido es seguro`,
      safeOrderAnchorLink: `../safe-order-spanish`,
      sectionTitleBillingInformation: `<span class="form__title_underline">I</span>nformación de Facturación`,
      sectionTitleShipping: `<span class="form__title_underline">E</span>nvío`,
      sectionTitlePaymentInformation: `<span class="form__title_underline">I</span>nformación del pago`,
      sectionTitleComments: `<span class="form__title_underline">C</span>omentarios`,
      inputLabelFirstName: 'Nombre',
      inputLabelLastName: 'Apellido',
      inputLabelPhone: 'Teléfono',
      inputLabelEmail: 'Correo Electrónico',
      inputLabelConfirmEmail: 'Confirmar correo electrónico',
      inputLabelAddress: 'Dirección',
      inputLabelAddress2: 'Dirección 2',
      inputLabelCity: 'Ciudad',
      inputLabelZip: 'ZIP/Código postal',
      inputLabelCountry: 'País o Región',
      inputLabelState: 'Estado',
      inputLabelShipToName: 'Envío a nombre',
      inputLabelDefaultState: 'Estado',
      inputLabelShipToDifferentAddress: `Envia a una dirección diferente?`,
      shippingMethodDefaultOption: `Opción predeterminada del método de envío`,
      shippingMethodUSPSOption: `USPS estándar (2-7 días hábiles) - `,
      priceSectionSubtotal: `Precio Regular`,
      priceSectionSavings:`Ahorros`,
      priceSectionSalesTax: `Impuesto de venta`,
      priceSectionShipping: `Envió`,
      priceSectionCouponDiscount: `cupón de descuento`,
      priceSectionTotal: `Total`,
      couponSectionInputLabel: `Código de cupón (opcional)`,
      couponApplyButtonText: `aplicar`,
      amazonContainer1CheckboxLabel: `marque la casilla de abajo`,
      amazonContainer2LogOut: `'Cerrar sesión`,
      inputLabelRememberInfo: `Recuerda mi información`,
      paymentTabTitle: 'Tarjeta de crédito',
      inputLabelPaymentCardName: 'Nombre en la tarjeta',
      inputLabelPaymentCardType: 'Tipo de tarjeta',
      inputLabelPaymentCardNumber: 'Número de tarjeta',
      inputLabelPaymentCardExpiration: 'Fecha de caducidad',
      inputLabelPaymentCardExpirationMonth: 'Mes',
      inputLabelPaymentCardExpirationYear: 'Año',
      inputLabelPaymentCardCVV2: 'CVV2',
      inputLabelPaymentCardWhatIsCVV2: 'Que es esto?',
      inputLabelPaymentCardWhatIsCVV2Link: '../what-is-this-spanish',
      commentsSectionText1: ``,
      termsConditionsAnchorText: `Términos y Condiciones`,
      termsConditionsLink: `terms-spanish`,
      commentsSectionAdditionalText1: `como se publican en su sitio web. Al presionar el botón Enviar pedido a continuación, acepto pagar Gundry MD.`,
      commentsSectionText2: `Al hacer clic en 'Enviar pedido', acepta que Nucific tiene permiso para utilizar tecnología automatizada para llamarlo sobre nuestros productos / servicios al número (s) de teléfono anterior; incluidos los números inalámbricos proporcionados. El consentimiento no es obligatorio para la compra, y puede realizar pedidos de productos llamando a nuestro Servicio de atención al cliente al 888-679-5520 en lugar de hacer clic en el botón Enviar.`,
      inputLabelSubmitButton: `Enviar Pedido`,
      errorsCheckCouponError: `error de cupón`,
      errorsCheckErrorsText: `'error de texto`,
      errorsFirstNameRequired: `Primer nombre requerido`,
      errorsFirstNameInvalid: `Nombre inválido`,
      errorsLastNameRequired: `Apellido requerido`,
      errorsLastNameInvalid: `Apellido inválido`,
      errorsPhoneRequired: `Teléfono requerido`,
      errorsPhoneInvalid: `Teléfono inválido`,
      errorsEmailRequired: `Correo electrónico requerido`,
      errorsEmailInvalid: `Correo electrónico inválido`,
      errorsConfirmEmailRequired: `confirmación de correo electrónico requerido`,
      errorsConfirmEmailInvalid: `confirmación de correo electrónico inválido`,
      errorsConfirmEmailNotMatching: `correo electrónico no coincide`,
      errorsAddressRequired: `Domicilio requerido`,
      errorsAddressInvalid: `Domicilio inválido`,
      errorsCityRequired: `Ciudad requerido`,
      errorsCityInvalid: `Ciudad inválido`,
      errorsZipRequired: `Código postal requerido`,
      errorsZipInvalid: `Código postal inválido`,
      errorsCountryRequired: `País requerido`,
      errorsStateRequired: `Estado requerido`,
      errorsShipToNameRequired: `Enviar a nombre requerido`,
      errorsShipToNameInvalid: `Enviar a nombre inválido`,
      errorsShippingAddressRequired: `dirección de envío requerida`,
      errorsShippingAddressInvalid: `dirección de envío inválido`,
      errorsShippingCityRequired: `ciudad de envío requerida`,
      errorsShippingCityInvalid: `ciudad de envío inválido`,
      errorsShippingZipRequired: `Zip de envío requerido`,
      errorsShippingZipInvalid: `Zip de envío inválido`,
      errorsShippingCountryRequired: `país de envío requerido`,
      errorsShippingStateRequired: `estado de envío requerido `,
      errorsPaymentNameRequired: `nombre de pago requerido`,
      errorsPaymentNameInvalid: `nombre de pago inválido`,
      errorsPaymentCardRequired: `tarjeta de pago requerida`,
      errorsPaymentCardInvalid: `tarjeta de pago inválido`,
      errorsPaymentExpMonthRequired: `mes de vencimiento requerido`,
      errorsPaymentExpYearRequired: `año de vencimiento requerido`,
      errorsPaymentCardExpired: `tarjeta de pago vencida`,
      errorsPaymentCCV2Required: `CCV2 requerido`,
      errorsPaymentCCV2Invalid: `CCV2 inválido`,
    }
  };

  constructor() {}

  static getCheckoutLanguages( language?: string ): any {
    if (language && this.languages.hasOwnProperty(language)) {
      return this.languages[language];
    } else {
      return this.languages['english'];
    }
  }
}
