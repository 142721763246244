import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LoggingProvider {
  constructor() {}

  logEvent(message: string) {
    // TOUC-9812
    let xhr = new XMLHttpRequest();
    let asy = true;
    xhr.open('GET', '/logging?msg=' + encodeURIComponent(message) +
      '&funnel_conf_destinations=' + (window as any)['funnel_confs'].destinations.length +
      '&funnel_conf_funnels=' + Object.keys((window as any)['funnel_confs'].funnels).length, asy);
    xhr.withCredentials = true;
    xhr.timeout = 5000;
    if (typeof (window as any)['getCookie'] === 'function') xhr.setRequestHeader('XSRF-TOKEN', (window as any)['getCookie']('XSRF-TOKEN')); // [TOUC-5682]
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 2) {
        console.info("%c[GH] logging complete" + message,  "color:lightgray;");
      }
    }
    xhr.send();
  }

  logPaymentEvent(message: string) {}

}