<div class="upsell-guarentee-div">
  <div class="row" margin-bottom justify-content-center>
    <div col-12 padding-horizontal class="guarantee_wrapper">
      <div>
        <div col-12 col-lg-8 no-padding class="button_wrapper" [ngClass]="{'full_width' : !showImage }">

          <button data-qa="cta-yes" margin-bottom  class="yesNoWrap_yesbutton  {{ upsellCtaTextButtonsClass }}" tappable (click)="nextPage(true)"  data-te="cta-yes" [innerHTML]="buttonYes"  title="YES I WANT THIS SPECIAL ADDED TO MY ORDER" >YES I WANT THIS SPECIAL ADDED TO MY ORDER</button>


          <button data-qa="cta-no" margin-bottom class="gray_button {{ upsellCtaTextButtonsClass }}" [ngClass]="{'hidden' : !showButtonNo }" tappable (click)="nextPage(false)" [innerHTML]="buttonNo" data-te="cta-no"></button>
          <p no-margin margin-bottom [ngClass]="{'hidden' : !showDescription }">{{ description }}</p>
        </div>
        <div col-12 col-lg-4 no-padding text-center class="guarantee_image" [ngClass]="{'hidden' : !showImage }">
          <img src="{{ image }}" alt="{{ imageAlt }}">
        </div>
      </div>
    </div>
  </div>
</div>
