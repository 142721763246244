import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { User } from 'src/app/models/user'
import { Address } from "src/app/models/address";
import { CHECKOUT_LABEL, SHIPPING_LABEL } from "src/constants/constants";

@Component({
  selector: 'shipping-form',
  templateUrl: './shipping-form.html',
  styleUrls: ['./shipping-form.scss']
})

export class ShippingFormComponent {

  @ViewChild('diffAddress') diffAddress: NgForm;
  @Input() guest: User = {} as User;
  @Input() countriesList: Array<any> = [];
  @Input() differentAddressStatesList: Array<any> = [];
  @Input() shippingAddress: Address = {} as Address;
  @Input() submittedAccountForm: boolean = false;
  @Input() couponValidation: boolean = false;
  @Input() shipToName: string = '';
  @Input() euSpecified : boolean = false;
  @Input() braintreeDefaultCountry: string = 'United States';


  @Output() linkForm = new EventEmitter();
  @Output() validateAddressAndCalculateTaxes = new EventEmitter<string>();
  @Output() rememberAccountInformation = new EventEmitter<void>();
  @Output() shipToNameBinder = new EventEmitter<string>();
  @Output() countryChange = new EventEmitter();
  @Output() stateChange = new EventEmitter();

  readonly errorImage: string = 'assets/img/icon-warning-pointer.gif';

  textPattern: string = '[,.[\/A-Za-z0-9 -¡«¿ßäéíñöüÄÉÍÑÖÜàÀÈèÌìòÒùÙáéíóúýÁÉÍÓÚÝ]*';
  mixedPattern: string = '^[a-zA-Z\\d][a-zA-Z\\d .,-]*$';
  addressPattern: string = '[*#\+,.%()^:;=?[\/A-Za-z0-9 -¡«¿ßäéíñöüÄÉÍÑÖÜàÀÈèÌìòÒùÙáéíóúýÁÉÍÓÚÝ]*' ; // TOUC-1036 xml safe list

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      this.linkForm.emit(this.diffAddress);
    }, 0);
  }

  validate_AddressAndCalculateTaxes(address: string) {
    this.validateAddressAndCalculateTaxes.emit(address);
  }

  remember_AccountInformation() {
    this.rememberAccountInformation.emit();
  }

  bindShipToName() {
    this.shipToNameBinder.emit(this.shipToName);
  }
  getLabel(label: string) {
    try {
      if(this.braintreeDefaultCountry && typeof CHECKOUT_LABEL[this.braintreeDefaultCountry] == 'object') return CHECKOUT_LABEL[this.braintreeDefaultCountry][label];
    } catch (e) {
    }
    return CHECKOUT_LABEL['United States'][label];
  }
  getLabelShipping(label: string) {
    try {
      if(this.braintreeDefaultCountry && typeof SHIPPING_LABEL[this.braintreeDefaultCountry] == 'object' && typeof  SHIPPING_LABEL[this.braintreeDefaultCountry][label] == 'string') {
       return SHIPPING_LABEL[this.braintreeDefaultCountry][label];
      }
    } catch (e) {
      console.log('err')
    }
    return SHIPPING_LABEL['United States'][label];
  }

  isFacebookApp() {
    if((window as any)['facebookAutofillComplete'])  {
      return false; // this means the FB auto fill already ran
    }
    let ua = navigator.userAgent || navigator.vendor || (window as any).opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)

  }
  onCountryChange() {
    this.countryChange.emit(true);
    //this.confirmAddress();
  }
  onStateChange() {
    this.stateChange.emit(true);
    //this.confirmAddress();
  }
  facebookAppShippingStateChangeHandler() {
    try {
      let fbshippingcountry = (document.querySelector('#FBShippingCountryCode') as any).value
      if (!fbshippingcountry || fbshippingcountry == '') fbshippingcountry = 'US';
      let FBStates = ((window as any)['countries']).find(({ value }: { [key: string]: any }) => value === fbshippingcountry);
      if (!FBStates) return;
      let FBState = FBStates.states;
      (document.querySelector('#facebookshippingstate') as any).value = FBState[(document.querySelector('#FBShippingStateCode') as any).value];
      if (this.diffAddress.form.controls.shipAddress.value == this.diffAddress.form.controls.addShipAddress.value) {
        this.diffAddress.form.controls.addShipAddress.patchValue('');
        this.diffAddress.form.controls.addShipAddress.markAsDirty();
        this.diffAddress.form.controls.addShipAddress.updateValueAndValidity();
      }
      if (FBStates.label != '' && FBStates.label != this.diffAddress.form.controls.shipCountry.value) {
        this.diffAddress.form.controls.shipCountry.patchValue(FBStates.label);
        this.diffAddress.form.controls.shipCountry.markAsDirty();
        this.diffAddress.form.controls.shipCountry.updateValueAndValidity();
        this.diffAddress.form.markAsDirty();
        this.validate_AddressAndCalculateTaxes('shipping');

        this.onCountryChange();
      }
      setTimeout(() => {
        if (FBState[(document.querySelector('#FBShippingStateCode') as any).value] != '') {
          this.diffAddress.form.controls.shipState.patchValue(FBState[(document.querySelector('#FBShippingStateCode') as any).value]);
          this.diffAddress.form.controls.shipState.markAsDirty();
          this.diffAddress.form.controls.shipState.updateValueAndValidity();
        }
        if (!this.diffAddress.form.controls.shipAddress.value && (document.querySelector('#FBAddressLine1') as any).value != '') {
          this.diffAddress.form.controls.shipAddress.patchValue((document.querySelector('#FBAddressLine1') as any).value);
          this.diffAddress.form.controls.shipAddress.markAsDirty();
          this.diffAddress.form.controls.shipAddress.updateValueAndValidity();
        }
        if (!this.diffAddress.form.controls.addShipAddress.value && (document.querySelector('#FBAddressLine2') as any).value != '') {
          this.diffAddress.form.controls.addShipAddress.patchValue((document.querySelector('#FBAddressLine2') as any).value);
          this.diffAddress.form.controls.addShipAddress.markAsDirty();
          this.diffAddress.form.controls.addShipAddress.updateValueAndValidity();
        }
        this.diffAddress.form.markAsDirty();
        this.validate_AddressAndCalculateTaxes('shipping');
        this.onStateChange();
      }, 300)
    } catch (e) {
      console.log('Facebook app shipping state changed', e);
    }
  }
}
