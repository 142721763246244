import { Component, Input } from "@angular/core";

@Component({
    selector: "upsell-product-box-full",
    templateUrl: "upsell-product-box-full.html",
    styleUrls: ["upsell-product-box-full.scss"],
})

export class UpsellProductBoxFullComponent {

    @Input() title: string = '';
    @Input() image: string = 'assets/img/No-Image-Available.jpg';
    @Input() imageAlt: string = '';
    @Input() additionalImage: string = 'assets/img/gundry-body.png';
    @Input() regularPrice: number = 0;
    @Input() instantSavings: number = 0;
    @Input() yourPrice: number = 0;
    @Input() regularText:string = 'Regular Price';
    @Input() instantSavingsText: string = 'Instant Savings';
    @Input() yourPriceText: string = 'Your Price';
    @Input() currency: string = 'USD';
    @Input() braintreeCurrencyConversion: number = 1;

    constructor() {}
}
