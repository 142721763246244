import { Component, Output, Input, EventEmitter } from "@angular/core";

@Component({
  selector: "upsell-guarantee",
  templateUrl: "upsell-guarantee.html",
  styleUrls: ["upsell-guarantee.scss"]
})

export class UpsellGuaranteeComponent {

  @Input() buttonYes: string = '';
  @Input() showButtonYes: boolean = true;
  @Input() buttonNo: string = '';
  @Input() showButtonNo: boolean = true;
  @Input() description: string = '';
  @Input() showDescription: boolean = false;
  @Input() image: string = '';
  @Input() imageAlt: string = '';
  @Input() showImage: boolean = true;
  @Input() upsellCtaTextButtonsClass: string;

  //Provide external method to navigate to next page over funnel
  @Output() eventEmitter: EventEmitter<boolean> = new EventEmitter();

  buttonValue: boolean = false;

  constructor() {}

  nextPage(buttonValue: boolean) {
    this.buttonValue = buttonValue ? true : false;
    this.eventEmitter.emit(this.buttonValue);
  }
}
