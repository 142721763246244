import { Injectable } from "@angular/core";
import { AppComponent } from "src/app/app.component";

import { UrlsProvider } from '../../../app/providers/urls';
import { TouchcrApiAffiliateComponent } from '../../../app/src/services/touchcr-api-affiliate/touchcr-api-affiliate.component';
import { FunnelSettingsExtenderProvider } from "../../../app/providers/funnel-settings-extender";
import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { StateExtenderProvider } from 'src/app/providers/state-extender';


@Injectable({
  providedIn: "root",
})
export class SpinnerProvider extends AppComponent {
  constructor(
    public override urls: UrlsProvider,
    public override tcrApiAffiliate: TouchcrApiAffiliateComponent,
    public override funnelProvider: FunnelSettingsExtenderProvider,
    public spinner: SpinnerProvider,
    public override navCtrl: NavControllerExt,
    public override stateProvider: StateExtenderProvider,
  ) {
    super(urls, tcrApiAffiliate, funnelProvider, navCtrl, stateProvider);
  }

  enable(message: string, additionalStyles?: string) {

  
   // ((document.querySelector('router-outlet') as any).nextElementSibling as any).classList.add('blurfilter');

    if (typeof message == "undefined" || message.trim().length == 0) message = "Loading...";
    (document.querySelector(".spinnerProvider-label") as HTMLDivElement).innerHTML = message;
    (document.querySelector(".spinnerProvider") as HTMLDivElement).style.display = "flex";
    (document.querySelector(".spinnerProvider-cover") as HTMLDivElement).classList.remove ('hidden');
    (document.querySelector(".spinnerProvider-cover") as HTMLDivElement).classList.add ('showing');

    document.body.classList.add('noscroll');
    
  }

  disable() {
   // ((document.querySelector('router-outlet') as any).nextElementSibling as any).classList.remove('blurfilter');

    document.body.classList.remove('noscroll');

    (document.querySelector(".spinnerProvider") as HTMLDivElement).style.display = "none";
    (document.querySelector(".spinnerProvider-cover") as HTMLDivElement).classList.add ('hidden');
    (document.querySelector(".spinnerProvider-cover") as HTMLDivElement).classList.remove ('showing');
  }

  create(data: any) {
    console.log("TODO if needed spinner create");
  }

  present() {
    console.log("TODO if needed spinner present");
  }

  dismiss() {
    console.log("TODO if needed spinner dismiss");
  }
}
