import { Component, Directive, Injectable, QueryList, TemplateRef, ViewChildren, ViewContainerRef } from '@angular/core';
import { ToastService } from 'src/app/services/toast-service';
// TODO didn't we get a piece of code from iTransition we can use for the ToastController?
@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-tutorial-details',
  templateUrl: './ToastController.html',
  styleUrls: ['./ToastController.css'],
})

export class ToastController {
  constructor(
    public toastService: ToastService,
    public viewContainerRef: ViewContainerRef
  ) {}
  @ViewChildren('standardTpl') standardTpl: any;
  @ViewChildren('successTpl') successTpl: any;
  @ViewChildren('dangerTpl') dangerTpl: any;

  create(data:any) {
    console.log('POC create toast ' + JSON.stringify(data));
    let type = 'info';
    if(typeof data.cssClass != 'undefined' && data.cssClass.indexOf('error') > -1) type='danger';
    else if (typeof data.cssClass != 'undefined' && data.cssClass.indexOf('success') > -1) type='success';
    else if (typeof data.cssClass != 'undefined' && data.cssClass.indexOf('warning') > -1) type='warning';
    else if (typeof data.cssClass != 'undefined' && data.cssClass.indexOf('standard') > -1) type='standard';

    (window as any)['VanillaToasts'].create({
    title: '',
    text: data.message,
    type: type,
    timeout: data.duration,
    positionClass: 'topCenter',
    callback: function(ele:any) { console.log('toast created',ele) } // executed when toast is clicked / optional parameter
    });

    if(typeof data.showCloseButton != 'undefined' && data.showCloseButton) {
      setTimeout(() => {
        ((document.querySelector('.vanillatoasts-toast') as any).firstChild as any).insertAdjacentHTML('afterEnd', `<p style="   
         position: absolute;
          top: 16px;
          right: 16px;">
          <button onclick="toastClose()">Close</button>
          </p>`);
      }, 100);
    }
  }
  present() {
    console.log('TODO if needed present toast');
  }
  openSnackBar(data:any) {
    console.log('TODO if needed snackbar: ' + data);
  }
}
