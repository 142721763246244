import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { OrderForm } from '../../models/orderForm';

const API_URL = (window as any)["process_env"].API_URL;

const GET_BUMP_OFFERS = 'orderforms/bumpoffers';
const GET_UPSELLS = 'orderforms/upsells';
const GET_FUNNEL = 'orderforms/funnel';


@Injectable({providedIn: 'root'})
export class TouchcrApiOrderformProvider {

  constructor(
    public http: HttpClient
  ) {
  }

  getBumpOffers(route:any): Observable<OrderForm[]> {
    return this.http.get<OrderForm[]>(`${API_URL}${GET_BUMP_OFFERS}/${route}`)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  getUpsells(route:any): Observable<OrderForm[]> {
    return this.http.get<OrderForm[]>(`${API_URL}${GET_UPSELLS}/${route}`)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  getFunnel(route:any): Observable<OrderForm> {
    return this.http.get<OrderForm>(`${API_URL}${GET_FUNNEL}/${route}`)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }
}
