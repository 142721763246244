<div class="scroll-content">
  <header-with-menu [showLogo]="showHeaderLogo" [icon]="gundryLogo" [showContent]="showHeaderContent"
    ></header-with-menu>
    <div class="gdpr-container">
      <p class="title"><strong>EU Privacy Policy</strong></p>
      <p>
        This privacy policy is only applicable to European Union residents.  We respect your privacy and appreciate the importance of your trust. As part of our efforts to help you understand how we handle the personal information you share with us, and in compliance with the European General Data Protection Regulation (“GDPR”), please review the following terms carefully.
      </p>
      <ol>
        <li>
          <strong>General Terms</strong>
          <p>As more fully described below, we may collect and process certain information that is provided by you to us.  We have a legal basis for collecting and processing such information because:</p>
          <ul>
            <li>
              You have given us permission to do so;
            </li>
            <li>
              We must provide services to you in conjunction with a purchase order that you have placed on our website; and/or
            </li>
            <li>
              We are required to do so in order to comply with applicable law.
            </li>
          </ul>
          <p>The Section below describes the types of information that we may collect from you and our business purposes for collecting such information.</p>
        </li>
        <li>
          <strong>What is Not Covered in this Policy?</strong>
          <p>Our website sometimes links to services run by other companies, such as paying for product orders through PayPal®.  Those companies have their own privacy policies, so please remember that the information you provide to them will follow their policies and not ours.</p>
        </li>
        <li>
          <strong>Information that We Collect and Process.</strong>
          <p><u>Information Provided by You</u>:</p>
          <p>We may collect Personal Information that you provide to us. “Personal Information” is information that can be used to identify you individually, and includes items such as your name, home address, e-mail address, telephone number, demographic information, and/or payment information, such as your account or card number, if used to make a purchase from our Website. If you maintain an account on our website, Personal Information may also include a user name, password and answers to our security questions. The type of Personal Information that we may collect depends on your use of this Website and what information you provide to us. Please note that in order to purchase any of the Products offered from our Website, you will be required to provide us with your name, billing address, shipping address, e-mail address and credit card number.</p>
          <p><u>Analytical Information</u>:</p>
          <p>We and our third-party service providers may also use a variety of technologies that automatically or passively collect information about how this Website is accessed and used (known as “Usage Information”).  “Usage Information” may include the type of browser and device you used to access our Website, your operating system and application version, the web pages accessed by you, the time you accessed these web pages, preceding web page views, and your use of any features or applications on this Website.  Statistical data like this helps us understand what is interesting and relevant to our customers, so we can best adapt our content for our customers’ benefit.</p>
          <p>We and our third-party service providers may also automatically collect an IP address or other unique identifier information from the computer, mobile device, or other device you use to access this Website.  We may use this information to, amongst other things, administer this Website, help diagnose server problems, analyze trends, track web page movements, help identify you and your shopping cart, and gather broad demographic information for collected use.</p>
          <p><u>Cookies and Other Technology</u>:</p>
          <p>Like many sites, our Website employs cookies, JavaScript tags and Web beacons (also known as clear GIF technology or “action tags”) to speed your navigation of our Website, recognize you and your access privileges, and track usage of our Website.  Cookies are small pieces of information that are stored as text files by your Internet browser on your computer’s hard drive.  We use both persistent cookies and third-party cookies.  Persistent and third-party cookies enable us to track and target the interests of our customers to enhance your experience on our Website.  Persistent cookies are used to make it easier for you to navigate our Website and remain on your hard drive for an extended period of time.  Third party cookies are only used for short-term tracking.</p>
          <p>Most Internet browsers are initially set up to accept cookies.  You may download an opt-out cookie <a (click)="navigateToPageByGenericUrl('/optout')">here</a> to disable any and all cookies we employ to track and enhance your user experience– however, your ability to use the website will be then limited and/or unavailable.</p>
          <p>Please note that this opt-out is cookie-based (i.e., a cookie must be on your computer to tell our systems that you have opted-out).  If you delete, block or otherwise restrict all cookies, this opt-out may not be effective.  Further, because different computers and different Internet browsers all require their own version of the opt-out cookie, you must perform this opt-out process on all computers and browsers that you wish to be opted-out.</p>
        </li>
        <li>
          <strong>Our Use and Sharing of Your Information.</strong>
          <p>We use the information that we collect about you for a variety reasons, including the following:</p>
          <ul class="bullet-list">
            <li>Verifying your identity;</li>
            <li>Fulfilling your product orders;</li>
            <li>Processing any product returns, exchanges or subscriptions;</li>
            <li>Responding to your questions;</li>
            <li>Communicating with you about your purchases and activities on this Website;</li>
            <li>Inviting you to participate in a survey regarding your health, our products or websites, which are always voluntary on your part;</li>
            <li>Improving the Website and our customers’ experience;</li>
            <li>Sending you emails and other marketing communications; and/or</li>
            <li>Sending notices or information.</li>
          </ul>
          <p>We may also use and disclose any information that is aggregated or de-identified so that it does not identify you personally, in our discretion.</p>
          <p>	If you provide us with a payment card when placing an order on our website, we use third-parties to validate your card, authenticate your identity and “tokenize” your payment credentials to make your payments more secure. Your “tokenized” credentials will be stored in your account in order to help make future purchases more convenient. You may delete or edit the stored payment methods in your account at any time by visiting our website or contacting customer support.</p>
          <p>We may share your Personal Information as follows:</p>
          <ul class="bullet-list">
            <li>With our vendors, consultants, agents, contractors, and other service providers or sub-processors (discussed more fully below) that we use to support our business;</li>
            <li>To provide you with any information or services that you request;</li>
            <li>To respond to subpoenas, court orders, and other legal process, or as otherwise required by law;</li>
            <li>To exercise our legal rights or to defend ourselves against legal claims, to enforce our contracts, to investigate, respond to and resolve problems or inquiries (including governmental inquiries), or to permit us to pursue available remedies or limit the damages that we may sustain;</li>
            <li>In connection with an actual or potential merger, sale, acquisition, assignment, or transfer of all or part of our assets, affiliates, lines of business, or products and services, including at bankruptcy;</li>
            <li>With our affiliates, subsidiaries, or parent companies; and</li>
            <li>With your consent.</li>
          </ul>
          <p>We contract with certain sub-processors to assist us in providing services to our customers.  These sub-processors use the Personal Information that you submit to us in order to perform the following tasks:</p>
          <ul class="bullet-list">
            <li>Order processing at checkout;</li>
            <li>Credit card authorization, tokenization and identity verification;</li>
            <li>Order shipping and handling;</li>
            <li>Emailing newsletters and other promotional materials for those customers on our mailing list;</li>
            <li>Storing and “backing up” our customer records.</li>
          </ul>
        </li>
        <li>
          <strong>Security and Your Information.</strong>
          <p>No data transmission over the Internet or website can be guaranteed to be secure from intrusion. However, we and our subprocessors maintain commercially reasonable physical, electronic and procedural safeguards to protect your Personal Information in accordance with data protection legislative requirements. We follow the Payment Card Industry Data Security Standards (PCI DSS) when handling credit card data. We also recommend that you take steps to protect against unauthorized access to your account through shared or public computers, devices, and applications.</p>
        </li>
        <li>
          <strong>How Long We Will Retain Your Information.</strong>
          <p>We will not retain your Personal Information for longer than necessary.  Your e-mail address will be retained on our mailing list until you request that we stop sending you advertising communications.  We will retain your other Personal Information in order to provide you with the ability to act on our Product warranties, to fulfill any Product subscriptions and/or for business record and legal purposes.</p>
          <p>For example, we may keep just enough of your personal information to ensure that we comply with your request to erase your personal information, or to exercise or defend our legal claims.</p>
        </li>
        <li>
          <strong>Consent to Processing Information.</strong>
          <p>This Website is governed by and operated in accordance with the laws of the State of California and the United States, and is intended for the enjoyment of residents of the United States.  If you are located outside of the United States, please be aware that information we collect will be transferred to and processed in the United States.</p>
        </li>
        <li>
          <strong>Third Party Links and Services.</strong>
          <p>Our Website may contain links to third-party websites and products and services.  We do not control such linked websites and this Privacy Policy does not apply to those websites.  We encourage you to read the privacy policy of any third-party website that you visit before you provide any information to that website’s owner.</p>
        </li>
        <li>
          <strong>Our Children’s Policy.</strong>
          <p>This Website is not directed to children under the age of sixteen (16).  Children may not use our Website or purchase our products, and we do not knowingly collect any personal information from children under the age of sixteen (16).  We have no way of distinguishing the age of individuals who access our Website. If a child has provided us with personal information, the parent or guardian should contact us to remove the information and opt out of promotional opportunities using the contact link provided below.</p>
        </li>
        <li>
          <strong>Changes and Updates to our Privacy Policy.</strong>
          <p>
            We may change this Privacy Policy at any time without prior notice to you.  Any changes that we make will be effective immediately upon our posting of the revised Privacy Policy on this Website.  Your continued use of this Website constitutes your consent to any changes made.  We will use your Personal Information in a manner consistent with the Privacy Policy in effect at the time you submitted your Personal Information, unless you later consent to a revised policy.  We encourage you to periodically review this page for the latest information on our privacy practices.
          </p>
        </li>
        <li>
          <strong>Your Personal Data Rights pursuant to GDPR.</strong>
          <p>As you may know, GDPR provides European citizens certain rights with respect to the access and control of their personal data.  For your convenience, we have summarized these rights here.  In the event you wish to contact us regarding any of these rights, or for any other reason, please use the link provided in Section 12.</p>
          <ol>
            <li>
              <p><u>Access your personal data</u>: You have the right to be informed of and request access to the personal data we process about you.</p>
              <p><u>Update your personal data</u>: You have the right to request that we amend or update personal data in our possession that is inaccurate or incomplete.</p>
              <p><u>Restrict the use of your personal data</u>: You have the right to request that we stop processing all or some of your personal data.</p>
              <p><u>Object to or complain about the use of your personal data</u>: You have the right to object to us processing your personal data if we no longer have a legitimate or legal need to do so. You also have the right to lodge a complaint with a supervisory authority regarding our use of your personal data.</p>
              <p><u>Port your personal data</u>: You have the right to request a copy of the personal data in our possession in a machine-readable format, as well as the right to transmit that personal data for use with another party’s service.</p>
              <p><u>Erase your personal data</u>: You have the right to request that we delete your personal data.</p>
            </li>
          </ol>
          <p>Please note that while we will try to accommodate your requests, there may be times when we might not be able to help.  For example, if you have recently purchased a product from us and this product is still within its applicable Money Back Guarantee period, we will not be able to honor a request to erase your personal data unless you first agree to waive your right to the Money Back Guarantee.</p>
        </li>
        <li>
          <strong>Questions or Requests?</strong>
          <p>In the event that you have any questions regarding our use of your personal information or wish to submit a request to view, modify, erase or restrict our processing of your personal information, please contact our Data Protection Officer <a href="mailto:dpa@gundrymd.com">here</a>.</p>
        </li>
      </ol>
      <p>Last updated: 7/23/2021</p>
    </div>
    <footer-common [links]="footerLinks"></footer-common>
</div>
