import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { State } from '../models/state';
import { TaxData } from '../models/taxData';
import { TouchcrApiShippingProvider } from './TouchcrApiShippingProvider';
import { Coupon } from "../models/coupon";
import { DebugProvider } from "./debugger";
import { UrlsProvider } from "./urls";

const NODE_ENV = (window as any)['process_env'].NODE_ENV;

@Injectable({providedIn: 'root'})
export class StateProvider {

  CHECKOUT_CARD_INFO: string = 'CheckoutCardInfo';
  UPSELL: string = 'Upsell';
  ORDER_SUCCESS: string = 'OrderSuccess';
  OFFER: string = 'Offer';
  PAYPAL_NVP: string = 'PayPalNVP';

  STATE_PARAM: string = 'state';
  debugMode: string = '';

  constructor(
    public storage: Storage,
    public shippingProvider: TouchcrApiShippingProvider,
    public urlsHelper: UrlsProvider,
    public debugProvider: DebugProvider
  ) {
    this.storage = new Storage({
        name: '__ghdb'
        });
      this.storage.create();
    let debugParameter = urlsHelper.getParameterFromUrl({ url: window.location.search, parameter: 'debug'});
    this.debugMode = debugParameter ? debugParameter.toLowerCase() : '';
  }

  set(state: State) {
    if (!state) return Promise.resolve();
    state.date = Date.now();
    return this.storage.set(this.STATE_PARAM, state);
  }

  get(): Promise<State> {
    if (NODE_ENV !== 'production' && this.debugMode) {
      if (this.debugMode === this.UPSELL.toLowerCase()) {
        return Promise.resolve(this.debugProvider.getUpsellDebugData());
      } else if (this.debugMode === this.ORDER_SUCCESS.toLowerCase()) {
        return Promise.resolve(this.debugProvider.getOrderSuccessData());
      }
    }

    return this.storage.get(this.STATE_PARAM);
  }

  clean() {
    return this.get()
      .then((state: State) => {
        if (!state) return Promise.resolve();
        delete state.pageName;
        delete state.navParams;
        delete state.orderDetails;
        delete state.funnel;
        delete state.date;
        delete state.accountDetails;
        delete state.taxData;
        delete state.upsells;
        delete state.shippingInfo;
        delete state.coupon;
        return this.set(state);
      })
  }

  setPageName(pageName: string) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.pageName = pageName;
        return this.set(state);
      })
  }

  setPageNameAndOrder(pageName: string, order: any) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.pageName = pageName;
        state.orderDetails = order;
        return this.set(state);
      })
  }

  setNameOrderUpsell(pageName: string, order: any, upsells: any) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.pageName = pageName;
        state.orderDetails = order;
        state.upsells = upsells;
        return this.set(state);
      })
  }

  /*Methods for purchased upsells*/
  setPurchasedUpsells(upsells: any) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        if (!state.upsells) state.upsells = [];
        state.upsells.push(upsells);
        return this.set(state);
      })
  }

  clearPurchasedUpsells() {
    return this.get()
      .then((state: State) => {
        if (!state) return Promise.resolve();
        delete state.upsells;
        return this.set(state);
      })
  }
  /*End methods for purchased upsells*/

  setUpsells(upsells: any) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        if (!state.orderDetails) return Promise.resolve();
        state.orderDetails.upsells = upsells;
        return this.set(state);
      })
  }

  /*Methods for tax*/
  setTaxData(taxData : TaxData) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        if (!taxData.isValid && state.taxData) taxData.isValid = state.taxData.isValid;
        state.taxData = taxData;
        return this.set(state);
      })
  }

  clearTaxDataInfo() {
    return this.get()
      .then(state => {
        if (!state) return Promise.resolve();
        delete state.taxData;
        return this.set(state);
      })
  }
  /*end methods for tax*/

  /*Methods for order*/
  setOrderDetails(orderDetails: any) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.orderDetails = orderDetails;
        return this.set(state);
      })
  }

  clearOrderDetails() {
    return this.get()
      .then(state => {
        if (!state) return Promise.resolve();
        delete state.orderDetails;
        return this.set(state);
      })
  }
  /*end methods for order*/

  /*methods for shipping*/
  setShippingInfo(shippingInfo: any) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.shippingInfo = shippingInfo;
        return this.set(state);
      })
  }

  clearShippingInfo() {
    return this.get()
      .then(state => {
        if (!state) return Promise.resolve();
        delete state.shippingInfo;
        return this.set(state);
      })
  }
  /*end methods for shipping*/

  /*methods for account details*/
  setAccountDetails(accountDetails: any) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.accountDetails = accountDetails;
        return this.set(state);
      })
  }

  clearAccountDetails() {
    return this.get()
      .then(state => {
        if (!state) return Promise.resolve();
        delete state.accountDetails;
        return this.set(state);
      })
  }
  /*end methods for account details*/

  addBumpExpireItems(bumpoffers: any[]) {
    return this.storage.get(this.STATE_PARAM)
      .then((state : State)=> {
        if (!state) state = {} as State;
        let oldList = state.bumpExpireList;
        let bumpArray = bumpoffers.reduce((acc, val) => {
          if (!oldList || !oldList.find((x: { sfid: any; }) => x.sfid === val.sfid)) {
            acc.push({
              sfid: val.sfid,
              viewDate: new Date(),
            })
          }
          return acc;
        }, []);
        bumpArray = oldList ? bumpArray.concat(oldList) : bumpArray;
        state.bumpExpireList = bumpArray;
        this.storage.set(this.STATE_PARAM, state);
        return bumpArray;
      });
  }

  setCoupon(coupon : Coupon) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.coupon = coupon;
        return this.set(state);
      })
  }

  clearCoupon() {
    return this.get()
      .then(state => {
        if (!state) return Promise.resolve();
        delete state.coupon;
        return this.set(state);
      })
  }

  clearFunnel() {
    return this.get()
      .then(state => {
        if (!state) return Promise.resolve();
        delete state.funnel;
        return this.set(state);
      })
  }
}
