import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Storage } from '@ionic/storage-angular';
import 'rxjs/add/observable/throw';

import { TouchcrApiAccountProvider } from './touchcr-api-account';
import { HttpInterceptorProvider } from '../src/services/http-interceptor/http-interceptor.component';

const API_URL = (window as any)["process_env"].API_URL;
const SHIPPING = (window as any)["process_env"].SHIPPING;
const GET_SHIPPING_RATES = 'remoteservices/shipping/getRates';
const CREATE_SHIPPING_INVOICE = "remoteservices/shipping/createInvoice";
const BRAND = (window as any)["process_env"].BRAND;


@Injectable({providedIn: 'root'})
export class TouchcrApiShippingProvider {

  constructor(
    public http: HttpClient,
    public accountProvider: TouchcrApiAccountProvider,
    public storage: Storage,
  ) { 
    this.storage = new Storage({
      name: '__ghdb'
      });
    this.storage.create();
  }


  //call this function to know if shipping is on
  isShippingAvailable(): boolean {
    return SHIPPING === true;
  }

  //body example:
  // {
  //  accountInfo: {
  //      firstName: 'firstName',
  //      lastName: 'lastName',
  //      shippingCountryCode: 'US',
  //      shippingStateCode: 'Il',
  //      shippingCity: 'Jacksonville',
  //      shippingStreet: '602 E Douglas Ave',
  //      shippingPostalCode: '62650',
  //      phone: '12321'
  //  },
  //  products: [
  //      {
  //        count: 1,
  //        shippingCost: 0
  //      },
  //    ]
  // }
  calculateShippingCost(body:any) {
    return this.http.post(`${API_URL}${GET_SHIPPING_RATES}`, body)
      .map((result: any) => {
        return result;
      })
      .catch((error) => {
        return Observable.throw(error);
      });
  }

  //call this function if close site, else call createInvoice function
  //body example(also same for function createInvoice):
  // {
  //  products: [
  //      {
  //        count: 1,
  //        shippingCost: 0
  //      }
  //   ],
  //  accountInfo: {
  //      firstName: 'firstName',
  //      lastName: 'lastName',
  //      shippingCountryCode: 'US',
  //      shippingStateCode: 'Il',
  //      shippingCity: 'Jacksonville',
  //      shippingStreet: '602 E Douglas Ave',
  //      shippingPostalCode: '62650',
  //      phone: '12321'
  //  },
  //  orderIds: 'orderId1, orderId2'
  // }
  createInvoiceViaXHR (body:any) {
    let bodyToSend = body;
    if ((window as any)["process_env"].NODE_ENV === 'production') {
      bodyToSend = HttpInterceptorProvider.encryptBody(body);
    }
    var xhr = new XMLHttpRequest();
    var url = (window as any)["process_env"].NODE_ENV === 'production' ? '/proxy/' : (window as any)["process_env"].API_URL;
    url += CREATE_SHIPPING_INVOICE;
    xhr.open("POST", url, false);
    xhr.setRequestHeader('x-brand', BRAND);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify(bodyToSend));
  }

  //see the comments of the method createInvoiceViaXHR
  createInvoice (body:any) {
    return this.http.post(`${API_URL}${CREATE_SHIPPING_INVOICE}`, body)
      .map((result: any) => {
        return result;
      })
      .catch((error) => {
        console.log(error);
        return Observable.throw(error);
      });
  }

}
