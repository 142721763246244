import { Injectable } from "@angular/core";
import { CreditCard } from "../../models/creditCard";

@Injectable({providedIn: 'root'})
export class CreditCardProvider {
  static readonly CARDS = {
  VISA: {
    type: 'visa',
    name: 'Visa',
    patterns: ['4'],
    gaps: [4, 4, 4, 4],
    numberLength: 16,
    codeLength: 3,
    image: 'assets/img/Visa.png'
  } as CreditCard,
  MASTERCARD: {
    type: 'mastercard',
    name: 'Mastercard',
    patterns: ['51-55', '222100-272099'],
    gaps: [4, 4, 4, 4],
    numberLength: 16,
    codeLength: 3,
    image: 'assets/img/Mastercard.png'
  } as CreditCard,
  DISCOVER: {
    type: 'discover',
    name: 'Discover',
    patterns: ['6011', '622126-622925', '644-649', '65'],
    gaps: [4, 4, 4, 4],
    numberLength: 16,
    codeLength: 3,
    image: 'assets/img/Discover.png'
  } as CreditCard,
  AMEX: {
    type: 'amex',
    name: 'American Express',
    patterns: ['34', '37'],
    gaps: [4, 6, 5],
    numberLength: 15,
    codeLength: 4,
    image: 'assets/img/Amex.png'
  } as CreditCard,
};
  constructor() {}

  isCardValid(cardData: any, card: CreditCard): boolean {
    return cardData && card
      && Number.isInteger(card.numberLength) && Number.isInteger(card.codeLength)
      && this.isTypeValid(card.type)
      && this.isNumberValid(String(cardData.number), card.numberLength)
      && this.isCodeValid(String(cardData.code), card.codeLength)
      && this.isExpirationDateValid(String(cardData.expmonth), String(cardData.expyear));
  }

  isTypeValid(type: string): boolean {
    if (type) {
      return CreditCardProvider.CARDS.hasOwnProperty(String(type).toUpperCase());
    }

    return false;
  }

  isNumberValid(number: string, numberLength: number): boolean {
    if (number && number.length === numberLength) {
      let sum = 0;

      for (let i = 0; i < numberLength; i++) {
        let cardNumber = parseInt(number[i]);
        if ((numberLength - i) % 2 === 0) {
          cardNumber = cardNumber * 2;
          if (cardNumber > 9) {
            cardNumber = cardNumber - 9;
          }
        }

        sum += cardNumber;
      }

      return sum % 10 === 0;
    }

    return false;
  }

  isCodeValid(code: string, codeLength: number): boolean {
    return !!code && code.length === codeLength;
  }

  isExpirationDateValid(expMonth: string, expYear: string): boolean {
    if (expMonth && expYear && (expYear.length === 2 || expYear.length === 4)) {
      let todayDate = new Date();
      let year = todayDate.getFullYear();
      if (expYear.length == 2) {
        expYear = `20${expYear}`;
      }

      let parsedYear = parseInt(expYear);
      if (parsedYear > year) {
        return true;
      } else if (parsedYear === year) {
        let month = todayDate.getMonth() + 1;
        return parseInt(expMonth) >= month;
      }
    }

    return false;
  }

  getCards(number: string): Array<CreditCard> {
    return number ? (Object.keys(CreditCardProvider.CARDS) as Array<keyof typeof CreditCardProvider.CARDS>)
        .map((key) => {
          return CreditCardProvider.CARDS[key];
        })
        .filter((card) => {
          return this.checkCardType(number, card.patterns);
        })
      : [];
  }

  checkCardType(number: string, patterns: Array<string>): boolean {
    let isCardTypeMatches = false;
    if (number && patterns) {
      for (let i = 0; i < patterns.length; i++) {
        let patternRange = patterns[i].split('-');
        if (patternRange.length === 1 && number.toString().indexOf(patternRange[0]) === 0) {
          isCardTypeMatches = true;
        } else {
          let rangeStart = parseInt(patternRange[0]);
          let rangeEnd = parseInt(patternRange[1]);
          let rangeLength = patternRange[0].length;

          let cardNumber;
          if (number.length < rangeLength) {
            cardNumber = parseInt(`${number}${new Array(rangeLength - number.length + 1).join('0')}`);
          } else {
            cardNumber = parseInt(number.substr(0, rangeLength));
          }

          if (rangeStart <= cardNumber && rangeEnd >= cardNumber) {
            isCardTypeMatches = true;
          }
        }
      }
    }

    return isCardTypeMatches;
  }
}
