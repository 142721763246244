import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { State } from '../models/state';
import { TouchcrApiShippingProvider } from './TouchcrApiShippingProvider';
import { DebugProvider } from "./debugger";
import { UrlsProvider } from "./urls";
import { StateProvider } from "./state";

const NODE_ENV = (window as any)['process_env'].NODE_ENV;

@Injectable({ providedIn: 'root' })
export class StateExtenderProvider extends StateProvider {

  isStorageAvailable: boolean = true;

  constructor(
    public override storage: Storage,
    public override shippingProvider: TouchcrApiShippingProvider,
    public override urlsHelper: UrlsProvider,
    public override debugProvider: DebugProvider
  ) {
    super(
      storage,
      shippingProvider,
      urlsHelper,
      debugProvider,
    );

    this.storage = new Storage({
      name: '__ghdb'
      });
    this.storage.create();

    //Check if storage is unavailable - TCR
    this.storage.set("StorageTest", {})
      .then(() => {
        this.storage.remove("StorageTest");
      })
      .catch(() => {
        this.isStorageAvailable = false;
      });
  }

  override set(state: State) {
    if (!state) return Promise.resolve();
    state.date = Date.now();
    if (this.isStorageAvailable) {
      return this.storage.set(this.STATE_PARAM, state);
    } else {
      return new Promise(resolve => {
        (window as any)['gh'][this.STATE_PARAM] = JSON.stringify(state);
        resolve(state);
      });
    }
  }

  override get(): Promise<State> {
    if (NODE_ENV !== 'production' && this.debugMode) {
      if (this.debugMode === this.UPSELL.toLowerCase()) {
        return Promise.resolve(this.debugProvider.getUpsellDebugData());
      } else if (this.debugMode === this.ORDER_SUCCESS.toLowerCase()) {
        return Promise.resolve(this.debugProvider.getOrderSuccessData());
      }
    }

    if (this.isStorageAvailable) {
      return this.storage.get(this.STATE_PARAM);
    } else {
      return new Promise<State>(resolve => {
        setTimeout(() => {
          let state = (window as any)['gh'][this.STATE_PARAM];
          let parsedState = JSON.parse(state ? state : "{}") as State;
          resolve(parsedState);
        }, 0);
      });
    }
  }

  override addBumpExpireItems(bumpoffers:any) {
    return this.get()
      .then((state : State)=> {
        if (!state) state = {} as State;
        let oldList = state.bumpExpireList;
        let bumpArray = bumpoffers.reduce((acc:any, val:any) => {
          if (!oldList || !oldList.find(x => x.sfid === val.sfid)) {
            acc.push({
              sfid: val.sfid,
              viewDate: new Date(),
            })
          }
          return acc;
        }, []);
        bumpArray = oldList ? bumpArray.concat(oldList) : bumpArray;
        state.bumpExpireList = bumpArray;
        this.set(state);
        return bumpArray;
      });
  }
}
