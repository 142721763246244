import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { FunnelSettingsProvider } from '../funnel-settings';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

const API_URL = (window as any)["process_env"].API_URL;
const CREATE_LEAD = 'lead/create/';

@Injectable({providedIn: 'root'})
export class TouchcrApiLeadProvider {

  constructor(
    public http: HttpClient,
    public storage: Storage,
    public funnelProvider: FunnelSettingsProvider,
  ) {

  }

  createLead(body:any) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${CREATE_LEAD}`, body).toPromise()
      .catch((error) => {
        return error && error.error && error.error.error;
      });
  }
}
