import { Injectable } from '@angular/core';

// import Keen from 'keen-js';
import { EventParserProvider } from './event-parse';

const KEEN_PROJECT_ID = (window as any)["process_env"].KEEN_PROJECT_ID;
const KEEN_WRITE_KEY = (window as any)["process_env"].KEEN_WRITE_KEY;
const KEEN_READ_KEY = (window as any)["process_env"].KEEN_READ_KEY;
const GTM = (window as any)['process_env'].GTM;

@Injectable({ providedIn: 'root' })
export class EventStreamerProvider {

  keenClient: any;

  constructor(
    public dataParser: EventParserProvider,
  ) {
    // this.keenClient = this.initKeenClient();
    dataParser.sessionID = this.getSessionId();
  }

  // initKeenClient() {
  //   return new Keen({
  //     projectId: KEEN_PROJECT_ID,
  //     writeKey: KEEN_WRITE_KEY,
  //     readKey: KEEN_READ_KEY,
  //   })
  // }

  streamEvent(eventType: string, eventData: any) {
    let event = this.dataParser.parseEvent(eventType, eventData);

    //Stream event to the google gtm
    if (GTM) {
      let eventGtm = Object.assign({}, event);
      eventGtm.event = eventType;

      if ((window as any)['dataLayer']) {
        (window as any)['dataLayer'].push(eventGtm);
      } else {
        (window as any)['dataLayer'] = [eventGtm];
      }
    }
    //Stream event to the google keen.io
    event.ipAddress = '${keen.ip}';
    event.userAgent = '${keen.user_agent}';
    // this.keenClient.addEvent(eventType, event);
  }

  getSessionId() {
    let gaId = this.dataParser.cookies.getCookie('GAID');
    if (!gaId) {
      gaId = this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
      this.dataParser.cookies.setCookie('GAID',gaId,7);
      this.streamEvent('session', gaId);
    }
    return gaId;
  }

  private s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
}
