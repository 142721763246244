import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

declare const InstallTrigger: any;

/**
* GeneralProvider class
* provides general methods for pages and components
*/
@Injectable({providedIn: 'root'})
export class GeneralProvider {
  private mWindow = <any>window;

  /** Constructor */
  constructor(
    public sanitized: DomSanitizer
  ) {

  }
  public  getsanitizedHTML(rawHTML:any) {
    return this.sanitized.sanitize(SecurityContext.HTML, this.sanitized.bypassSecurityTrustHtml(rawHTML))

		//return this.sanitized.bypassSecurityTrustHtml(rawHTML);
	}
  /** Return list of next 12 years */
  getExpiryYearList(currentYear: number) {
    /** Init result param */
    const result = [];
    /** Filling list of expiry years */
    for (let i = currentYear; i <= currentYear + 12; i++) {
      result.push(i);
    }
    /** Return list of current and next 12 years */
    return result;
  }

  isMobile() {
    return /Mobi/.test(navigator.userAgent);
  }

  getBrowserName() {
    // Firefox 1.0+
    if (typeof InstallTrigger !== 'undefined') {
      return 'Firefox';
    }

    if (this.mWindow.chrome) {
      return 'Chrome';
    }

    // Opera 8.0+
    if ((this.mWindow.opr && this.mWindow.opr.addons)
      || this.mWindow.opera
      || navigator.userAgent.indexOf(' OPR/') >= 0) {
      return 'Opera';
    }

    // Safari 3.0+ "[object HTMLElementConstructor]"
    if (/constructor/i.test(this.mWindow.HTMLElement) || ((p) => {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!this.mWindow['safari'])) {
      return 'Safari';
    }

    // Internet Explorer 6-11
    if (/* @cc_on!@*/false || document.DOCUMENT_NODE) {
      return 'Internet Explorer';
    }

    // Edge 20+
    if (this.mWindow.styleMedia) {
      return 'Microsoft Edge';
    }

    return null;
  }

  androidVersion() {
    if (/Android/.test(navigator.appVersion)) {
      return (navigator.appVersion).match(/Android (\d+).(\d+)/);
    }
    return null;
  }

  IOSVersion() {
    if (/iP(hone|od|ad)/.test(navigator.appVersion)) {
      const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [parseInt(v![1], 10), parseInt(v![2], 10), parseInt('' + (v![3] || 0), 10)];
    }
    return null;
  }

  getOSName() {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  getDeviceInfo() {
    return {
      os: this.getOSName(),
      browser: this.getBrowserName(),
      language: navigator.language,
      device: this.isMobile() ? 'Mobile' : 'Desktop',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      screen_resolution: screen.width + ' x ' + screen.height,
    };
  }

  /**
   *  @Deprecated Method was used to force content scrolling on page loading
   *  but caused scrolling issues on mobile devices - on scrolling to page top
   *  page was reloaded
   */
  enableJSScroll(content: any) {
    // if (this.platform.is('mobileweb') && this.platform.is('ios') && content && (navigator.userAgent.indexOf('CriOS') === -1)) {
    //   content.enableJsScroll();
    // }
  }

  disableBackButton() {
    //if(document.location.search.toLowerCase().indexOf('paypalnvp') === -1) {
      window.onpopstate = null;
      window.history.pushState(null, '', window.location.href);
      history.back();
      history.forward();
      window.onpopstate = () => {
        history.go(1);
      };
    //}
  }

}
