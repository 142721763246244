import { Component, Input } from "@angular/core";

@Component({
  selector: "upsell-section",
  templateUrl: "upsell-section.html",
  styleUrls: ["upsell-section.scss"],
})

export class UpsellSectionComponent {

  /**
   * @apiDescription Used to render content
   * @apiParam {content} Array containing content objects
   * @apiParamExample {content} Example input Array
   *  [{
   *    label: 'Step 1',
        active: false,
        showCheck: true
      }, {
   *    label: 'Step 2',
   *    active: true,
   *    showCheck: true
   *  }]
   */
  @Input() content: Array<any> = [];

  constructor() {}
}