import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

/* This interceptor is used for reqeusts with empty responses. Otherwise angular will try to parse null strung as JSON and crashes */
@Injectable({providedIn: 'root'})
export class EmptyBodyInterceptorProvider implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .catch((err: HttpErrorResponse) => {
        if (err.status >= 200 && err.status < 300) {
          const res = new HttpResponse({
            body: undefined, // Providing undefined instead of null
            headers: err.headers,
            status: err.status,
            statusText: err.statusText,
            url: err.url || undefined
          });
          return Observable.of(res);
        } else {
          return Observable.throw(err);
        }
      });
  }
}
