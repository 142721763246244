import { Injectable } from '@angular/core';

import { CookiesProvider } from './cookies';

@Injectable({providedIn: 'root'})
export class EventParserProvider {

  sessionID: string | undefined;

  isStorageAvailable: boolean = true;

  constructor(
    public cookies: CookiesProvider,
  ) {

  }
  parseEvent(eventType: string, eventData: any) {
    let productPayload:any = {};
    let affReplacer = JSON.stringify({});
    let affiliateInfo = this.isStorageAvailable ? window.sessionStorage.getItem('affiliateInfo')
      : (window as any)['gh'] ? (window as any)['gh'].affiliateInfo ? (window as any)['gh'].affiliateInfo : affReplacer : affReplacer;

    if (eventType === 'checkout') {
      productPayload = this.checkout(eventType, eventData);
    }
    else if (eventType === 'checkoutOption') {
      productPayload = this.checkoutOption(eventType, eventData);
    }
    else if (eventType === 'funnelOption') {
      productPayload = this.funnelOption(eventType, eventData);
    }
    else if (eventType === 'purchase') {
      productPayload = this.purchase(eventType, eventData);
    }
    else if (eventType === 'appScreenView') {
      productPayload = this.screenView(eventType, eventData);
    }
    else if (eventType === 'session') {
      productPayload = this.session(eventType, eventData);
    }
    if (affiliateInfo) {
      productPayload.affiliateInfo = JSON.parse(affiliateInfo);
    }
    if (productPayload) {
      productPayload.source = (window as any)["process_env"].SITE_NAME;
      return productPayload;
    }
  }
  parseEventBase(eventType: string, eventData: any) {
    let productPayload:any;
    //Check if storage is unavailable - TCR
    let isStorageAvailable = true;
    try {
      sessionStorage.setItem('StorageTest', '');
      sessionStorage.removeItem('StorageTest');
    } catch (e) {
      isStorageAvailable = false;
    }
    let affiliateInfo = null;
    if (isStorageAvailable) affiliateInfo = window.sessionStorage.getItem('affiliateInfo');

    if (eventType === 'checkout') {
      productPayload = this.checkout(eventType, eventData);
    }
    else if (eventType === 'checkoutOption') {
      productPayload = this.checkoutOption(eventType, eventData);
    }
    else if (eventType === 'funnelOption') {
      productPayload = this.funnelOption(eventType, eventData);
    }
    else if (eventType === 'purchase') {
      productPayload = this.purchase(eventType, eventData);
    }
    else if (eventType === 'appScreenView') {
      productPayload = this.screenView(eventType, eventData);
    }
    else if (eventType === 'session') {
      productPayload = this.session(eventType, eventData);
    }
    if (affiliateInfo) {
      productPayload.affiliateInfo = JSON.parse(affiliateInfo);
    }
    if (productPayload) {
      productPayload.source = (window as any)["process_env"].SITE_NAME;
      return productPayload;
    }
  }

  screenView(eventType: string, eventData: { screenPath: any; screenName: any; }) {
    return {
      eventType: eventType,
      screenPath: eventData.screenPath,
      screenName: eventData.screenName,
      sessionID: this.sessionID,
    };
  }

  checkout(eventType:any, eventData: any) {
    let brand: any;
    if (typeof eventData.data[0].orderFormBrand !== 'undefined') {
      brand = (typeof eventData.data[0].orderFormBrand.uniqueName !== 'undefined') ? eventData.data[0].orderFormBrand.uniqueName : '';
    } else if (typeof eventData.data[0].offerBrand !== 'undefined') {
      (typeof eventData.data[0].offerBrand.uniqueName !== 'undefined') ? eventData.data[0].offerBrand.uniqueName : '';
    }  else {
      brand = '';
    }
    return {
      eventType: eventType,
      customTitle: eventData.customTitle,
      ecommerce: {
        checkout: {
          actionField: {
            step: eventData.step,
            option: eventData.option,
          },
          products: {
            name: (typeof eventData.data[0].product.name !== 'undefined') ? eventData.data[0].product.name : '',
            id: (typeof  eventData.data[0].product.sfid !== 'undefined') ? eventData.data[0].product.sfid : '',
            price: (typeof eventData.data[0].standardPrice !== 'undefined') ? eventData.data[0].standardPrice : '',
            brand: brand,
            category: '',
            quantity: 1,

          },
          orderFormData: {
            orderFormName: (typeof eventData.data[0].name !== 'undefined') ? eventData.data[0].name : '',
            viewType: (typeof eventData.data[0].viewType !== 'undefined') ? eventData.data[0].viewType : '',
            orderFormType: (typeof eventData.data[0].formType !== 'undefined') ? eventData.data[0].formType : '',
            route: (typeof eventData.data[0].route !== 'undefined') ? eventData.data[0].route : '',
            purchasePrice: (typeof eventData.data[0].offerPrice !== 'undefined') ? eventData.data[0].offerPrice : '',
            offerShippingCost: (typeof eventData.data[0].shippingCost !== 'undefined') ? eventData.data[0].shippingCost: '',
            subscriptionPrice: (typeof eventData.data[0].subscriptionPrice !== 'undefined') ? eventData.data[0].subscriptionPrice : '',
          },
        }
      },
      sessionID: this.sessionID,
    };
  }

  funnelOption(eventType:any, eventData:any) {
    return {
      eventType: eventType,
      customTitle: eventData.customTitle,
      ecommerce: {
        checkout_option: {
          actionField: {
            step: eventData.step,
            option: eventData.option,
          }
        }
      },
      sessionID: this.sessionID,
    };
  }

  checkoutOption(eventType:any, eventData:any) {
    return {
      eventType: eventType,
      customTitle: eventData.customTitle,
      ecommerce: {
        checkout_option: {
          actionField: {
            step: eventData.step,
            option: eventData.option,
          }
        }
      },
      sessionID: this.sessionID,
    };
  }

  purchase(eventType:any, eventData:any) {
    return {
      eventType: eventType,
      customTitle: eventData.customTitle,
      ecommerce: {
        purchase: {
          actionField: {
            id: (typeof eventData.data.id !== 'undefined') ? eventData.data.id : '',
            affiliation: 'TouchCR Funnel site',
            revenue: (typeof eventData.data.total !== 'undefined') ? eventData.data.total : 0,
            tax: (typeof eventData.data.tax !== 'undefined') ? eventData.data.tax : 0,
            shipping: (typeof eventData.data.orderShippingCost !== 'undefined') ? eventData.data.orderShippingCost : 0,
            conversionid: (typeof eventData.data.name !== 'undefined') ? eventData.data.name : '',
            email: (typeof eventData.data.customerEmail !== 'undefined') ? eventData.data.customerEmail : '',
            coupon: '',
          },
          products: ([] as string[]).concat(this.getProductsData( eventData.data.orderItems, eventData.data.brand)),
        }
      },
      sessionID: this.sessionID,
    };
  }

  getProductsData(products:any, brand:any) {
    const result = [];
    for (let i = 0; i < products.length; i++) {
      let product:any = {};
      product['id'] = (typeof products[i].productId !== 'undefined') ? products[i].productId : '';
      product['name'] = (typeof products[i].productName !== 'undefined') ? products[i].productName : '';
      product['price'] = (typeof products[i].productPrice !== 'undefined') ? products[i].productPrice : 0;
      product['brand'] = (typeof brand !== 'undefined') ? brand : '';
      product['category'] = '';
      product['imageUrl'] = (typeof products[i].productImage !== 'undefined') ? products[i].productImage : '';
      product['count'] = (typeof products[i].quantity !== 'undefined') ? products[i].quantity : 1;
      result.push(product);
    }
    return result;
  }


  session(eventType:any, eventData:any) {
    this.sessionID = eventData;
    return {
      eventType: eventType,
      sessionID: this.sessionID,
    };
  }
}
