import { Injectable } from '@angular/core';
import { AffParameters } from '../models/affParameters';

export let QueryParams = '';
@Injectable({providedIn: 'root'})
export class UrlsProvider {
  PAYPAL_PARAMETERS = ['paypal', 'action', 'paymentId', 'token', 'PayerID'];
  PAYPAL_SUBSCRIPTION_PARAMETERS = ['paypalsubscription', 'action', 'token'];
  PAYPAL_NVP_PARAMETERS = ['paypalnvp', 'action', 'token', 'PayerID'];
  SUCCESS_PARAMETERS = ['orderId', 'orderReferenceId', 'amount', 'currencyCode', 'sellerOrderId', 'resultCode'];
  FAILURE_PARAMETERS = ['paypal', 'action', 'paymentId', 'token', 'PayerID'];

  onstep: number = 1;
  stepmovetime: number = 0;

  constructor() {
    try {
      if (sessionStorage.getItem('PayPalNVPParameters')) {
        console.log('[GH] restoring PayPalNVPParameters: ' + sessionStorage.getItem('PayPalNVPParameters'));
        QueryParams = sessionStorage.getItem('PayPalNVPParameters') || '';
        sessionStorage.removeItem('PayPalNVPParameters');
        // keep the token in the URL and combine with outgoing PayPalNVPParameters
        console.log('[GH] QueryParams', QueryParams + '&' + document.location.search.replace('&sessionid=','&papaynvpsid='))
        if(document.location.search.indexOf('uid=') == -1) {
           this.changeState(QueryParams + '&' + document.location.search.substring(1).replace('&sessionid=','&papaynvpsid='));
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  getNavigator() {
    return navigator.userAgent.substr(0, 100);
  };

  getDataFromUrl(url: string) {
    const result = this.getParameterFromUrl({ url: url, parameter: 'resultCode' });
    const isPayPal = this.getParameterFromUrl({ url: url, parameter: 'paypal' });
    const isPayPalSubscription = this.getParameterFromUrl({ url: url, parameter: 'paypalsubscription' });
    const isPayPalNVP = this.getParameterFromUrl({ url: url, parameter: 'paypalnvp' });
    let dataKeys: any[] = [];

    if (isPayPal) {
      dataKeys = this.PAYPAL_PARAMETERS;
    } else if (isPayPalSubscription) {
      dataKeys = this.PAYPAL_SUBSCRIPTION_PARAMETERS;
    } else if (isPayPalNVP) {
      dataKeys = this.PAYPAL_NVP_PARAMETERS;
    } else if (result === 'Success') {
      dataKeys = this.SUCCESS_PARAMETERS;
    } else if (result === 'Failure') {
      dataKeys = this.FAILURE_PARAMETERS;
    }

    const data:any = {};
    dataKeys.forEach((key) => {
      data[key] = this.getParameterFromUrl({ url: url, parameter: key });
    });

    data['paymentMethod'] = isPayPal ? 'PayPal' : result ? 'amazon' : 'cart';

    return data;
  }

  getParameterFromUrl({ url = '', parameter = '' }: { url: string, parameter: string }) {
    let parameterStart = url.indexOf(`${parameter}=`);
    if (parameterStart === -1) return '';

    parameterStart = parameterStart + parameter.length + 1;

    const parameterEnd = url.indexOf('&', parameterStart);
    if (parameterEnd === -1) {
      return url.substring(parameterStart);
    }

    return url.substring(parameterStart, parameterEnd);
  }

  getAffParamsFromUrl(url: string): AffParameters {
    return {
      utmSource: this.getParameterFromUrl({ url, parameter: 'utm_source' }),
      utmMedium: this.getParameterFromUrl({ url, parameter: 'utm_medium' }),
      utmCampaign: this.getParameterFromUrl({ url, parameter: 'utm_campaign' }),
      utmCampaignId: this.getParameterFromUrl({ url, parameter: 'utm_campaign_id' }),
      utmContent: this.getParameterFromUrl({ url, parameter: 'utm_content' }),
      utmTerm: this.getParameterFromUrl({ url, parameter: 'utm_term' }),
      affId: this.getParameterFromUrl({ url, parameter: 'affid' }),
      offId: this.getParameterFromUrl({ url, parameter: 'offid' }),
      subId1: this.getParameterFromUrl({ url, parameter: 'subid1' }),
      subId2: this.getParameterFromUrl({ url, parameter: 'subid2' }),
      subId3: this.getParameterFromUrl({ url, parameter: 'subid3' }),
      subId4: this.getParameterFromUrl({ url, parameter: 'subid4' }),
      subId5: this.getParameterFromUrl({ url, parameter: 'subid5' }),
    };
  }

  addParamToQuery(query: string, paramKey: string, paramValue: string) {
    //console.log('addParamToQuery ' + query + ' ' + paramKey + ' ' + paramValue);
    if (!paramKey || !paramValue) return query;
    if (this.getParameterFromUrl({ url: query, parameter: paramKey })) return query;
    if (!query) return `?${paramKey}=${paramValue}`;
    return `${query}&${paramKey}=${paramValue}`;
  }

  changeParamInQuery(query: string, paramKey: string, paramValue: string) {
    //console.log('changeParamInQuery', query, paramKey, paramValue);
    if (!query || !paramKey || !paramValue) return;
    let newQuery:any = this.removeParamFromQuery(query, paramKey);
    return this.addParamToQuery(newQuery, paramKey, paramValue);
  }

  removeParamFromQuery(query: string, paramKey: string) {
    if (!query) return;
    if (!paramKey) return query;
    const paramValue = this.getParameterFromUrl({ url: query, parameter: paramKey });
    if (!paramValue) return query;
    const keyVal = `${paramKey}=${paramValue}`;
    const startIndex = query.indexOf(keyVal);
    if (startIndex === -1) return;
    query.substr(startIndex - 1, 1);
    if (query[startIndex + keyVal.length] && query[startIndex + keyVal.length] === '&') {
      return query.substr(0, startIndex) + query.substr(startIndex + 1 + keyVal.length);
    } else {
      return query.substr(0, startIndex - 1) + query.substr(startIndex + keyVal.length);
    }
  }

  changeState(queryParams: string = '') {
    if(!queryParams) {
      console.log('[GH] changeState empty', queryParams);
      return;
    }
    this.setQueryParams(queryParams);
    const url = `${location.origin}${location.pathname}${queryParams}`;
    //console.log('replaceState', url);
    history.replaceState(null, '', url);
  }

  clearPayPalParams() {
    let query:any = window.location.search;
    if (this.getParameterFromUrl({ url: query, parameter: 'paypal'}))
      query = this.removeParamFromQuery(query, 'paypal');
    if (this.getParameterFromUrl({ url: query, parameter: 'paypalsubscription'}))
      query = this.removeParamFromQuery(query, 'paypalsubscription');
    if (this.getParameterFromUrl({ url: query, parameter: 'paypalnvp'}))
      query = this.removeParamFromQuery(query, 'paypalnvp');
    if (this.getParameterFromUrl({ url: query, parameter: 'action'}))
      query = this.removeParamFromQuery(query, 'action');
    if (this.getParameterFromUrl({ url: query, parameter: 'paymentId'}))
      query = this.removeParamFromQuery(query, 'paymentId');
    if (this.getParameterFromUrl({ url: query, parameter: 'token'}))
      query = this.removeParamFromQuery(query, 'token');
    if (this.getParameterFromUrl({ url: query, parameter: 'PayerID'}))
      query = this.removeParamFromQuery(query, 'PayerID');
    this.changeState(query);
  }

  getQueryParams() {
    return QueryParams;
  }
  fixQueryParams(str: string) {
    QueryParams = str;
  }
  setQueryParams(str: string) {
    if(!str) {
      console.log('[GH] setQueryParams empty', str);
      return;
    }
    if((!QueryParams ||  QueryParams.length == 0 ) && document.location.pathname.startsWith('/fst/')) {
      if(document.location.search != str) QueryParams = document.location.search + str;
      else QueryParams = str;
      return;
    } 
    if(document.location.pathname.startsWith('/fst/')) {
      QueryParams = str;
      return;
    }

    try {
      console.log('[GH] setQueryParams:' + str, QueryParams)
      let stpmove = parseInt(this.getParameterFromUrl({ url: str, parameter: 'step' }));
      if(!stpmove) stpmove = this.onstep;
      if(!this.stepmovetime) this.stepmovetime = new Date().getTime();
      if (QueryParams && QueryParams.length > 0 && stpmove > this.onstep && this.stepmovetime < (new Date().getTime() - 300)) {
        console.log('[GH] step move time', this.stepmovetime - new Date().getTime());
        this.onstep = stpmove;
      } else {
        console.log('[GH] step move skip ', this.onstep, stpmove, (this.stepmovetime - new Date().getTime()));
        return;
      }
    } catch (e) {
      console.log(e)
    }

    QueryParams = str;
  }

  clearAffParams() {
    let query:any = window.location.search;
    if (this.getParameterFromUrl({ url: query, parameter: 'utm_source'}))
      query = this.removeParamFromQuery(query, 'utm_source');
    if (this.getParameterFromUrl({ url: query, parameter: 'utm_medium'}))
      query = this.removeParamFromQuery(query, 'utm_medium');
    if (this.getParameterFromUrl({ url: query, parameter: 'utm_campaign'}))
      query = this.removeParamFromQuery(query, 'utm_campaign');
    if (this.getParameterFromUrl({ url: query, parameter: 'utm_campaign_id'}))
      query = this.removeParamFromQuery(query, 'utm_campaign_id');
    if (this.getParameterFromUrl({ url: query, parameter: 'utm_content'}))
      query = this.removeParamFromQuery(query, 'utm_content');
    if (this.getParameterFromUrl({ url: query, parameter: 'utm_term'}))
      query = this.removeParamFromQuery(query, 'utm_term');
    if (this.getParameterFromUrl({ url: query, parameter: 'affid'}))
      query = this.removeParamFromQuery(query, 'affid');
    if (this.getParameterFromUrl({ url: query, parameter: 'offid'}))
      query = this.removeParamFromQuery(query, 'offid');
    if (this.getParameterFromUrl({ url: query, parameter: 'subid1'}))
      query = this.removeParamFromQuery(query, 'subid1');
    if (this.getParameterFromUrl({ url: query, parameter: 'subid2'}))
      query = this.removeParamFromQuery(query, 'subid2');
    if (this.getParameterFromUrl({ url: query, parameter: 'subid3'}))
      query = this.removeParamFromQuery(query, 'subid3');
    if (this.getParameterFromUrl({ url: query, parameter: 'subid4'}))
      query = this.removeParamFromQuery(query, 'subid4');
    if (this.getParameterFromUrl({ url: query, parameter: 'subid5'}))
      query = this.removeParamFromQuery(query, 'subid5');

    this.changeState(query);
  }

  clearAmazonParams() {
    let query:any = window.location.search;
    if (this.getParameterFromUrl({ url: query, parameter: 'orderId'}))
      query = this.removeParamFromQuery(query, 'orderId');
    if (this.getParameterFromUrl({ url: query, parameter: 'resultCode'}))
      query = this.removeParamFromQuery(query, 'resultCode');
    if (this.getParameterFromUrl({ url: query, parameter: 'signature'}))
      query = this.removeParamFromQuery(query, 'signature');
    if (this.getParameterFromUrl({ url: query, parameter: 'sellerId'}))
      query = this.removeParamFromQuery(query, 'sellerId');
    if (this.getParameterFromUrl({ url: query, parameter: 'AWSAccessKeyId'}))
      query = this.removeParamFromQuery(query, 'AWSAccessKeyId');
    if (this.getParameterFromUrl({ url: query, parameter: 'SignatureMethod'}))
      query = this.removeParamFromQuery(query, 'SignatureMethod');
    if (this.getParameterFromUrl({ url: query, parameter: 'SignatureVersion'}))
      query = this.removeParamFromQuery(query, 'SignatureVersion');
    if (this.getParameterFromUrl({ url: query, parameter: 'orderReferenceId'}))
      query = this.removeParamFromQuery(query, 'orderReferenceId');
    if (this.getParameterFromUrl({ url: query, parameter: 'amount'}))
      query = this.removeParamFromQuery(query, 'amount');
    if (this.getParameterFromUrl({ url: query, parameter: 'currencyCode'}))
      query = this.removeParamFromQuery(query, 'currencyCode');
    if (this.getParameterFromUrl({ url: query, parameter: 'paymentAction'}))
      query = this.removeParamFromQuery(query, 'paymentAction');
    if (this.getParameterFromUrl({ url: query, parameter: 'sellerOrderId'}))
      query = this.removeParamFromQuery(query, 'sellerOrderId');
    this.changeState(query);
  }
}
