import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { EventStreamerProvider } from '../event-stream';


@Injectable({ providedIn: 'root' })
export class CartProvider {

  cartItems: any = new BehaviorSubject<any>(null);
  cartCount: any = new BehaviorSubject<any>(null);
  constructor(
    public storage: Storage,
    public eventStreamer: EventStreamerProvider,
  ) {
    this.storage = new Storage({
      name: '__ghdb'
      });
    this.storage.create();   
    this.getCartInfo();
  }

  getCartInfo() {
    this.storage.get('productCart')
      .then((value) => {
        let count = 0;
        if (value) {
          count = value.reduce((acc:any, elem:any) => {
            return acc + elem.count;
          }, 0);
        }
        this.cartItems.next(value);
        this.cartCount.next(count);
      })
      .catch((error) => {
        console.log('Error on getting cart data: ', error)
      })
  }

  updateProductCart(cart:any) {
    return this.storage.set('productCart', cart)
      .then(() => {
        this.getCartInfo();
      });
  }

  addToCart(product:any) {
    this.storage.get('productCart')
      .then((value) => {
        let oldCart = [];
        if (value) {
          oldCart = value;
        }
        let isAdded = false;
        for (let i = 0; i < oldCart.length; i++) {
          if (oldCart[i].sfid === product.sfid) {
            oldCart[i].count += product.count;
            isAdded = true;
            break;
          }
        }
        if (!isAdded) {
          oldCart.push(product);
        }

        this.eventStreamer.streamEvent('addToCart', {
          customTitle: product.name,
          fullUrl: window.location.href,
          data: oldCart,
        });
        return this.storage.set('productCart', oldCart);
      })
      .then(() => {
        this.getCartInfo();
      });
  }

  minusCartItem(item:any) {
    let cart = this.cartItems.value;
    let index = cart.indexOf(item);
    if (index >= 0 && cart[index].count > 1) {
      cart[index].count -= 1;
      this.updateProductCart(cart);
    }

    this.eventStreamer.streamEvent('removeFromCart', {
      customTitle: item.name,
      fullUrl: window.location.href,
      data: cart,
    });
  }

  deleteCartItem(item:any) {
    let cart = this.cartItems.value;
    let index = cart.indexOf(item);
    if (index >= 0) {
      cart.splice(index, 1);
      this.updateProductCart(cart);
    }

    this.eventStreamer.streamEvent('removeFromCart', {
      customTitle: item.name,
      fullUrl: window.location.href,
      data: cart,
    });
  }

  plusCartItem(item:any) {
    let cart = this.cartItems.value;
    let index = cart.indexOf(item);
    if (index >= 0) {
      cart[index].count += 1;
      this.updateProductCart(cart);
    }

    this.eventStreamer.streamEvent('addToCart', {
      customTitle: item.name,
      fullUrl: window.location.href,
      data: cart,
    });
  }

  cleanCart() {
    const promise = new Promise((resolve, reject) => {
      this.storage.remove('productCart')
        .then(() => {
          return this.storage.remove('bumpOffers')
        })
        .then(() => {
          this.getCartInfo();
          resolve('Cart is clean!');
        })
        .catch((error) => {
          reject(error);
        })
    })
    return promise;
  }

  setBumpOffers(bumpOffers:any) {
    return this.storage.set('bumpOffers', bumpOffers);
  }

  getBumpOffers() {
    return this.storage.get('bumpOffers');
  }

  // addBumpExpireItems(bumpoffers) {
  //   return this.storage.get('bumpExpireList')
  //     .then(oldList => {
  //       let bumpArray = bumpoffers.reduce((acc, val) => {
  //         if (!oldList || !oldList.find(x => x.sfid === val.sfid)) {
  //           acc.push({
  //             sfid: val.sfid,
  //             viewDate: new Date(),
  //           })
  //         }
  //         return acc;
  //       }, []);
  //       bumpArray = oldList ? bumpArray.concat(oldList) : bumpArray;
  //       this.storage.set('bumpExpireList', bumpArray);
  //       return bumpArray;
  //     });
  // }
}
