import { NavParams } from 'src/app/components/ionic-replacements/NavParams';
import { ToastController } from 'src/app/components/ionic-replacements/ToastController';
import { LoadingController } from 'src/app/components/ionic-replacements/LoadingController';

import { Component, ElementRef, HostListener, NgZone } from '@angular/core';

import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { OrderDetailsProvider } from 'src/app/providers/order-details/order-details';
import { TouchcrApiShippingProvider } from 'src/app/providers/TouchcrApiShippingProvider'
import { TouchcrApiRemoteServicesProvider } from 'src/app/providers/touchcr-api-remoteservices';
import { CalculateProvider } from 'src/app/providers/calculate/calculate';
import { UrlsProvider } from 'src/app/providers/urls';
import { TouchcrApiOrderformProvider } from 'src/app/providers/touchcr-api-orderform/touchcr-api-orderform';
import { GeneralProvider } from 'src/app/providers/general';
import { TouchcrApiGeneralExtenderProvider } from 'src/app/providers/touchcr-api-general/touchcr-api-general-extender';
import { TouchcrApiOrderExtenderProvider } from 'src/app/providers/touchcr-api-order/touchcr-api-order-extender';
import { EventStreamerProvider } from 'src/app/providers/event-stream';
import { PaypalPaymentExtenderProvider } from 'src/app/providers/paypal/paypal-extender';
import { StateProvider } from 'src/app/providers/state';
import { FunnelSettingsProvider } from 'src/app/providers/funnel-settings';
import { SpinnerProvider } from 'src/app/providers/spinner/spinner';
import { UpsellCommon } from './upsell-common';

const BRAND = (window as any)['process_env'].BRAND;


@HostListener('unloaded')
@Component({
  selector: 'upsell-common',
  templateUrl: './up-down-sell.html',
  styleUrls: ['./up-down-sell.scss'],
})
export class UpsellDynamic extends UpsellCommon {

	constructor(
		public override navCtrl: NavControllerExt,
		public override navParams: NavParams,
		public override tcrApi: TouchcrApiGeneralExtenderProvider,
		public override tcrApiOrder: TouchcrApiOrderExtenderProvider,
		public override loadingCtrl: LoadingController,
		public override eventStreamer: EventStreamerProvider,
		public override orderDetails: OrderDetailsProvider,
		public override paypalPayment: PaypalPaymentExtenderProvider,
		public override toastCtrl: ToastController,
		public override ngZone: NgZone,
	
		public override shippingProvider: TouchcrApiShippingProvider,
		public override tcrApiRemoteServices: TouchcrApiRemoteServicesProvider,
		public override calculateHelper: CalculateProvider,
		public override urlsHelper: UrlsProvider,
		public override stateProvider: StateProvider,
		public override tcrApiOrderform: TouchcrApiOrderformProvider,
		public override funnelProvider: FunnelSettingsProvider,
		public override generalProvider: GeneralProvider,
		public override spinner: SpinnerProvider,
		public override elementRef: ElementRef,
	
	  ) {
		super(
		  navCtrl,
		  navParams,
		  tcrApi,
		  tcrApiOrder,
		  loadingCtrl,
		  eventStreamer,
		  orderDetails,
		  paypalPayment,
		  toastCtrl,
		  ngZone,
	
		  shippingProvider,
		  tcrApiRemoteServices,
		  calculateHelper,
		  urlsHelper,
		  stateProvider,
		  tcrApiOrderform,
		  funnelProvider,
		  generalProvider,
		  spinner,
		  elementRef
		);

		this.isMobile = this.generalProvider.isMobile();
		if (this.isMobile) {
			console.log("This is a tablet device.");
		} else {
			if ((navigator.userAgent as any).mobile) {
				this.isMobile = true;
				console.log("This is a tablet device.");
			} else if (window.matchMedia("(max-width: 768px)").matches) {
				this.isTablet = true;
				console.log("This is a tablet device.");
			} else {
				console.log("This is a desktop device.");
			}
		}

	
		//TOUC-1525
		(window as any)['spintest'] = this.spintest;
	
		this.urlParameters = urlsHelper.getDataFromUrl(window.location.search);
		this.urlParameters.brand = BRAND;
		//Check if storage is unavailable - TCR
		try {
		  sessionStorage.setItem('StorageTest', '');
		  sessionStorage.removeItem('StorageTest');
		} catch (e) {
		  this.isStorageAvailable = false;
		}
	
	
		try { // TOUC-1251 v1.24.9.1
		  if (!this.beforeunloadHandler && window.addEventListener) {
			this.visibilityStateChangeHandler = (e:any) => {
			  console.log(`Tab state : ${document.visibilityState}`);
			  this.sendCustomEvent(`visibilitychange ${document.visibilityState}`, `Tab state : ${document.visibilityState}`);
			};
			window.addEventListener("visibilitychange", this.visibilityStateChangeHandler);
			console.log('added visibilitychange handler');
			this.beforeunloadHandler = (e:any) => {
			  if (!this.exitpop) return; //TOUC-1490
			  this.sendCustomEvent('beforeunload');
			  console.log('emitted beforeunload event');
			  this.saveduserHandler = () => {
				window.removeEventListener("focus", this.saveduserHandler);
				setTimeout(() => {
				  this.sendCustomEvent('saveduser');
				  console.log('emitted saveduser event');
				  this.spinner.disable();
				}, 1000);
			  };
			  window.addEventListener("focus", this.saveduserHandler);
			  console.log('added focus handler');
			  if (this.plannedNav) return null;
	
			  (e || window.event).returnValue = this.confirmationMessage;
			  return this.confirmationMessage;
			};
			window.addEventListener("beforeunload", this.beforeunloadHandler);
			console.log('added beforeunload handler');
		  }
		} catch (e) {
		  console.log("unable to setup TOUC-1251 handlers: ", e);
		}
	
	  }


	override braintreeCurrencyConversion: number = 1; // TOUC-14710
	override currencySymbol: string = "$"; // TOUC-14710
	override currency: string = 'USD'; // TOUC-14710

	public override async ngOnInit() {
		super.ngOnInit();
		this.setupPage();
	}

	async setupPage() {
		this.spinner.enable('Loading page.<br>Please Wait');
		await this.populatePageData();
		setTimeout(() => {
			this.spinner.disable();
		}, 5000);
	}

	getPathName() {
		if (location.pathname.indexOf('{') > -1) return '';
		return location.pathname.substring(1);
	}

	//overwrites ionViewDidLoad from upsell-common.ts to remove populatepagedata calls and extra spinners
	//async ionViewDidLoad() {
	public async  ngAfterViewInit ()  {
		try {
			this.initTimer = new Date().getTime(); // TOUC-902
			setTimeout(() => { this.sendCustomEvent('Sell Page View'); }, 1000); // TOUC-982
		/* 	(document.querySelector('.scroll-content') as any).addEventListener('scroll', (e:any) => {
				this.scrollEvents++;
			}); */
		} catch (e) {
			console.log("TOUC-982 warning setting up scroll handler ", e);
		}

		this.isAmazonScriptLoaded = false;
		this.state = await this.stateProvider.get();

		console.log("this.state", this.state);

		// [TOUD-5477]

		try { // TOUC-962
			if (!this.state || !this.state.orderDetails || (document.location.search && document.location.search.indexOf("pseudoState=true") > -1)) {
				if (localStorage.getItem("debugMode") == 'true') {
					this.state = this.getPseudoState('');
				}
			}
		} catch (e) {
			console.log("TOUC-962 error", e);
		}



		if (!this.state || !this.state.orderDetails) {
			const params: any = await this.funnelProvider.getPageByStep('first');
			if (!params) {
				return this.navCtrl.setRootExt('Home', { name: 'Home' });
			}
			const { component } = params;
			return this.navCtrl.pushExt(component.name, { name: component.name }).then(() => {
				return this.navCtrl.clearStack();
			});
		} else {
			try {
				(window as any)['USER_EMAIL'] = this.state.orderDetails.order.customerEmail;
				(window as any)['CONVERSION_ID'] = this.state.orderDetails.order.name;
				(window as any)['ORDER_TOTAL'] = this.state.orderDetails.order.total;
				console.log("upsell dynamic conversion happened: " + (window as any)['USER_EMAIL'] + '::' + (window as any)['CONVERSION_ID'])
				console.log('attempting to set ctotal or cid cookies: ' + (window as any)['ORDER_TOTAL'] + ' ' + (window as any)['CONVERSION_ID']);
				document.cookie = 'ctotal=' + (window as any)['ORDER_TOTAL'] + '; expires=3600; path=/';
				document.cookie = 'cid=' + (window as any)['CONVERSION_ID'] + '; expires=3600; path=/';
			} catch (e) {
				console.log('upsell dynamic unable to set ctotal or cid cookies: ' + e);
			}
			
			if (!this.isStorageAvailable && this.state.orderDetails.paymentMethod == 'PayPal') {
				return this.stateProvider.setPageNameAndOrder(this.stateProvider.ORDER_SUCCESS, this.state.orderDetails.order)
					.then(() => {
						console.log('redirect to order success');
						this.plannedLeavePage = true;
						this.sendCustomEvent(`THANK YOU`, 'paypal no storage');

						return this.navCtrl.setRootExt(this.stateProvider.ORDER_SUCCESS, { name: 'Order Success', isUpsell: true });
					})
			}
		}
		// Get shipping information from storage
		if (this.isShippingAvailable) {
			if (this.state.shippingInfo) {
				this.productsForShipping = this.state.shippingInfo.productsForShipping;
				this.isShippingValid = this.state.shippingInfo.isValid;
				this.accountInfoForShipping = this.state.shippingInfo.accountInfoForShipping;
				this.selectedDeliveryDay = this.state.shippingInfo.selectedDeliveryDay;
			} else {
				this.isShippingValid = false;
			}
		}
		if (this.taxServiceEnabled) {
			if (this.state.taxData) {
				this.isTaxValid = this.state.taxData.isValid;
			}
		}

		this.orderDetailsInfo = this.state.orderDetails;
		await this.tcrApi.getCountries().toPromise() //Get map of countries
			.then(async (countries:any) => {
				if (countries.length === 0) {
					console.log('getting a backup static copy of the country state payload');
					countries = await this.tcrApi.getCountriesDefault().toPromise();
				}

				if (countries) {
					countries.forEach((country:any) => {
						this.countries[country.label as any] = country;
					});
				}
			})
			.then(async () => {
				(window as any)['CheckoutBasePlaceOrder'] = { changePlaceOrder: this.changePlaceOrder.bind(this), zone: this.ngZone };
				(window as any)['buttonList'] = { buttonList: this.buttonList, zone: this.ngZone };
				(window as any)['upsellInfo'] = { isUpsell: true, zone: this.ngZone };
				return Promise.reject({
					allOk: true,
				});
			})
			.catch(async (error:any) => {
				if (!error.allOk) {
					console.log('Error', error);
					this.toastCtrl.create({ message: `Error! ${error.message}`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
				}
			})

			await this.setData();
	return;
		}

	//overwritten setData() function from upsell-common.ts to remove populatePageData calls
	//adds setProductData() function within here as well to grab the necessary upsell data
	override async setData() {
		let paymentInProcess = false;
		if (!this.orderDetailsInfo.navParams) {
			this.orderDetailsInfo.navParams = {};
		}

		this.orderDetailsInfo.navParams.order = this.orderDetailsInfo.order;
		this.order = this.orderDetailsInfo.order;
		this.orderDetailsInfo.navParams.upsells = this.orderDetailsInfo.upsells;
		this.purchasedUpsells = this.orderDetailsInfo.purchasedUpsells || [];

		this.upsellIndex =
			this.orderDetailsInfo.upsellIndex === undefined ? -1 : this.orderDetailsInfo.upsellIndex;

		if (!this.orderDetailsInfo.navParams.paymentMethod && !this.urlParameters['resultCode']) {
			this.upsellIndex--;
		}

		this.orderDetailsInfo.navParams.paymentMethod = this.orderDetailsInfo.paymentMethod;
		this.isAmazon = this.orderDetailsInfo.paymentMethod === 'amazon';

		if (this.urlParameters['paypal'] || this.urlParameters['paypalnvp']) {
			paymentInProcess = true;

			this.orderDetailsInfo.navParams.paymentMethod = 'PayPal';
			if (this.urlParameters['action'] === 'execute') {
				if (this.urlParameters['paypalnvp']) {
					this.executePayPalNVP(this.urlParameters, this.purchasedUpsells[this.purchasedUpsells.length - 1]);
				} else {
					this.executePayPalPayment(this.urlParameters);
				}
			} else if (this.urlParameters['action'] === 'cancel') {
				let cancelOrder = this.purchasedUpsells.pop();
				this.paypalPayment.cancelPayment(cancelOrder.id).then(
					(result: any) => {
						if (result.message === 'success') {
							this.sendCustomEvent(`ORDER CANCELLED!`);

							this.toastCtrl
								.create({ message: `ORDER CANCELLED!`, duration: 3000, position: 'top' })
								//.present();
						}
					},
					() => {
						this.sendCustomEvent(`Error on cancelling order!`);

						this.toastCtrl
							.create({ message: `Error on cancelling order!`, duration: 3000, position: 'top' })
							//.present();
					},
				);
			}
		}

		if (this.orderDetailsInfo.navParams.upsells && this.orderDetailsInfo.navParams.order) {
			if (this.orderDetailsInfo.navParams.paymentMethod === 'Standard')
				this.paymentMethod.standard = true;


			if (this.orderDetailsInfo.navParams.paymentMethod.startsWith('Braintree ')) {  // TOUC-14710
				if(this.orderDetailsInfo.cr && this.orderDetailsInfo.order.crv) {
					if(this.orderDetailsInfo.cr != this.orderDetailsInfo.order.crv) {
					  console.log('[GH] currency conversion rate changed');
					  return this.navCtrl.setRootExt(this.stateProvider.ORDER_SUCCESS, { name: 'Order Success', isUpsell: true });
					}
					this.braintreeCurrencyConversion = parseFloat(this.orderDetailsInfo.cr);
				  }
				this.paymentMethod.braintreeMethod = true;
				this.currency = this.orderDetailsInfo.navParams.paymentMethod.replace( 
					'Braintree ', ''
				  );
				if(sessionStorage.getItem('currency')) this.currency = (sessionStorage as any).getItem('currency');
				if(sessionStorage.getItem('currencySymbol')) this.currencySymbol = (sessionStorage as any).getItem('currencySymbol');

				console.log('[GH] this upsell', this.upsell);
				console.log('[GH] this currency', this.currency);
				console.log('[GH] this currency symbol', this.currencySymbol);
			  }
		  

			if (this.orderDetailsInfo.navParams.paymentMethod === 'PayPal')
				this.paymentMethod.paypal = true;
			this.order = this.orderDetailsInfo.navParams.order;
			if (
				this.orderDetailsInfo.navParams.upsells &&
				this.orderDetailsInfo.navParams.upsells.length > 0
			) {
				[this.upsell] = this.orderDetailsInfo.navParams.upsells;
				this.setProductData(); //added here to set the product data of pricing, etc. once we know the upsell
			}
			if (this.taxServiceEnabled) this.showNext(false);
			if (!this.taxServiceEnabled) this.calculateTransactionFee();
		} else {
			const params: any = await this.funnelProvider.getPageByStep('first');
			if (!params) {
				return this.navCtrl.setRootExt('Home', { name: 'Home' });
			}
			const { component } = params;
			return this.navCtrl.pushExt(component.name, { name: component.name }).then(() => {
				return this.navCtrl.clearStack();
			});
		}

		setTimeout(() => {
			try {
				// listen to known navigation events, control what beforeunload handler does
				let links = document.getElementsByTagName('a');
				for (let lc = 0; lc < links.length; lc++) {
					window.addEventListener('click', (event) => {
						this.plannedNav = true;
						setTimeout(() => {
							this.plannedNav = false;
						}, 300);
					});
				}
			} catch (e) {
				console.log('error ' + e);
			}
		}, 1); // TOUC-1243

		// Pixel
		this.appendPixel();
		this.generalProvider.disableBackButton();
	return;
	}


	wait(ms:any) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	override async populatePageData() {
		// TODO see if we need to keep waiting with this version the app, this line came from Gundry B
		await this.wait(300); //wait 300ms to ensure we are populating the correct step number from url parameter
		const urlParams = new URLSearchParams(window.location.search);
		let uid = urlParams.get('uid');

		let pv = urlParams.get('preview');
		let stepInUrlParam = urlParams.get('step');
		let preview = "";
		if (pv && pv == 'true') {
			preview = "-preview";
		}
		if (uid && uid.indexOf("-step") > -1 && !stepInUrlParam) {
			// [TOUC-5927] trying to look at a page without passing a page= parameter will result in stepInUrlParam=null
			let stp = uid.substring(uid.indexOf("-step") + 5);
			stp = stp.substring(0, stp.indexOf("-"));
			if (stp) {
				try {
					parseInt(stp);
					stepInUrlParam = stp;
					if (pv && pv == 'true') {
						uid = uid.replace("-step" + stepInUrlParam + "-preview", "");
					} else {
						uid = uid.replace("-step" + stepInUrlParam, "");
					}
				} catch (e) {
					// non integer parsed, ignoring
				}
			}
		} else if (uid && uid.indexOf("-step") > -1 && stepInUrlParam) {
			try {
				parseInt(stepInUrlParam);
				if (pv && pv == 'true') {
					uid = uid.replace("-step" + stepInUrlParam + "-preview", "");
				} else {
					uid = uid.replace("-step" + stepInUrlParam, "");
				}
			} catch (e) {
				// non integer parsed, ignoring
			}
		}
		
		// TODO determine if doing it this way is better than the way it was done before (with steps in url)
		/* const dataFromApi = await this.getDynamicContent(`/assets/content/upDownDyn-${uid}-step${stepInUrlParam}${preview}.json`);*/			

		try {
			if (!this.dynamicContentTransformedDataFromApi) {
				this.spinner.enable("Loading page.<br>Please Wait");
				let dataFromApi:any;
				if(`${this.getPathName()}` == 'upDownDyn') dataFromApi = await this.getDynamicContent(`/assets/content/upDownDyn-${uid}.json`);
				else dataFromApi = await this.getDynamicContent(`/assets/content/upDownDyn-${this.getPathName()}.json`);

			}
		} catch (err) {
			console.log(`Error: ${err}`);
		}
	}
	removeHandlers() {
		try {
			if(document.querySelector('.yesNoWrap_yesbutton')) {
				let btns = document.querySelectorAll('.yesNoWrap_yesbutton');
				for (let i = 0; i < btns.length; ++i) {
					(btns[i] as any).removeEventListener('click', this.orangesButtonClick);
				}
			}
			if(document.querySelector('.gray_button')) {
				let btns = document.querySelectorAll('.gray_button');
				for (let i = 0; i < btns.length; ++i) {
					(btns[i] as any).removeEventListener('click', this.grayButtonClick );
				}
			}
		} catch (e) {
			console.log('Error in ngOnDestroy', e);
		}
	}
	override ngOnDestroy() {

		if(document.querySelector('#dynamicCss')) {
			let styleTag = document.querySelector('#dynamicCss');
			(styleTag as any).remove();
		}
		super.ngOnDestroy();
		this.mintercnt = 9999;
		if (this.beforeunloadHandler) {
			if (this.beforeunloadHandler) window.removeEventListener("beforeunload", this.beforeunloadHandler);
		}
		if (this.saveduserHandler) {
			if (this.saveduserHandler) window.removeEventListener("focus", this.saveduserHandler);
		}
		if (this.visibilityStateChangeHandler) {
			window.removeEventListener("visibilitychange", this.visibilityStateChangeHandler);
		}
		//empty pagecontents on leave;
		this.pageContents = [];
		//remove style tag when leaving the page so new styles can be added on page load
		let styleTag = document.getElementById('cmsUniqueStyleTag');
		if(styleTag) styleTag.remove();
	}
}