import { Component, Input } from "@angular/core";

@Component({
    selector: "upsell-steps",
    templateUrl: "upsell-steps.html",
    styleUrls: ["upsell-steps.scss"],
})

export class UpsellStepsComponent {

  /**
   * @apiDescription Used to render steps
   * @apiParam {steps} Array containing steps objects
   * @apiParamExample {steps} Example input Array
   *  [{
   *    title: "",
   *    blocks: [
   *       `<p>Some text</p>`,
   *       `<p>Some text</p>`,
   *       `<p>Some text</p>`,
   *    ]
   *  }, {
   *    title: "Some title",
   *    blocks: [
   *       `<p>Some text</p>`,
   *    ]
   *  }]
   */
  @Input() steps: Array<any> = [];
  @Input() stepLabel: string = 'step';

    constructor() {}
}
