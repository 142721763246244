import { Component, EventEmitter, Input, NgModule, Output, ViewChild } from "@angular/core";
import { AbstractControl, NgForm, ValidatorFn, Validators } from "@angular/forms";
import { CardDetails } from "../../app/models/cardDetails";
import { CreditCardProvider } from '../../app/providers/credit-card/credit-card';
import { CreditCard } from "../../app/models/creditCard";

enum FIELDS {
  NUMBER = <any>'number',
  CODE = <any>'code',
  EXP_MONTH = <any>'expmonth',
  EXP_YEAR = <any>'expyear',
}

@Component({
  selector: 'payment-form-base',
  template: ''
})
export class PaymentFormComponentBase {
  static readonly DEFAULT_CARD: CreditCard = {
    gaps: [4, 4, 4, 4],
    numberLength: 16,
    codeLength: 3,
  } as CreditCard;

  readonly FIELDS = FIELDS;
  card: CreditCard = PaymentFormComponentBase.DEFAULT_CARD;

  firstValidation: boolean = true;

  @ViewChild('paymentForm') paymentForm: NgForm | any;

  //@Input() mContent: Content;
  @Input() cardDetails: CardDetails = {} as CardDetails;
  @Input() submittedPaymentForm: boolean = false;
  @Input() monthArray: Array<any> = [];
  @Input() yearArray: Array<any> = [];

  @Output() linkForm = new EventEmitter();

  constructor(public ccProvider: CreditCardProvider) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.paymentForm.controls[this.FIELDS.NUMBER].setValidators([this.cardNumberValidator(this), this.cardTypeValidator(this), Validators.required]);
      this.paymentForm.controls[this.FIELDS.EXP_MONTH].setValidators([this.expirationDateValidator(this), Validators.required]);
      this.paymentForm.controls[this.FIELDS.EXP_YEAR].setValidators([this.expirationDateValidator(this), Validators.required]);
      this.linkForm.emit(this.paymentForm);
    }, 0);
  }

  findCard(): void {
    let cards = this.ccProvider.getCards(this.removeNonNumberCharacters(String(this.cardDetails.card)));
    if (cards.length === 1) {
      this.card = cards[0];
    } else {
      this.card = PaymentFormComponentBase.DEFAULT_CARD;
    }

    this.formatInputData(this.FIELDS.NUMBER);
  }

  formatInputData(field: FIELDS): void {
    if (this.FIELDS.hasOwnProperty(field)) {
      if (field === this.FIELDS.NUMBER) {
        let strippedNumber = this.removeNonNumberCharacters(String(this.cardDetails.card));
        let formattedNumber:any = [];
        if (strippedNumber.length > 0) {
          let originNumber = strippedNumber;

          for (let i = 0; i < this.card.gaps.length; i++) {
            let regex = new RegExp(`.{1,${this.card.gaps[i]}}`, 'g');
            let matchedParts = originNumber.match(regex);
            if (matchedParts && matchedParts.length > 0) {
              formattedNumber.push(matchedParts[0]);
            }

            originNumber = originNumber.substring(this.card.gaps[i]);
          }
        }
        this.paymentForm.controls[this.FIELDS.NUMBER].setValue(formattedNumber.join(' '));
      } else if (field === FIELDS.CODE) {
        this.paymentForm.controls[this.FIELDS.CODE].setValue(this.removeNonNumberCharacters(String(this.cardDetails.cardccv)));
      }
    }
  }

  removeNonNumberCharacters(stringData: string): string {
    if (stringData) {
      return stringData.replace(/\D/g, '');
    } else {
      return stringData;
    }
  }

  cardTypeValidator(scope:any): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return scope.ccProvider.isTypeValid(String(scope.card.type))
      ? null
      : {'invalidType': {value: control.value}}
    }
  }

  cardNumberValidator(scope:any): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return scope.ccProvider.isNumberValid(scope.removeNonNumberCharacters(String(scope.cardDetails.card)), scope.card.numberLength)
        ? null
        : {'invalidNumber': {value: control.value}};
    };
  }

  expirationDateValidator(scope:any): ValidatorFn {
    // TODO observed that this function is called twice on blur event of month and year fields - TCR
    // getting an error here when posting the form, with a valid date getting cardExpired
    /* 
    expyear
: 

defaultValue
: 
null
errors
: 
cardExpired
: 
value
: 
"2026"


    */
/*     console.log('expirationDateValidator scope', scope);
    console.log('this.cardDetails scope', this.cardDetails); */

    //if(!scope.cardDetails.expyear) scope.cardDetails.expyear = new Date().getFullYear();
    //if(!scope.cardDetails.cardType) scope.cardDetails.cardType = 'Visa';
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (scope.firstValidation) {
        scope.firstValidation = !scope.firstValidation;
        let parent:any = control.parent;
        if (parent.controls[scope.FIELDS.EXP_MONTH] === control) {
          parent.controls[scope.FIELDS.EXP_YEAR].updateValueAndValidity();
        } else if (parent.controls[scope.FIELDS.EXP_YEAR] === control) {
          parent.controls[scope.FIELDS.EXP_MONTH].updateValueAndValidity();
        }
        scope.firstValidation = !scope.firstValidation;
      }

      return scope.ccProvider.isExpirationDateValid(String(scope.cardDetails.expmonth), String(scope.cardDetails.expyear))
        ? null
        : {'cardExpired': {value: control.value}};
    };
  }
}
