import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Storage } from '@ionic/storage-angular';
import 'rxjs/add/observable/throw';

import { TouchcrApiAccountProvider } from './touchcr-api-account';
import { StateProvider } from './state';
import { TaxData } from '../models/taxData';

const API_URL = (window as any)["process_env"].API_URL;

const CALCULATE_TAX = 'remoteservices/calculatetax/';
const CALCULATE_TRANSACTION_FEE = 'remoteservices/calculateTransactionsFee/';
const CAPTURE_TRANSACTION_FEE = 'remoteservices/captureTransactionsFee/';

@Injectable({providedIn: 'root'})
export class TouchcrApiRemoteServicesProvider {

  taxServiceEnabled: boolean = (window as any)["process_env"].IS_ENABLED_SALESTAX;

  constructor(
    public http: HttpClient,
    public accountProvider: TouchcrApiAccountProvider,
    public storage: Storage,
    public stateProvider: StateProvider,
  ) {
    this.storage = new Storage({
      name: '__ghdb'
      });
    this.storage.create();
   }

  /** Method are create request for calculating taxes;
    cartData : cart data  for car site;
    funnelOffersData: funnel data for funnel site;
    bumpOffersData: bump offer data for both site;
    account: first variant for logged user - account = AcoountInformation(object);
    account: second variant for guest user - account = account:{
                                                                  shipTo:{
                                                                    line1: Guest.street,
                                                                    city: Guest.city,
                                                                    region: Guest.region,
                                                                    country: Guest.country,
                                                                    postalCode: Guest.postalCode,
                                                                  }
                                                                }
    coupon: coupon object
    shipping: shipping amount for eaxample 'shiiping = 20';
  **/
  requestDataForTaxes(body: any) {
    const { cartData, funnelOffersData, bumpOffersData, account, subtotal, coupon, shipping, forSubscription = false } = body;
    let taxRequest = { accountDetails: { shipTo: {} } } as TaxData;
    let shipTo = {};
    let discountPercent: any;
    let productDetails : any[] = [];

    if (account.shipTo) {
      taxRequest.accountDetails.shipTo = account.shipTo;
    } else {
      taxRequest.accountDetails.shipTo = {
        line1: account.shippingStreet,
        city: account.shippingCity,
        region: account.shippingStateCode,
        country: account.shippingCountryCode,
        postalCode: account.shippingPostalCode
      }
    }

    // If coupon has been aplied and has coupon Discount
    if (!coupon || !coupon.couponDiscount) {
      discountPercent = 0;
    }
    else if (coupon.discountType === '$ Off') {
      discountPercent = (coupon.discountAmount / subtotal) > 1 ? 1 : (coupon.discountAmount / subtotal);
    }
    else if (coupon.discountType === 'Discount %') {
      discountPercent = (coupon.discountAmount / 100);
    }

    // If funnel Offer data is exist
    if (funnelOffersData) {
      for (let i = 0; i < funnelOffersData.length; i++) {
        let item: any = {};
        item.productDescription = funnelOffersData[i]['product']['description'];
        item.description = funnelOffersData[i]['product']['name'];
        item.taxCode = funnelOffersData[i]['product']['productTaxCode'];
        item.number = funnelOffersData[i]['product']['sfid'];
        item.quantity = 1;
        if (coupon) {
          item.amount = forSubscription ? funnelOffersData[i].subscriptionPrice : funnelOffersData[i].offerPrice - (funnelOffersData[i].offerPrice * discountPercent);
        } else {
          item.amount = forSubscription ? funnelOffersData[i].subscriptionPrice : funnelOffersData[i].offerPrice;
        }
        productDetails.push(item);
      }
    }
    // if Cart data is exist
    if (cartData) {
      for (let i = 0; i < cartData.length; i++) {
        let item: any = {};
        item.taxCode = cartData[i]['productTaxCode'];

        if (!cartData[i].activeVariant) { // check if it is a product variant
          item.number = cartData[i]['sfid'];
          item.quantity = cartData[i]['count'];
          item.description = cartData[i]['name'];
          item.productDescription = cartData[i]['description'];
          if (coupon) {
            item.amount = +(cartData[i]['price'][0]['unitprice'] - (cartData[i]['price'][0]['unitprice'] * discountPercent)).toFixed(2);
          } else {
            item.amount = +cartData[i]['price'][0]['unitprice'].toFixed(2);
          }

        } else {
          item.number = cartData[i]['sfid'];
          item.quantity = cartData[i]['count'];
          item.description = cartData[i]['name'];
          if (coupon) {
            item.amount = +(cartData[i].activeVariant.price - (cartData[i].activeVariant.price * discountPercent)).toFixed(2);
          } else {
            item.amount = +cartData[i].activeVariant.price.toFixed(2);
          }
        }

        productDetails.push(item);
      }
    }
    // if bumpOffersData is exist
    if (bumpOffersData) {
      for (let i = 0; i < bumpOffersData.length; i++) {
        if (bumpOffersData[i].isInCart) {
          let item = {
            productDescription: bumpOffersData[i].product.description,
            description: bumpOffersData[i].product.name,
            taxCode: bumpOffersData[i].productTaxCode,
            number: bumpOffersData[i].product.sfid,
            quantity: 1,
            amount: bumpOffersData[i].offerPrice,
          };
          productDetails.push(item);
        }
      }
    }

    taxRequest.productDetails = productDetails;
    taxRequest.shipping = shipping;
    taxRequest.total = subtotal;
    return this.http.post(`${API_URL}${CALCULATE_TAX}`, taxRequest)
      .map((res: any) => {
        let taxData: any = {};
        taxData['taxRate'] = res;
        taxData['taxRequest'] = taxRequest;
        taxData['productDetails'] = productDetails;
        taxRequest.isValid = true;
        this.stateProvider.setTaxData(taxRequest);
        return taxData;
      })
      .catch((error) => {
        taxRequest.isValid = false;
        this.stateProvider.setTaxData(taxRequest);
        console.log('CALCULATE_TAX ERR catch ==> ', error);
        return Observable.throw(error);
      });
  }

  /*  body - upsells object
      method for calculate tax amount foreach upsell
  */
  getTaxRateForUpsell(body: any, state: any) {
    if (!this.taxServiceEnabled || (state.taxData && !state.taxData.isValid)) {
      return Observable.of({ skip: true });
    }
    return this.http.post(`${API_URL}${CALCULATE_TAX}`, body)
      .map((body: any) => {
        return body;
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        return Observable.of('error');
      });
  }

  //  check shipping Address to  is if changed
  //  taxData - stored object from storage.
  //  acctualAccInfo - actual Account info.
  //  method using onliy for logged user.
  checkAccInfo(taxData: any, actualAccInfo: any) {
    let changed: boolean = false;
    if (taxData.dataForTax.accountDetails.shipTo.line1 != actualAccInfo.shippingStreet ||
      taxData.dataForTax.accountDetails.shipTo.city != actualAccInfo.shippingCity ||
      taxData.dataForTax.accountDetails.shipTo.country != actualAccInfo.shippingCountryCode ||
      taxData.dataForTax.accountDetails.shipTo.postalCode != actualAccInfo.shippingPostalCode ||
      taxData.dataForTax.accountDetails.shipTo.region != actualAccInfo.shippingStateCode) {
      return changed = true;
    } else {
      return changed;
    }
  }

  // generating account information
  generateAccDataForTax(accountInfo: any) {
    let accountDetails: any = {};
    if (accountInfo.shippingStreet &&
      accountInfo.shippingCity &&
      accountInfo.shippingStateCode &&
      accountInfo.shippingCountryCode &&
      accountInfo.shippingPostalCode) {
      accountDetails['shipTo'] = {
        line1: accountInfo.shippingStreet,
        city: accountInfo.shippingCity,
        region: accountInfo.shippingStateCode,
        country: accountInfo.shippingCountryCode,
        postalCode: accountInfo.shippingPostalCode
      };
      return accountDetails;
    } else {
      return 'You should fill a valid shipping address on Account details Page for calculate tax!';
    }
  }

  // generate object for upgrade upsell.
  getDataForTaxUpgradeOrder(allTaxData: any, currentUpsell: any) {
    let dataForTax = {} as TaxData;
    dataForTax.accountDetails = allTaxData.accountDetails;
    const item = {
      productDescription: currentUpsell.product.description,
      description: currentUpsell.product.name,
      taxCode: currentUpsell.productTaxCode,
      number: currentUpsell.product.sfid,
      quantity: 1,
      amount: currentUpsell.offerPrice
    };
    dataForTax.productDetails = [];
    dataForTax.productDetails.push(item);
    dataForTax.total = currentUpsell.offerPrice;
    dataForTax.shipping = currentUpsell.shippingCost || 0;
    this.stateProvider.setTaxData(dataForTax);
    return dataForTax;
  }

  // transaction fee
  requestDataForTrFee(body: any) {
    const { brand, account, orderTotalWithoutFee, orderCardNicn, orderIdTemp, orderSTxId,
      orderTotalWithoutFeeSubscription, orderIdTempSubscription, orderSTxIdSubscription } = body;
    const trFeeRequest = [];
    const orderBillingCountry = account.billingCountryCode ? account.billingCountryCode : account.billingCountry;
    const orderInfo: any = {
      orderId: orderIdTemp,
      orderBrand: brand,
      orderTotalWithoutFee: +orderTotalWithoutFee.toFixed(2),
      orderCampaign: '',
      orderBillingCountry: orderBillingCountry,   // 'United States', 'USA', 'US'
      orderBillingZip: account.billingPostalCode, // '90210'
      orderCardNicn: orderCardNicn
    };
    if (orderSTxId && orderSTxId !== '') orderInfo['sTxId'] = orderSTxId;
    trFeeRequest.push(orderInfo);

    if (orderTotalWithoutFeeSubscription > 0) {
      const secondPayment: any = {
        orderId: orderIdTempSubscription,
        orderBrand: brand,
        orderTotalWithoutFee: +orderTotalWithoutFeeSubscription.toFixed(2),
        orderCampaign: '',
        orderBillingCountry: orderBillingCountry,   // 'United States', 'USA', 'US'
        orderBillingZip: account.billingPostalCode, // '90210'
        orderCardNicn: orderCardNicn
      };
      if (orderSTxIdSubscription && orderSTxIdSubscription !== '') secondPayment['sTxId'] = orderSTxIdSubscription;
      trFeeRequest.push(secondPayment);
    }

    return this.getTransactionFee(trFeeRequest);
  }

  getTransactionFee(trFeeRequest: any) {
    return this.http.post(`${API_URL}${CALCULATE_TRANSACTION_FEE}`, trFeeRequest)
      .map((res: any) => {
        let feeData: any = {};
        feeData['trFeeRequest'] = trFeeRequest;
        feeData['trFeeResponse'] = res;
        console.log('Calculate Transaction Fee: ', feeData);
        return feeData;
      })
      .catch((error) => {
        let feeData: any = {};
        feeData['trFeeRequest'] = trFeeRequest;
        feeData['trFeeResponse'] = { transactionFee: 0 };
        error.feeData = feeData;
        console.log('CALCULATE_TRANSACTION_FEE ERR catch ==> ', error);
        return Observable.throw(error);
      });
  }

  captureTransactionFeeRequest(body: any) {
    const { orderBrand, orderMTxId, orderSTxId, orderId, subscriptionSFId, subscriptionSTxId } = body;
    const captureFeeRequest: any[] = [];
    const orderInfo: any = {
      orderId: orderId,
      orderBrand: orderBrand,
    };
    if (orderMTxId && orderMTxId !== '') orderInfo['mTxId'] = orderMTxId;
    if (orderSTxId && orderSTxId !== '') orderInfo['sTxId'] = orderSTxId;
    captureFeeRequest.push(orderInfo);

    if (subscriptionSFId && subscriptionSFId !== '') {
      const subscriptionInfo: any = {
        orderId: subscriptionSFId,
        orderBrand: orderBrand,
        mTxId: subscriptionSFId,
      };
      if (subscriptionSTxId && subscriptionSTxId !== '') subscriptionInfo['sTxId'] = subscriptionSTxId;
      captureFeeRequest.push(subscriptionInfo);
    }

    return this.http.post(`${API_URL}${CAPTURE_TRANSACTION_FEE}`, captureFeeRequest)
      .map((res: any) => {
        let feeData: any = {};
        feeData['trFeeRequest'] = captureFeeRequest;
        feeData['trFeeResponse'] = res;
        console.log('Capture Transaction Fee: ', feeData);
        return feeData;
      })
      .catch((error) => {
        let feeData: any = {};
        feeData['trFeeRequest'] = captureFeeRequest;
        feeData['trFeeResponse'] = { transactionFee: 0 };
        error.feeData = feeData;
        console.log('CAPTURE_TRANSACTION_FEE ERR catch ==> ', error);
        return Observable.throw(error);
      });
  }

}
