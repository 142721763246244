import { Component, Input } from '@angular/core';
import * as constants from '../../constants/constants';
import { ToastController } from 'src/app/components/ionic-replacements/ToastController';

@Component({
  selector: 'video-player',
  templateUrl: 'video-player.html'
})
export class VideoPlayerComponent {

  @Input() videoID: string = '';
  @Input() videoMessageTitle: string;
  @Input() videoMessage: string;
  @Input() constants = constants;
  @Input() videoPlayType: string;
  videoScriptAutoPlay: any = [];
  constructor(
    public toastCtrl: ToastController
    ) {
      
  }
  // DPUqvBFAwn is autoplay variable need to assign to videoPlayType on iterationis
  player: any;
  window: any = window;
  randomScrumble: string = this.create_UUID();

  
  async ngAfterViewInit() {
    console.log('begin: ', this.randomScrumble);
    console.log('begin: ', this.videoID);
    await this.insertVideoScripts();
    console.log('end: ', this.randomScrumble);
    this.loadVideo();
    console.log('ngAfterViewChecked', this.videoPlayType);
  }

  ngOnDestroy() {
    try {
      if ( this.player ) {
        this.player.destroy();
      }
    } catch ( e ) {
      console.log( e );
    }
  } 

  async insertVideoScripts() {
    this.videoScriptAutoPlay = [`https://players.brightcove.net/6058004206001/${this.videoPlayType}_default/index.min.js`];
    const scripts = document.getElementsByClassName( this.videoScriptAutoPlay );

    console.log('scripts', scripts);

    if ( scripts.length === 0 ) {
      // tslint:disable-next-line:prefer-for-of
      for ( let i = 0; i < this.videoScriptAutoPlay.length; i++ ) {
        await this.loadVideoScript( this.videoScriptAutoPlay[ i ] )
          .catch( e => {
            return this.toastCtrl
              .create( {
                message: e.message,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast'
              } )
             // .present();
          } );
      }
    }
  }

  loadVideoScript( scriptCode:any ) {
    return new Promise( ( resolve, reject ) => {
      const script = document.createElement( 'script' );
      script.src = scriptCode;
      script.setAttribute( 'class', 'brightcoveCont' );
      document.head.appendChild( script );

      script.addEventListener( 'load', () => {
        resolve( script );
      } );

      script.addEventListener( 'error', () => {
        reject( new Error( `${ scriptCode } failed to load.` ) );
      } );
    } );
  }


  loadVideo() {
    const playerID = `player_${ this.randomScrumble }`;
    console.log('player id:', playerID);
  }

  // Borrowed from https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
  create_UUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, ( c ) => {
      const r = ( dt + Math.random() * 16 ) % 16 | 0;
      dt = Math.floor( dt / 16 );
      return ( c === 'x' ? r : ( r & 0x3 | 0x8 ) ).toString( 16 );
    } );
    return uuid;
  }

}