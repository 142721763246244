//import { Content, LoadingController, NavParams, ToastController } from 'ionic-angular';
import { ToastController } from 'src/app/components/ionic-replacements/ToastController';
import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { Component, NgZone } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { TouchcrApiOrderformProvider } from "src/app/providers/touchcr-api-orderform/touchcr-api-orderform";
import { Observable, Subscription } from "rxjs/Rx";
import { CartProvider } from "src/app/providers/cart/cart";
import { TouchcrApiGeneralProvider } from "src/app/providers/touchcr-api-general/touchcr-api-general";
import { TouchcrApiOrderProvider } from "src/app/providers/touchcr-api-order/touchcr-api-order";
import { EventStreamerProvider } from "src/app/providers/event-stream";
import { TouchcrApiLeadProvider } from "src/app/providers/touchcr-api-lead/touchcr-api-lead";
import { TouchcrApiCouponProvider } from "src/app/providers/touchcr-api-coupon/touchcr-api-coupon";
import { OrderDetailsProvider } from 'src/app/providers/order-details/order-details';
import { PaypalPaymentProvider } from 'src/app/providers/paypal/paypal';

import { TouchcrApiRemoteServicesProvider } from "src/app/providers/touchcr-api-remoteservices";
import { TouchcrApiShippingProvider } from "src/app/providers/TouchcrApiShippingProvider";
import { CalculateProvider } from 'src/app/providers/calculate/calculate';
import { UrlsProvider } from 'src/app/providers/urls';
import { OrderProvider } from 'src/app/providers/order/order';
import { User } from "src/app/models/user";
import { CardDetails } from 'src/app/models/cardDetails';
import { OrderForm } from 'src/app/models/orderForm';
import { Country } from 'src/app/models/country';
import { OrderFormProduct } from 'src/app/models/orderFormProduct';
import { Coupon } from 'src/app/models/coupon';
import { State } from 'src/app/models/state';
import { StateProvider } from 'src/app/providers/state';
import { GeneralProvider } from 'src/app/providers/general';
import { FunnelSettingsProvider } from 'src/app/providers/funnel-settings';
import { SpinnerProvider } from "src/app/providers/spinner/spinner";

export const AMAZON_SCRIPT = ['./assets/js/amazonPayButton.js'];
export const AMAZON_SUB_SCRIPT = ['./assets/js/amazonPaySubButton.js'];
import { Router } from '@angular/router';


const BRAND = (window as any)['process_env'].BRAND;
const ROUTE = (window as any)['process_env'].ROUTE;
const SHIP_FROM_COUNTRY = (window as any)['process_env'].SHIP_FROM_COUNTRY;


@Component({
  selector: 'page-two-step-order-form-base',
  templateUrl: 'two-step-order-form-base.html'
})
export class TwoStepOrderFormBase {
  //@ViewChild(Content) mContent: Content;
  @ViewChild('slider') slider: any;
 // @ViewChild('paymentForm') paymentForm: NgForm;

  cardOptions?: Subscription;
  accountOptions: Subscription = new Subscription;

  state: State = new State;
  guest: User = {} as User;
  cardDetails: CardDetails = {};
  monthArray: Array<any> = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  countriesList: Array<Country> = [];
  yearArray: Array<number> = [];
  statesList: Array<any> = [];
  bumpOffers: Array<OrderForm> = [];
  bumpOffersInCart: Array<any> = [];
  orderFormProduct: Array<OrderFormProduct> = [];
  timer: any;
  timerS: Subscription = new Subscription;
  today: Date = new Date();
  countries: Country = {};
  funnel: OrderForm = {};
  submittedAccountForm: boolean = false;
  submittedPaymentForm: boolean = false;
  isSubscription: boolean = false;
  isPurchaseAvailable: boolean = false;
  showGooglePayButton: boolean = false;
  showApplePayButton: boolean = false;
  showPayPalPayButton: boolean = (window as any)['process_env'].PAYPAL_IS_ENABLED === true ? true : false;
  showAmazonButton: boolean = (window as any)['process_env'].AMAZON_IS_ENABLED === true ? true : false;
  showCardPayButton: boolean = (window as any)['process_env'].CARDPAY_IS_ENABLED === true ? true : false;

  funnelOffersData: Array<any> = [];
  totalPrice: number = 0;       // without transaction fee
  grandTotalPrice: number = 0;  // with transaction fee
  totalSubscriptionPrice: number = 0;
  subscriptionTax: number = 0;
  funnelPrice: number = 0;
  bumpPrice: number = 0;
  subtotal: number = 0;
  totalAmount: number = 0;
  shippingCost: number = 0;
  orderFormProductPrice: number = 0;
  selectedProduct: string = '';
  isFreeChecked: boolean = false;

  selectedProductVideoUrl: SafeResourceUrl;
  selectedProductDescription: string = '';
  selectedProductContent: string = '';
  urlParameters: any = {};
  isAmazonEnabled: boolean = (window as any)['process_env'].AMAZON_IS_ENABLED;

  //Tax service variables
  isValidAddr = false;
  taxData: any = {};
  totalPriceIncludeTax: number = 0;
  totalTaxforBody: number = 0;
  totalTax: number = 0;
  taxServiceEnabled: boolean = (window as any)['process_env'].IS_ENABLED_SALESTAX;
  taxServiceOnPocess: boolean = false;
  showTaxes: boolean = false;
  isTaxValid: boolean = true;

  coupon: Coupon = {};
  couponCode: string = '';
  couponDiscount: number = 0;
  productsForCoupon: Array<any> = [];
  couponProductsOption: any = null;

  // Shipping fields
  isCalculatingShippingRates: boolean = false;
  calculatedShippingCost: number = 0;
  shippingRates: Array<any> = [];
  shippingRateIndex: number = 0;
  isShippingAvailable: boolean = false;
  isShippingValid: boolean = true;
  disablePaymentDueToShipping: boolean = true;
  isShowShippingRates: boolean = false;
  selectedDeliveryDay: any = 'Free';
  addressError: string = 'Please, enter valid address and phone number!';

  // Transaction fee // must be calculated in the end (after shipping and taxes)
  transactionFeeEnabled: boolean = (window as any)['process_env'].IS_ENABLED_TRANSACTION_FEE;
  transactionFeeAmount: number = 0; // calculated transaction fee
  subscriptionTransactionFee: number = 0; // calculated transaction fee of second subscription order
  orderCardNicn: any = '';
  disableCardPaymentDueToTransactionFee: boolean = false;
  isCalculatingTransactionFee: boolean = false;
  oldTotalPriceWithoutFee: number = 0;
  orderIdTemp: string = '';  // order Id in salesforce (before order is created = random value)
  orderIdTempSubscription: string = '';
  orderSTxId: string = '';  // order Id in surchx (also for initial order of subscription)
  orderSTxIdSubscription: string = '';  // order Id in surchx (used for calculate second payment of subscription)
  oldTotalPriceWithoutFeeSubscription: number = 0;
  oldBillingPostalCode: string = '';
  oldBillingCountry: string = '';

  // disable/show button "pay"
  isProcessRemoteServices: boolean = false;
  isConsentStatus: boolean = true;
  buttonList: any = [
    {
      buttonId: 'AmazonPayButton',
      walletId: 'walletWidgetDiv',
      addressId: 'addressBookWidgetDiv',
      isPlaceOrder: true,
    }
  ];
  //params for google and apple pay
  googleApplePaymentAgent: string = (window as any)["process_env"].GOOGLE_APPLE_PAYMENT_AGENT;

  CONTENT: any = {
      default: {
        videoUrl: '',
        content: ``
      }
  };

  constructor(
    public navCtrl: NavControllerExt,
    public sanitizer: DomSanitizer,
    public tcrApiOrderform: TouchcrApiOrderformProvider,
    public cart: CartProvider,
    public tcrApi: TouchcrApiGeneralProvider,
    public tcrApiOrder: TouchcrApiOrderProvider,
    public tcrApiRemoteServices: TouchcrApiRemoteServicesProvider,
    public toastCtrl: ToastController,
    public eventStreamer: EventStreamerProvider,
    public tcrApiLead: TouchcrApiLeadProvider,
    public tcrApiCoupon: TouchcrApiCouponProvider,
    public orderDetails: OrderDetailsProvider,
    public paypalPayment: PaypalPaymentProvider,
    public ngZone: NgZone,
    public shippingProvider: TouchcrApiShippingProvider,
    public calculateHelper: CalculateProvider,
    public urlsHelper: UrlsProvider,
    public orderHelper: OrderProvider,
    public stateProvider: StateProvider,
    public general: GeneralProvider,
    public funnelProvider: FunnelSettingsProvider,
    public spinner: SpinnerProvider,
    public router: Router
  ) {
    this.urlParameters = urlsHelper.getDataFromUrl(window.location.search);
    this.urlParameters.brand = BRAND;
    this.selectedProductVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.CONTENT.default.videoUrl);
    this.createTransactionAmazon();
  }

  ngOnInit() {
    this.spinner.enable('Loading page <br> Please wait...');
   // this.general.enableJSScroll(this.mContent);
  }
  

  // 'Transaction Fee' customization
  subscribeToCardOptions(paymentForm:any) {
    this.cardOptions = paymentForm.valueChanges.subscribe((cardData:any) => {
      const cardNicn = cardData.number ? '' + cardData.number.split(' ').join('').substring(0, 8) : '';
      const needToCalculate = cardNicn.length < 8 && this.orderCardNicn.length > 7;
      const isNicnChanged = this.orderCardNicn !== cardNicn;
      this.orderCardNicn = cardNicn;

      // 'needToCalculate' is checked if 'cardNicn'.length is redused from 8 to 7
      // (we must clear 'Transaction Fee', also we can show message, that user must complete card info)
      if ((isNicnChanged && cardNicn.length > 7) || needToCalculate) {
        this.calculateTransactionFee();
      }
    });
  }

  // 'Transaction Fee' customization
  subscribeToAccountOptions(accountForm:any) {
    this.accountOptions = accountForm.valueChanges.subscribe((accountData:any) => {
      if ((this.oldBillingPostalCode === accountData.postalCode && this.oldBillingCountry === accountData.country) || accountData.postalCode < 5) {
        return;
      }
      this.calculateTransactionFee();
    });
  }

  ionViewWillLeave() {
    if (this.cardOptions) this.cardOptions.unsubscribe();
    if (this.accountOptions) this.accountOptions.unsubscribe();
  }



  async insertScripts(scriptsToLoad: Array<string>, className: string = '') {
    let scripts = document.getElementsByClassName(className);
    if (scripts.length === 0) {
      for (let i = 0; i < scriptsToLoad.length; i++) {
        await this.loadScript(scriptsToLoad[i], className).catch(e => {
          return this.toastCtrl
            .create({
              message: e.message,
              position: "top",
              showCloseButton: true,
              cssClass: "errorToast"
            })
        //    .present();
        });
      }
    }
  }

  loadScript(scriptCode: any, className: string = '') {
    return new Promise((resolve, reject) => {
      let script = document.createElement("script");
      script.src = scriptCode;
      if (className) {
        script.setAttribute("class", className);
      }

      document.head.appendChild(script);

      script.addEventListener('load', () => {
        resolve(script);
      });

      script.addEventListener('error', () => {
        reject(new Error(`${scriptCode} failed to load.`));
      });
    });
  }

  initBumpOffersTimers() {
    this.timer = Observable.timer(0, 1000);
    let bumpExpirelist:any = [];
    this.stateProvider.addBumpExpireItems(this.bumpOffers)
      .then(result => {
        bumpExpirelist = result;
        this.bumpOffers.forEach((val) => {
          if (val.timer) {
            let timeFromFirstView = Math.floor((this.today.getTime() - (new Date(bumpExpirelist.find((x: { sfid: string | undefined; }) => x.sfid === val.sfid).viewDate)).getTime()) / 1000);
            val.timeLeft = val.timer - timeFromFirstView;
          }
        });
        this.timerS = this.timer
          .subscribe(() => {
            this.bumpOffers.forEach((val:any) => {
              if (val.timer) {
                val.timeLeft = val.timeLeft - 1;
                let seconds = val.timeLeft;
                let days = Math.floor(seconds / (3600 * 24));
                seconds -= days * 3600 * 24;
                let hrs = Math.floor(seconds / 3600);
                seconds -= hrs * 3600;
                let mnts = Math.floor(seconds / 60);
                seconds -= mnts * 60;
                val.delta = {
                  days: ('' + days).padStart(2, '0'),
                  hours: ('' + hrs).padStart(2, '0'),
                  minutes: ('' + mnts).padStart(2, '0'),
                  seconds: ('' + seconds).padStart(2, '0'),
                }
              }
            });
          });
      })
  }

  async payProductLogic(body:any, paymentMethod:any, completion:any) {
    if (!completion) this.spinner.enable('Executing order.<br> Please Wait');
    try {
      // add 'transaction fee' values to order body
      if (paymentMethod === 'Standard' && this.transactionFeeAmount > 0) {
        body.orderTransactionFee = +this.transactionFeeAmount.toFixed(2);
        body.transactionFeeDocumentId = this.orderSTxId;
        body.subscriptionTransactionFee = this.subscriptionTransactionFee.toFixed(2);
        body.subscriptionTransactionFeeDocumentId = this.orderSTxIdSubscription;
        body.orderTotal = (+body.orderTotal + +this.transactionFeeAmount).toFixed(2);
      }

      const result = await this.tcrApiOrder.payProductWithoutLogin(body).toPromise();
      this.cart.cleanCart();
      if (result.name) {
        this.toastCtrl.create({ message: `SUCCESS!`, duration: 2000, position: 'top' })//.present();
        if (paymentMethod === 'Standard' && this.transactionFeeAmount > 0) this.captureTransactionFee(result.id, result.subscriptionId);
        await this.spinner.enable('Loading page <br> Please wait...',);
        if (completion) completion((<any>window).ApplePaySession.STATUS_SUCCESS);
        result.subscribed = this.funnelOffersData && this.funnelOffersData[0] && this.funnelOffersData[0].isSubscriptionAvailable;
        result.authorized = this.funnelOffersData && this.funnelOffersData[0] && !!this.funnelOffersData[0].subscriptionDaysPostpone;
        this.eventStreamer.streamEvent('purchase', {
          customTitle: 'Pay now',
          data: result,
        });
        // Save shipping information to storage
        if (this.shippingProvider.isShippingAvailable()) {
          await this.saveShippingInformation();
        }
        let orderDetailsInfowCopy = {} as any;
        if (this.state.orderDetails) {
          orderDetailsInfowCopy = JSON.parse(JSON.stringify(this.state.orderDetails))
        }
        orderDetailsInfowCopy.order = result;
        orderDetailsInfowCopy.paymentMethod = paymentMethod;
        orderDetailsInfowCopy.order.nicn = this.orderCardNicn;
        this.stateProvider.setPageNameAndOrder(this.stateProvider.UPSELL, orderDetailsInfowCopy)
          .then(() => {
            return this.navCtrl.setRootExt(this.stateProvider.UPSELL, { name: 'ONE TIME OFFER SPECIAL', isUpsell: true }, { taken: true});
          })
          .then(() => {
            this.spinner.disable();
          })
          .catch((error) => {
            this.toastCtrl.create({
              message: `Couldn't navigate to the next page`,
              position: 'top',
              showCloseButton: true,
              cssClass: 'errorToast',
            });
            console.log('Error on payProductLogic()', error);
            this.spinner.disable();
          });
      } else {
        if (!completion) this.spinner.disable();
        if (completion) completion((<any>window).ApplePaySession.STATUS_FAILURE);
        this.toastCtrl.create({ message: `Error! Order was not created!`, duration: 5000, position: 'top' })//.present();
      }
    } catch (error) {
      console.log('Error on payment with guest', error);
      if (!completion) this.spinner.disable();
      if (completion) completion((<any>window).ApplePaySession.STATUS_FAILURE);
      this.toastCtrl.create({ message: `Error! ${(error as any).message}`, duration: 3000, position: 'top' })//.present();
    }
  }

  saveShippingInformation() {
    if (this.isShippingValid) {
      return this.stateProvider.setShippingInfo({
        productsForShipping: this.getProductsForShipping(),
        accountInfoForShipping: {
          firstName: this.guest.firstName,
          lastName: this.guest.lastName,
          phone: this.guest.phone,
          shippingCity: this.guest.city,
          shippingCountryCode: this.guest.country,
          shippingPostalCode: this.guest.zipPostalCode,
          shippingStateCode: this.guest.state,
          shippingStreet: this.guest.street,
          shippingApartment: this.guest.apartment,
          additionalShippingAddressLine: this.guest.additionalAddressLine,
          billingApartment: this.guest.apartment,
          additionalBillingAddressLine: this.guest.additionalAddressLine
        },
        selectedDeliveryDay: this.selectedDeliveryDay,
        isValid: true,
      });
    } else {
      return this.stateProvider.setShippingInfo({
        isValid: false,
      })
    }
  }

  onPayClicked(accountForm: NgForm, paymentForm: NgForm) {
    this.submittedPaymentForm = true;
    if (paymentForm.invalid) {
      let payForm = document.getElementById("paymentForm");
      if (payForm) {
        payForm.scrollIntoView({ behavior: 'smooth' });
      }
    }
    const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.guest.country as any);
    if (accountForm.valid && paymentForm.valid) {
      const body = this.orderHelper.createBody({
        coupon: this.coupon,
        couponDiscount: this.couponDiscount,
        totalPrice: +(this.totalPrice).toFixed(2),
        guest: this.guest,
        totalTaxforBody: this.totalTaxforBody,
        dataForTax: this.taxData.dataForTax,
        isSubscription: this.isSubscription,
        shippingCost: +this.shippingCost,
        calculatedShippingCost: +this.calculatedShippingCost,
        customerIp: sessionStorage.getItem('ip') as any,
        bumpOffers: this.bumpOffersInCart,
        cardDetails: this.cardDetails,
        funnelOffersData: this.funnelOffersData,
        orderFormProduct: this.returnSelectedFunnel(this.orderFormProduct, this.selectedProduct),
        subscriptionTax: this.subscriptionTax,
        shippingType: this.shippingProvider.isShippingAvailable() ? this.selectedDeliveryDay : '',
        totalSubscriptionPrice: +(this.totalSubscriptionPrice).toFixed(2),
        country: country,
      });
      if (this.isFacebookApp()) {
        (window as any)['facebookAutofillComplete'] = true;
      }
      this.payProductLogic(body, 'Standard', null);
    }
  }
  isFacebookApp() {
    return false;
    let ua = navigator.userAgent || navigator.vendor || (window as any).opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
  }
  wait(ms:any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  openSlide(slideNumber:any) {
    this.slider.lockSwipes(false);
    this.slider.slideTo(slideNumber, 1000);
    this.slider.lockSwipes(true);
  }

  onCountryChange(cleanState:any) {
    this.statesList = [];
    if (this.countries && (this.countries as any)[this.guest.country as any]) {
      for (let state in (this.countries as any)[this.guest.country as any].states) {
        this.statesList.push({ value: state, label: (this.countries as any)[this.guest.country as any].states[state] });
      }
      this.statesList.reverse();
    }
    if (cleanState) this.guest.state = '';
  }



  checkoutPayPal() {
    this.spinner.enable('Executing order.<br> Please Wait');
    const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.guest.country as any);

    return this.paypalPayment.createPayment({
      totalPrice: +this.totalPrice.toFixed(2),
      totalTax: this.totalTaxforBody,
      accDetails: this.orderHelper.getAccountDetailsFromGuest(this.guest),
      orderType: this.isSubscription ? 'Subscription' : 'Standard',
      OffersData: this.funnelOffersData,
      orderFormProducts: this.orderFormProduct,
      bumpOffers: this.bumpOffersInCart,
      coupon: this.coupon,
      selectedProduct: this.selectedProduct,
      calculatedShippingCost: this.calculatedShippingCost,
      shippingType: this.shippingProvider.isShippingAvailable() ? this.selectedDeliveryDay : '',
      isInternationalShipping: country != SHIP_FROM_COUNTRY,
    })
      .then((result: any) => {
        this.stateProvider.setOrderDetails({ order: result.order })
          .then(state => {
            this.state = state;
            // Save shipping information to storage
            if (this.shippingProvider.isShippingAvailable()) {
              this.saveShippingInformation();
            }
            window.open(result.url, '_self', 'location=no');
          })
      }, (error) => {
        this.spinner.disable();
        const message = error && error.message ? error.message : 'Something went wrong';
        this.toastCtrl.create({ message: `Error! ${message}`, duration: 3000, position: 'top' })//.present();
      })
  }

  checkoutPayPalSubscription() {
    this.spinner.enable('Creating subscription.<br> Please Wait');
    let accDetails = this.orderHelper.getAccountDetailsFromGuest(this.guest);
    const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.guest.country as any);
    return this.paypalPayment.createSubscription({
      totalPrice: this.funnelOffersData[0].isFreeFirstShipping ? +this.totalPrice.toFixed(2) : +this.totalSubscriptionPrice.toFixed(2),
      accDetails: accDetails,
      orderType: this.isSubscription ? 'Subscription' : 'Standard',
      OffersData: this.funnelOffersData,
      totalTax: this.funnelOffersData[0].isFreeFirstShipping ? this.totalTaxforBody : this.subscriptionTax,
      subscriptionTax: this.subscriptionTax,
      calculatedShippingCost: this.calculatedShippingCost,
      shippingType: this.selectedDeliveryDay,
      accDetailsForPayPal: this.calculateHelper.generateAccDataForTax(accDetails.shippingAddress, this.countries),
      isInternationalShipping: country != SHIP_FROM_COUNTRY,
      orderFormProducts: this.returnSelectedFunnel(this.orderFormProduct, this.selectedProduct),
    })
      .then((result: any) => {
        result.order.paymentMethod = 'PayPal';
        this.stateProvider.setOrderDetails({ order: result.order })
          .then((state) => {
            this.state = state;
            // Save shipping information to storage
            if (this.shippingProvider.isShippingAvailable()) {
              this.saveShippingInformation();
            }
            this.stateProvider.setTaxData(this.taxData.dataForTax).then(() => {
              window.open(result.url, '_self', 'location=no');
            });
          });
      }, (error) => {
        this.spinner.disable();
        const message = error && error.message ? error.message : 'Something went wrong';
        this.toastCtrl.create({ message: `Error! ${message}`, duration: 3000, position: 'top' })//.present();
      })
  }

  executePayPalSubscription(paramData:any, dataForTax:any) {
    let executePayPalSubscriptionResult;
    this.spinner.enable('Executing subscription.<br> Please Wait');
    paramData.dataForTax = dataForTax;
    this.paypalPayment.executeSubscription(paramData)
      .then((res: any) => {
        executePayPalSubscriptionResult = res;
        this.cart.cleanCart();
        this.toastCtrl.create({ message: `SUCCESS!`, duration: 2000, position: 'top' })//.present();
        this.spinner.enable('Loading page <br> Please wait...');
        executePayPalSubscriptionResult.orderDetails.order.subscribed = true;
        executePayPalSubscriptionResult.orderDetails.order.authorized = false;
        this.eventStreamer.streamEvent('purchase', {
          customTitle: 'PayPal Subscription',
          data: executePayPalSubscriptionResult.orderDetails.order,
        });
        let orderDetailsInfowCopy = JSON.parse(JSON.stringify(this.state.orderDetails)) || {};
        orderDetailsInfowCopy.order = executePayPalSubscriptionResult.orderDetails.order;
        orderDetailsInfowCopy.paymentMethod = 'PayPal';
        return this.stateProvider.setPageNameAndOrder(this.stateProvider.UPSELL, orderDetailsInfowCopy)
          .then(() => {
            return this.navCtrl.setRootExt(this.stateProvider.UPSELL, { name: 'ONE TIME OFFER SPECIAL', isUpsell: true }, { taken: true});
          })
          .then(() => {
            this.spinner.disable();
          })
          .catch((error) => {
            this.toastCtrl.create({
              message: `Couldn't navigate to the next page`,
              position: 'top',
              showCloseButton: true,
              cssClass: 'errorToast',
            })
            console.log('Error on executePayPalSubscription()', error);
            this.spinner.disable();
            this.toastCtrl.create({ message: `Error! ${error}`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
          });
      })
      .catch((error) => {
        this.spinner.disable();
        const message = error && error.message ? error.message : 'Something went wrong';
        this.toastCtrl.create({ message: `Error! ${message}`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
      })

  }

  cancelPayPalSubscription(orderId:any) {
    this.spinner.enable('Cancelling subscription.<br> Please Wait');
    this.paypalPayment.cancelPayPalSubscription(orderId)
      .then((res: any) => {
        this.cart.cleanCart();
        this.toastCtrl.create({ message: `SUBSCRIPTION CANCELLED!`, duration: 2000, position: 'top' })//.present();
        this.spinner.disable();
        res.orderDetails.order.subscribed = this.funnelOffersData && this.funnelOffersData[0] && this.funnelOffersData[0].isSubscriptionAvailable;
        res.orderDetails.order.authorized = this.funnelOffersData && this.funnelOffersData[0] && !!this.funnelOffersData[0].subscriptionDaysPostpone;
        this.eventStreamer.streamEvent('purchase', {
          customTitle: 'PayPal Subscription',
          data: res.orderDetails.order,
        });
        let orderDetailsInfowCopy = JSON.parse(JSON.stringify(this.state.orderDetails)) || {};
        orderDetailsInfowCopy.order = res.orderDetails.order;
        orderDetailsInfowCopy.paymentMethod = 'PayPal';
      }, (error) => {
        console.log('error on cancaling cancelPayPalSubscription:', error);
        this.toastCtrl.create({ message: `Error! ${error.response.message}`, duration: 3000, position: 'top' })//.present();
        this.spinner.disable();
      });
  }

  async createTransactionAmazon() {

  }

  executePayPalPayment(paramData:any, dataForTax:any) {
    this.spinner.enable('Executing order.<br> Please Wait');
    let executePayPalResult;
    paramData.dataForTax = dataForTax;
    this.paypalPayment.executePayment(paramData)
      .then((res: any) => {
        executePayPalResult = res;
        this.cart.cleanCart();
        this.toastCtrl.create({ message: `SUCCESS!`, duration: 2000, position: 'top' })//.present();
        this.spinner.enable('Loading page <br> Please wait...');
        executePayPalResult.orderDetails.order.subscribed = this.funnelOffersData && this.funnelOffersData[0] && this.funnelOffersData[0].isSubscriptionAvailable;
        executePayPalResult.orderDetails.order.authorized = this.funnelOffersData && this.funnelOffersData[0] && !!this.funnelOffersData[0].subscriptionDaysPostpone;
        this.eventStreamer.streamEvent('purchase', {
          customTitle: 'PayPal Pay',
          data: executePayPalResult.orderDetails.order,
        });
        let orderDetailsInfowCopy = JSON.parse(JSON.stringify(this.state.orderDetails)) || {};
        orderDetailsInfowCopy.order = executePayPalResult.orderDetails.order;
        orderDetailsInfowCopy.paymentMethod = 'PayPal';
        return this.stateProvider.setPageNameAndOrder(this.stateProvider.UPSELL, orderDetailsInfowCopy)
          .then(() => {
            return this.navCtrl.setRootExt(this.stateProvider.UPSELL, { name: 'ONE TIME OFFER SPECIAL', isUpsell: true }, { taken: true});
          })
          .then(() => {
            this.spinner.disable();
          })
          .catch((error) => {
            console.log('Error on executePayPalPayment()', error);
            this.spinner.disable();
            this.toastCtrl.create({ message: `Error! ${error}`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
          });
      })
      .catch(error => {
        this.spinner.disable();
        const message = error && error.message ? error.message : 'Something went wrong';
        this.toastCtrl.create({ message: `Error! ${message}`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
      })
  }

  payWithAmazon() {

  }
  getInfoAmazon() {

  }

  getInfoAmazonSub() {

  
  }

  changeConsentStatus(consentStatus:any) {
    this.isConsentStatus = consentStatus;
  }

  changePlaceOrder(index:any) {
    for (let i = 0; i < this.buttonList.length; i++){
      if (i === index) this.buttonList[i].isPlaceOrder = false;
      else this.buttonList[i].isPlaceOrder = true;
    } 
  }

  payWithAmazonSubscription() {
   
  }

  getCouponBase() {
    this.spinner.enable('Executing coupon.<br> Please Wait');

    return this.tcrApiCoupon.checkCoupon({ couponCode: this.couponCode }).toPromise()
      .then((result) => {
        this.spinner.disable();
        if (result.discountAmount) {
          this.productsForCoupon = result.products as any;
          this.couponProductsOption = result.couponProductsOption;
          if (this.checkValidCoupon()) {
            this.coupon = result;
            this.coupon.price = 0;
            this.coupon.count = 1;
            this.coupon.couponId = result.sfid;
            // this.cart.setCoupons(this.coupon);
            this.calculate(false);
          } else {
            this.resetCoupon();
            this.toastCtrl.create({ message: 'Coupon does not apply to this product', position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
          }
        } else {
          this.resetCoupon();
          this.toastCtrl.create({ message: `${(result as any)['message']}`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
        }
      }, (error) => {
        this.toastCtrl.create({ message: ` Coupon '${this.couponCode}' Error! ${error.error.message}`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
        this.resetCoupon();
        this.spinner.disable();
      });
  }

  resetCoupon() {
    this.coupon = {};
    this.couponDiscount = 0;
    this.couponCode = '';
    //  this.cart.setCoupons(undefined);
    this.calculate(false);
  }

  checkValidCoupon() {
    let products = this.productsForCoupon;
    if (!products || products.length === 0 || !this.couponProductsOption) {
      return true;
    }
    const productIds = [...new Set(products.map(e => e.productId || e.productVariantId))];
    const orderFormProduct = this.returnSelectedFunnel(this.orderFormProduct, this.selectedProduct);
    let productId;
    if (orderFormProduct && orderFormProduct.length > 0) {
      const [orderForm] = orderFormProduct;
      productId = orderForm && orderForm.product && orderForm.product.sfid;
    } else {
      const [funnel] = this.funnelOffersData;
      productId = funnel && funnel.product && funnel.product.sfid;
    }
    const isInclude = this.couponProductsOption === 'Include';
    if (
      (productId && productIds.includes(productId) && isInclude) ||
      (productId && !productIds.includes(productId) && !isInclude)
    ) {
      return true;
    }

    this.resetCoupon();
    return false;
  }

  calculate(getShipping: Boolean) {
    this.isProcessRemoteServices = true;
    this.bumpOffersInCart = [];
    this.orderFormProduct = [];

    const shippingAddress = {
      country: this.guest.country,
      state: this.guest.state,
      postal: this.guest.zipPostalCode,
      city: this.guest.city,
      street: this.guest.street
    };

    if (this.isFreeChecked) {
      if (this.funnelOffersData && (this.funnelOffersData[0].isFreeFirstShipping === true) && this.selectedDeliveryDay && this.selectedDeliveryDay >= 1) {
        this.funnelOffersData[0].isFreeFirstShipping = false;
      } else if (this.funnelOffersData && (this.funnelOffersData[0].isFreeFirstShipping === false) && this.selectedDeliveryDay && this.selectedDeliveryDay == 'Free') {
        this.funnelOffersData[0].isFreeFirstShipping = true;
      }
    }

    let funnelOffers: any = [];

    if (this.funnelOffersData && this.funnelOffersData.length) {
      const funnelArray = this.createFunnelBody(this.funnelOffersData, this.selectedProduct);
      if (funnelArray.length) {
        funnelOffers = funnelArray;
      }
    }
    if (this.funnel && this.funnel.orderFormProduct && this.funnel.orderFormProduct.length) {
      const funnelArray = this.createFunnelProductBody(this.funnel, this.funnel.orderFormProduct, this.selectedProduct);
      if (funnelArray.length) {
        funnelOffers = funnelArray;
      }
    }

    if (this.coupon && this.coupon.couponId) {
      this.checkValidCoupon();
    }

    if (this.isShippingAvailable && getShipping) {
      this.isShowShippingRates = false;
      this.isCalculatingShippingRates = true;
      this.disablePaymentDueToShipping = true;
      this.isShippingValid = true;
      this.shippingRates = [];
    }

    if (this.taxServiceEnabled) {
      this.isTaxValid = true;
      this.taxServiceOnPocess = true;
      this.showTaxes = false;
    }

    if (this.bumpOffers) {
      for (let i = 0; i < this.bumpOffers.length; i++) {
        if (this.bumpOffers[i].isInCart) {
          this.bumpOffersInCart.push(this.bumpOffers[i]);
        }
      }
    }

    this.calculateHelper.amountsRecalculation({
      funnelOffersData: funnelOffers,
      bumpOffers: this.bumpOffers,
      coupon: this.coupon,
      calculateTax: this.taxServiceEnabled,
      shippingAddress: shippingAddress,
      countries: this.countries,
      calculatedShippingCost: +this.calculatedShippingCost,
      accInfoForShipping: this.getAccountInfoForShipping(this.guest),
      productsForShipping: this.getProductsForShipping(),
      getShipping: this.isShippingAvailable && getShipping,
      isSubscription: this.isSubscription,
    })
      .then(({ totalPrice, funnelPrice, bumpPrice, shippingCost, couponDiscount, totalSubscriptionPrice, taxCost, taxData, taxError, error, shippingRates, shippingError, subscriptionTax }) => {
        this.totalPrice = totalPrice;
        this.funnelPrice = funnelPrice;
        this.bumpPrice = bumpPrice;
        this.shippingCost = shippingCost;
        this.couponDiscount = couponDiscount;
        this.totalSubscriptionPrice = totalSubscriptionPrice;
        this.subscriptionTax = subscriptionTax;
        // Tax params
        this.totalTaxforBody = taxCost;
        this.totalTax = taxCost;
        this.taxData.dataForTax = taxData;
        this.showTaxes = true;
        this.taxServiceOnPocess = false;
        const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.guest.country as any);
        const standardShipping = country === SHIP_FROM_COUNTRY ? this.funnel.shippingCost : this.funnel.internationalShippingCost;
        if (this.isShippingAvailable) {
          this.isCalculatingShippingRates = false;
          this.disablePaymentDueToShipping = false;
          if (shippingError) {
            this.addressError = 'Entered address is not valid';
            this.isShippingValid = false;
            this.shippingCost = standardShipping as number;
          } else {
            this.isShowShippingRates = true;
            if (shippingRates && shippingRates.length) {
              this.shippingRates = shippingRates;
              this.applyShippingRate();
            }
          }
        }

        if (taxError) {
          this.isTaxValid = false;
          this.toastCtrl.create({ message: `Sorry, we are couldn't calculate the tax. Your tax amount is 0$.`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
        }
        if (error !== '') {
          this.toastCtrl.create({ message: error, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
        }
        this.isProcessRemoteServices = false;
        if (!getShipping || !shippingRates || shippingRates.length == 0) this.calculateTransactionFee();
      })
  }

  //need to call when shipping options is changed
  applyShippingRate() {
    const isFree = +this.shippingRateIndex === 0;
    const currentShippingOption = this.shippingRates[+this.shippingRateIndex];
    this.calculatedShippingCost = isFree ? 0 : currentShippingOption.rate;
    this.selectedDeliveryDay = isFree ? 'Free' : currentShippingOption.delivery_days;
    this.calculate(false);
  }

  // Get Account Info for calculate shipping
  getAccountInfoForShipping(guest:any) {
    return {
      firstName: guest.firstName,
      lastName: guest.lastName,
      shippingCountryCode: guest.country,
      shippingStateCode: guest.state,
      shippingCity: guest.city,
      shippingStreet: guest.street,
      shippingPostalCode: guest.zipPostalCode,
      phone: guest.phone,
    };
  }

  // Get list of products for calculate shipping
  getProductsForShipping() {
    const products: { count: any; shippingCost: any; productId: any; }[] = [];
    // Get products from funnel
    this.funnelOffersData.forEach((e) => {
      if (e.product.sfid === this.selectedProduct) {
        products.push({ count: e.count || 1, shippingCost: e.shippingCost || 0, productId: e.product.sfid });
      }
      if (e.orderFormProduct && e.orderFormProduct.length > 0) {
        e.orderFormProduct.forEach((el: { product: { sfid: string; }; }) => {
          if (el.product.sfid === this.selectedProduct) {
            products.push({ count: 1, shippingCost: e.shippingCost || 0, productId: el.product.sfid });
          }
        })
      }
    });
    // Get products from bump offers
    this.bumpOffersInCart.forEach((e) => {
      // products.push({ count: e.count || 1, shippingCost: e.shippingCost || 0, weight: e.product.weight || 0 })
      products.push({ count: e.count || 1, shippingCost: e.shippingCost || 0, productId: e.product.sfid });
    });
    return products;
  }

  // Return funnel data or OrderForm Product Data that was selected user
  returnSelectedFunnel(orderFormData:any, selectedProduct:any) {

    // Init result param
    const result: any = [];

    // if selected product equals orderForm product, add to list
    if (orderFormData && selectedProduct) {
      for (let i = 0; i < orderFormData.length; i++) {
        if (orderFormData[i].product.sfid === selectedProduct) {
          result.push(orderFormData[i]);
        }
      }
    }

    // Return Empty array or array orderForm product Data
    return result;
  }

  // Create body for funnel data
  createFunnelBody(funnelData:any, selectedProduct:any) {
    const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.guest.country as any);
    // Init result param
    let result: any = [];

    // Check and filling result
    if (funnelData && funnelData.length && selectedProduct) {
      for (let i = 0; i < funnelData.length; i++) {
        if (funnelData[i].product.sfid === selectedProduct) {
          let data: any = {};
          data.sfid = funnelData[i].sfid;
          data.offerPrice = funnelData[i].offerPrice;
          data.subscriptionPrice = funnelData[i].subscriptionPrice;
          data.shippingCost = country === SHIP_FROM_COUNTRY ? funnelData[i].shippingCost : funnelData[i].internationalShippingCost;
          data.product = funnelData[i].product;
          data.isSubscriptionAvailable = funnelData[i].isSubscriptionAvailable;
          data.isPurchaseAvailable = funnelData[i].isPurchaseAvailable;
          data.isFreeFirstShipping = funnelData[i].isFreeFirstShipping;
          data.internationalShippingCost = funnelData[i].internationalShippingCost;
          result.push(data);
        }
      }
    }
    // Return empty array or funnel data array
    return result;
  }

  // Create body for funnel products data
  createFunnelProductBody(funnel:any, funnelProducts:any, selectedProduct:any) {
    const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.guest.country as any);

    // Init result param
    let result: any = [];

    // Check and filling result
    if (funnel && funnelProducts && funnelProducts.length && selectedProduct) {
      for (let i = 0; i < funnelProducts.length; i++) {
        if (funnelProducts[i].product.sfid === selectedProduct) {
          this.orderFormProduct.push(funnelProducts[i]);
          let data: any = {};
          data.sfid = funnel.sfid;
          data.offerPrice = funnelProducts[i].purchasePrice;
          data.subscriptionPrice = funnelProducts[i].subscriptionPrice || 0;
          data.shippingCost = country === SHIP_FROM_COUNTRY ? funnel.shippingCost : funnel.internationalShippingCost;
          data.product = funnelProducts[i].product;
          data.isSubscriptionAvailable = funnel.isSubscriptionAvailable;
          data.isPurchaseAvailable = funnel.isPurchaseAvailable;
          data.isFreeFirstShipping = funnel.isFreeFirstShipping;
          data.internationalShippingCost = funnel.internationalShippingCost;
          result.push(data);
        }
      }
    }

    // Return empty array or funnel product data array
    return result;
  }

  onChooseAddress(addressDetails:any) {
    if (addressDetails) {
      this.guest.country = addressDetails.country;
      this.guest.state = addressDetails.state;
      this.guest.street = addressDetails.street;
      this.guest.city = addressDetails.city;
      this.guest.zipPostalCode = addressDetails.postalCode;
      this.onCountryChange(false);
    }
  }


  // callout to the API for calculate Transaction Fee
  calculateTransactionFee() {
    const orderTotalWithoutFee = this.coupon.discountType === 'Free Shipping' ? +this.subtotal.toFixed(2) : +this.totalPrice.toFixed(2);
    const orderTotalForCalculation = this.isSubscription && !this.isPurchaseAvailable
      ? (!this.funnelOffersData[0].isFreeFirstShipping ? this.totalSubscriptionPrice : this.totalPrice) : orderTotalWithoutFee;
    const orderTotalWithoutFeeSubscription = this.isSubscription
      ? (!this.funnelOffersData[0].isFreeFirstShipping ? this.totalSubscriptionPrice : this.totalSubscriptionPrice + this.calculatedShippingCost + this.shippingCost) : 0;

    if (!this.transactionFeeEnabled || orderTotalForCalculation == 0 || this.orderCardNicn.length < 8 || (!this.guest.zipPostalCode || this.guest.zipPostalCode.length < 5)) {
      this.disableCardPaymentDueToTransactionFee = false;
      this.grandTotalPrice = orderTotalWithoutFee;
      this.oldTotalPriceWithoutFee = 0;
      this.transactionFeeAmount = 0;
      this.subscriptionTransactionFee = 0;
      return;
    }

    if (this.oldTotalPriceWithoutFee.toFixed(2) === orderTotalForCalculation.toFixed(2)
      && this.oldTotalPriceWithoutFeeSubscription.toFixed(2) === orderTotalWithoutFeeSubscription.toFixed(2)
      && this.oldBillingPostalCode === this.guest.zipPostalCode
      && this.oldBillingCountry === this.guest.country) {
      return;
    }

    this.isCalculatingTransactionFee = true;
    this.disableCardPaymentDueToTransactionFee = true;

    this.oldTotalPriceWithoutFee = +(orderTotalForCalculation).toFixed(2);
    this.oldTotalPriceWithoutFeeSubscription = +(orderTotalWithoutFeeSubscription).toFixed(2);
    this.oldBillingPostalCode = this.guest.zipPostalCode;
    this.oldBillingCountry = this.guest.country as string;

    const accountInfo = {
      billingPostalCode: this.guest.zipPostalCode,
      billingCountryCode: this.guest.country,
    };

    this.orderIdTemp = !this.orderIdTemp || this.orderIdTemp === ''
      ? `_${Math.random().toString(36).substr(2, 11)}` : this.orderIdTemp;
    this.orderIdTempSubscription = !this.orderIdTempSubscription || this.orderIdTempSubscription === ''
      ? `_${Math.random().toString(36).substr(2, 11)}` : this.orderIdTempSubscription;

    this.tcrApiRemoteServices.requestDataForTrFee({
      brand: BRAND,
      account: accountInfo,
      orderTotalWithoutFee: this.oldTotalPriceWithoutFee,
      orderCardNicn: this.orderCardNicn,
      orderIdTemp: this.orderIdTemp,
      orderSTxId: this.orderSTxId,
      orderTotalWithoutFeeSubscription: this.oldTotalPriceWithoutFeeSubscription,
      orderIdTempSubscription: this.orderIdTempSubscription,
      orderSTxIdSubscription: this.orderSTxIdSubscription,
    }
    ).subscribe((res) => {
      this.isCalculatingTransactionFee = false;
      const calculationResponse = res.trFeeResponse[this.orderIdTemp] ? res.trFeeResponse[this.orderIdTemp] : {};
      this.transactionFeeAmount = calculationResponse.transactionFee ? calculationResponse.transactionFee : 0;
      this.grandTotalPrice = orderTotalWithoutFee + this.transactionFeeAmount;
      this.orderSTxId = calculationResponse.sTxId ? calculationResponse.sTxId : '';
      // response also contains: are calculation is valid in 'calculationResponse.isSuccess'
      this.disableCardPaymentDueToTransactionFee = false;
      // calculation of the 'Transaction Fee' for the second subscription payment
      const calculationResponseSubscription = res.trFeeResponse[this.orderIdTempSubscription]
        ? res.trFeeResponse[this.orderIdTempSubscription] : {};
      this.subscriptionTransactionFee = calculationResponseSubscription.transactionFee
        ? calculationResponseSubscription.transactionFee : 0;
      this.orderSTxIdSubscription = calculationResponseSubscription.sTxId
        ? calculationResponseSubscription.sTxId : '';
    }, (err) => {
      this.transactionFeeAmount = 0;
      this.isCalculatingTransactionFee = false;
      this.grandTotalPrice = orderTotalWithoutFee;
      this.disableCardPaymentDueToTransactionFee = false;
      this.orderSTxId = '';
      this.subscriptionTransactionFee = 0;
      this.orderSTxIdSubscription = '';

      console.log('Error on transaction fee in Сalculate Transaction Fee: ', err);
      this.toastCtrl.create({ message: `Error transaction fee calculating!`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
    })
  }

  // Transaction Fee 'capture' callout to API
  captureTransactionFee(orderId:any, subscriptionId:any) {
    this.tcrApiRemoteServices.captureTransactionFeeRequest({
      orderBrand: BRAND,
      orderMTxId: orderId,
      orderSTxId: this.orderSTxId,
      orderId: orderId,
      subscriptionSFId: subscriptionId,
      subscriptionSTxId: this.orderSTxIdSubscription,
    }
    ).subscribe(() => {}, (err) => {
      console.log('Error on capture transaction fee: ', err);
    })
  }

}
