import {Component, Input} from "@angular/core";
import { PaymentFormComponentBase } from "./payment-form-base";
import { CreditCardProvider } from '../../app/providers/credit-card/credit-card';
import {AbstractControl, ValidatorFn, Validators} from "@angular/forms";
import { PAYMENT_LABEL, SHIPPING_LABEL } from 'src/constants/constants';

enum FIELDS {
  NUMBER = <any>'number',
  CODE = <any>'code',
  EXP_MONTH = <any>'expmonth',
  EXP_YEAR = <any>'expyear',
}

@Component({
  selector: 'payment-form',
  templateUrl: 'payment-form.html',
  styleUrls: ['./payment-form.scss']
})
export class PaymentFormComponent extends PaymentFormComponentBase {

  textPattern: string = '[,.[\/A-Za-z0-9 -]*'; // TOUC-1036
  nameOnCardPattern: string = '[,.&[\/A-Za-z0-9 -]*'; // TOUC-963 to match 1SC control better, and make it xml safe to support TCR api


  @Input() cardName: string = '';
  @Input() cardType: string = '';
  @Input() braintreeDefaultCountry: string = 'United States';

  cardBrands: Array<any> = [];
  readonly errorImage: string = 'assets/img/icon-warning-pointer.gif';
  readonly paymentCard: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPBAMAAAD5dJlYAAAAGFBMVEUAAQA6f8bV2tzn7vH3+fb5+/j8/vv+//zeh3d6AAAAPUlEQVR42mNwYIACFgcG9nIoKAhAYiswMApCgQBxbGS9ONns7Ag2A7sxGEDUpIEASLwA5jYmBYZyJRgoAACOZSUIIJM5YwAAAABJRU5ErkJggg==';

  constructor(public override ccProvider: CreditCardProvider) {
    super(ccProvider);
    this.cardBrands = Object.keys(CreditCardProvider.CARDS).map((key) => {
      return {
        type: (CreditCardProvider.CARDS as any)[key].type,
        name: (CreditCardProvider.CARDS as any)[key].name == 'American Express' ? 'Amex': (CreditCardProvider.CARDS as any)[key].name
      } 
    });

  }

  override ngAfterViewInit() {
    setTimeout(() => {
      this.paymentForm.controls[this.FIELDS.NUMBER].setValidators([this.cardNumberValidator(this), this.cardTypeValidator(this), this.cardTypeNumberValidator(this), Validators.required]);
      this.paymentForm.controls[this.FIELDS.EXP_MONTH].setValidators([this.expirationDateValidator(this), Validators.required]);
      this.paymentForm.controls[this.FIELDS.EXP_YEAR].setValidators([this.expirationDateValidator(this), Validators.required]);
      this.linkForm.emit(this.paymentForm);
    }, 0);
     setTimeout(() => {
      // this resolved issues with expiration date validation
       try {
        if(!document.querySelector("#expMonth")) {
          console.log('[GH] no legacy paymentForm, using new one');
          return;
        }
         (
           document.querySelector("#expMonth") as HTMLSelectElement
         ).addEventListener("blur", (event) => {
           console.log(
             "paymentForm " + this.paymentForm.valid,
             this.paymentForm
           );
           this.paymentForm.controls.expmonth.updateValueAndValidity();
           this.paymentForm.controls.expyear.updateValueAndValidity();
           console.log(
             "paymentForm " + this.paymentForm.valid,
             this.paymentForm
           );
         });
         (
           document.querySelector("#expYear") as HTMLSelectElement
         ).addEventListener("blur", (event) => {
           console.log(
             "paymentForm " + this.paymentForm.valid,
             this.paymentForm
           );
           this.paymentForm.controls.expmonth.updateValueAndValidity();
           this.paymentForm.controls.expyear.updateValueAndValidity();
           console.log(
             "paymentForm " + this.paymentForm.valid,
             this.paymentForm
           );
         });
       } catch (e) {
         console.log(e);
       }
     }, 300); 
  }

  getLabel(label: string) {
    if(!this.braintreeDefaultCountry) {
      try {
        this.braintreeDefaultCountry = sessionStorage.getItem('braintreeDefaultCountry') || 'United States';
      } catch (e) {
        this.braintreeDefaultCountry = 'United States';
      }

    }
    try {
      if(this.braintreeDefaultCountry && typeof PAYMENT_LABEL[this.braintreeDefaultCountry] == 'object' && typeof PAYMENT_LABEL[this.braintreeDefaultCountry][label] == 'string') return PAYMENT_LABEL[this.braintreeDefaultCountry][label];
    } catch (e) {
    }
    return PAYMENT_LABEL['United States'][label];
  }

  cardTypeNumberValidator(scope:any): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return scope.cardType === scope.card.type
        ? null
        : {'invalidTypeNumber': {value: control.value}}
    }
  }
  forceTypeNumberValidation(){
    this.paymentForm.controls[this.FIELDS.NUMBER].updateValueAndValidity();
  }

  override formatInputData(field: FIELDS): void {

    if (this.FIELDS.hasOwnProperty(field)) {

      if (field === this.FIELDS.NUMBER) {
        this.paymentForm.controls[this.FIELDS.NUMBER].setValue(this.removeNonNumberCharacters(String(this.cardDetails.card)));

      } else if (field === FIELDS.CODE) {
        this.paymentForm.controls[this.FIELDS.CODE].setValue(this.removeNonNumberCharacters(String(this.cardDetails.cardccv)));
      }
    }
  }
  public navigateToPageByGenericUrl(url: string, linkTarget: string, linkOptions: string): boolean {

    if(url.startsWith("http")) {
      window.open(url, linkTarget, linkOptions);
    } else {
      const pathName = url;

      const nextUrl = `${window.location.origin}/${
        pathName
      }`;
      window.open(nextUrl, linkTarget, linkOptions);
    }
   return false; 
  }
}
