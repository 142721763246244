import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { AffParameters } from '../../../models/affParameters';
import { Offer } from '../../../models/offer';

const API_URL = (window as any)["process_env"].API_URL;
const OFFERS_BY_SF_ID = 'offers/bysfid/';
const SET_PARAMETERS = 'affiliate/setaffparameters';


@Injectable({providedIn: 'root'})
export class TouchcrApiAffiliateComponent {
  allOffers: any;
  categories: any;

  constructor(
    public http: HttpClient,
  ) {
  }

  setAffParameters(parameters: AffParameters) {
    let needSet;
    for (let parameter in parameters) {
      if ((parameters as any)[parameter] !== null && (parameters as any)[parameter] != "") {
        needSet = parameter;
      }
    }
    (parameters as any)['referralUrl'] = document.referrer.split("?")[0];
    (parameters as any)['landingUrl'] = window.location.href.split("?")[0];
    (parameters as any)['salesFunnel'] = parameters.offId ? parameters.offId : 'Funnel';
    let isStorageAvailable = true;
    //Check if storage is unavailable - TCR
    try {
      window.sessionStorage.setItem("StorageTest", "");
      window.sessionStorage.removeItem("StorageTest");
    } catch (e) {
      isStorageAvailable = false;
    }

    if (isStorageAvailable && !window.sessionStorage.getItem('affiliateInfo')) {
      this.saveAffParamsToSessionStorage(parameters);
      return this.http.post(`${API_URL}${SET_PARAMETERS}`, parameters)
        .catch((error) => {
          return Observable.of(error && error.error && error.error.error)
        });
    }
    if (!needSet) return Observable.of();
    if (isStorageAvailable) this.saveAffParamsToSessionStorage(parameters);
    return this.http.post(`${API_URL}${SET_PARAMETERS}`, parameters)
      .catch(error => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  saveAffParamsToSessionStorage(parameters:any) {
    if (!parameters) return;
    window.sessionStorage.setItem('affiliateInfo', JSON.stringify(parameters));
  }

  getOffersBySalesforceId(sfId: string): Observable<Offer> {
    return this.http.get<Offer>(`${API_URL}${OFFERS_BY_SF_ID}${sfId}`)
      .map((offer: Offer) => {
        if (offer.product &&
          offer.product.image &&
          offer.product.image[0] &&
          offer.product.image[0].imageUrl) {
          offer.imageUrl = offer.product.image[0].imageUrl;
        }
        return offer;
      })
      .catch((error) => {
        return Observable.of(error && error.error && error.error.error)
      });
  }
}