import { Component, OnInit } from '@angular/core';
import { deepLinkConfig } from 'src/app/app.module';
import { DESTINATION_ID } from '../../src/app/providers/funnel-settings';
import { CONTENT_ID } from '../../src/app/providers/funnel-settings';
import { PlatformLocation } from '@angular/common';

import { UrlsProvider } from '../../src/app/providers/urls';
import { TouchcrApiAffiliateComponent } from './src/services/touchcr-api-affiliate/touchcr-api-affiliate.component';
import { FunnelSettingsExtenderProvider } from "./providers/funnel-settings-extender";
import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { StateExtenderProvider } from 'src/app/providers/state-extender';
import { ChildrenOutletContexts, RouterLink, RouterOutlet } from '@angular/router';
import { fadeAnimation, slideInAnimation } from './animations';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
/*   animations: [
    fadeAnimation
  ], */
/*   animations: [
    slideInAnimation
  ], */
  
})
export class AppComponent {
  public spinnerMessage: string = 'Loading...';
  title = 'Gundry E POC';
 
  headerText: string = '';
  shoppingCart: any = {};
  brandFooterObj: any = {};
  commentSectionTxt1: string = '';
  commentSectionTxt2: string = '';
  commentSectionTxt3: string = '';
  showOnOrderForm: boolean = false;
  showHeaderLogo: boolean = true;
  showHeaderContent: boolean = true;
  noticeOn: boolean = false;
  noticeText: string = '';
  guaranteeImage: string = '';
  guaranteeTitle: string = '';
  guaranteeText: string = '';
  tabs: Array<any> = [];
  currentYear: number = new Date().getFullYear();
  isStorageAvailable: boolean = true;

  rootPage: any = '';


  public description: string;
  public spinButtonText: string;
  public spinnedTitle: string;
  public spinnedDescription: string;
  public spinnedButtonText: string;
  public declineButtonText: string;
  public wheelCenterImage: string;
  public wheelOptions: any;
  public pointerAngle: number = 90;
  public closeAfterSpinTime: number = 0;
  public spinningState: string = 'not-spinning';

  constructor(
    public urls: UrlsProvider,
    public tcrApiAffiliate: TouchcrApiAffiliateComponent,
    public funnelProvider: FunnelSettingsExtenderProvider,
    public navCtrl: NavControllerExt,
    public stateProvider: StateExtenderProvider,
  ) {
    try {
      if (parseInt(this.urls.getParameterFromUrl({ url: `${this.getSearch()}`, parameter: 'step' })) > 1) {
        this.overridePushState();
        this.backButtonHandler();
      }
    }
    catch (e) {
      console.log('error in app component', e);
    }
    const now = new Date();

    this.urls.changeState(decodeURIComponent(`${this.getSearch()}`));

    let sessionId = '';
    let isCreated: boolean = false;
    if (this.urls.getParameterFromUrl({ url: `${this.getSearch()}`, parameter: 'sessionid' })) {
      sessionId = this.urls.getParameterFromUrl({
        url: `${this.getSearch()}`,
        parameter: 'sessionid',
      });
    } else {

      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');

      for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
              c = c.substring(1);
          }
          if (c.indexOf('SessionId=') == 0) {
            sessionId = c.substring('SessionId='.length, c.length);
          }
      }

      if(sessionId == '') {
        isCreated = true;
        sessionId = (+now * Math.random()).toString();
        sessionId += now.getFullYear().toString();
        sessionId += (now.getMonth() < 9 ? '0' : '') + now.getMonth().toString();
        sessionId += (now.getDate() < 10 ? '0' : '') + now.getDate();
        sessionId = (+sessionId).toFixed(0);
      }
    }
    const contentId = this.urls.getParameterFromUrl({
      url: `${this.getSearch()}`,
      parameter: CONTENT_ID,
    });
    if (contentId) {
      this.funnelProvider.setContentId(contentId);
    }
    const affParameters = this.urls.getAffParamsFromUrl(`${this.getSearch()}`);
    this.tcrApiAffiliate.setAffParameters(affParameters).toPromise();

    if (this.urls.getParameterFromUrl({ url: `${this.getSearch()}`, parameter: DESTINATION_ID })) {
      const destId = this.urls.getParameterFromUrl({
        url: `${this.getSearch()}`,
        parameter: DESTINATION_ID,
      });
      this.funnelProvider.setDestinationIdValue(destId);
    }
    this.funnelProvider.setSessionId({ sessionId, isCreated });
    const path = location.pathname;
    const pathArray = path.split('/');
    (async () => {
      let root = 'Home';
      if (pathArray.length > 1) {
        if (pathArray[1] === 'fst' && pathArray[2]) {
          const destGenericUrl = pathArray[2];
          const params = await this.funnelProvider.getNextPageByDestination(destGenericUrl);
          root = await this.getRootPage(params);
        } else if (pathArray[1]) {
          const component: any = deepLinkConfig.links.find((e:any) => e.segment === pathArray[1]);
          if (!component) {
            const params = await this.funnelProvider.getNextPage(pathArray[1]);
            root = await this.getRootPage(params);
          }
        }
      }
      this.rootPage = root;
    })()
  }
/*   getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  } */
  closeModal(event: any) {
    console.log('closeModal');
  }
  spinTheWHeel() {
    console.log('spinTheWHeel');
  }

  async getRootPage(params: any): Promise<string> {
    let root = 'Home';
    if (params) {
      await this.stateProvider.clearFunnel();
      const { component } = params;
      if (component.name) {
        const pathName = component.segment;
        history.replaceState(null, '', `${location.origin}/${pathName}${this.urls.getQueryParams()}`);
        this.navCtrl.streamEvent(component.name, { name: component.name });
        root = component.name;
      }
    }

    return root;
  }

  backButtonHandler() {
    window.onpopstate = async (e: any) => {
      const qParams = new URLSearchParams(this.urls.getQueryParams());
      if(qParams && qParams.get('step') !== null) {
        const params = await this.funnelProvider.getPageByStep(qParams.get('step'));
        if (!params || (params.type != 'Upsell' && params.type != 'DownSell')) {
          this.urls.setQueryParams(e.target.location.search);
        }
      }
    };
  }
  public getSearch () {
    return window.location.search ; // [TOUC-5682]
  }
  overridePushState() { /* TODO: need testing on this since it is converted to use PlatformLocation */
    PlatformLocation.prototype.pushState = function (state, title, url) {
      let getSearch = function(){
        return window.location.search ; // [TOUC-5682]
      }
      if (!!window.history.pushState) {
        if (url[url.length-1] !== '/') {
          url = url + '/';
        }
        url = url + `${getSearch()}`;
        if (
          url === location.pathname + `${getSearch()}` ||
          url === location.pathname + '/' + `${getSearch()}`
        ) {
          return;
        }
        this.pushState(state, title, url);
      }
      else {
        window.location.hash = url;
      }
    };
  }

}
