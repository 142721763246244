import { Component, HostListener, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
@HostListener('unloaded')
@Component({
  selector: 'app-destination',
  template: ''
})
export class DestinationComponent {
  constructor(
    public router: Router,
  ) { 
    const currentLocation =  `${this.getPath()}` +  `${this.getSearch()}`;
    const changeLocation = setInterval(() => {
      if (currentLocation !==  `${this.getPath()}` +  `${this.getSearch()}`) {
        clearInterval(changeLocation);
        console.log('Destination Navigation Event', `${this.getPath()}` +  `${this.getSearch()}` )
        this.router.navigateByUrl( `${this.getPath()}` +  `${this.getSearch()}` ); 
      }
    },10)
  }
  public getSearch() {
    return document.location.search;
  }
  public getPath() {
    return document.location.pathname;
  }
}