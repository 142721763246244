<div class="payment_tab_row">
  <div col-auto class="payment_tab_col">Credit card<img src="{{ paymentCard }}" alt="paymentcard"></div>
</div>
<div class="payment_form_row">
  <div col-12 class="payment_form_col">
    <form #paymentForm="ngForm" class="payment_form" id="paymentForm" novalidate>
      <div class="form_item_wrapper">
        <label class="form_item_label" for="nameOnCard">{{ getLabel('nameCard')}}</label>
        <div class="form_item_elements">
          <input
            data-qa="card-name"
            id="nameOnCard"
            name="nameOnCard"
            #nameOnCard="ngModel"
            [(ngModel)]="cardName"
            type="text"
            required
            pattern="{{ nameOnCardPattern }}"
            class="item_input"
          />
        </div>
        <div class="form_item_image">
          <img *ngIf="submittedPaymentForm && nameOnCard.invalid" src="{{ errorImage }}" alt="">
        </div>
      </div>
      <div class="form_item_wrapper">
        <label class="form_item_label" for="typeOfCard">{{ getLabel('cardType')}}</label><br>
        <div class="form_item_elements">
          <select
            data-qa="card-type"
            id="typeOfCard"
            name="typeOfCard"
            #typeOfCard="ngModel"
            [(ngModel)]="cardType"
            required
            class="item_select item_cartType"
            (change)="forceTypeNumberValidation()"
          >
            <option *ngFor="let brand of cardBrands" value="{{ brand.type }}">{{ brand.name}}</option>
          </select>
        </div>
        <div class="form_item_image">
          <img *ngIf="submittedPaymentForm && typeOfCard.invalid" src="{{ errorImage }}" alt="">
        </div>
      </div>
      <div class="form_item_wrapper">
        <label class="form_item_label" for="cardNumber">{{ getLabel('cardNumber')}}</label>
        <div class="form_item_elements">
          <input
            data-qa="card-num"
            id="cardNumber"
            name="number"
            #number="ngModel"
            [(ngModel)]="cardDetails.card"
            required
            [minlength]="card.numberLength"
            [maxlength]="card.numberLength"
            data-stripe="number"
            data-storage="false"
            type="tel"
            class="item_input"
            (input)="findCard()"
          />
        </div>
        <div class="form_item_image">
          <img *ngIf="submittedPaymentForm && number.invalid" src="{{ errorImage }}" alt="">
        </div>
      </div>
      <div class="form_item_wrapper">
        <label class="form_item_label" for="expMonth">{{ getLabel('expirationDate')}}</label>
        <div class="form_item_elements">
          <select
          data-qa="card-exp-month"
            id="expMonth"
            name="expmonth"
            #expmonth="ngModel"
            [(ngModel)]="cardDetails.expmonth"
            required
            data-stripe="exp_month"
            data-storage="false"
            class="item_select item_month"
          >
            <option value="undefined" >Month</option>
            <option *ngFor="let month of monthArray" value="{{month}}">{{month}}</option>
          </select>
          <span class="item_separator">/</span>
          <select
          data-qa="card-exp-year"
            id="expYear"
            name="expyear"
            #expyear="ngModel"
            [(ngModel)]="cardDetails.expyear"
            required
            data-stripe="exp_year"
            data-storage="false"
            class="item_select item_year"
          >
            <option value="undefined" >Year</option>
            <option *ngFor="let year of yearArray" value="{{year}}" selected>{{year}}</option>
          </select>
          <span class="item_info"><label for="expYear">{{ getLabel('expirationDateLabel')}}</label></span>
        </div>
        <div class="form_item_image">
          <img *ngIf="submittedPaymentForm && expmonth.invalid || submittedPaymentForm && expyear.invalid" src="{{ errorImage }}" alt="">
        </div>
      </div>
      <div class="form_item_wrapper form_item_code">
        <label class="form_item_label" for="cvcCode">{{ getLabel('cvv')}}</label>
        <div class="form_item_elements">
          <input
          data-qa="card-cvv2"
            id="cvcCode"
            name="code"
            #code="ngModel"
            [(ngModel)]="cardDetails.cardccv"
            required
            autocomplete="off"
            [minlength]="card.codeLength"
            [maxlength]="card.codeLength"
            data-stripe="cvc" data-storage="false"
            (input)="formatInputData(FIELDS.CODE)"
            type="tel"
            class="item_input item_code"
          />
          <a class="item_link" (click)="navigateToPageByGenericUrl('/what-is-this', 'name', 'width=500,height=330')" href="/what-is-this">What is this?</a>
          <img *ngIf="submittedPaymentForm && code.invalid" src="{{ errorImage }}" alt="" class="item_img_error">
        </div>
        <div class="form_item_image">
          <img *ngIf="submittedPaymentForm && code.invalid" src="{{ errorImage }}" alt="" class="hidden-md">
        </div>
      </div>
    </form>
  </div>
</div>