import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/observable/throw';
const API_URL = (window as any)["process_env"].API_URL;

const ACCOUNTS_LOGIN = 'accounts/login/';
const ACCOUNTS_SOCIAL_LOGIN = 'accounts/sociallogin/';
const ACCOUNTS_LOGOUT = 'accounts/logout/';
const ACCOUNTS_REGISTER = 'accounts/registration/';
const ACCOUNTS_GET_ACCOUNT_DETAILS = 'accounts/getpersonaccountdetails/';
const ACCOUNTS_SET_ACCOUNT_DETAILS = 'accounts/updatepersonaccountdetails/';
const ACCOUNTS_IS_LOGGINED = 'accounts/isloggined/';
const ACCOUNT_SET_PASSWORD = 'accounts/setpassword';
const ACCOUNT_RESET_PASSWORD = 'accounts/resetpassword';
const ACCOUNT_CHANGEEMAILLINK = 'accounts/changeemaillink';
const ACCOUNT_CHANGEEMAIL = 'accounts/changeemail';

@Injectable({providedIn: 'root'})
export class TouchcrApiAccountProvider {
  isLoggedIn: any = new BehaviorSubject<any>({ isLoggedIn: null });
  gotStatus = false;
  accountDetails: any = new BehaviorSubject<any>({});
  SITE_NAME = (window as any)['process_env'].SITE_NAME;

  constructor(
    public http: HttpClient,
  ) { }

  login({ code, username, password }: { code: string, username: string, password: string }) {
    const USER_MANAGMENT_SYSTEM = (window as any)['process_env'].USER_MANAGMENT_SYSTEM;
    const reqbody = (USER_MANAGMENT_SYSTEM === 'salesforce') ? { code: code } : { email: username, password: password, }
    return this.http.post(`${API_URL}${ACCOUNTS_LOGIN}`, reqbody)
      .map((body: any) => {
        if (!body.error) {
          this.isLoggedIn.next({
            isLoggedIn: true,
            recordType: body.recordType,
            affiliateApproval: body.affiliateApproval,
            fullName: body.firstName + '' + body.lastName,
          });
        }
        return body;
      })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  socialLogin(accessToken: any, provider: any) {
    return this.http.post(`${API_URL}${ACCOUNTS_SOCIAL_LOGIN}`, {
      token: accessToken,
      provider: provider,
    })
      .map((body: any) => {
        this.isLoggedIn.next({
          isLoggedIn: true,
          recordType: body.recordType,
          affiliateApproval: body.affiliateApproval,
          fullName: body.firstName + '' + body.lastName,
        });
        return body;
      })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  logout() {
    this.isLoggedIn.next({ isLoggedIn: false });
    this.accountDetails.next({});
    return this.http.post(`${API_URL}${ACCOUNTS_LOGOUT}`, {})
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  register( { firstName, lastName, email } : { firstName: string, lastName: string, email: string }) {
    let body = {
      firstName: firstName,
      lastName: lastName,
      personEmail: email,
      source: this.SITE_NAME,
    };
    return this.http.post(`${API_URL}${ACCOUNTS_REGISTER}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  loadAccdetails() {
    return this.http.post(`${API_URL}${ACCOUNTS_GET_ACCOUNT_DETAILS}`, {})
      .map((body: any) => {
        this.isLoggedIn.next({
          isLoggedIn: true,
          recordType: body.recordType,
          affiliateApproval: body.affiliateApproval,
          fullName: body.firstName + ' ' + body.lastName,
        });
        this.accountDetails.next(body);
        return body;
      })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  getAccountDetails() {
    if (!this.accountDetails.value.firstName) {
      this.loadAccdetails()
        .subscribe();
    }
    return this.accountDetails.asObservable();
  }

  refreshAccountDetails() {
    this.loadAccdetails().subscribe(() => { });
  }

  nextAccountDetails(accountDetails: any) {
    this.accountDetails.next(accountDetails);
    this.isLoggedIn.next({
      isLoggedIn: true,
      recordType: accountDetails.recordType,
      affiliateApproval: accountDetails.affiliateApproval,
      fullName: accountDetails.firstName + ' ' + accountDetails.lastName,
    });
  }

  setAccountDetails(accountDetails: any) {
    return this.http.post(`${API_URL}${ACCOUNTS_SET_ACCOUNT_DETAILS}`, accountDetails)
      .map((response: any) => {
        this.isLoggedIn.next({
          isLoggedIn: true,
          recordType: accountDetails.recordType,
          affiliateApproval: accountDetails.affiliateApproval,
          fullName: accountDetails.firstName + ' ' + accountDetails.lastName,
        });
        this.refreshAccountDetails();
        return response;
      })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  setPassword(credentials: any) {
    return this.http.post(`${API_URL}${ACCOUNT_SET_PASSWORD}`, credentials)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  resetPassword(credentials: any) {
    const body = {
      email: credentials.email,
      source: this.SITE_NAME,
    };

    return this.http.post(`${API_URL}${ACCOUNT_RESET_PASSWORD}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  changeEmailLink(credentials: any) {
    const body = {
      password: credentials.password,
      recoveryToken: credentials.recoveryToken,
      email: credentials.email,
      source: this.SITE_NAME,
    };

    return this.http.post(`${API_URL}${ACCOUNT_CHANGEEMAILLINK}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  changeEmail(credentials: any) {
    return this.http.post(`${API_URL}${ACCOUNT_CHANGEEMAIL}`, credentials)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  isLoggined() {
    return this.http.get(`${API_URL}${ACCOUNTS_IS_LOGGINED}`)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error)
      });
  }

  hasLoggedIn() {
    if (!this.gotStatus) {
      this.gotStatus = true;
      this.isLoggined()
        .subscribe((response: any) => {
          if (response.isLoggined) {
            this.isLoggedIn.next({
              isLoggedIn: response.isLoggined,
              recordType: response.recordType,
              affiliateApproval: response.affiliateApproval,
              fullName: response.firstName + ' ' + response.lastName,
            });
          } else {
            this.logout();
          }
        }, () => {
          this.logout();
        });
    }
    return this.isLoggedIn.asObservable();
  }
}
