import { Component, OnInit } from "@angular/core";
import { TouchcrApiCouponProvider } from "../src/services/touchcr-api-coupon/touchcr-api-coupon.component";
import { TouchcrApiPaymentProvider } from "../src/services/touchcr-api-payment/touchcr-api-payment";
import { CookiesProvider } from "../providers/cookies";
import { EventStreamerProvider } from "../providers/event-stream";
import { TouchcrApiAffiliateComponent } from "../src/services/touchcr-api-affiliate/touchcr-api-affiliate.component";
import { Storage } from '@ionic/storage-angular';

import { UrlsProvider } from 'src/app/providers/urls';
import { SpinnerProvider } from 'src/app/providers/spinner/spinner';
import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { StateExtenderProvider } from 'src/app/providers/state-extender';
import { FunnelSettingsExtenderProvider } from "src/app/providers/funnel-settings-extender";
import { GundrySecureCartComponent } from "../gundry-secure-cart/gundry-secure-cart.component";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent extends GundrySecureCartComponent implements OnInit {
  footerLinks: Array<any> = [];
  public genericUrlToOpenNewTab: string = '';
  public innerWidth: number;
  public innerHeight: number;


  gundryLogo = 'https://cdn.gundrymd.com/images/gundry-logo.jpg';
  override showHeaderContent: boolean = true;
  override showHeaderLogo: boolean = true;
  constructor(
    public override urls: UrlsProvider,
    public override spinner: SpinnerProvider,
    public override navCtrl: NavControllerExt,
    public override stateProvider: StateExtenderProvider,
    public tcrApiCoupon: TouchcrApiCouponProvider,
    public tcrApiPayment: TouchcrApiPaymentProvider,
    public cookiesProvider: CookiesProvider,
    public eventStreamer: EventStreamerProvider,
    public override tcrApiAffiliate: TouchcrApiAffiliateComponent,
    public storage: Storage,
    public override funnelProvider: FunnelSettingsExtenderProvider,

  ) {
    super( urls, tcrApiAffiliate, funnelProvider, spinner, navCtrl, stateProvider );
 
    this.populatePageData();
    this.footerLinks = [];
  }

  async ngOnInit() {
    // await this.storage.create();
  }

  async populatePageData() {
    this.noticeOn = false;
    this.noticeText = `<p><span class="bold">UPDATE:</span> Due to extreme weather conditions, some customers may experience shipping delays.<p>`;
    this.showOnOrderForm = true;
    this.brandFooterObj = { link: "http://gundrymd.com", label: "GundryMD" };
  }

     // Use for navigation to specific page by its Generic Url
     public navigateToPageByGenericUrl(url: string): void {

      if(url.startsWith("http")) {
        window.open(url, '_top', `width=${this.innerWidth},height=${this.innerHeight}`);
      } else {
        const currentSessionId = this.funnelProvider.getSessionId();
        const pathName = url;
        let newQueryParams = this.urls.addParamToQuery(
          `${this.getSearch()}`,
          'sessionid',
          currentSessionId
        );
        const nextUrl = `${window.location.origin}/${
          pathName
        }${newQueryParams}`;
        window.open(nextUrl, '_top', `width=${this.innerWidth},height=${this.innerHeight}`); 
      }
    }
}
