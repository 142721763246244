<div class="upsell-section-div">
  <div justify-content-center class="upsell-section-content">
    <div col-12 class="section_wrapper">
      <ng-template ngFor let-item [ngForOf]="content" let-i="index">
        <h4 *ngIf="item.title && item.title.length > 0" text-center text-md-left margin-bottom text-uppercase [innerHTML]="item.title"></h4>
        <ng-template ngFor let-block [ngForOf]="item.blocks" let-i="index">
            <div class="upsell-section-inner-div-{{i+1}}" [innerHTML]="block"></div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>