import { Component, Input } from "@angular/core";
import { FunnelSettingsProvider } from "../../app/providers/funnel-settings";
import { UrlsProvider } from "../../app/providers/urls";
import { Form, FormsModule } from "@angular/forms";

@Component({
    selector: "upsell-footer",
    styleUrls: ['./upsell-footer.scss'],
    templateUrl: "upsell-footer.html"
})

export class UpsellFooterComponent {

  /**
   * @apiDescription Used to render links
   * @apiParam {links} Array containing link objects
   * @apiParamExample {links} Example input Array
   *  [{
   *    label: 'Privacy Policy',
   *    target: '_blank',
   *  }]
   */
    @Input() links: Array<any> = [];
    @Input() text: string = '';
    @Input() brand: string = '';
    @Input() address: string = '';

    year: string = new Date().getFullYear().toString();

    constructor(public funnelProvider: FunnelSettingsProvider, public urls: UrlsProvider, public forms: FormsModule) {}
    public getSearch () {
      return window.location.search ; // [TOUC-5682]
    }
    public navigateToPageByGenericUrl(url: string, linkTarget: string, linkOptions: string): void {

        if(url.startsWith("http")) {
          window.open(url, linkTarget, linkOptions);
        } else {
          const currentSessionId = this.funnelProvider.getSessionId();
          const pathName = url;
          let newQueryParams = this.urls.addParamToQuery(
            `${this.getSearch()}`,
            'sessionid',
            currentSessionId
          );
          const nextUrl = `${window.location.origin}/${
            pathName
          }${newQueryParams}`;
          window.open(nextUrl, linkTarget, linkOptions);
        }
        
      }
}