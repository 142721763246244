import { Injectable } from "@angular/core";

import { TouchcrApiOrderProvider } from "../touchcr-api-order/touchcr-api-order";
import { OrderDetailsProvider } from '../order-details/order-details';
import { TouchcrApiRemoteServicesProvider } from '../touchcr-api-remoteservices';
import { StateProvider } from '../state';
import { FunnelSettingsProvider } from '../funnel-settings'
import { UrlsProvider } from '../urls'
import { CalculateProvider } from "../calculate/calculate";

const SHIP_FROM_COUNTRY = (window as any)['process_env'].SHIP_FROM_COUNTRY;
const PAYPAL_NVP_ENABLED = (window as any)['process_env'].PAYPAL_NVP_ENABLED;
const SUBSCRIPTION_FREQUENCY:any = {
  Daily: {
    frequencyForPayPal: 'DAY',
    frequencyForPayPalNVP: 'Day',
    days: 1,
  },
  Weekly: {
    frequencyForPayPal: 'WEEK',
    frequencyForPayPalNVP: 'Week',
    days: 7,
  },
  Monthly: {
    frequencyForPayPal: 'MONTH',
    frequencyForPayPalNVP: 'Month',
    days: 30,
  },
  Quarterly: {
    frequencyForPayPal: 'MONTH',
    frequencyForPayPalNVP: 'Month',
    days: 90,
  },
  Annual: {
    frequencyForPayPal: 'YEAR',
    frequencyForPayPalNVP: 'Year',
    days: 365,
  }
};

@Injectable({providedIn: 'root'})
export class PaypalPaymentProvider {
  constructor(
    public tcrApiOrder: TouchcrApiOrderProvider,
    public orderDetails: OrderDetailsProvider,
    public tcrApiRemoteServices: TouchcrApiRemoteServicesProvider,
    public stateProvider: StateProvider,
    public funnelProvider: FunnelSettingsProvider,
    public urls: UrlsProvider,
    public calculateHelper: CalculateProvider,
  ) {
  }


  getOrderBody({
                 orderFormProducts, OffersData, bumpOffers, totalPrice, accDetails, orderType, coupon, totalTax, calculatedShippingCost, shippingType,
                 subscriptionTax, isPayPalSubscription, isInternationalShipping
               } : any) {
    let createOrderBody:any = {
      products: [],
      variants: [],
      bumpoffers: [],
      funnels: [],
      orderFormProduct: [],
      paymentMethod: 'PayPal',
      namedAgent: 'PayPal',
      cardDetails: {},
      orderTotal: 0,
      totalTax: totalTax,
      authorize: false,
      coupons: coupon && Object.keys(coupon).length ? [coupon] : [],
      definedShippingCost: calculatedShippingCost,
      shippingType: shippingType,
      subscriptionTax: subscriptionTax,
      isPayPalSubscription: isPayPalSubscription ? isPayPalSubscription : false,
      isInternationalShipping: isInternationalShipping,
      isGuest: true,
    };

    // Add order form (funnels) in the body if there is no order form products
    if (!orderFormProducts || !orderFormProducts.length) {
      createOrderBody.funnels.push(...OffersData.map((elem:any) => {
        createOrderBody.authorize = elem.isSubscriptionAvailable && !!elem.subscriptionDaysPostpone;
        return  {
          orderFormId: elem.sfid,
          count: 1,
        };
      }  ) ) ;
    }

    // Add bump offers in the body
    if (bumpOffers && bumpOffers.length) {
      createOrderBody.bumpoffers.push(...bumpOffers.map((elem:any) => {
        return {
          orderFormId: elem.sfid,
          count: 1,
        };
      }));
    }

    // Add order form products in the body
    if (orderFormProducts && orderFormProducts.length) {
      createOrderBody.orderFormProduct.push(...orderFormProducts.map((elem:any) => {
        createOrderBody.authorize = (elem.isSubscriptionAvailable) && !!elem.subscriptionDaysPostpone;
        return {
          orderFormProductId: elem.sfid,
          count: 1,
        };
      }));
    }
    // alert(orderType);
    createOrderBody.orderTotal = totalPrice;
    createOrderBody['accDetails'] = accDetails;
    createOrderBody['orderSource'] = 'Funnel';
    createOrderBody['orderType'] = orderType;
    createOrderBody['customerIp'] = (window as any)['gh']['ip'];
    if (coupon) {
      createOrderBody['couponDiscount'] = coupon.couponDiscount;
    }
    createOrderBody['statsInfo'] = this.funnelProvider.getStats();
    return createOrderBody;
  }

  getUpsellOrderBody (order:any, upsells:any, type:any, paymentMethod:any, totalTax:any) {
    return  {
      accId: order.accountId,
      orderType: type,
      parent: order.id,
      orderSource: 'Funnel',
      customerIp: (window as any)['gh']['ip'],
      upsells: upsells,
      paymentMethod: paymentMethod,
      namedAgent: paymentMethod,
      cardDetails: {},
      totalTax: totalTax,
      statsInfo: this.funnelProvider.getStats(),
    }
  }

  getPayPalSubscriptionBody(data:any) {
    const country = data.accDetailsForPayPal.shipTo.country;
    let orderFormData = data.OffersData[0];
    const subscriptionMupltiplier = orderFormData.subscriptionFrequencyMultiplier || 1;
    let shippingCost = +data.calculatedShippingCost;
    const subtotal = +(+data.totalPrice - +data.totalTax - (!data.OffersData[0].isFreeFirstShipping ? +data.OffersData[0].shippingCost : 0)
      - +data.calculatedShippingCost).toFixed(2);
    if (country === SHIP_FROM_COUNTRY){
      shippingCost +=  orderFormData.shippingCost ? +orderFormData.shippingCost : 0
    } else {
      shippingCost += orderFormData.internationalShippingCost ? +orderFormData.internationalShippingCost : 0;
    }
    let cycles = this.getBillingCyclesForPayPal(data.OffersData[0]);
    const currentQuery = this.urls.getQueryParams();
    let returnQuery = this.urls.addParamToQuery(currentQuery, 'paypalsubscription', 'true');
    returnQuery = this.urls.addParamToQuery(returnQuery, 'action', 'execute');
    returnQuery = this.urls.addParamToQuery(returnQuery, 'sessionid', this.funnelProvider.getSessionId());
    let cancelQuery = this.urls.addParamToQuery(currentQuery, 'paypalsubscription', 'true');
    cancelQuery = this.urls.addParamToQuery(cancelQuery, 'action', 'cancel');
    cancelQuery = this.urls.addParamToQuery(cancelQuery, 'sessionid', this.funnelProvider.getSessionId());
    let paymentData = {
      billingPlan: {
        description: 'Plan for ' +  orderFormData.sfid,
        merchant_preferences: {
          auto_bill_amount: 'no',
          cancel_url: `${location.origin}${this.getPath()}${encodeURI(cancelQuery)}`,
          initial_fail_amount_action: 'cancel',
          max_fail_attempts: '3',
          return_url: `${location.origin}${this.getPath()}${encodeURI(returnQuery)}`,
          setup_fee: {
            currency: 'USD',
            value: '0',
          },
        },
        name: 'Payment definition for ' + orderFormData.sfid,
        payment_definitions: [
          {
            amount: {
              currency: 'USD',
              value: subtotal,
            },
            charge_models: [
              {
                amount: {
                  currency: 'USD',
                  value: +(shippingCost.toFixed(2)),
                },
                type: 'SHIPPING',
              },
              {
                amount: {
                  currency: 'USD',
                  value: data.subscriptionTax,
                },
                type: 'TAX',
              },
            ],
            cycles: '' + cycles,
            frequency: SUBSCRIPTION_FREQUENCY[orderFormData.subscriptionFrequency].frequencyForPayPal,
            frequency_interval: orderFormData.subscriptionFrequency != 'Quarterly' ? subscriptionMupltiplier : subscriptionMupltiplier * 3,
            name: 'Regular payment for ' +  orderFormData.sfid,
            type: 'REGULAR',
          },
        ],
        type: (orderFormData.subscriptionStopOn == 'Never' || orderFormData.subscriptionStopOn == null) ? 'INFINITE' : 'FIXED',
      },
      shipping_address: {
        line1: data.accDetailsForPayPal.shipTo.line1,
        city: data.accDetailsForPayPal.shipTo.city,
        state: data.accDetailsForPayPal.shipTo.region,
        postal_code: data.accDetailsForPayPal.shipTo.postalCode,
        country_code: data.accDetailsForPayPal.shipTo.country,
      },
      subscriptionDaysPostpone: orderFormData.subscriptionDaysPostpone ? orderFormData.subscriptionDaysPostpone : 0,
    };
    if (data.shippingType == 'Free' && orderFormData.isFreeFirstShipping &&
      ((orderFormData.subscriptionStopOn != null && orderFormData.subscriptionStopOn != 'Never' && cycles > 1) ||
        orderFormData.subscriptionStopOn == null || orderFormData.subscriptionStopOn == 'Never')) {
      paymentData = this.updatePayPalSubscriptionBodyWithTrialPayment(data, paymentData, cycles, false);
      paymentData.billingPlan.payment_definitions[0].cycles = cycles == 0 ? '0' : '' + (cycles - 1);
    }
    else if (data.shippingType == 'Free' && orderFormData.isFreeFirstShipping) {
      paymentData.billingPlan.payment_definitions[0].charge_models[0].amount.value = '0';
      paymentData.billingPlan.payment_definitions[0].charge_models[1].amount.value = '' + data.totalTax;
    }
    return paymentData;
  }

  getSubscriptionBodyForPayPalNVP(data:any) {
    const shippingAdress = data.accDetailsForPayPal.shipTo;
    const orderFormData = data.OffersData[0];
    const shippingCost = shippingAdress.country === SHIP_FROM_COUNTRY ? data.OffersData[0].shippingCost ? data.OffersData[0].shippingCost : 0 : data.OffersData[0].internationalShippingCost ? data.OffersData[0].internationalShippingCost : 0;
    const cycles = this.getBillingCyclesForPayPal(orderFormData);
    let date = new Date();
    if (orderFormData.subscriptionDaysPostpone) {
      date.setDate(date.getDate() + (+orderFormData.subscriptionDaysPostpone));
    }
    const subscriptionMupltiplier = orderFormData.subscriptionFrequencyMultiplier || 1;
    let subscriptionBody = {
      order: this.getOrderBody(data),
      dataForTax: data.dataForTax,
      paymentBody: {
        TOKEN: data.token,
        SHIPPINGAMT: +(parseFloat(shippingCost) + parseFloat(data.calculatedShippingCost)).toFixed(2),
        TAXAMT: data.subscriptionTax,
        PROFILESTARTDATE: date.toISOString(),
        DESC: data.OffersData[0].product.name.toString(),
        CURRENCYCODE:'USD',
        AMT: data.OffersData[0].offerPrice,
        TOTALBILLINGCYCLES: cycles,
        BILLINGPERIOD: SUBSCRIPTION_FREQUENCY[data.OffersData[0].subscriptionFrequency].frequencyForPayPalNVP,
        BILLINGFREQUENCY: subscriptionMupltiplier,
        MAXFAILEDPAYMENTS: '3',
        SHIPTONAME: data.accDetails.firstName + ' ' + data.accDetails.lastName,
        SHIPTOSTREET: shippingAdress.line1,
        SHIPTOCITY: shippingAdress.city,
        SHIPTOSTATE: shippingAdress.region,
        SHIPTOZIP: shippingAdress.postalCode,
        SHIPTOCOUNTRY: shippingAdress.country,
      }
    };
    if (data.shippingType == 'Free' && orderFormData.isFreeFirstShipping &&
      ((orderFormData.subscriptionStopOn != null && orderFormData.subscriptionStopOn != 'Never' && cycles > 1) ||
        orderFormData.subscriptionStopOn == null || orderFormData.subscriptionStopOn == 'Never')) {
      subscriptionBody.paymentBody = this.updatePayPalSubscriptionBodyWithTrialPayment(data, subscriptionBody.paymentBody, cycles, true);
      subscriptionBody.paymentBody.TOTALBILLINGCYCLES = cycles == 0 ? 0 : (cycles - 1);
    }
    else if (data.shippingType == 'Free' && orderFormData.isFreeFirstShipping) {
      subscriptionBody.paymentBody.SHIPPINGAMT = 0.00;
      subscriptionBody.paymentBody.TAXAMT = '' + data.totalTax;
    }
    return subscriptionBody;
  }

  createPayment(data:any) {
    return new Promise((resolve, reject) => {
      const objBody = this.getOrderBody(data);
      data.shipping = data.isInternationalShipping ? data.OffersData[0].internationalShippingCost :
        data.OffersData[0].shippingCost;
      if (data.calculatedShippingCost) {
        data.shipping += +data.calculatedShippingCost;
      }
      const currentQuery = this.urls.getQueryParams();
      let returnQuery = this.urls.addParamToQuery(currentQuery, 'paypal', 'true');
      returnQuery = this.urls.addParamToQuery(returnQuery, 'action', 'execute');
      returnQuery = this.urls.addParamToQuery(returnQuery, 'sessionid', this.funnelProvider.getSessionId());
      let cancelQuery = this.urls.addParamToQuery(currentQuery, 'paypal', 'true');
      cancelQuery = this.urls.addParamToQuery(cancelQuery, 'action', 'cancel');
      cancelQuery = this.urls.addParamToQuery(cancelQuery, 'sessionid', this.funnelProvider.getSessionId());
      let body = {
        redirect_urls: {
          return_url: `${location.origin}${this.getPath()}${encodeURI(returnQuery)}`,
          cancel_url: `${location.origin}${this.getPath()}${encodeURI(cancelQuery)}`,
        },
        orderData: objBody,
      };
      this.tcrApiOrder.createPayPalPayment(body)
        .subscribe((response:any) => {
          resolve ({ order: response.order, url: response.redirectUrl });
        }, (error) => {
          reject(error);
        })
    });
  }

  createPaymentUpsell({ parentOrder, OffersData, orderType, totalPrice, totalTax , countries } : any) {
    return new Promise((resolve, reject) => {
       if (PAYPAL_NVP_ENABLED) {
         this.tcrApiOrder.setUpsells(parentOrder, [{ sfid: OffersData[0].sfid, count: 1 }], orderType, 'PayPal', totalTax)
           .subscribe((resultOrder:any) => {
             this.setExpressCheckout({
               orderType: 'Standard',
               offersData: OffersData[0],
               coupon: null,
               shipToCountry: this.calculateHelper.getCountryISOCode(countries, resultOrder.order.shippingCountry),
               isUpsell: true,
               totalTax: totalTax,
             })
               .then((response:any) => {
                 resolve({order: resultOrder.order, url: response['url']});
               })
               .catch((error) => {
                 reject(error);
               })
           })
          } else {
            const currentQuery = this.urls.getQueryParams();
            let returnQuery = this.urls.addParamToQuery(currentQuery, 'paypal', 'true');
            returnQuery = this.urls.addParamToQuery(returnQuery, 'action', 'execute');
            returnQuery = this.urls.addParamToQuery(returnQuery, 'sessionid', this.funnelProvider.getSessionId());
            let cancelQuery = this.urls.addParamToQuery(currentQuery, 'paypal', 'true');
            cancelQuery = this.urls.addParamToQuery(cancelQuery, 'action', 'cancel');
            cancelQuery = this.urls.addParamToQuery(cancelQuery, 'sessionid', this.funnelProvider.getSessionId());
            let body = {
              orderData: this.getUpsellOrderBody(parentOrder, [{ sfid: OffersData[0].sfid, count: 1 }], orderType, 'PayPal', totalTax),
              redirect_urls: {
                return_url: `${location.origin}${this.getPath()}${encodeURI(returnQuery)}`,
                cancel_url: `${location.origin}${this.getPath()}${encodeURI(cancelQuery)}`,
              },
            };
            this.tcrApiOrder.createPayPalPayment(body)
              .subscribe((response:any) => {
                resolve({ order: response.order, url: response.redirectUrl });
              }, (error) => {
                reject(error);
              })
          }
    });
  }

  // TOUC-14710 - Braintree
  createOrderBraintreeUpsell({ parentOrder, OffersData, orderType, totalPrice, totalTax , countries } : any) {
    console.log('[GH] createOrderBraintreeUpsell called with parentOrder: ', parentOrder);
    return new Promise((resolve, reject) => {
        this.tcrApiOrder.setBraintreeUpsells(parentOrder, [{ sfid: OffersData[0].sfid, count: 1 }], orderType, parentOrder.paymentMethod, totalTax)
          .subscribe((resultOrder:any) => {
            resolve({order: resultOrder.order, url: null});
          })
   });
  }
  
  executePayment({ paymentId, PayerID, isUpsell, dataForTax, token } : any) {
    this.urls.clearPayPalParams();
    return new Promise((resolve, reject) => {
      this.stateProvider.get()
        .then(state => {
          let orderDetails = state.orderDetails;
          let order: any;
          if (isUpsell) {
            if (!orderDetails || !orderDetails.purchasedUpsells) {
              return reject('Upsell info in ionic storage does not exist');
            }
            let upsellOrder = orderDetails.purchasedUpsells[orderDetails.purchasedUpsells.length - 1];
            order = upsellOrder;
          } else {
            order = orderDetails.order
          }
          const executePaymentBody = {
            payment: {
              token: token,
              paymentId: paymentId,
              executePaymentJson: {
                payer_id: PayerID,
              }
            },
            order: order,
            dataForTax: dataForTax,
          };
          this.tcrApiOrder.executePayPalPayment(executePaymentBody)
            .subscribe((resultPayment) => {
                resolve({ message: 'success', orderDetails: orderDetails });
            }, (error) => {
              reject(error);
            });
        }, (err) => {
          reject(err);
        });
    });
  }


  createSubscription(data:any) {
    return new Promise((resolve, reject) => {
      data.isPayPalSubscription = true;
      this.tcrApiOrder.createPayPalSubscription({
        paymentData: this.getPayPalSubscriptionBody(data),
        orderData: this.getOrderBody(data),
      })
        .subscribe((response:any) => {
              resolve ({ order: response.order, url: response.redirectUrl });
        }, (error) => {
          reject(error);
        });
    });
  }

  setExpressCheckout(data:any) {
    return new Promise((resolve, reject) => {
      this.tcrApiOrder.setExpressCheckout(this.getPayPalBodyForNVP(data))
        .subscribe((response:any) => {
          resolve({url: response.redirectUrl});
        }, (error) => {
          reject({message: error.message});
        });
    });
  }

  createRecurringPaymentsProfile(paramData:any) {
    return new Promise((resolve, reject) => {
      paramData.isPayPalSubscription = true;
      let body = this.getSubscriptionBodyForPayPalNVP(paramData);
      this.tcrApiOrder.createRecurringPayment(body)
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          console.log(error);
          reject({message: error.L_LONGMESSAGE0 || error.message});
        });
    });
  }

  doExpressCheckout(paramData:any) {
    return new Promise((resolve, reject) => {
      const shippingAdress = paramData.accDetailsForPayPal.shipTo;
      const shippingCost = shippingAdress.country === SHIP_FROM_COUNTRY ? paramData.OffersData[0].shippingCost : paramData.OffersData[0].internationalShippingCost;
      const subtotal = paramData.totalPrice - paramData.totalTax - paramData.calculatedShippingCost - shippingCost;

      let body = {
        order: this.getOrderBody(paramData),
        dataForTax: paramData.dataForTax,
        paymentBody: {
          TOKEN: paramData.token,
          PAYERID: paramData.payerId,
          PAYMENTREQUEST_0_AMT: +paramData.totalPrice.toFixed(2),
          PAYMENTREQUEST_0_CURRENCYCODE: paramData.currencyCode,
          PAYMENTREQUEST_0_SHIPTONAME: paramData.accDetails.firstName + ' ' + paramData.accDetails.lastName,
          PAYMENTREQUEST_0_SHIPTOSTREET: shippingAdress.line1,
          PAYMENTREQUEST_0_SHIPTOCITY: shippingAdress.city,
          PAYMENTREQUEST_0_SHIPTOSTATE: shippingAdress.region,
          PAYMENTREQUEST_0_SHIPTOZIP: shippingAdress.postalCode,
          PAYMENTREQUEST_0_SHIPTOCOUNTRYCODE: shippingAdress.country,
          L_PAYMENTREQUEST_0_AMT0: +subtotal.toFixed(2),
          L_PAYMENTREQUEST_0_NAME0: paramData.OffersData[0].product.name,
          L_PAYMENTREQUEST_0_QTY0: paramData.OffersData[0].product.count,
          PAYMENTREQUEST_0_ITEMAMT: +(subtotal / paramData.OffersData[0].product.count).toFixed(2),
          PAYMENTREQUEST_0_SHIPPINGAMT: +(+(paramData.calculatedShippingCost) + +(shippingCost)).toFixed(2),
          PAYMENTREQUEST_0_TAXAMT: +paramData.totalTax.toFixed(2),

        }
      };
      this.tcrApiOrder.doExpressCheckout(body)
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject({message: error.L_LONGMESSAGE0 || error.message});
        });
    });
  }

  executeSubscription(paramData:any) {
    this.urls.clearPayPalParams();
    return new Promise((resolve, reject) => {
      this.stateProvider.get()
        .then(state => {
          let orderDetails = state.orderDetails;
          let executeSubscriptionBody = {
            accountId: orderDetails.order.accountId,
            billingAgreementToken: paramData.token,
            dataForTax: paramData.dataForTax,
            order: orderDetails.order
          };
          this.tcrApiOrder.executePayPalSubscription(executeSubscriptionBody)
            .subscribe((resultPayment:any) => {
              if (resultPayment.message === 'success') {
                resolve({ message: 'success', orderDetails: orderDetails});
              } else {
                reject(resultPayment);
              }
            }, (error) => {
              reject(error);
            });
        });
    });
  }


  cancelPayment(orderId:any) {
    this.urls.clearPayPalParams();
    return new Promise((resolve, reject) => {
      this.tcrApiOrder.createTransaction({ orderId: orderId, status: 'Cancelled', method: 'PayPal', message: 'Order Has been cancelled' })
        .subscribe((result:any) => {
          if (result.message === 'The transaction has been created') {
            resolve({ message: 'success' });
          } else {
            reject('Transaction was not created!');
          }
        }, (error) => {
          reject(error);
        });
    });
  }

  cancelPayPalSubscription(orderId:any) {
    this.urls.clearPayPalParams();
    return new Promise((resolve, reject) => {
      this.stateProvider.get()
        .then(state => {
          let orderDetails = state.orderDetails;
          this.tcrApiOrder.cancelPayPalSubscription({ orderId: orderId, status: 'Cancelled' })
            .subscribe((result:any) => {
              if (result.message === 'success') {
                resolve({ message: 'success', orderDetails: orderDetails});
              } else {
                reject('Subscription was not canceled!');
              }
            }, (error) => {
              reject(error);
            });
        });
    });
  }

  getPayPalPayerDetailsNVP(data:any) {
    this.urls.clearPayPalParams();
    return new Promise((resolve, reject) => {
      this.tcrApiOrder.getExpressCheckoutDetails(data)
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject({message: error.L_LONGMESSAGE0 || error.message});
        });
    });
  }

  getBillingCyclesForPayPal(orderFormData:any) {
    let cycles = 0;
    let billingCycles = 0;
    const subscriptionMupltiplier = orderFormData.subscriptionFrequencyMultiplier || 1;
    if (orderFormData.subscriptionStopOn == 'First' || orderFormData.subscriptionStopOn == 'Full Payment') {
      cycles = 1;
    }
    if (orderFormData.subscriptionStopOn == 'Count') {
      cycles = orderFormData.subscriptionCount;
    }
    if (orderFormData.subscriptionStopOn == 'Date') {
      let stopDate = new Date(orderFormData.subscriptionStopDate);
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + (!orderFormData.subscriptionDaysPostpone ? 0 : orderFormData.subscriptionDaysPostpone));
      for (currentDate; currentDate <= stopDate; currentDate.setDate(currentDate.getDate() +
        SUBSCRIPTION_FREQUENCY[orderFormData.subscriptionFrequency].days * subscriptionMupltiplier)) {
        billingCycles++;
      }
      cycles = billingCycles;
    }
    return cycles;
  }

  updatePayPalSubscriptionBodyWithTrialPayment(data:any, paymentData:any, cycles:any, isPayPalNVP:any) {
    const subtotal = +(+data.totalPrice - +data.totalTax - (!data.OffersData[0].isFreeFirstShipping ? +data.OffersData[0].shippingCost : 0)
      - +data.calculatedShippingCost).toFixed(2);
    let orderFormData = data.OffersData[0];
    if (isPayPalNVP) {
      paymentData.TRIALBILLINGPERIOD = paymentData.BILLINGPERIOD;
      paymentData.TRIALBILLINGFREQUENCY = paymentData.BILLINGFREQUENCY;
      paymentData.TRIALTOTALBILLINGCYCLES = '1';
      paymentData.TRIALAMT = +orderFormData.subscriptionPrice.toFixed(2);
      paymentData.TRIALTAXAMT = +data.totalTax.toFixed(2);
      paymentData.TRIALSHIPPINGAMT = 0;
    }
    else {
      paymentData.billingPlan.payment_definitions[1] = {
        amount: {
          currency: 'USD',
          value: subtotal,
        },
        charge_models: [
          {
            amount: {
              currency: 'USD',
              value: 0,
            },
            type: 'SHIPPING',
          },
          {
            amount: {
              currency: 'USD',
              value: +data.totalTax.toFixed(2),
            },
            type: 'TAX',
          },
        ],
        cycles: '1',
        frequency: paymentData.billingPlan.payment_definitions[0].frequency,
        frequency_interval: paymentData.billingPlan.payment_definitions[0].frequency_interval,
        name: 'Free first shipping for ' + orderFormData.sfid,
        type: 'TRIAL',
      }
    }

    return paymentData;
  }
  getPath = function() {
    return window.location.pathname ; // [TOUC-5682]
  }
  getPayPalBodyForNVP(data:any) {
    const defaultShipAmount = data.shipToCountry === SHIP_FROM_COUNTRY ? data.offersData.shippingCost : data.offersData.internationalShippingCost;
    const subtotal = data.orderType == 'Subscription' ? +data.offersData.subscriptionPrice : +data.offersData.offerPrice;
    const shippingAmt = data.offersData.isFreeFirstShipping ? 0 : (defaultShipAmount ? +defaultShipAmount : 0);
    const totalTax = data.totalTax ? +data.totalTax.toFixed(2) : 0;
    let couponDiscount = 0;
    if (data.coupon && data.coupon.couponDiscount) {
      couponDiscount = +data.coupon.couponDiscount;
    }
    const currentQuery = this.urls.getQueryParams();
    let returnQuery = this.urls.addParamToQuery(currentQuery, 'paypalnvp', 'true');
    returnQuery = this.urls.addParamToQuery(returnQuery, 'action', 'execute');
    returnQuery = this.urls.addParamToQuery(returnQuery, 'sessionid', this.funnelProvider.getSessionId());
    let cancelQuery = this.urls.addParamToQuery(currentQuery, 'paypalnvp', 'true');
    cancelQuery = this.urls.addParamToQuery(cancelQuery, 'action', 'cancel');
    cancelQuery = this.urls.addParamToQuery(cancelQuery, 'sessionid', this.funnelProvider.getSessionId());
    let body:any = {
      paramData: {
        PAYMENTREQUEST_0_AMT: +(subtotal + shippingAmt + couponDiscount + totalTax).toFixed(2),
        PAYMENTREQUEST_0_CURRENCYCODE: 'USD',
        PAYMENTREQUEST_0_PAYMENTACTION: 'Sale',
        PAYMENTREQUEST_0_ITEMAMT: +(subtotal + couponDiscount).toFixed(2),
        L_PAYMENTREQUEST_0_AMT0: +subtotal.toFixed(2),
        L_PAYMENTREQUEST_0_NAME0: data.offersData.product.name.toString(),
        L_PAYMENTREQUEST_0_QTY0: '1',
        PAYMENTREQUEST_0_SHIPPINGAMT: +shippingAmt.toFixed(2),
        PAYMENTREQUEST_0_TAXAMT: totalTax,
        RETURNURL: `${location.origin}${this.getPath()}${encodeURI(returnQuery)}`,
        CANCELURL: `${location.origin}${this.getPath()}${encodeURI(cancelQuery)}`
      }
    };
    if (data.isUpsell) {
      body.paramData['NOSHIPPING'] = '1';
    }
    if (data.coupon && data.coupon.couponDiscount) {
      body.paramData['L_PAYMENTREQUEST_0_AMT1'] = +data.coupon.couponDiscount.toFixed(2);
      body.paramData['L_PAYMENTREQUEST_0_NAME1'] = data.coupon.name.toString();
      body.paramData['L_PAYMENTREQUEST_0_QTY1'] = '1';
    }
    if (data.orderType == 'Subscription') {
      body.paramData['L_BILLINGTYPE0'] = 'RecurringPayments';
      body.paramData['L_BILLINGAGREEMENTDESCRIPTION0'] = data.offersData.product.name.toString();
    }
    return body;
  }
}
