import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class FaqProvider {

  constructor() {
  }

  static getFaqs() {
    return [
      {
        question: {
          mobile: `I’ve seen ‘leaky gut’ products advertised… how is Gundry MD Bio Complete 3 different?`,
          desktop: `+ I’ve seen ‘leaky gut’ products advertised… how is Gundry MD Bio Complete 3 different?`
        },
        answer: {
          mobile: `
          <p>Before I developed this formula, I looked at the other products out there. And I saw huge problems with every one of them… so much so, I refused to recommend any of them. Here’s why…</p>
          <p>First, most ‘leaky gut’ products have added sugars or artificial sweeteners. Both are terrible for your body, and they’re also highly addictive. Bio Complete 3 has no sugar or artificial sweeteners whatsoever.</p>
          <p>Second, many other leaky gut products contain LECTINS — and that’s absolutely a no-no for me. We talked earlier about how lectins cause damage all over your body… creating serious problems, including leaky gut. So, Bio Complete 3 has ZERO lectins. It also has no soy, another “toxic health food” I tell everyone to avoid.</p>
          <p>Most importantly, though — leaky gut formulas are designed to keep toxins from seeping out into your system. Bio Complete 3 also does this — but it does SO much more, too.</p>
          <p>Bio Complete 3 utilizes powerful butyrate so you can build a strong, healthy gut lining… which means your prebiotics and probiotics cannot “slip” through the holes in your gut. When your probiotics, prebiotics, and postbiotics are all safe and secure in your gut… THAT’S when they can do their best work.</p>
          <p>So, not only does it keep the toxins from leaking into your system… it keeps the GOOD stuff, right where it belongs. That means YOU get to experience a supercharge in energy, an incredible ‘slimming effect,’ even better bowel movements, and amazing mental clarity.</p>
          <p>It also means any other Gundry MD formula you’re currently taking will be supercharged as well. Because when those nutrients and ingredients are “locked in” to your gut, it allows your body to use them much more effectively.</p>
          <p>Plus, the big difference between Bio Complete 3 and other digestive health formulas is that I chose the ingredients for this formula based on clinical results.</p>
          <p>Look, I’m a surgeon with 30 plus years experience and a published medical nutrition researcher, so I brought that expertise to the table. This formula gets real results because it’s got proven ingredients at the right dosage.</p>
          <p>Also, Bio Complete 3 is not just a gut health blend at all. It’s really a 3-in-1 formula that can help boost energy, slim your waist, and solve leaky gut like nothing else can. It’s a complete package with the right stuff that works.</p>
          `,
          desktop: `
          <p>Before I developed this formula, I looked at the other products out there. And I saw huge problems with every one of them… so much so, I refused to recommend any of them. Here’s why…</p>
          <p>First, most ‘leaky gut’ products have added sugars or artificial sweeteners. Both are terrible for your body, and they’re also highly addictive. Bio Complete 3 has no sugar or artificial sweeteners whatsoever.</p>
          <p>Second, many other leaky gut products contain LECTINS — and that’s absolutely a no-no for me. We talked earlier about how lectins cause damage all over your body… creating serious problems, including leaky gut. So, Bio Complete 3 has ZERO lectins. It also has no soy, another “toxic health food” I tell everyone to avoid.</p>
          <p>Most importantly, though — leaky gut formulas are designed to keep toxins from seeping out into your system. Bio Complete 3 also does this — but it does SO much more, too.</p>
          <p>Bio Complete 3 utilizes powerful butyrate so you can build a strong, healthy gut lining… which means your prebiotics and probiotics cannot “slip” through the holes in your gut. When your probiotics, prebiotics, and postbiotics are all safe and secure in your gut… THAT’S when they can do their best work.</p>
          <p>So, not only does it keep the toxins from leaking into your system… it keeps the GOOD stuff, right where it belongs. That means YOU get to experience a supercharge in energy, an incredible ‘slimming effect,’ even better bowel movements, and amazing mental clarity.</p>
          <p>It also means any other Gundry MD formula you’re currently taking will be supercharged as well. Because when those nutrients and ingredients are “locked in” to your gut, it allows your body to use them much more effectively.</p>
          <p>Plus, the big difference between Bio Complete 3 and other digestive health formulas is that I chose the ingredients for this formula based on clinical results.</p>
          <p>Look, I’m a surgeon with 30 plus years experience and a published medical nutrition researcher, so I brought that expertise to the table. This formula gets real results because it’s got proven ingredients at the right dosage.</p>
          <p>Also, Bio Complete 3 is not just a gut health blend at all. It’s really a 3-in-1 formula that can help boost energy, slim your waist, and solve leaky gut like nothing else can. It’s a complete package with the right stuff that works.</p>
          `
        }
      },
      {
        question: {
          mobile: `Are there any side effects?`,
          desktop: `+ Are there any side effects?`
        },
        answer: {
          mobile: `
            <p>Gundry MD Bio Complete 3 contains only 100% pure, quality ingredients. The content of every batch is tested at my own facility, and then tested again for quality and purity at an outside, 3rd-party laboratory.</p>
            <p>And again, my formula contains none of the toxic elements you’ll find in lots of other so-called “health products.” Gundry MD Bio Complete 3 is sugar-free, artificial sweetener-free, lectin-free, soy-free, and dairy-free.</p>
            <p>As a physician, I’m putting my reputation on the line with this product — so you can be sure I go to great lengths to make sure every bottle is absolutely safe.</p>
            <p>I made a pledge when I developed the Gundry MD line: I will never sell any product I wouldn’t personally recommend to a loved one.</p>
            <p>But, of course, as a physician I know that no supplement works for everyone. There's always a very small segment of the population who may be allergic or who can't tolerate certain ingredients in supplements.</p>
            <p>So consult your doctor before starting any new dietary supplement. And if you want to return it for any reason at all, simply contact our US-based customer support team for an immediate refund.</p>
          `,
          desktop: `
            <p>Gundry MD Bio Complete 3 contains only 100% pure, quality ingredients. The content of every batch is tested at my own facility, and then tested again for quality and purity at an outside, 3rd-party laboratory.</p>
            <p>And again, my formula contains none of the toxic elements you’ll find in lots of other so-called “health products.” Gundry MD Bio Complete 3 is sugar-free, artificial sweetener-free, lectin-free, soy-free, and dairy-free.</p>
            <p>As a physician, I’m putting my reputation on the line with this product — so you can be sure I go to great lengths to make sure every bottle is absolutely safe.</p>
            <p>I made a pledge when I developed the Gundry MD line: I will never sell any product I wouldn’t personally recommend to a loved one.</p>
            <p>But, of course, as a physician I know that no supplement works for everyone. There's always a very small segment of the population who may be allergic or who can't tolerate certain ingredients in supplements.</p>
            <p>So consult your doctor before starting any new dietary supplement. And if you want to return it for any reason at all, simply contact our US-based customer support team for an immediate refund.</p>
          `
        }
      },
      {
        question: {
          mobile: `How will I know if it’s working?`,
          desktop: `+ How will I know if it’s working?`
        },
        answer: {
          mobile: `
          <p>Great question. The best thing about this formula is that you’re going to feel it very quickly.</p>
          <p>Most users report feeling a distinctive, noticeable energy charge within a week of consuming. It may take longer for some than for others, but it’s a feeling you’re going to want again and again.</p>
          <p>You’ll notice a lifting of the “brain fog” that often clouds your day. Breakfast should digest more smoothly.</p>
          <p>You’ll feel better about completing tasks, one after the other… without losing steam, without losing stamina. After lunch, you’ll wonder why you don’t feel more lethargic!</p>
          <p>And the energy from this breakthrough formula comes without the crash you get from caffeine, without the crash you get from sugar or empty carbs. This is a clean energy… because it’s the kind of energy your body is supposed to be getting.</p>
          <p>You’ll know it’s working when you look up at the clock and it’s already 10 pm… you didn’t notice because you were busy playing cards with friends, or working on that personal project, or watching that romantic movie with your spouse without falling asleep.</p>
          <p>This is what I’m talking about… the real meaning of having more energy.</p>
          <p>Of course, there’s only one way to really find out if Bio Complete 3 will work for you…and that’s to try it for yourself.</p>
          <p>If you’re ready right now to get started — and if you’re still here, you should be ready! — simply click the “Next Step” button just below.</p>
          `,
          desktop: `
          <p>Great question. The best thing about this formula is that you’re going to feel it very quickly.</p>
          <p>Most users report feeling a distinctive, noticeable energy charge within a week of consuming. It may take longer for some than for others, but it’s a feeling you’re going to want again and again.</p>
          <p>You’ll notice a lifting of the “brain fog” that often clouds your day. Breakfast should digest more smoothly.</p>
          <p>You’ll feel better about completing tasks, one after the other… without losing steam, without losing stamina. After lunch, you’ll wonder why you don’t feel more lethargic!</p>
          <p>And the energy from this breakthrough formula comes without the crash you get from caffeine, without the crash you get from sugar or empty carbs. This is a clean energy… because it’s the kind of energy your body is supposed to be getting.</p>
          <p>You’ll know it’s working when you look up at the clock and it’s already 10 pm… you didn’t notice because you were busy playing cards with friends, or working on that personal project, or watching that romantic movie with your spouse without falling asleep.</p>
          <p>This is what I’m talking about… the real meaning of having more energy.</p>
          <p>Of course, there’s only one way to really find out if Bio Complete 3 will work for you…and that’s to try it for yourself.</p>
          <p>If you’re ready right now to get started — and if you’re still here, you should be ready! — simply click the “Next Step” button just below.</p>
          `
          
        }
      },
      {
        question: {
          mobile: `How do I use Bio Complete 3?`,
          desktop: `+ How do I use Bio Complete 3?`
        },
        answer: {
          mobile: `  <p>Now, this is the best part because it’s just incredibly easy. It literally takes not even one minute out of your day.</p>
          <p>Simply take two small capsules twice per day, preferably before a meal. The extracts in this formula digest so easily, your body starts metabolizing them quickly into energy you can really feel.</p>
          <p>It is important to take your Bio Complete 3 every day on a consistent basis. I take mine every morning with breakfast so I can get my day started with a boost of long-lasting mental and physical energy.</p>`,
          desktop: `
          <p>Now, this is the best part because it’s just incredibly easy. It literally takes not even one minute out of your day.</p>
          <p>Simply take two small capsules twice per day, preferably before a meal. The extracts in this formula digest so easily, your body starts metabolizing them quickly into energy you can really feel.</p>
          <p>It is important to take your Bio Complete 3 every day on a consistent basis. I take mine every morning with breakfast so I can get my day started with a boost of long-lasting mental and physical energy.</p>`
        }
      },
      {
        question: {
          mobile: `What if it doesn’t work for me?`,
          desktop: `+ What if it doesn’t work for me?`
        },
        answer: {
          mobile: ` <p>Another great question, one we get all the time. Look, as a physician, I believe that everyone can benefit from the energy boosting, gut-restoring ingredients in this formula.</p>
          <p>However, I’m experienced enough in medicine to know that people are different. An individual body can react very differently to identical compounds.</p>
          <p>I’ve even seen very effective drugs have little to no effect on certain patients… and it’s all just down to their individual body dynamics. So, even though our users rave about the incredible changes they see when using Bio Complete 3 — there are going to be some cases with fewer results.</p>
          <p>However, the great news is this… if the formula doesn’t get results for you, I’m going to give you your money back.</p>
          <p>In fact, if there’s any reason at all you’re unsatisfied after a full 90 days of use, I’ll return your investment.</p>
          <p>Again, my reputation is on the line here… I want every single person who comes to Gundry MD™ to have a completely positive experience. It’s my name on the label, and I’ve come too far to risk that.</p>
          <p>So…try Gundry MD Bio Complete 3 for three full months. If you don’t see truly remarkable results — less fatigue, reduced digestive discomfort, fewer unexplained aches and pains, clearer thinking, and better physical stamina — I’ll give you your money back, and that’s my personal guarantee.</p>`,
          desktop: `
          <p>Another great question, one we get all the time. Look, as a physician, I believe that everyone can benefit from the energy boosting, gut-restoring ingredients in this formula.</p>
          <p>However, I’m experienced enough in medicine to know that people are different. An individual body can react very differently to identical compounds.</p>
          <p>I’ve even seen very effective drugs have little to no effect on certain patients… and it’s all just down to their individual body dynamics. So, even though our users rave about the incredible changes they see when using Bio Complete 3 — there are going to be some cases with fewer results.</p>
          <p>However, the great news is this… if the formula doesn’t get results for you, I’m going to give you your money back.</p>
          <p>In fact, if there’s any reason at all you’re unsatisfied after a full 90 days of use, I’ll return your investment.</p>
          <p>Again, my reputation is on the line here… I want every single person who comes to Gundry MD™ to have a completely positive experience. It’s my name on the label, and I’ve come too far to risk that.</p>
          <p>So…try Gundry MD Bio Complete 3 for three full months. If you don’t see truly remarkable results — less fatigue, reduced digestive discomfort, fewer unexplained aches and pains, clearer thinking, and better physical stamina — I’ll give you your money back, and that’s my personal guarantee.</p>
          `
        }
      },
      {
        question: {
          mobile: `What are the ingredients of Bio Complete 3?`,
          desktop:`+ What are the ingredients of Bio Complete 3?`
        },
        answer: {
          mobile: `<img src="https://cdn.gundrymd.com/images/bio-complete-3/BioComplete3-supp.png">`,
          desktop:`<img src="https://cdn.gundrymd.com/images/bio-complete-3/BioComplete3-supp.png">`
        }
      }
    ];
  }
}

