import { Component } from '@angular/core';

@Component({
  selector: 'page-what-is-this',
  templateUrl: './what-is-this.html',
  styleUrls: ['./what-is-this.scss'],
})
export class WhatIsThisPage {

  public creditCardImg: string = '../../assets/img/cvv2.jpg';

  constructor() {}
}
