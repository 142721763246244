<div class="upsell-product-box-full-div" margin-top>
  <div justify-content-center class="title_wrapper">
    <div col-12 padding margin-vertical [innerHTML]="title" class="title_content"></div>
  </div>
  <div justify-content-center class="product_wrapper">
    <div col-12 no-padding padding-horizontal padding-top class="product_content">
      <div class="product_content_flex">
        <div col-sm-6 col-xs-6 no-padding padding-top class="additionalImage_wrapper">
          <img src="{{ additionalImage }}" alt="{{ imageAlt }}">
        </div>
        <div col-sm-6 col-xs-6 no-padding class="content_wrapper">
          <img src="{{ image }}" alt="">
          <div class="price_wrapper notranslate">
            <p class="regularPrice">{{regularText}}: {{ regularPrice * braintreeCurrencyConversion | currency: currency:"symbol":".2-2" }}</p>
            <p class="instantSavings">{{instantSavingsText}}: {{ instantSavings * braintreeCurrencyConversion | currency: currency:"symbol":".2-2" }}</p>
            <p class="yourPrice">{{yourPriceText}}: {{ yourPrice * braintreeCurrencyConversion | currency: currency:"symbol":".2-2" }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="triangle"></div>
  </div>
</div>
