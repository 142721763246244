<header-with-menu [showLogo]="showHeaderLogo" [icon]="gundryLogo" [showContent]="showHeaderContent"
	[headerText]="headerText" [showOnOrderForm]="showOnOrderForm"></header-with-menu>

<div class="container-fluid">

	<div style="margin:auto; font-weight:bold; max-width: 700px; text-align:center; font-family: Montserrat; font-size: 16px;">
        <p>Oops! We had a technical error. Our team is working to fix it right now.</p>
        <p>In the meantime, please call 800-852-0477 to ensure your order processed correctly.</p>
        <p>We apologize for any inconvenience. Thank you for joining the Gundry MD family!</p>
	</div>
	
</div>