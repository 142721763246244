<div class="upsell-sticky-bar-div">
  <div class="upsell-sticky-bar_container">
    <div>
<!--       <div class="upsell-sticky-bar_image-container">
        <img [src]="image" [alt]="name" class="upsell-sticky-bar_image">
      </div>
      <div class="upsell-sticky-bar_summary">
        <span class="upsell-sticky-bar_brand">{{ brand }}</span>
        <span class="upsell-sticky-bar_name">{{ name }}</span>
        <br>
        <span class="upsell-sticky-bar_quantity">{{ quantity }}</span>
        <span class="upsell-sticky-bar_unit-type">{{ productUnitType }}</span>
      </div>
      <div class="upsell-sticky-bar_total">Total: {{ total | currency:'USD' }}</div>
      <div class="upsell-sticky-bar_button-container">
        <button
          class="upsell-sticky-bar_button"
          (click)="addToCart()"
        >
          {{ addToCartText }}
        </button>
      </div>
       -->
      <div class="upsell-sticky-bar_upgrade-downgrade-buttons" *ngIf="showButton">
        <upsell-guarantee 
          [buttonYes]="buttonYes"
          [showButtonYes]="showButtonYes" [buttonNo]="buttonNo"
          [showButtonNo]="showButtonNo" [description]="description"
          [showDescription]="showDescription" [image]="image"
          [imageAlt]="imageAlt" [showImage]="showImage" [upsellCtaTextButtonsClass]="upsellCtaTextButtonsClass"
          (eventEmitter)="nextPage($event)">
        </upsell-guarantee>
      </div>
    </div>
  </div>
</div>