import { Component } from '@angular/core';

/**
 * Generated class for the SafeOrderPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-safe-order',
  templateUrl: 'safe-order.html',
})
export class SafeOrderPage {

  greenCheckImg: string = "https://images.thenewgutfix.com/checkmark.jpg";
  validatedImg: string = "";
  comodoImg: string = "";

  constructor() {
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad SafeOrderPage');
  }
  
  public closeWindow(): void {
    // Close the window
    window.close();
  }

}
