// Alterncheckout v2.3

import { PayPalNVP } from 'src/pages/paypalnvp/paypal-nvp';

import { AfterViewInit, Component, HostListener, NgZone, OnDestroy, OnInit, Pipe } from '@angular/core';
import { PlatformLocation } from '@angular/common'
import { DomSanitizer } from '@angular/platform-browser';
import { NgForm, FormControl } from '@angular/forms';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';


import { ToastController } from 'src/app/components/ionic-replacements/ToastController';
import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { OrderProviderExtender } from '../../../../providers/order/order-extender';
import { TouchcrApiOrderformProvider } from '../../../../providers/touchcr-api-orderform/touchcr-api-orderform';
import { CartProvider } from '../../../../providers/cart/cart';
import { TouchcrApiRemoteServicesProvider } from 'src/app/providers/touchcr-api-remoteservices';
import { TouchcrApiLeadProvider } from '../../../../providers/touchcr-api-lead/touchcr-api-lead';
import { TouchcrApiCouponProvider } from '../../../../providers/touchcr-api-coupon/touchcr-api-coupon';
import { OrderDetailsProvider } from '../../../../providers/order-details/order-details';
import { TouchcrApiShippingProvider } from '../../../../providers/TouchcrApiShippingProvider';
import { CalculateProvider } from '../../../../providers/calculate/calculate';
import { UrlsProvider } from 'src/app/providers/urls';
import { OrderProvider } from 'src/app/providers/order/order';
import { GeneralProvider } from 'src/app/providers/general';
import { LoggingProvider } from 'src/app/providers/logging';
import { SpinnerProvider } from '../../../../providers/spinner/spinner';
import { TouchcrApiGeneralExtenderProvider } from '../../../../providers/touchcr-api-general/touchcr-api-general-extender';
import { TouchcrApiOrderExtenderProvider } from '../../../../providers/touchcr-api-order/touchcr-api-order-extender';
import { EventStreamerProvider } from 'src/app/providers/event-stream';
import { PaypalPaymentExtenderProvider } from '../../../../providers/paypal/paypal-extender';
import { StateProvider } from 'src/app/providers/state';
import { FunnelSettingsProvider } from 'src/app/providers/funnel-settings';
import { Country } from '../../../../models/country';
import { CHECKOUT_LABEL, PAYMENT_LABEL, SHIPPING_LABEL } from 'src/constants/constants';


const ROUTE =  (window as any)['process_env'].ROUTE;
const IS_PAYPAL_NVP =  (window as any)['process_env'].PAYPAL_NVP_ENABLED;
const TAX_ERROR_MESSAGE = 'Sorry, but we couldn\'t calculate the tax. Please correct your address.';
const ADDRESS_ERROR_MESSAGE = 'Entered address is not valid.';
const COUNTRY_ERROR = 'Sorry, but we don\'t ship to this address';
const SHIP_FROM_COUNTRY =  (window as any)['process_env'].SHIP_FROM_COUNTRY;
const BRAND =  (window as any)['process_env'].BRAND;

@HostListener('unloaded')
@Component({
  selector: 'checkout-common',
  templateUrl: './checkout-common.html',
  styleUrls: ['./checkout-common.scss']
})
export class CheckoutCommon extends PayPalNVP implements OnInit,  AfterViewInit, OnDestroy  {
  // ported in A16
  currentFunnel: any;
  newImage: string = '';
  showProductBox: boolean = false;
  termsAgreed: boolean = false;
  submitOrderCheckBox: boolean = false;
  submitOrderCheckboxImg = "https://cdn.gundrymd.com/images/red-check.png";
  termsURL: string | undefined;
  override countriesList: Array<Country> = [];
  iconArrow: string = 'data:image/gif;base64,R0lGODlhCAAIAJEDAIGBgZiYmGFhYf///yH5BAEAAAMALAAAAAAIAAgAAAISnCenksgckgi0MoDz2+0wZXQFADs=';

  // end port A16

  accountForm: NgForm | any;
  diffAddress: NgForm | any;
  paymentForm: NgForm | any;

  iconPadlock: string = 'data:image/gif;base64,R0lGODlhEAAQAMQfAO/v79GdFee+Su/TbvPdgMXFxevJXeO0OPrhkfz10v354v3328SCDti0If377dSlF+vr68mMEcfHx/rwucySEvvzx9XV1ditGvnsqNy2HMN/Dffnkvnadq2trf///////yH5BAEAAB8ALAAAAAAQABAAAAV24CeKXmmOKNmtrJeqniVLReeing2wq2TjnZ0pV7uphKZOceTJdCCdRibTUHYut6bHoVgkEpUJBhHIZhSYzYYwMAgOiIgZrWa74QzzIr1uvzkaZgl8dn+BJBmDdX4HgFkXCBySk5OHHx4PARQRDBqen5ZDoqIfIQA7';
  iconComodo: string = '';
  linkComodo: string = '';
  iconPaypal: string = 'assets/img/paypalButton.jpg';

  guaranteeImage: string = '';
  guaranteeTitle: string = '';
  guaranteeText: string = '';

  isPayPalExecuted: boolean = false;
  isCouponChecked: boolean = false;
  shouldCreateLeadOnFulfillment: boolean = false;
  isRememberInfoChecked: boolean = false;
  isSelectedMainCheckBox: boolean = false;
  isSelectedMainCheckBoxKey: any = '';

  rejectOrder: boolean = false;
  rejectOrderMessage: any = {
    header: '',
    body: '',
  };

  gundryLogo: string = '';
  headerText: string = '';
  shoppingCart: any = {};
  brandFooterObj: any = {};
  commentSectionTxt1: string = '';
  commentSectionTxt2: string = '';
  commentSectionTxt3: string = '';
  showOnOrderForm: boolean = false;
  showHeaderLogo: boolean = true;
  showHeaderContent: boolean = true;
  noticeOn: boolean = false;
  noticeText: string = '';

  tabs: Array<any> = [];
  currentYear: number = new Date().getFullYear();

  errorBoxId: string = 'orderForm-validation-error-box';
  isStorageAvailable: boolean = true;

  override textPattern: RegExp = /[,.[\/A-Za-z0-9 -]*/ ; // TOUC-1036
  addressPattern: RegExp = /[*#\+,.%()^:;=?[\/A-Za-z0-9 -]*/ ; // TOUC-1036

  
  // TOUC-14710
  braintreeEnabled:boolean = false;
  braintreeDropInEnabled: boolean = true;
  braintreeClientToken: any;
  braintreeInstance: any = null;
  braintreeDropinValid: boolean = false;
  braintreeToast: any;
  paypalCheckoutInstance: any = null;
  paypalpaymentId: any = null;
  braintreeCurrencyConversion: number = 1; // TOUC-14710
  braintreeCurrencConversionInitial: number = 1; // TOUC-14710ß
  currencySymbol: string = "$"; // TOUC-14710
  currency: string = 'USD'; // TOUC-14710
  officialCurrency: string = 'USD'; // TOUC-18106
  braintreeSplitTesting: boolean = false; // TOUC-14710
  braintreeUpdatingCalculation: boolean = false; // TOUC-14710
  braintreePayPalTokenizedPayment: any = null; // TOUC-14710
  braintreeHidePayPalButton: boolean = true; // TOUC-14710
  braintreePayPalEmail: any = null; // TOUC-14710
  braintreeNonce: any = null; // TOUC-14710
  braintreeTokenizePaymentData: any = null; // TOUC-14710
  braintreeSubtotalAmount: number = 0; // TOUC-14710
  braintreeDropinPayload: any = null; // TOUC-14710
  braintreePayPalBillingState: any = null; // TOUC-14710
  braintreePayPalShippingState: any = null; // TOUC-14710
  braintreeDefaultCountry: any = null;
  isBraintreeLoading: boolean = false;
  braintreeCardFocusSent: boolean = false;
  braintreeMessageToUser: any = null;
  useNewBraintreeStyles: boolean = false; // DEV-20535 - after getting the request to style each BT page differently
  currencyConversion: number = 1; // TOUC-14710
  braintreeCountryLabel: boolean = false;
  euSpecified: boolean = false;
  braintreeHostedFieldsValid: boolean = false;
  braintreeHostedFieldsInstance: any = null;
  braintreeeHostedFieldsEvent: any = null;
  braintreeHideShipping: boolean = false;
  showSubtotal: boolean = false;
  hideRegularPrice: boolean = false;
  hideSavings: boolean = false;
  isMobile: boolean = false;
  braintreeCountrySelector: boolean = false; // TODO redo this
  braintreeEUCountries =[ "Austria", "Belgium", "Bulgaria", "Croatia" , "Cyprus" ,"Czech Republic","Denmark","Estonia","Finland","France","Germany","Greece","Hungary","Ireland","Italy","Latvia","Lithuania","Luxembourg","Malta","The Netherlands","Poland","Portugal","Romania","Slovakia","Slovenia","Spain","Sweden"];
  braintreeCurrencies = [ "USD", "GBP", "EUR", "MXN", "CAD", "AUD", "HKD", "JPY"];
  braintreeCurrencySymbols = [ "$", "£", "€", "MEX$", "C$", "A$", "HK$", "¥"];
  braintreeCurrencyRates = [ 1, 0.83, 0.95, 19.5, 1.38, 1.54, 7.90, 150.00];
  braintreeLanguageTranslation: boolean = false;
  braintreeTaxEstimate: number = 0;
  braintreeSubscriptionSupported: boolean = false;

  constructor(
    public override navCtrl: NavControllerExt,
    public override sanitizer: DomSanitizer,
    public override tcrApiOrderform: TouchcrApiOrderformProvider,
    public override cart: CartProvider,
    public override tcrApi: TouchcrApiGeneralExtenderProvider,
    public override tcrApiOrder: TouchcrApiOrderExtenderProvider,
    public override tcrApiRemoteServices: TouchcrApiRemoteServicesProvider,
    public override toastCtrl: ToastController,
    public override eventStreamer: EventStreamerProvider,
    public override tcrApiLead: TouchcrApiLeadProvider,
    public override tcrApiCoupon: TouchcrApiCouponProvider,
    public override orderDetails: OrderDetailsProvider,
    public override paypalPayment: PaypalPaymentExtenderProvider,
    public override ngZone: NgZone,
    public override shippingProvider: TouchcrApiShippingProvider,
    public override calculateHelper: CalculateProvider,
    public override urlsHelper: UrlsProvider,
    public override orderHelper: OrderProvider,
    public override orderHelperExtender: OrderProviderExtender,
    public override stateProvider: StateProvider,
    public override general: GeneralProvider,
    public override funnelProvider: FunnelSettingsProvider,
    public override spinner: SpinnerProvider,
    public location: PlatformLocation,
    public loggingProvider: LoggingProvider,  
    public override router: Router,
    public override storage?: Storage,
  ) {
    super(
      navCtrl,
      sanitizer,
      tcrApiOrderform,
      cart,
      tcrApi,
      tcrApiOrder,
      tcrApiRemoteServices,
      toastCtrl,
      eventStreamer,
      tcrApiLead,
      tcrApiCoupon,
      orderDetails,
      paypalPayment,
      ngZone,
      shippingProvider,
      calculateHelper,
      urlsHelper,
      orderHelper,
      orderHelperExtender,
      stateProvider,
      general,
      funnelProvider,
      spinner,
      router,
    );
    try {
      let one = this.router.config.filter(
        function (config) { return config.path == document.location.pathname.substring(1); }
      );
      console.log('[GH] checkoutFeature: ' +  JSON.stringify(one), one);
      if(one && one.length == 1 && one[0].data) {
        let rate = 1;
        if (one[0].data.checkoutFeature == 'showProductBox') this.showProductBox = true;
        if (one[0].data.braintreeSubscriptionSupported) this.braintreeSubscriptionSupported = one[0].data.braintreeSubscriptionSupported;

        if (this.braintreeReady()) {
          if (one[0].data.currency) this.currency = one[0].data.currency;
          if (one[0].data.currencySymbol) this.currencySymbol = one[0].data.currencySymbol;
          if (one[0].data.officialCurrency) this.officialCurrency = one[0].data.officialCurrency;
          if (one[0].data.braintreeDefaultCountry) this.braintreeDefaultCountry = one[0].data.braintreeDefaultCountry;
          if (one[0].data.braintreeEnabled) this.braintreeEnabled = one[0].data.braintreeEnabled;
          if (one[0].data.braintreeCountryLabel) this.braintreeCountryLabel = one[0].data.braintreeCountryLabel;
          if (one[0].data.euSpecified) this.euSpecified = one[0].data.euSpecified;
          if (one[0].data.braintreeCountrySelector) this.braintreeCountrySelector = one[0].data.braintreeCountrySelector;
          if (one[0].data.braintreeLanguageTranslation) this.braintreeLanguageTranslation = one[0].data.braintreeLanguageTranslation;
          if (one[0].data.braintreeTaxEstimate) this.braintreeTaxEstimate = one[0].data.braintreeTaxEstimate;
          if (one[0].data.showSubtotal) this.showSubtotal = one[0].data.showSubtotal;
          if (this.showSubtotal) {
            this.hideRegularPrice = true;
            this.hideSavings = true;
          }
          if (!one[0].data.braintreeDropInEnabled) this.braintreeDropInEnabled = one[0].data.braintreeDropInEnabled;
          if (one[0].data.braintreeCurrencyConversion) {
            try {
              this.braintreeCurrencyConversion = one[0].data.braintreeCurrencyConversion;
              this.braintreeCurrencConversionInitial = this.braintreeCurrencyConversion;
              rate = parseFloat(this.convertRateFloor(one[0].data.braintreeCurrencyConversion));
            } catch (e) {
              console.log('error converting rate', e);
              this.braintreeCurrencyConversion = 1;
              this.braintreeCurrencConversionInitial = 1;
            }
          }
          if (this.braintreeEnabled) {
  //          if (!this.braintreeReady() || this.isSubscription) {
            if (!this.braintreeReady() || (this.isSubscription && !this.braintreeSubscriptionSupported)) {
              this.braintreeDisplayHandler();
              console.log('[GH] Braintree not ready to run');
            } else {
              sessionStorage.setItem('officialCurrency', this.officialCurrency);
              this.setupBraintreeClient().then(() => {
                console.log('[GH] braintree client setup complete');
                this.braintreeSplitTesting = true;  // TOUC-14710 enable the braintree feature on this instance of checkout-common
              }).catch((e) => {
                console.log('[GH] braintree client setup exception ', e);
                this.tearDownBraintree();
              });
              console.log('[GH] braintree currency conversion rate', rate);
            }
          }
        }
      }
    } catch (e) {
      console.log('error in app component', e);
    }
    this.storage = new Storage({
      name: '__ghdb'
      });
    this.storage.create();   
    this.isMobile = this.general.isMobile();

    //Check if storage is unavailable - TCR
    try {
      sessionStorage.setItem('StorageTest', '');
      sessionStorage.removeItem('StorageTest');
    } catch (e) {
      this.isStorageAvailable = false;
    }
  }

  termsAcceptedCheckbox(){
    this.termsAgreed = !this.termsAgreed;
  }
  public navigateToPageByGenericUrl(url: string, linkTarget: string, linkOptions: string): boolean {

    if(url.startsWith("http")) {
      window.open(url, linkTarget, linkOptions);
    } else {
      const currentSessionId = this.funnelProvider.getSessionId();
      const pathName = url;
      let newQueryParams = this.urlsHelper.addParamToQuery(
        `${this.getSearch()}`,
        'sessionid',
        currentSessionId
      );
      const nextUrl = `${window.location.origin}/${
        pathName
      }${newQueryParams}`;
      window.open(nextUrl, linkTarget, linkOptions);
    }
    return false;
  }
  willLeave() {
    // TOUC-2763-DrMarty
    this.spinner.disable();
  }
  public getPath () {
    return window.location.pathname ; // [TOUC-5682]
  }
  public getHost () {
    return window.location.hostname ; // [TOUC-5682]
  }
  public getSearch () {
    return window.location.search ; // [TOUC-5682]
  }
  /* Use 300ms timeout to ensure query parameters are available
     and to provide correct funnel search
  */
  public ngAfterViewInit(): void {
    (window as any)['facebookAutofillDisabled']=true;
    window.addEventListener('mousedown', (e) => {
      (window as any)['facebookAutofillDisabled']=false;
    });

    //super.ngOnInit();
    // Subscribe to router events
    setTimeout(() => {
      console.log('[GH] Parameters',document.location.search);
      this.onEnter();
      this.didLoad();

     // console.log('started with this.paymentForm', this.paymentForm);
    }, 0);
    /*    this.router.events
            .subscribe((event:any) => {
              console.log('router event', event)
              // Your logic here, similar to ionViewWillEnter
              this.onEnter();
            });*/
  }
  ngOnDestroy() {
    this.willLeave();
  }
  async onEnter() {
    setTimeout(() => {
      this.init();
    }, 0);
    // [TOUC-5969]
    try {
      if (this.urlsHelper.getParameterFromUrl({ url: document.location.search, parameter: 'coupon' })) {
        this.couponCode = this.urlsHelper.getParameterFromUrl({ url: `${this.getSearch()}`, parameter: 'coupon' });;
        setTimeout(async () => {
          console.log("[GH] attempting to auto apply coupon ");
          (<HTMLInputElement>document.getElementById("order-form-coupon-input")).focus();
          (<HTMLInputElement>document.getElementById("order-form-coupon-input")).value = this.urlsHelper.getParameterFromUrl({ url: `${this.getSearch()}`, parameter: 'coupon' });
          this.getCoupon();
        }, 300);
      }
    } catch (e) {
      console.log("[GH] can't auto apply coupon " + e);
    }
/*     try {
      sessionStorage.setItem('timer_checkout_ionviewwillenter', new Date().getTime().toString());
    } catch (e) {
      console.log('error setting up '+e);
    } */
  }

  async init() {
/*     try {
      sessionStorage.setItem('timer_checkout_init_start', new Date().getTime().toString());
    } catch (e) {
      console.log('error setting up '+e);
    } */

    /*
    try {
      let braintreeMonitor = setInterval(() => {
        if (this.accountForm.touched) {
          this.emitCustomEvent('AccountFormTouched', 'monitored');
          clearInterval(braintreeMonitor);
        }
      }, 100);
    } catch (e) {
      console.log("[GH] can't monitor braintree " + e);
    }
    try {
      let braintreeMonitor = setInterval(() => {
        if (this.accountForm.valid) {
          this.emitCustomEvent('AccountFormValid', 'monitored');
          clearInterval(braintreeMonitor);
        }
      }, 100);
    } catch (e) {
      console.log("[GH] can't monitor braintree " + e);
    }

    try {
      let braintreeMonitor = setInterval(() => {
        if (this.paymentForm.touched) {
          this.emitCustomEvent( 'PaymentFormTouched', 'Cybersource');
          clearInterval(braintreeMonitor);
        }
      }, 100);
    } catch (e) {
      console.log("[GH] can't monitor braintree " + e);
    }
    try {
      let braintreeMonitor = setInterval(() => {
        if (this.paymentForm.valid) {
          this.emitCustomEvent('PaymentFormValid', 'Cybersource');
          clearInterval(braintreeMonitor);
        }
      }, 100);
    } catch (e) {
      console.log("[GH] can't monitor braintree " + e);
    }
    */

    //this.spinner.enable('Loading page.<br> Please Wait');
    this.populatePageData();

    // Logic regarding "Remember my information" checkbox. Sets key for saving flag into local storage.
    if (this.isStorageAvailable) {
      this.isSelectedMainCheckBoxKey = localStorage.getItem('checkBoxStatus');
    } else {
      this.isSelectedMainCheckBoxKey = (window as any)['gh']['checkBoxStatus'];
    }

    if (this.isSelectedMainCheckBoxKey === 'true') {
      this.isRememberInfoChecked = true;
      let accountForm;

      if (this.isStorageAvailable) {
        accountForm = localStorage.getItem('accountForm');
      } else {
        accountForm = (window as any)['gh']['accountForm'];
      }

      if (accountForm) {
        const accountFormObj = JSON.parse(accountForm);
        await this.stateProvider.setAccountDetails(accountFormObj);
      }

    } else if (this.isSelectedMainCheckBoxKey === 'false') {
      this.isRememberInfoChecked = false;

      if (this.isStorageAvailable) {
        localStorage.removeItem('checkBoxStatus');
      } else {
        delete (window as any)['gh']['checkBoxStatus'];
      }
    }

    if (this.slider) this.slider.lockSwipes(true);
    this.state = await this.stateProvider.get();
    this.isShippingAvailable = this.shippingProvider.isShippingAvailable();

    if (this.urlParameters['paypal']) {
      this.spinner.disable();
      if (this.urlParameters['action'] === 'execute') {
        this.isPayPalExecuted = true;
        this.executePayPalPayment(this.urlParameters, this.state.taxData);
      } else if (this.urlParameters['action'] === 'cancel') {
        try {
          const result: any = await this.paypalPayment.cancelPayment(
            this.state.orderDetails.id
          );

          if (result.message === 'success') {
            await this.toastCtrl
              .create({
                message: `ORDER CANCELLED!`,
                duration: 2000,
                position: 'top'
              })
              //.present();
          }
        } catch (e) {
          await this.toastCtrl
            .create({
              message: `Error on cancelling order!`,
              duration: 3000,
              position: 'top'
            })
            //.present();
        }
      }
    } else if (this.urlParameters['paypalsubscription']) {
      this.spinner.disable();
      if (this.urlParameters['action'] === 'execute') {
        this.isPayPalExecuted = true;
        this.executePayPalSubscription(this.urlParameters, this.state.taxData);
      } else if (this.urlParameters['action'] === 'cancel') {
        await this.cancelPayPalSubscription(this.state.orderDetails.order.id);
      }
    } else if (this.urlParameters['paypalnvp']) {
      if (this.urlParameters['action'] === 'execute') {
        this.isPayPalBillingAgreementApproved = true;
        this.isPayPalExecuted = true;
        this.shouldCreateLeadOnFulfillment = true;
        await this.populateData();

        this.guest = await this.getPayPalPayerDetailsNVP(this.urlParameters);
        this.guest.state = (this.countries as any)[this.guest.country as any].states[this.guest.state as any];
        this.emailToConfirm = this.guest.email;

        (this.address.postal as any) = this.guest.zipPostalCode;
        (this.address.country as any) = this.guest.country;
        (this.address.city as any) = this.guest.city;
        this.address.state = this.guest.state;
        (this.address.street as any) = this.guest.street;

        this.checkCountry(false);

        this.spinner.disable();
        this.calculate(true);
      } else if (this.urlParameters['action'] === 'cancel') {
        this.urlsHelper.clearPayPalParams();
      }
    }
    if (!this.isPayPalExecuted) {
      await this.populateData();
      this.spinner.disable();
    }

    if (this.state && this.state.accountDetails && this.countries && (this.countries as any)[this.state.accountDetails.country as any]) {
      this.statesList = this.getStates((this.countries as any)[this.state.accountDetails.country as any] as Country);
    }

    if (this.isSelectedCB2 && this.shippingAddress && this.shippingAddress.country) {
      this.differentAddressStatesList = this.getStates((this.countries as any)[this.shippingAddress.country]);
    }

    if (this.transactionFeeEnabled) this.subscribeToCardOptions(this.paymentForm);
    this.calculateTransactionFee();
/*     try {
      sessionStorage.setItem('timer_checkout_init_complete', new Date().getTime().toString());
    } catch (e) {
      console.log('error setting up '+e);
    } */
  }

  getLabelCheckout(label: string) {
    try {
      if(this.braintreeDefaultCountry && typeof CHECKOUT_LABEL[this.braintreeDefaultCountry] == 'object' && typeof CHECKOUT_LABEL[this.braintreeDefaultCountry][label] == 'string') {
       return CHECKOUT_LABEL[this.braintreeDefaultCountry][label];
      }
    } catch (e) {
      console.log('err')
    }
    return CHECKOUT_LABEL['United States'][label];
  }
  getLabelShipping(label: string) {
    try {
      if(this.braintreeDefaultCountry && typeof SHIPPING_LABEL[this.braintreeDefaultCountry] == 'object' && typeof SHIPPING_LABEL[this.braintreeDefaultCountry][label] == 'string') {
       return SHIPPING_LABEL[this.braintreeDefaultCountry][label];
      }
    } catch (e) {
      console.log('err')
    }
    return SHIPPING_LABEL['United States'][label];
  }
  getLabelPayment(label: string) {
    try {
      if(this.braintreeDefaultCountry && typeof PAYMENT_LABEL[this.braintreeDefaultCountry] == 'object' && typeof PAYMENT_LABEL[this.braintreeDefaultCountry][label] == 'string') {
            return PAYMENT_LABEL[this.braintreeDefaultCountry][label];
      }
    } catch (e) {
      console.log('err')
    }
    return PAYMENT_LABEL['United States'][label];
  }
  getLabel(label: string) {
    try {
      if(this.braintreeDefaultCountry && typeof CHECKOUT_LABEL[this.braintreeDefaultCountry] == 'object') return CHECKOUT_LABEL[this.braintreeDefaultCountry][label];
    } catch (e) {
    }
    return CHECKOUT_LABEL['United States'][label];
  }
  async populateData() {
    const funnel: any = await this.funnelProvider.getOrderFormByCurrentUrl();
    const newFunnel = await this.funnelProvider.getMainFunnelByCurrentUrl();
    if (newFunnel) {
      this.newImage = newFunnel.defaultFunnels[1].upsell.product.image.length > 1 ? newFunnel.defaultFunnels[1].upsell.product.image.filter((image: any) => image.displayOrder === 2)[0].imageUrl :  newFunnel.defaultFunnels[1].upsell.product.image[0].imageUrl;
    }
    if (funnel) {
      console.log('funnel', funnel);
      this.funnel = funnel;
      this.currentFunnel = this.funnel;
    } else if (this.state && this.state.funnel) {
      this.funnel = this.state.funnel;
      this.currentFunnel = this.funnel;
      try {
        this.bumpOffers = await this.tcrApiOrderform
          .getBumpOffers(ROUTE)
          .toPromise();
        this.initBumpOffersTimers();
      } catch (e) {
        console.log(e);
      }
    } else {
      // As Checkout form is always set as a 1st step of a destination then redirection to
      // the first step will loop navigation
      const params: any = await this.funnelProvider.getPageByStep('first');
      if (!params) {
        return this.navCtrl.setRootExt('Home', { name: 'Home' });
      }
      const { component } = params;     
      return this.navCtrl.pushExt(component.name, { name: component.name }).then(() => {
        return this.navCtrl.clearStack();
      });
      
    }

    let countries = await this.tcrApi.getCountries().toPromise();
    if (countries.length === 0) {
      console.log('getting a backup static copy of the country state payload');
      countries = await this.tcrApi.getCountriesDefault().toPromise();
    }
    this.parseCountriesInfo(countries);
    await this.parseFunnel();
    if(typeof this.funnel.subscriptionPrice  !== 'undefined' && this.funnel.subscriptionPrice > 0 && typeof this.funnel.name != 'undefined' && this.funnel.name.indexOf('Bio Complete') > -1){
      this.commentSectionTxt3 = `
      This order contains a subscription, you agree to receive a shipment of Bio Compplete 3 each month. You are authorizing us to charge your credit card monthly, matching the package you have
      selected. You can stop shipments at any time by calling our customer support team at 1-800-852-0477. Agents are standing by 7 days a week, Monday-Friday, 5:00 a.m. to 7:00 p.m. PST, and
      Saturday-Sunday, 6:00 a.m. to 4:30 p.m. PST. 
    `;
    if(typeof this.funnel.name != 'undefined' && this.funnel.name.indexOf('Bio Complete') > -1 )
    this.commentSectionTxt3 += `
    <a id="biolink" class='blue_link' " >(All terms
      and conditions apply)</a>`
      ;
      setTimeout(() => {
        try {
          (<HTMLAnchorElement>document.querySelector('.blue_link')).onclick = () => {
            window.open('https://cdn.gundrymd.com/continuitiy-terms/bio-complete-3-terms.html', '_blank', 'width=500,height=500');
          }
        } catch (e) {
          console.log('error setting up '+e);
        }
      }, 1000);
    }
    if (this.braintreeEnabled && (this.accountForm.valid || (this.diffAddress && this.diffAddress.valid))) {
      this.braintreeCustomLoadingMessage();
    }
    this.calculate(true);
   return;
  }  
  braintreeCustomCardLoadingMessage() {
    /*
    let loading = this.loadingCtrl.create({
      spinner: 'hide',
      content: `
      <div class="custom-spinner-container">
        <div class="custom-spinner-box">
          <div class="braintreeloader braintreesticky braintreecard"></div>Please wait...</div>
      </div>`,
      duration: 3690
    });
    loading//.present();
    */
   console.log('braintree loading 1 goes here')
  }
  braintreeCustomLoadingMessage() {
    /*
    let loading = this.loadingCtrl.create({
      spinner: 'hide',
      content: `
      <div class="custom-spinner-container">
        <div class="custom-spinner-box">
          <div class="braintreeloader braintreesticky"></div>Please wait...</div>
      </div>`,
      duration: 3690
    });
    loading//.present();
    */
   
   //this.braintreeHideShipping = true;
   //this.braintreeHidePayPalButton = true;
    console.log('[GH] braintree paypal button pressed');
    (window as any)['facebookAutofillDisabled'] = true;

  }

  public changeBraintreeSettings(settings: any) {
    console.log('[GH] settings in changeBraintreeSettings', settings);
    console.log('[GH] braintreeDefaultCountry', this.braintreeDefaultCountry);
    // expose the country and currency to the braintree instance
    try {
      if (settings.type == 'change') {
        console.log('[GH] change ' + settings.srcElement.selectedIndex + '::' + settings.srcElement[settings.srcElement.selectedIndex].value, settings);
        if(settings.srcElement[settings.srcElement.selectedIndex].value && settings.srcElement[settings.srcElement.selectedIndex].value != '') this.braintreeDefaultCountry = settings.srcElement[settings.srcElement.selectedIndex].value;
      }
    }
    catch (e) {
      console.log('[GH] error setting up ' + e);
    }
    if(settings.braintreeDefaultCountry) {
      console.log('[GH] settings.braintreeDefaultCountry', settings.braintreeDefaultCountry);
      this.braintreeDefaultCountry = settings.braintreeDefaultCountry;
      this.braintreeCountryConfig();
    }   
  }

  public changeBraintreeCurrencySettings(settings: any) {
    console.log('[GH] settings in changeBraintreeSettings', settings);
    console.log('[GH] braintreeDefaultCountry', this.braintreeDefaultCountry);
    try {
      if (settings.type == 'change') {
        console.log('[GH] change ' + settings.srcElement.selectedIndex + '::' + settings.srcElement[settings.srcElement.selectedIndex].value, settings);
        if(settings.srcElement[settings.srcElement.selectedIndex].value && settings.srcElement[settings.srcElement.selectedIndex].value != '') {
          this.currency = settings.srcElement[settings.srcElement.selectedIndex].value;
          this.officialCurrency = this.currency;
          this.braintreeCurrencies.forEach(element => {
            if(element == this.currency) {
              this.braintreeCurrencyConversion = this.braintreeCurrencyRates[this.braintreeCurrencies.indexOf(element)];
              this.braintreeCurrencConversionInitial = this.braintreeCurrencyConversion;
              this.currencySymbol = this.braintreeCurrencySymbols[this.braintreeCurrencies.indexOf(element)];
              console.log('[GH] default rate is ' + this.braintreeCurrencyConversion);
              sessionStorage.setItem('currency', this.currency);
              sessionStorage.setItem('currencySymbol', this.currencySymbol);
              sessionStorage.setItem('officialCurrency', this.officialCurrency);
              this.braintreeHostedFieldsInstance.teardown(function (teardownErr:any) {
                if (teardownErr) {
                  console.error('[GH] Could not tear down Hosted Fields!');
                } else {
                  console.info('[GH] Hosted Fields has been torn down!');
                }
              });
              this.setupBraintreeClient();
            }            
          });
        }
      }
    }
    catch (e) {
      console.log('[GH] error setting up ' + e);
    }
    if(settings.currency && settings.currencySymbol && settings.braintreeCurrencyConversion) {
      console.log('[GH] settings.currency', settings.currency);
      // add rules about rate transformations
      this.currency = settings.currency;
      this.currencySymbol = settings.currencySymbol;
      this.braintreeCurrencyConversion = settings.braintreeCurrencyConversion;
      // TODO: add to confiruations for most recent currency conversion rate to use here instead of requiring the developer to pass that value into the function
    }
    
  }
  override scrollToErrorBox() {
    let errorBox = document.getElementById(this.errorBoxId);
    if (errorBox) {
      errorBox.scrollIntoView({ behavior: 'smooth' });
    }
  }

  emitCustomEvent(event_type: any, event_payload: any) {
    let errors = '';
    try {
      if (this.accountForm.form.valid && (this.paymentForm.form.valid || this.braintreeDropinValid) && (this.isSelectedCB2 && this.diffAddress.form.valid ) ) {
           console.log('send evnet immediately before building the errors objects', errors)
           this.sendCustomEvent(event_type, event_payload, errors);
      } else {
        if(this.isSelectedCB2) {
          if(this.diffAddress && this.diffAddress.controls) console.log('diffAddress: valid:' + this.diffAddress.form.valid, this.diffAddress); 
          console.log('CB2:' + this.isSelectedCB2);
        }
        setTimeout(() => {
          try {
            if (<HTMLDivElement>document.querySelector('.errors__list')) {
              errors = (<HTMLDivElement>document.querySelector('.errors__list')).innerText
                .replace(/ is a required field.\n/g, ', ')
                .replace(/\n/g, ' ');
  
              // TOUC-945 capture more information
              // First Name, Last Name, Phone, Email, Confirm Email, Address, City, ZIP/Postal Code, State, 
              // First Name contains invalid characters. Ship to Name, Address, City, ZIP/Postal Code, State,
              // Name on Card, Card Number, Expiration Month, Expiration Year, CVV2 is a required field.
              console.log('errors:', errors);
              errors = 
              errors.replace('First Name contains invalid characters',  this.accountForm.form.value.firstName ? "First Name:"+ this.accountForm.form.value.firstName + " contains invalid characters": "")
              .replace('Last Name contains invalid characters',  this.accountForm.form.value.lastName ? "Last Name:"+ this.accountForm.form.value.lastName  + " contains invalid characters": "")
              .replace('Phone contains invalid characters',  this.accountForm.form.value.phone ? "Phone:"+ this.accountForm.form.value.phone  + " contains invalid characters": "")
              .replace('Confirm Email address is invalid',  this.accountForm.form.value.confirmEmail ? "Confirm Email address:"+ this.accountForm.form.value.confirmEmail  + " is invalid": "")
              .replace('Email address is invalid',  this.accountForm.form.value.email ? "Email address:"+ this.accountForm.form.value.email  + " is invalid": "")
              .replace('Address contains invalid characters',  this.accountForm.form.value.street ? "Address:"+ this.accountForm.form.value.street  + " contains invalid characters": "")
              .replace('City contains invalid characters',  this.accountForm.form.value.city ? "City:"+ this.accountForm.form.value.city  + " contains invalid characters": "")
              .replace('Zip/Postal Code contains invalid characters',  this.accountForm.form.value.zipPostalCode ? "Zip/Postal Code:"+ this.accountForm.form.value.zipPostalCode  + " contains invalid characters": "");
              if(typeof this.diffAddress != 'undefined' ) {
                 errors = 
                 errors.replace('Ship to Name contains invalid characters',  this.diffAddress.form.value.nameToShip ? "Ship to Name:"+ this.diffAddress.form.value.nameToShip  + " contains invalid characters": "")
                 .replace('Shipping Address contains invalid characters',  this.diffAddress.form.value.shipAddress ? "Shipping Address:"+ this.diffAddress.form.value.shipAddress  + " contains invalid characters": "")
                 .replace('Shipping City contains invalid characters',  this.diffAddress.form.value.shipCity ? "Shipping City:"+ this.diffAddress.form.value.shipCity  + " contains invalid characters": "")
                 .replace('Shipping ZIP/Postal Code contains invalid characters',  this.diffAddress.form.value.shipPostal ? "Shipping Zip/Postal Code:"+ this.diffAddress.form.value.shipPostal  + " contains invalid characters": "")
                 .substring(0, 200);
              }
              console.log('emitCustomEvent:', errors);
            }
            // check if there's a braintree error message
            if(this.braintreeMessageToUser) {
              errors = errors + " " + this.braintreeMessageToUser;
              this.braintreeMessageToUser = null;
            }
            if(!this.braintreeDropinValid) {
              errors+= " Braintree Dropin is not valid";
            }
            this.sendCustomEvent(event_type, event_payload, errors);
          
          } catch(e) {
            console.log("error gathering validation information " + e);
          }
        }, 300);
      } 
    } catch (e) {
      console.log('TOUC-774 error1:', e);
    }
  }

  sendCustomEvent(event_type: any, event_payload: any, errors: any) {
    try {
      // TOUC-774
      this.funnelProvider.sendCustomStats(
        event_type,
        { function: event_payload, errors: errors }
      );
    } catch (e) {
      console.log('TOUC-774 error2:', e);
    }
  }

  onPayWithDifferentAddressClicked(
    accountForm: NgForm,
    paymentForm: NgForm,
    diffAddress: NgForm
  ) {
    console.log('onPayWithDifferentAddressClicked paymentForm', paymentForm)
    this.spinner.enable('Please Wait');
    this.emitCustomEvent('submitOrder', 'onPayWithDifferentAddressClicked'); // TOUC-812

    this.errorMessage = '';
    this.rejectOrder = false;
    this.submittedAccountForm = true;
    this.submittedPaymentForm = true;
    this.couponValidation = true;

    if (
      accountForm.invalid || ((this.guest.state as any).length === 0 && this.statesList.length > 0) ||
      paymentForm.invalid ||
      (this.isSelectedCB2 &&
        (diffAddress.invalid || ((this.shippingAddress.state as any).length === 0 && this.differentAddressStatesList.length > 0)))
    ) {
      this.spinner.disable();
      this.scrollToErrorBox();
      console.log('onPayWithDifferentAddressClicked paymentForm', paymentForm)

    } else {
      if (!this.isTaxCalculated) {
        this.spinner.disable();
        this.showTaxErrorToast(TAX_ERROR_MESSAGE);
        try { // TOUC-891
          const taxAddressMessage = 
          this.guest.street + ' ' +
          this.guest.city + ' ' +
          this.guest.state + ' ' +
          this.guest.zipPostalCode + ' ' +
          this.guest.country;
          this.sendCustomEvent('Checkout Tax 0', window.location.href.substring(0,200) , taxAddressMessage.substring(0,200) ); //TOUC-860
        } catch (e) {
          console.log("error logging tax message event: " + e);
        }
        return;
      }

      if (this.isSelectedCB2) {
        this.guest.shipToName = this.shipToName;
        this.guest.shippingAddress = this.shippingAddress;
      }

      const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.address.country);
      const body = this.orderHelperExtender.createBody({
        coupon: this.coupon,
        couponDiscount: +(+this.couponDiscount).toFixed(2),
        totalPrice: +(+this.totalPrice).toFixed(2),
        guest: this.guest,
        totalTaxforBody: +(+this.totalTaxforBody).toFixed(2),
        dataForTax: this.taxData.dataForTax,
        isSubscription: this.isSubscription,
        shippingCost: +(+this.shippingCost).toFixed(2),
        calculatedShippingCost: +(+this.calculatedShippingCost).toFixed(2),
        customerIp: (window as any)['gh'].ip,
        bumpOffers: this.bumpOffersInCart,
        cardDetails: this.cardDetails,
        funnelOffersData: this.funnelOffersData,
        orderFormProduct: this.returnSelectedFunnel(
          this.orderFormProduct,
          this.selectedProduct
        ),
        subscriptionTax: +(+this.subscriptionTax).toFixed(2),
        shippingType: this.shippingProvider.isShippingAvailable()
          ? this.selectedDeliveryDay
          : '',
        totalSubscriptionPrice: +(+this.totalSubscriptionPrice).toFixed(2),
        country: country,
      });

      this.payProductLogic(body, 'Standard', null);
    }
  }

  dollarSign() {

  }
  getCookie(cname:any) {
    try {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
    } catch (e) {
      
    }
    return "";
  }
  getOrderCount(result: any) {
    let content = {}
    let xhr = new XMLHttpRequest();
    let url = "/alternproxy/getOrderCount?accountid=" + result.accountId + "&orderid=" + result.id;
    xhr.open("GET", url, false);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.onload = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        content = xhr.response;
        if(typeof xhr.response == 'string') content = JSON.parse(xhr.response);
        this.funnelProvider.sendCustomStats("CMS - XHR Success",{ "message": `Success: ${JSON.stringify(content).length}` });
        if (Object.keys(content).length === 0) {
          throw new Error("Error getting upsell dynamic content")
        }
        return content;
      }
      return '';
    };

    xhr.onerror = (error) => {
      console.log(`XHR Error: ${error}`);
      this.funnelProvider.sendCustomStats(
        "CMS - XHR Error",
        { "message": `(${this.urlsHelper.getNavigator()}) ${error}` }
      );

      throw new Error(`Error: ${error}`)
    }

    xhr.send();
    return content;
  }
  ghTracking(result:any) {
    try { // TOUC-1896
      if( !(window as any)['$']) {
        (window as any)['$'] = this.dollarSign;
        console.log('no dollarsign', (window as any)['$'] );
      }
      if( !(window as any)['$'] || !(window as any)['$'].parseJSON) {
        (window as any)['$'].parseJSON = JSON.parse;
        console.log('no parseJSON', (window as any)['$'] );
      }
      if( !(window as any)['$'] || !(window as any)['$'].cookie) {
        (window as any)['$'].cookie = this.getCookie;
        console.log('no cookie', (window as any)['$'] );
      }
      console.log('attempting to set ctotal or cid cookies: ' , result);
      if (result.name) {
        // TOUC-2562
        (window as any)['USER_EMAIL'] = this.guest.email;
        (window as any)['CONVERSION_ID'] = result.name;
        console.log("ghtrack result.name conversion happened: " + (window as any)['USER_EMAIL'] + '::' + (window as any)['CONVERSION_ID']);      
        let jtrack = JSON.stringify( {orderId: result.name, grandTotal: result.total} );
        console.log("tracking result.name: "+ jtrack , result);
        document.cookie = 'ctotal=' + result.total + '; expires=0; path=/';
        document.cookie = 'cid=' + result.name + '; expires=0; path=/';
        document.cookie = 'info=' + jtrack + '; expires=0; path=/';

        try {
          let countdata: any = this.getOrderCount(result);
          console.log("tracking countdata: ", countdata);
          let eventGtm =  {eventType: 'NewOrExisting', existingOrdercount: countdata.count, newOrExisting: countdata.count > 0 ? 'Existing' : 'New', accountid: result.accountId, orderid: result.id, email: this.guest.email, total: result.total, order: result.name};
          // new or existing customer DEV-32592
          if ((window as any)['dataLayer']) {
            (window as any)['dataLayer'].push(eventGtm);
          } else {
            (window as any)['dataLayer'] = [eventGtm];
          }
        } catch (e) {
          console.log("error getting order count: " + e);
        }


      } else if (result.order) {
        // TOUC-2562
        (window as any)['USER_EMAIL'] = this.guest.email;
        (window as any)['CONVERSION_ID'] = result.order.name;
        console.log("ghtrack conversion happened: " + (window as any)['USER_EMAIL'] + '::' + (window as any)['CONVERSION_ID']);

        let jtrack = JSON.stringify( {orderId: result.order.name, grandTotal: result.order.total} );
        console.log("ghtrack result.order tracking result.order: " +  jtrack , result);
        document.cookie = 'ctotal=' + result.order.total + '; expires=0; path=/';
        document.cookie = 'cid=' + result.order.name + '; expires=0; path=/';
        document.cookie = 'info=' + jtrack + '; expires=0; path=/';

        try {
          let countdata: any = this.getOrderCount(result.order);
          console.log("tracking countdata: ", countdata);
          let eventGtm =  {eventType: 'NewOrExisting', existingOrdercount: countdata.count, newOrExisting: countdata.count > 0 ? 'Existing' : 'New', accountid: result.order.accountId, orderid: result.order.id, email: this.guest.email, total: result.order.total, order: result.order.name};
          // new or existing customer DEV-32592
          if ((window as any)['dataLayer']) {
            (window as any)['dataLayer'].push(eventGtm);
          } else {
            (window as any)['dataLayer'] = [eventGtm];
          }
        } catch (e) {
          console.log("error getting order count: " + e);
        }
      } else {
        console.log("can't track result: " , result);
      }
    } catch (e) {
      console.log('ghtrack unable to set ctotal or cid cookies: ' + e);
    }
  }

  similarity(s1:any, s2:any) {
    var longer = s1;
    var shorter = s2;
    if(!s1 || !s2) return 0;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
  }


  editDistance(s1:any, s2:any) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0)
          costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue),
                costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0)
        costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  getAlternativeOffer(brokenactual:any) {
    console.log("getAlternativeOffer: " + brokenactual);
    let foundp = false;
    let winner:any = '';
    let winnerPerc =0;
    if (this.navCtrl.getConfig()) {

      for (let prod = 0; prod < this.navCtrl.getConfig().length; prod++) {
        if (this.navCtrl.getConfig()[prod].path?.startsWith('CTUS_')) {
          let perc = Math.round(this.similarity(brokenactual, this.navCtrl.getConfig()[prod].path) * 10000) / 100;
          if(perc > winnerPerc) {
            winnerPerc = perc;
            winner = this.navCtrl.getConfig()[prod].path;
          }
          foundp = true;
          console.log("found p: " + brokenactual  + " and " + this.navCtrl.getConfig()[prod].path + " are similar " + perc + "%");
        }
      }
    }
    if (!foundp) {
      return null;
    }
    return winner;   
  }

  override async payProductLogic(body:any, paymentMethod:any, completion:any) {
    if (!completion) { 
      this.spinner.enable('Executing order.<br> Please Wait');
      this.toastCtrl.create({ message: `Executing order...`, duration: 3000, position: 'top'})//.present();
    }
    this.emitCustomEvent('submitOrder', 'payProductLogic'); // TOUC-812
    try {
      // add 'transaction fee' values to order body
      if (paymentMethod === 'Standard' && this.transactionFeeAmount > 0) {
        body.orderTransactionFee = +(+this.transactionFeeAmount).toFixed(2);
        body.transactionFeeDocumentId = this.orderSTxId;
        body.subscriptionTransactionFee = +(+this.subscriptionTransactionFee).toFixed(2);
        body.subscriptionTransactionFeeDocumentId = this.orderSTxIdSubscription;
        body.orderTotal = +(+body.orderTotal + +this.transactionFeeAmount).toFixed(2);
      }

      const result = await this.tcrApiOrder.payProductWithoutLogin(body).toPromise();
      console.log('Order result success: ', result);
  
      this.cart.cleanCart();
      if (result.name) {
        this.ghTracking(result);
        this.toastCtrl.create({ message: `SUCCESS!`, duration: 2000, position: 'top' })//.present();
        this.sendCustomEvent('Checkout Success', window.location.href.substring(0,200) , `SUCCESS!`); //TOUC-1243
        if (!completion) this.spinner.disable();
        if (paymentMethod === 'Standard' && this.transactionFeeAmount > 0) this.captureTransactionFee(result.id, result.subscriptionId);
        await this.spinner.enable('Loading page.<br>Please Wait');
        if (completion) completion((<any>window).ApplePaySession.STATUS_SUCCESS);
        result.subscribed =
          this.funnelOffersData &&
          this.funnelOffersData[0] &&
          this.funnelOffersData[0].isSubscriptionAvailable;
        result.authorized =
          this.funnelOffersData &&
          this.funnelOffersData[0] &&
          !!this.funnelOffersData[0].subscriptionDaysPostpone;
        this.eventStreamer.streamEvent('purchase', {
          customTitle: 'Pay now',
          data: result
        });
        // Save shipping information to storage
        if (this.shippingProvider.isShippingAvailable()) {
          await this.saveShippingInformation();
        }
        let orderDetailsInfowCopy = {} as any;
        if (this.state && this.state.orderDetails) {
          orderDetailsInfowCopy = JSON.parse(
            JSON.stringify(this.state.orderDetails)
          );
        }
        orderDetailsInfowCopy.order = result;
        orderDetailsInfowCopy.paymentMethod = paymentMethod;
        orderDetailsInfowCopy.order.nicn = this.orderCardNicn;
        this.stateProvider
          .setPageNameAndOrder(this.stateProvider.UPSELL, orderDetailsInfowCopy)
          .then(async () => {
            await this.stateProvider.clearPurchasedUpsells();
            const params: any = await this.funnelProvider.getNextPage();
            if (!params) {
              if( typeof (window as any)['funnelConfsForUUID'] == 'object') {
                // handle the configuration error
                let brokenfunnel = (window as any)['funnelConfsForUUID'];
                let brokenfunnel_firstUpsell = brokenfunnel.defaultFunnels[1].variants[0].url;
                let alt = this.getAlternativeOffer(brokenfunnel_firstUpsell);
                if(alt) {
                  console.log("redirecting to alternative offer: " + alt);
                  return this.navCtrl.setRootExt(alt, { name: alt},{currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 2 }); //TOUC-1202
                }
              }

              this.loggingProvider.logEvent('Page not found');
              this.sendCustomEvent('Checkout Error', window.location.href.substring(0,200) , `Page not found`); //TOUC-860
              console.log('No Params: Created Order ' + result.name)
              return this.navCtrl.setRootExt(this.stateProvider.ORDER_SUCCESS, { name: 'Order Success'},{currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 8 }); //TOUC-1202
            }
            const { component, orderForm, type } = params;
            if (type === 'Upsell' || type === 'Downsell') {
              await this.stateProvider.setUpsells([orderForm]);
            }
            console.log('Created Order ' + result.name);
            console.log('navigating to component ', component) ;
            return this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true  }, {currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 2 }); //TOUC-1202
          })
          .then(() => {
            this.spinner.disable();
          })
          .catch((error) => {
            console.log('Error on payProductLogic()', error);
            this.spinner.disable();
            const message = error && error.message ? error.message : 'Something went wrong';
            this.loggingProvider.logEvent('Error on payProductLogic() ' + message);
            this.toastCtrl.create({ message: message, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
          });
      } else {
        this.loggingProvider.logPaymentEvent('Error! Order was not created!');

        if (!completion) this.spinner.disable();
        if (completion) {
          completion((<any>window).ApplePaySession.STATUS_FAILURE);
        }

        this.toastCtrl
          .create({
            message: `Error! Order was not created!`,
            duration: 5000,
            position: 'top'
          })
          //.present();
      }
    } catch (error) {
      this.loggingProvider.logPaymentEvent('Error on payment with guest');

      console.log('Error on payment with guest', error);
      if (!completion) this.spinner.disable();
      if (completion) {
        completion((<any>window).ApplePaySession.STATUS_FAILURE);
      }

      this.rejectOrder = true;
      this.rejectOrderMessage.header = 'Sorry, your card has been declined.';
      this.rejectOrderMessage.body = 'Please confirm your payment information and re-submit order.';
      this.scrollToErrorBox();
    }
  }

  checkoutPayPalWithDifferentAddress(accountForm: NgForm, diffAddress: NgForm) {
    this.emitCustomEvent('PayPalButtonClick', 'PayPalNVP');
    this.rejectOrder = false;
    this.spinner.enable('Please Wait');

    if (IS_PAYPAL_NVP) {
      this.checkoutPayPalNVP();
      return;
    }
    this.emitCustomEvent('submitOrder', 'checkoutPayPalWithDifferentAddress'); // TOUC-812

    this.submittedAccountForm = true;
    this.submittedPaymentForm = false;
    this.couponValidation = true;

    if (
      accountForm.invalid || ((this.guest.state as any).length === 0 && this.statesList.length > 0) ||
      (this.isSelectedCB2 &&
        (diffAddress.invalid ||
          ((this.shippingAddress.state as any).length === 0 && this.differentAddressStatesList.length > 0)))
    ) {
      this.spinner.disable();
      this.scrollToErrorBox();
    } else {
      if (!this.isTaxCalculated) {
        this.spinner.disable();
        this.showTaxErrorToast(TAX_ERROR_MESSAGE);
        try { // TOUC-891
          const taxAddressMessage = 
          this.guest.street + ' ' +
          this.guest.city + ' ' +
          this.guest.state + ' ' +
          this.guest.zipPostalCode + ' ' +
          this.guest.country;
          this.sendCustomEvent('Checkout Tax 0', window.location.href.substring(0,200) , taxAddressMessage.substring(0,200) ); //TOUC-860
        } catch (e) {
          console.log("error logging tax message event: " + e);
        }
        return;
      }

      if (this.isSelectedCB2) {
        this.guest.shipToName = this.shipToName;
        this.guest.shippingAddress = this.shippingAddress;
      }

      this.stateProvider.setAccountDetails(this.guest);

      if (this.isSubscription) {
        this.checkoutPayPalSubscription();
      } else {
        this.checkoutPayPal();
      }
    }
  }

  override checkoutPayPalNVP() {
    this.errorMessage = '';
    this.rejectOrder = false;
    this.spinner.enable('Redirecting to PayPal<br> Please Wait');
    this.emitCustomEvent('submitOrder', 'checkoutPayPalNVP'); // TOUC-812
    return this.paypalPayment.setExpressCheckout({
      orderType: this.isSubscription ? 'Subscription' : 'Standard',
      offersData: this.funnelOffersData[0],
      coupon: this.coupon,
      shipToCountry: this.calculateHelper.getCountryISOCode(this.countries as any, this.address.country),
      isUpsell: false,
    })
      .then((result: any) => {
        this.spinner.disable(); // TOUC-2763-DrMarty
        console.log('[GH] saving PayPalNVPParameters',document.location.search)
        sessionStorage.setItem('PayPalNVPParameters', document.location.search);
        let ppwin = window.open(result.url, '_self', 'location=no'); 
        if(!ppwin) 
          document.location.href = result.url;  
      }, (error) => {
        console.log('Error on checkoutPayPalNVP()', error);
        this.spinner.disable();
        const message = error && error.message ? error.message : 'Something went wrong';
        this.loggingProvider.logEvent('Error on checkoutPayPalNVP() ' + message);
        this.toastCtrl.create({ message: message, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
      })
  }

  override submitPayPalOrder(accountForm: NgForm) {
    this.spinner.enable('Executing order.<br> Please Wait');
    this.toastCtrl.create({ message: `Executing order...`, duration: 3000, position: 'top'})//.present();

    this.errorMessage = '';
    this.emitCustomEvent('submitOrder', 'submitPayPalOrder'); // TOUC-812

    this.rejectOrder = false;
    this.submittedAccountForm = true;
    this.submittedPaymentForm = false;
    this.couponValidation = true;
    const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.address.country);

    if (accountForm.invalid || ((this.guest.state as any).length === 0 && this.statesList.length > 0)) {
      this.spinner.disable();
      this.scrollToErrorBox();
    } else {
      if (!this.isTaxCalculated) {
        this.spinner.disable();
        this.showTaxErrorToast(TAX_ERROR_MESSAGE);
        try { // TOUC-891
          const taxAddressMessage = 
          this.guest.street + ' ' +
          this.guest.city + ' ' +
          this.guest.state + ' ' +
          this.guest.zipPostalCode + ' ' +
          this.guest.country;
          this.sendCustomEvent('Checkout Tax 0', window.location.href.substring(0,200) , taxAddressMessage.substring(0,200) ); //TOUC-860
        } catch (e) {
          console.log("error logging tax message event: " + e);
        }
        return;
      }

      let accDetails = this.orderHelperExtender.getAccountDetailsFromGuest(this.guest);
      const shippingAddress = {
        country: this.address.country,
        state: this.address.state,
        postal: this.address.postal,
        city: this.address.city,
        street: this.address.street
      };

      this.funnelOffersData[0].product['count'] = 1;
      let executeBody = {
        totalPrice: (!this.funnelOffersData[0].isFreeFirstShipping && this.isSubscription) ? +(+this.totalSubscriptionPrice).toFixed(2) : +(+this.totalPrice).toFixed(2),
        accDetails: accDetails,
        orderType: this.isSubscription ? 'Subscription' : 'Standard',
        OffersData: this.funnelOffersData,
        totalTax: (!this.funnelOffersData[0].isFreeFirstShipping && this.isSubscription) ? +(+this.subscriptionTax).toFixed(2) : +(+this.totalTaxforBody).toFixed(2),
        subscriptionTax: +(+this.subscriptionTax).toFixed(2),
        calculatedShippingCost: +(+this.calculatedShippingCost).toFixed(2),
        shippingType: this.shippingProvider.isShippingAvailable() ? this.selectedDeliveryDay : '',
        accDetailsForPayPal: this.calculateHelper.generateAccDataForTax(accDetails.shippingAddress, this.countries),
        token: this.token,
        payerId: this.payerId,
        shippingAddress: shippingAddress,
        isInternationalShipping: country != SHIP_FROM_COUNTRY,
      };
      if (this.taxServiceEnabled) {
        (executeBody as any)['dataForTax'] = this.taxData.dataForTax;
      }
      let executeFunction;

      if (this.isSubscription) {
        executeFunction = this.paypalPayment.createRecurringPaymentsProfile(executeBody);
      }
      else {
        (executeBody as any)['coupon'] = this.coupon;
        executeFunction = this.paypalPayment.doExpressCheckout(executeBody);
      }

      return executeFunction
        .then((result: any) => {
          result.order.paymentMethod = 'PayPal';
          return this.stateProvider.setOrderDetails({ order: result.order })
            .then(async (state) => {
              this.state = state;
              // Save shipping information to storage
              if (this.shippingProvider.isShippingAvailable()) {
                await this.saveShippingInformation();
              }
              await this.stateProvider.setTaxData(this.taxData.dataForTax);
              this.cart.cleanCart();
              this.ghTracking(result);
              this.toastCtrl.create({ message: `SUCCESS!`, duration: 2000, position: 'top' })//.present();
              this.sendCustomEvent('Checkout Success', window.location.href.substring(0,200) , `SUCCESS!`); //TOUC-1243

              this.spinner.enable('Loading page.<br> Please wait');
              result.order.subscribed = this.isSubscription;
              result.order.authorized = false;
              this.eventStreamer.streamEvent('purchase', {
                customTitle: 'PayPal Subscription',
                data: result.order,
              });
              let orderDetailsInfowCopy = JSON.parse(JSON.stringify(this.state.orderDetails)) || {};
              orderDetailsInfowCopy.order = result.order;
              orderDetailsInfowCopy.paymentMethod = 'PayPal';
              return this.stateProvider.setPageNameAndOrder(this.stateProvider.UPSELL, orderDetailsInfowCopy)
                .then(async () => {
                  console.log('Created Order ' + result.order.name)

                  await this.stateProvider.clearPurchasedUpsells();
                  const params: any = await this.funnelProvider.getNextPage();
                  if (!params) {
                    this.loggingProvider.logEvent('Page not found');
                    this.sendCustomEvent('Checkout Error', window.location.href.substring(0,200) , `Page not found`); //TOUC-860
                    console.log('navigating to component ' + this.stateProvider.ORDER_SUCCESS) ;
                    return this.navCtrl.setRootExt(this.stateProvider.ORDER_SUCCESS, { name: 'Order Success' }, {currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 8 }); //TOUC-1202
                  //  return this.toastCtrl.create({ message: `Page not found`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
                  }

                  const { component, orderForm, type } = params;
                  if (type === 'Upsell' || type === 'Downsell') {
                    await this.stateProvider.setUpsells([orderForm]);
                  }
                  console.log('[GH] navigating to component ' + component.name)
                  return setTimeout(() => {
                    return this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true}, {currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 2 }); //TOUC-1202
                  }, 33);
                });
            });

        })
        .then(() => {
          this.spinner.disable();
        })
        .catch((error) => {
          console.log('Error on submitPayPalOrder()', error);
          this.spinner.disable();
          this.isPayPalBillingAgreementApproved = false;
          this.rejectOrder = true;
          this.errorMessage = error && error.message ? error.message : 'Something went wrong';
          this.loggingProvider.logEvent('Error on submitPayPalOrder ' + this.errorMessage);
          this.sendCustomEvent('Checkout Error', window.location.href.substring(0,200) , `Sorry, your payment has been declined. ${this.errorMessage}`); //TOUC-860

          this.isPayPalBillingAgreementApproved = false;
          this.rejectOrderMessage.header = 'Sorry, your payment has been declined.';
          this.rejectOrderMessage.body = this.errorMessage;
          this.scrollToErrorBox();

        });
    }

    return;
  }

  override checkoutPayPalSubscription() {
    this.spinner.enable('Creating subscription.<br>Please Wait');
    this.emitCustomEvent('submitOrder', 'checkoutPayPalSubscription'); // TOUC-812

    let accDetails = this.orderHelperExtender.getAccountDetailsFromGuest(this.guest);
    const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.address.country);

    return this.paypalPayment.createSubscription({
      totalPrice: this.funnelOffersData[0].isFreeFirstShipping ? +(+this.totalPrice).toFixed(2) : +(+this.totalSubscriptionPrice).toFixed(2),
      accDetails: accDetails,
      orderType: this.isSubscription ? 'Subscription' : 'Standard',
      OffersData: this.funnelOffersData,
      totalTax: this.funnelOffersData[0].isFreeFirstShipping ? +(+this.totalTaxforBody).toFixed(2) : +(+this.subscriptionTax).toFixed(2),
      subscriptionTax: +(+this.subscriptionTax).toFixed(2),
      calculatedShippingCost: +(+this.calculatedShippingCost).toFixed(2),
      shippingType: this.shippingProvider.isShippingAvailable() ? this.selectedDeliveryDay : '',
      accDetailsForPayPal: this.calculateHelper.generateAccDataForTax(accDetails.shippingAddress, this.countries),
      isInternationalShipping: country != SHIP_FROM_COUNTRY,
    })
      .then((result: any) => {
        result.order.paymentMethod = 'PayPal';
        this.stateProvider.setOrderDetails({ order: result.order })
          .then(async (state) => {
            this.state = state;

            if (this.shippingProvider.isShippingAvailable()) {
              await this.saveShippingInformation();
            }

            await this.stateProvider.setTaxData(this.taxData.dataForTax).then(() => {
              this.spinner.disable(); // TOUC-2763-DrMarty
              console.log('[GH] saving PayPalNVPParameters',document.location.search)
              sessionStorage.setItem('PayPalNVPParameters', document.location.search);

              let ppwin = window.open(result.url, '_self', 'location=no'); 
              if(!ppwin) 
                document.location.href = result.url;  
            });
          });
      }, (error) => {
        console.log('Error on checkoutPayPalSubscription()', error);
        this.spinner.disable();
        const message = error && error.message ? error.message : 'Something went wrong';
        this.loggingProvider.logEvent('Error on checkoutPayPalSubscription() ' + message);
        this.toastCtrl.create({ message: message, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
      })
  }

  override executePayPalSubscription(paramData: any, dataForTax:any) {
    this.spinner.enable('Executing subscription.<br> Please Wait');
    this.emitCustomEvent('submitOrder', 'executePayPalSubscription'); // TOUC-812

    let executePayPalSubscriptionResult;
    paramData.dataForTax = dataForTax;

    this.paypalPayment.executeSubscription(paramData)
      .then((res: any) => {
        executePayPalSubscriptionResult = res;
        this.cart.cleanCart();
        this.ghTracking(res);
        this.toastCtrl.create({ message: `SUCCESS!`, duration: 2000, position: 'top' })//.present();
        this.sendCustomEvent('Checkout Success', window.location.href.substring(0,200) , `SUCCESS!`); //TOUC-1243

        //this.spinner.disable();
        this.spinner.enable('Loading page.<br> Please wait');
        executePayPalSubscriptionResult.orderDetails.order.subscribed = true;
        executePayPalSubscriptionResult.orderDetails.order.authorized = false;
        this.eventStreamer.streamEvent('purchase', {
          customTitle: 'PayPal Subscription',
          data: executePayPalSubscriptionResult.orderDetails.order,
        });

        let orderDetailsInfowCopy = JSON.parse(JSON.stringify(this.state.orderDetails)) || {};
        orderDetailsInfowCopy.order = executePayPalSubscriptionResult.orderDetails.order;
        orderDetailsInfowCopy.paymentMethod = 'PayPal';
        return this.stateProvider.setPageNameAndOrder(this.stateProvider.UPSELL, orderDetailsInfowCopy)
          .then(async () => {
            await this.stateProvider.clearPurchasedUpsells();
            const params: any = await this.funnelProvider.getNextPage();
            if (!params) {
              this.loggingProvider.logEvent('Page not found');
              this.sendCustomEvent('Checkout Error', window.location.href.substring(0,200) , `Page not found`); //TOUC-860
              return this.navCtrl.setRootExt(this.stateProvider.ORDER_SUCCESS, { name: 'Order Success' }, {currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 8}); //TOUC-1202
              //return this.toastCtrl.create({ message: `Page not found`,  position: 'top', showCloseButton: true,     cssClass: 'errorToast',       })//.present();
            }

            const { component, orderForm, type } = params;
            if (type === 'Upsell' || type === 'Downsell') {
              await this.stateProvider.setUpsells([orderForm]);
            }
            console.log('[GH] navigating to component ' + component.name)
            return setTimeout(() => {
              return this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true}, {currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 2 }); //TOUC-1202
            }, 33);

          })
          .then(() => {
            this.spinner.disable();
          })
          .catch(error => {
            console.log('Error on executePayPalSubscription()', error);
            this.spinner.disable();
            const message = error && error.message ? error.message : 'Something went wrong';
            this.loggingProvider.logEvent('Error on executePayPalSubscription() ' + message);
            this.toastCtrl.create({ message: `Error! ${message}`, duration: 3000, position: 'top' })//.present();
          })
      })
  }

  override cancelPayPalSubscription(orderId:any) {
    this.spinner.enable('Cancelling subscription.<br> Please Wait');
    this.emitCustomEvent('submitOrder', 'cancelPayPalSubscription'); // TOUC-812

    return this.paypalPayment.cancelPayPalSubscription(orderId)
      .then(async (res: any) => {
        this.cart.cleanCart();
        this.toastCtrl.create({ message: `SUBSCRIPTION CANCELLED!`, duration: 2000, position: 'top' })//.present();

        res.orderDetails.order.subscribed = this.funnelOffersData && this.funnelOffersData[0] && this.funnelOffersData[0].isSubscriptionAvailable;
        res.orderDetails.order.authorized = this.funnelOffersData && this.funnelOffersData[0] && !!this.funnelOffersData[0].subscriptionDaysPostpone;
        this.eventStreamer.streamEvent('purchase', {
          customTitle: 'PayPal Subscription',
          data: res.orderDetails.order,
        });

        let orderDetailsInfowCopy = JSON.parse(JSON.stringify(this.state.orderDetails)) || {};
        orderDetailsInfowCopy.order = res.orderDetails.order;
        orderDetailsInfowCopy.paymentMethod = 'PayPal';

        this.spinner.disable();
      }, (error) => {
        console.log('Error on cancelPayPalSubscription:', error);
        this.spinner.disable();
        const message = error && error.message ? error.message : 'Something went wrong';
        this.loggingProvider.logEvent('Error on cancelPayPalSubscription() ' + message);
        this.toastCtrl.create({ message: `Error! ${message}`, duration: 3000, position: 'top' })//.present();
      });
  }

  override checkoutPayPal() {
    this.spinner.enable('Executing order.<br> Please Wait');
    this.toastCtrl.create({ message: `Executing order...`, duration: 3000, position: 'top'})//.present();
    this.emitCustomEvent('submitOrder', 'checkoutPayPal'); // TOUC-812

    const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.address.country);

    return this.paypalPayment.createPayment({
      totalPrice: +(+this.totalPrice).toFixed(2),
      totalTax: +(+this.totalTaxforBody).toFixed(2),
      accDetails: this.orderHelperExtender.getAccountDetailsFromGuest(this.guest),
      orderType: this.isSubscription ? 'Subscription' : 'Standard',
      OffersData: this.funnelOffersData,
      orderFormProducts: this.orderFormProduct,
      bumpOffers: this.bumpOffersInCart,
      coupon: this.coupon,
      selectedProduct: this.selectedProduct,
      calculatedShippingCost: +(+this.calculatedShippingCost).toFixed(2),
      shippingType: this.shippingProvider.isShippingAvailable() ? this.selectedDeliveryDay : '',
      isInternationalShipping: country != SHIP_FROM_COUNTRY,
    })
      .then((result: any) => {
        this.stateProvider.setOrderDetails({ order: result.order })
          .then(async state => {
            this.state = state;

            if (this.shippingProvider.isShippingAvailable()) {
              await this.saveShippingInformation();
            }
            this.spinner.disable(); // TOUC-2763-DrMarty
            console.log('[GH] saving PayPalNVPParameters',document.location.search)
            sessionStorage.setItem('PayPalNVPParameters', document.location.search);

            let ppwin = window.open(result.url, '_self', 'location=no'); 
            if(!ppwin) 
              document.location.href = result.url;  
          })
      }, (error) => {
        console.log('Error on checkoutPayPal()', error);
        this.spinner.disable();
        const message = error && error.message ? error.message : 'Something went wrong';
        this.loggingProvider.logEvent('Error on checkoutPayPal()' + message);
        this.sendCustomEvent('Checkout Error', window.location.href.substring(0,200) , `Error! ${message}`); //TOUC-860

        this.toastCtrl.create({ message: `Error! ${message}`, duration: 3000, position: 'top' })//.present();
      });
  }

  override executePayPalPayment(paramData:any, dataForTax:any) {
    this.spinner.enable('Executing order.<br> Please Wait');
    this.emitCustomEvent('submitOrder', 'executePayPalPayment'); // TOUC-812
    this.toastCtrl.create({ message: `Executing order...`, duration: 3000, position: 'top'})//.present();

    let executePayPalResult;
    paramData.dataForTax = dataForTax;
    this.paypalPayment.executePayment(paramData)
      .then((res: any) => {
        executePayPalResult = res;
        this.cart.cleanCart();
        this.ghTracking(res);
        this.toastCtrl.create({ message: `SUCCESS!`, duration: 2000, position: 'top' })//.present();
        this.sendCustomEvent('Checkout Success', window.location.href.substring(0,200) , `SUCCESS!`); //TOUC-1243
        //this.spinner.disable();
        this.spinner.enable('Loading page <br> please wait...');
        executePayPalResult.orderDetails.order.subscribed = this.funnelOffersData && this.funnelOffersData[0] && this.funnelOffersData[0].isSubscriptionAvailable;
        executePayPalResult.orderDetails.order.authorized = this.funnelOffersData && this.funnelOffersData[0] && !!this.funnelOffersData[0].subscriptionDaysPostpone;
        this.eventStreamer.streamEvent('purchase', {
          customTitle: 'PayPal Pay',
          data: executePayPalResult.orderDetails.order,
        });
        let orderDetailsInfowCopy = JSON.parse(JSON.stringify(this.state.orderDetails)) || {};
        orderDetailsInfowCopy.order = executePayPalResult.orderDetails.order;
        orderDetailsInfowCopy.paymentMethod = 'PayPal';
        return this.stateProvider.setPageNameAndOrder(this.stateProvider.UPSELL, orderDetailsInfowCopy)
          .then(async () => {
            await this.stateProvider.clearPurchasedUpsells();
            const params: any = await this.funnelProvider.getNextPage();
            if (!params) {
              this.loggingProvider.logEvent('Page not found');
              this.sendCustomEvent('Checkout Error', window.location.href.substring(0,200) , `Page not found`); //TOUC-860
              return this.navCtrl.setRootExt(this.stateProvider.ORDER_SUCCESS, { name: 'Order Success' }, {currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 8}); //TOUC-1202
              //return this.toastCtrl                .create({                  message: `Page not found`,                  position: 'top',                  showCloseButton: true,                  cssClass: 'errorToast',                })//.present();

            }
            const { component, orderForm, type } = params;
            if (type === 'Upsell' || type === 'Downsell') {
              await this.stateProvider.setUpsells([orderForm]);
            }
            return this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, {currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 2}); //TOUC-1202
          })
          .then(() => {
            this.spinner.disable();
          })
      })
      .catch(error => {
        console.log('Error on executePayPalPayment()', error);
        this.spinner.disable();
        const message = error && error.message ? error.message : 'Something went wrong';
        this.loggingProvider.logEvent('Error on executePayPalPayment() ' + message);
        this.sendCustomEvent('Checkout Error', window.location.href.substring(0,200) , `Error! ${message}`); //TOUC-860

        this.toastCtrl.create({ message: `Error! ${message}`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
      })
  }

  //Use to set custom title of the page
  didLoad() {
    let titleId = document.getElementById('title');
    if (titleId) {
      titleId.innerHTML = BRAND;
    }
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  override async calculate(getShipping: Boolean, getTax: Boolean = true, country: any = undefined) {
    await this.delay(300);
    if (getTax) {
      this.spinner.enable('Calculating taxes.<br> Please Wait');
      this.emitCustomEvent('CheckoutCommon', 'Calculating taxes'); // TOUC-812

    }

    let shouldShowErrors = true;

    this.isTaxCalculated = true;
    this.isProcessRemoteServices = true;
    this.bumpOffersInCart = [];
    this.orderFormProduct = [];

    const shippingAddress = getTax ? {
      country: this.isSelectedCB2 ? this.shippingAddress.country : this.guest.country,
      state: this.isSelectedCB2 ? this.shippingAddress.state : this.guest.state,
      postal: this.isSelectedCB2 ? this.shippingAddress.postal : this.guest.zipPostalCode,
      city: this.isSelectedCB2 ? this.shippingAddress.city : this.guest.city,
      street: this.isSelectedCB2 ? this.shippingAddress.street : this.guest.street
    } : {
      country : country
    };

    if (this.isCouponChecked) {
      shouldShowErrors = false;
      this.isCouponChecked = false;
    }

    if (this.isFreeChecked) {
      if (this.funnelOffersData && (this.funnelOffersData[0].isFreeFirstShipping === true) && this.selectedDeliveryDay && this.selectedDeliveryDay >= 1) {
        this.funnelOffersData[0].isFreeFirstShipping = false;
      } else if (this.funnelOffersData && (this.funnelOffersData[0].isFreeFirstShipping === false) && this.selectedDeliveryDay && this.selectedDeliveryDay == 'Free') {
        this.funnelOffersData[0].isFreeFirstShipping = true;
      }
    }

    let funnelOffers: any = [];

    if (this.funnelOffersData && this.funnelOffersData.length) {
      const funnelArray = this.createFunnelBodyForCountry(
        this.funnelOffersData,
        this.selectedProduct,
        shippingAddress.country
      );
      if (funnelArray.length) {
        funnelOffers = funnelArray;
      }
    }
    if (
      this.funnel &&
      this.funnel.orderFormProduct &&
      this.funnel.orderFormProduct.length
    ) {
      const funnelArray = this.createFunnelProductBodyForCountry(
        this.funnel,
        this.funnel.orderFormProduct,
        this.selectedProduct,
        shippingAddress.country
      );
      if (funnelArray.length) {
        funnelOffers = funnelArray;
      }
    }

    if (this.isShippingAvailable && getShipping) {
      this.isShowShippingRates = false;
      this.isCalculatingShippingRates = true;
      this.disablePaymentDueToShipping = true;
      this.isShippingValid = true;
      this.shippingRates = [];
    }

    if (this.taxServiceEnabled) {
      this.isTaxValid = true;
      this.taxServiceOnPocess = true;
      this.showTaxes = false;
    }

    if (this.bumpOffers) {
      for (let i = 0; i < this.bumpOffers.length; i++) {
        if (this.bumpOffers[i].isInCart) {
          this.bumpOffersInCart.push(this.bumpOffers[i]);
        }
      }
    }

    this.calculateHelper.amountsRecalculation({
      funnelOffersData: funnelOffers,
      bumpOffers: this.bumpOffers,
      coupon: this.coupon,
      calculateTax: this.taxServiceEnabled && getTax,
      shippingAddress: shippingAddress,
      countries: this.countries,
      calculatedShippingCost: +this.calculatedShippingCost,
      accInfoForShipping: this.getAccountInfoForShipping(shippingAddress),
      productsForShipping: this.getProductsForShipping(),
      getShipping: this.isShippingAvailable && getShipping,
      isSubscription: this.isSubscription,
    })
      .then(({totalPrice, funnelPrice, bumpPrice, shippingCost, couponDiscount, totalSubscriptionPrice, taxCost, taxData, taxError, error, shippingRates, shippingError, subscriptionTax}) => {
        this.totalPrice = totalPrice;
        this.funnelPrice = funnelPrice;
        this.bumpPrice = bumpPrice;
        this.shippingCost = shippingCost;
        (this.standardShipping as any) = (this.countries as any)[shippingAddress.country].value === SHIP_FROM_COUNTRY ? this.funnel.shippingCost : this.funnel.internationalShippingCost;
        this.couponDiscount = couponDiscount;
        this.totalSubscriptionPrice = totalSubscriptionPrice;
        this.subscriptionTax = subscriptionTax;

        this.totalTaxforBody = taxCost;
        this.totalTax = taxCost;
        this.taxData.dataForTax = taxData;
        this.showTaxes = true;
        this.taxServiceOnPocess = false;

        if (getTax) {
          this.spinner.disable();
          this.braintreeUpdatingCalculation = false;
        }

        if (this.isShippingAvailable) {
          this.isCalculatingShippingRates = false;
          this.disablePaymentDueToShipping = false;
          if (shippingError) {
            this.isTaxCalculated = false;
            this.isShippingValid = false;
            this.shippingCost = this.standardShipping;

            if (shouldShowErrors) {
              this.showTaxErrorToast(TAX_ERROR_MESSAGE);
              try { // TOUC-891
                const taxAddressMessage = 
                this.guest.street + ' ' +
                this.guest.city + ' ' +
                this.guest.state + ' ' +
                this.guest.zipPostalCode + ' ' +
                this.guest.country;
                this.sendCustomEvent('Checkout Tax 0', window.location.href.substring(0,200) , taxAddressMessage.substring(0,200) ); //TOUC-860
              } catch (e) {
                console.log("error logging tax message event: " + e);
              }
              this.showTaxErrorToast(ADDRESS_ERROR_MESSAGE);
            }
          } else {
            this.isShowShippingRates = true;
            if (shippingRates && shippingRates.length) {
              this.shippingRates = shippingRates;
              this.applyShippingRate();
            }
          }
        }

        if (taxError) {
          this.isTaxValid = false;
          this.isTaxCalculated = false;

          if (shouldShowErrors) {
            // comment out this one so the user only gets this message upon submit.  TOUC-1036
            //this.showTaxErrorToast(TAX_ERROR_MESSAGE);
            try { // TOUC-891
              const taxAddressMessage = 
              this.guest.street + ' ' +
              this.guest.city + ' ' +
              this.guest.state + ' ' +
              this.guest.zipPostalCode + ' ' +
              this.guest.country;
              this.sendCustomEvent('Checkout Tax 0', window.location.href.substring(0,200) , taxAddressMessage.substring(0,200) ); //TOUC-860
            } catch (e) {
              console.log("error logging tax message event: " + e);
            }
          }
        }

        if (error !== '') {
          this.isTaxCalculated = false;

          if (shouldShowErrors) {
            this.showTaxErrorToast(error);

            try { // TOUC-891
              const taxAddressMessage = 
              this.guest.street + ' ' +
              this.guest.city + ' ' +
              this.guest.state + ' ' +
              this.guest.zipPostalCode + ' ' +
              this.guest.country;
              this.sendCustomEvent('Checkout Tax 0', window.location.href.substring(0,200) , taxAddressMessage.substring(0,200) ); //TOUC-860
            } catch (e) {
              console.log("error logging tax message event: " + e);
            }
          }
        }
        this.isProcessRemoteServices = false;

        if (this.shouldCreateLeadOnFulfillment) {
          this.readyToCreateLead();
          this.shouldCreateLeadOnFulfillment = false;
        }
        this.ngZone.run(() => {
          //console.log('[GH] ngZone.run()');
        });
    
      }).catch((reason) => {
      if (getTax) {
        this.spinner.disable();
        this.braintreeUpdatingCalculation = false;
        this.ngZone.run(() => {
          //console.log('[GH] ngZone.run() on error ' + reason);
        });

      }
      console.log(reason);
    });
  }

  createFunnelBodyForCountry(funnelData:any, selectedProduct:any, shipToCountry:any) {
    const country = this.calculateHelper.getCountryISOCode(this.countries as any, shipToCountry);
    let result: any = [];

    if (funnelData && funnelData.length && selectedProduct) {
      for (let i = 0; i < funnelData.length; i++) {
        if (funnelData[i].product.sfid === selectedProduct) {
          let data: any = {};
          data.sfid = funnelData[i].sfid;
          data.offerPrice = funnelData[i].offerPrice;
          data.subscriptionPrice = funnelData[i].subscriptionPrice;
          data.shippingCost = country === SHIP_FROM_COUNTRY ? funnelData[i].shippingCost : funnelData[i].internationalShippingCost;
          data.product = funnelData[i].product;
          data.isSubscriptionAvailable = funnelData[i].isSubscriptionAvailable;
          data.isPurchaseAvailable = funnelData[i].isPurchaseAvailable;
          data.isFreeFirstShipping = funnelData[i].isFreeFirstShipping;
          data.internationalShippingCost = funnelData[i].internationalShippingCost;
          result.push(data);
        }
      }
    }

    return result;
  }

  createFunnelProductBodyForCountry(funnel:any, funnelProducts:any, selectedProduct:any, shipToCountry:any) {
    const country = this.calculateHelper.getCountryISOCode(this.countries as any, shipToCountry);

    let result: any = [];

    if (funnel && funnelProducts && funnelProducts.length && selectedProduct) {
      for (let i = 0; i < funnelProducts.length; i++) {
        if (funnelProducts[i].product.sfid === selectedProduct) {
          this.orderFormProduct.push(funnelProducts[i]);
          let data: any = {};
          data.sfid = funnel.sfid;
          data.offerPrice = funnelProducts[i].purchasePrice;
          data.subscriptionPrice = funnelProducts[i].subscriptionPrice || 0;
          data.shippingCost = country === SHIP_FROM_COUNTRY ? funnel.shippingCost : funnel.internationalShippingCost;
          data.product = funnelProducts[i].product;
          data.isSubscriptionAvailable = funnel.isSubscriptionAvailable;
          data.isPurchaseAvailable = funnel.isPurchaseAvailable;
          data.isFreeFirstShipping = funnel.isFreeFirstShipping;
          data.internationalShippingCost = funnel.internationalShippingCost;
          result.push(data);
        }
      }
    }

    return result;
  }

  override getAccountInfoForShipping(address:any) {
    return {
      firstName: this.guest.firstName,
      lastName: this.guest.lastName,
      shippingCountryCode: address.country,
      shippingStateCode: address.state,
      shippingCity: address.city,
      shippingStreet: address.street,
      shippingPostalCode: address.postal,
      phone: this.guest.phone
    };
  }

  override saveShippingInformation() {
    if (this.isShippingValid) {
      return this.stateProvider.setShippingInfo({
        productsForShipping: this.getProductsForShipping(),
        accountInfoForShipping: {
          firstName: this.guest.firstName,
          lastName: this.guest.lastName,
          phone: this.guest.phone,
          shippingCity: this.address.city,
          shippingCountryCode: this.address.country,
          shippingPostalCode: this.address.postal,
          shippingStateCode: this.address.state,
          shippingStreet: this.address.street,
          shippingApartment: this.shippingAddress && this.isSelectedCB2 ? this.guest.apartment : this.guest.billingApartment,
          additionalShippingAddressLine: this.shippingAddress && this.isSelectedCB2 ? this.guest.additionalAddressLine : this.guest.additionalBillingAddressLine,
          billingApartment: this.guest.billingApartment,
          additionalBillingAddressLine: this.guest.additionalBillingAddressLine
        },
        selectedDeliveryDay: this.selectedDeliveryDay,
        isValid: true
      });
    } else {
      return this.stateProvider.setShippingInfo({
        isValid: false
      });
    }
  }

  override getCoupon() {
    this.spinner.enable('Executing coupon.<br> Please Wait');
    // [TOUC-5830]
    if (this.isSubscription) {
      try {
        if(document.querySelector(".paypal_button")) (<HTMLButtonElement>document.querySelector(".paypal_button")).style.display='none';
      } catch (e) {

      }
    }

    /*
    if (this.isSubscription) {
      this.spinner.disable();
      this.sendCustomEvent('Checkout Error', window.location.href.substring(0,200) , 'Coupon is not available for Subscription!'); //TOUC-860

      this.toastCtrl
        .create({
          message: 'Coupon is not available for Subscription!',
          position: 'top',
          showCloseButton: true,
          cssClass: 'errorToast'
        })
        //.present();
    } else {
      */
      this.submittedAccountForm = false;
      this.submittedPaymentForm = false;
      this.isCouponChecked = true;
      return this.tcrApiCoupon
        .checkCoupon({ couponCode: this.couponCode })
        .toPromise()
        .then(
          async result => {
            this.spinner.disable();

            if (result.discountAmount) {
              (this.productsForCoupon as any) = result.products;
              this.couponProductsOption = result.couponProductsOption;
              if (this.checkValidCoupon()) {
                this.couponValidation = true;
                this.coupon = result;
                this.coupon.price = 0;
                this.coupon.count = 1;
                this.coupon.couponId = result.sfid;
                await this.calculate(false);
                await this.stateProvider.setCoupon(this.coupon);
                if (this.braintreePayPalTokenizedPayment && this.braintreeEnabled) {
                  this.payPalCallUpdatePayment();
                }

              } else {
                this.couponValidation = false;
                this.scrollToErrorBox();
              }
            } else {
              this.couponValidation = false;
              this.scrollToErrorBox();
              this.resetCoupon();
            }
          },
          error => {
            this.sendCustomEvent('Checkout Error', window.location.href.substring(0,200) , ` Coupon '${this.couponCode}' Error! ${
              error.error.message
              }`); //TOUC-860
            this.toastCtrl
              .create({
                message: ` Coupon '${this.couponCode}' Error! ${
                  error.error.message
                  }`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast'
              })
              //.present();
            this.resetCoupon();
            this.spinner.disable();
          }
        );
    //}
  }

  override resetCoupon() {
    this.stateProvider.clearCoupon();
    this.state.coupon = undefined;
    this.coupon = {};
    this.couponDiscount = 0;
    this.couponCode = '';
    this.calculate(false);
  }

  override validateAddressAndCalculateTaxes(address:any) {
    let taxAddress = {};
    let statesNumber = 0;
    if (address === 'billing') {
      this.checkCountry(true);
      statesNumber = this.statesList.length;
      if (!this.isSelectedCB2) {
        taxAddress = {
          country: this.guest.country,
          state: this.guest.state,
          street: this.guest.street,
          city: this.guest.city,
          postalCode: this.guest.zipPostalCode
        };
        const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.guest.country as any);
        this.shippingCost = country === SHIP_FROM_COUNTRY ? (this.funnel.shippingCost as any) + this.calculatedShippingCost :
          (this.funnel.internationalShippingCost as any) + this.calculatedShippingCost;
        this.calculate(false, false, this.guest.country);
      }
    } else if (address === 'shipping' && this.isSelectedCB2) {
      this.checkDifferentCountry(true);
      statesNumber = this.differentAddressStatesList.length;
      taxAddress = {
        country: this.shippingAddress.country,
        state: this.shippingAddress.state,
        street: this.shippingAddress.street,
        city: this.shippingAddress.city,
        postalCode: this.shippingAddress.postal
      };
      const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.shippingAddress.country);
      this.shippingCost = country === SHIP_FROM_COUNTRY ? (this.funnel.shippingCost as any) + this.calculatedShippingCost :
        (this.funnel.internationalShippingCost as any) + this.calculatedShippingCost;
      this.calculate(false, false, this.shippingAddress.country);
    }

    if (this.validateAddressFields(taxAddress, statesNumber)) {
      this.setAddressForTaxCalculation(taxAddress);
    }

    this.calculateTransactionFee();
  }
  override validateAddressFields(address:any, statesNumber:any) { // TOUC-1036 need this to match pattern in account-form.html for address
    return address.country && (address.state || statesNumber === 0)  &&
      this.validateCityInput(address.city) &&
      this.validateZipInput(address.postalCode) &&
      this.validateStreetInput(address.street);
  }
  validateZipInput(string:any) { // TOUC-1036 need this to match pattern in account-form.html for address
    if (string) {
      return this.mixedPattern.test(string);
    } else {
      return false;
    }
  }
  validateCityInput(string:any) { // TOUC-1036 need this to match pattern in account-form.html for address
    if (string) {
      return this.addressPattern.test(string);
    } else {
      return false;
    }
  }
  validateStreetInput(string:any) { // TOUC-1036 need this to match pattern in account-form.html for address
    if (string) {
      return this.addressPattern.test(string);
    } else {
      return false;
    }
  }
  parseFunnel() {
    if (this.funnel.isSubscriptionAvailable && !this.funnel.offerPrice) {
      this.funnel.offerPrice = this.funnel.subscriptionPrice;
    }
    this.subtotal = this.funnel.standardPrice as any;
    this.totalAmount = this.subtotal + (this.funnel.shippingCost as any);

    if (this.state && this.state.navParams) {
      const navParams = this.state.navParams;
      if (navParams && navParams.email) this.guest.email = navParams.email;
    }

    let isCouponApplied = false;
    if (this.state && this.state.coupon) {
      if (this.isPayPalBillingAgreementApproved) {
        this.coupon = this.state.coupon;
        this.couponCode = this.coupon.couponCode as any;
        isCouponApplied = true;
      } else {
        this.stateProvider.clearCoupon();
        this.state.coupon = undefined;
      }
    }

    this.yearArray = this.general.getExpiryYearList(this.currentYear);
    this.countryChosen = this.getCountryByCode('US');
    this.differentAddressCountryChosen = this.countryChosen;
    this.statesList = this.getStates(this.countryChosen);
    this.differentAddressStatesList = this.statesList;

    this.guest.state = '';
    this.guest.country = this.countryChosen.label;
    this.address.state = '';
    this.address.country = this.countryChosen.label as any;
    this.shippingAddress.country = this.guest.country as any;
    this.shippingAddress.state = '';
    this.cardType = 'visa';
    this.funnelOffersData = [this.funnel];
    this.isSubscription = this.funnelOffersData[0] && this.funnelOffersData[0].isSubscriptionAvailable;
    this.isPurchaseAvailable = this.funnelOffersData[0] && this.funnelOffersData[0].isPurchaseAvailable;
    this.selectedProduct = this.funnelOffersData[0].product.sfid;
    this.isFreeChecked = this.funnelOffersData[0].isFreeFirstShipping;

    if (this.funnel.shippingCost) {
      this.shippingCost = this.funnel.shippingCost;
      this.standardShipping = this.shippingCost;
    }

    if (this.state && this.state.accountDetails) {
      if (this.isPayPalBillingAgreementApproved) {
        if (this.isRememberInfoChecked) {
          this.changeStatusIsRemembered();
        }
      } else {
        this.countryChosen = (this.countries as any)[(this.state.accountDetails as any).country];
        this.guest = this.state.accountDetails;
        this.emailToConfirm = this.guest.email;

        let addressToValidate = 'billing';

        if (this.guest.shippingAddress) {
          this.isSelectedCB2 = true;
          this.differentAddressCountryChosen = (this.countries as any)[this.guest.shippingAddress.country];
          this.shippingAddress = this.guest.shippingAddress;
          this.address.postal = this.shippingAddress.postal;
          this.address.country = this.shippingAddress.country;
          this.address.city = this.shippingAddress.city;
          this.address.state = this.shippingAddress.state;
          this.address.street = this.shippingAddress.street;
          this.guest.shippingAddress = undefined;
          this.shipToName = this.guest.shipToName as any;
          addressToValidate = 'shipping';
        } else {
          this.address.postal = this.guest.zipPostalCode as any;
          this.address.country = this.guest.country as any;
          this.address.city = this.guest.city as any;
          this.address.state = this.guest.state as any;
          this.address.street = this.guest.street as any;
        }

        this.validateAddressAndCalculateTaxes(addressToValidate);
        if (!this.isRememberInfoChecked) {
          this.stateProvider.clearAccountDetails();
          this.state.accountDetails = undefined;
        }
        isCouponApplied = false;
      }
    }

    if (isCouponApplied && !this.isPayPalBillingAgreementApproved) {
      this.isCouponChecked = true;
      this.calculate(true);
    }
  }
  checkboxClickHandler() {
    if (this.braintreePayPalTokenizedPayment && this.braintreeEnabled) {
      // after the user unchecks the checkbox, patch the payment method
      this.braintreeCustomLoadingMessage();
      this.payPalCallUpdatePayment();
    }
  }
  // Logic to remember account information after checking "Remember my information' checkbox
  rememberAccountInformation() {
    if (this.isRememberInfoChecked) {
      // Create copy of guest to prevent rewriting old data
      let guestToRemember:any = {};
      if (this.guest) {
        Object.keys(this.guest).map((key) => {
          guestToRemember[key] = (this.guest as any) [key];
        });
      }
      // If "Ship to a different address" checked then pass shipping info to copied guest
      if (this.isSelectedCB2 === true) {
        guestToRemember['shipToName'] = this.shipToName;
        guestToRemember['shippingAddress'] = this.shippingAddress;
      }
      if (guestToRemember) {
        this.stateProvider.setAccountDetails(guestToRemember);
        const accountFormForLocalStorage = JSON.stringify(guestToRemember);
        if (this.isStorageAvailable) {
          localStorage.setItem('accountForm', accountFormForLocalStorage);
        } else {
          (window as any)['gh']['accountForm'] = accountFormForLocalStorage;
        }
      } else {
        this.stateProvider.clearAccountDetails();
      }
    }

  }

  // Logic to trigger information saving if "Remember my information" checkbox is active
  changeStatusIsRemembered() {
    this.isRememberInfoChecked = !this.isRememberInfoChecked;
    if (this.isRememberInfoChecked === true) {
      if (this.isStorageAvailable) {
        localStorage.setItem('checkBoxStatus', 'true');
      } else {
        (window as any)['gh']['checkBoxStatus'] = 'true';
      }
    } else if (this.isRememberInfoChecked === false) {
      if (this.isStorageAvailable) {
        localStorage.setItem('checkBoxStatus', 'false');
      } else {
        (window as any)['gh']['checkBoxStatus'] = 'false';
      }
    }
    if (this.isRememberInfoChecked === true) {
      this.rememberAccountInformation();
    } else {
      this.stateProvider.clearAccountDetails();

      if (this.isStorageAvailable) {
        localStorage.removeItem('accountForm');
      } else {
        delete (window as any)['gh']['accountForm'];
      }
    }
  }

  sanitizeCouponCode() {
    this.couponCode = this.couponCode.toUpperCase().trim();
  }

  //Use this method to set up initial values for template variables
  populatePageData(){
    let body = document.getElementsByTagName('body')[0];
    body.setAttribute('data-qa','checkout-page');

    this.gundryLogo = '/assets/img/gundry-logo.jpg';
    this.headerText = '';
    this.showOnOrderForm = true;
    this.shoppingCart = {};
    this.brandFooterObj = {};
    this.commentSectionTxt1 = '';
    this.commentSectionTxt2 = '';
    this.commentSectionTxt3 = '';

    
    this.guaranteeTitle = '';
    this.guaranteeText = '';

    this.tabs = [];


    // copeid from Gundry B
    this.noticeOn = false;
    this.noticeText = `<p><span class="bold">UPDATE:</span> Due to extreme weather conditions, some customers may experience shipping delays.<p>`;
    this.gundryLogo = '/assets/img/gundry-logo.jpg';
    if (this.braintreeDefaultCountry && this.braintreeDefaultCountry == 'Germany') {
      this.headerText = `<h2>KUNDENDIENST</h2><p>+1 (800) 852-0477 | MF 7:00–17:00 UHR PST | SS 7:00–16:00 UHR PST</p>`;
    } else if (this.braintreeDefaultCountry && this.braintreeDefaultCountry == 'Mexico') {
      this.headerText = `<h2>Servicio al cliente</h2><p>(800) 611-9075 |M-F 7AM-5PM PST | S-S 7AM-4PM PST</p><p>servicioalcliente@gundrymd.com</p>`;
    } else {
      if(this.braintreeDefaultCountry && this.braintreeDefaultCountry != 'United States' ) {
      this.headerText = '<br><h2>CUSTOMER SERVICE</h2>' +
        '<p>(800) 852-0477 | M-F 6AM-5PM PST | S-S 6AM-4PM PST</p>';
      } else {
        this.headerText = '<h2>FREE SHIPPING</h2>' +
        '<p>U.S. ORDERS OVER $60</p>' +
        '<h2>CUSTOMER SERVICE</h2>' +
        '<p>(800) 852-0477 | M-F 6AM-5PM PST | S-S 6AM-4PM PST</p>';
      }
    }
    this.showOnOrderForm = true;
    this.shoppingCart = { page:'http://www.1shoppingcart.com', label: '1ShoppingCart' };
    this.brandFooterObj = {link:'http://gundrymd.com', label: 'GundryMD'};
    this.commentSectionTxt1 = `
  `;

  this.commentSectionTxt2 = ``;
    this.guaranteeTitle = '100% money back guarantee';
    this.guaranteeText = 'We work hard to formulate the most advanced products on the market. Our 90-Day Customer Satisfaction Guarantee is designed to give you ample opportunity to experience optimal results from your product, completely risk-free.';
    this.tabs = [
      { name: this.getLabelCheckout('Cart Contents'), active: false },
      { name: this.getLabelCheckout('Checkout'), active: true },
      { name: this.getLabelCheckout('Confirmation'), active: false }
    ];
  }

  override getInfoAmazonSub() {
   
  }

  override getInfoAmazon() {

  }

  submitAmazonPay(accountForm: NgForm) {

  }

  override async payWithAmazonSubscription() {
  
  }

  override async payWithAmazon() {
  }

  override async createTransactionAmazon() {
  }
  convertRateFloor(val:any){
    let fl = Math.floor(val*100);
    let xf = fl.toFixed(0);
    let f = (parseFloat(xf)/100);
    let r = f.toFixed(2);
    return r;
  }
  braintreeReady() {
    (window as any)['changePaymentSettings'] = (settings:any) => {
      this.changeBraintreeSettings(settings);
    }
    if (navigator && navigator.userAgent && navigator.userAgent.indexOf(' UCBrowser/') >= 0) {
      console.log('[GH] UCBrowser detected, not running braintree');
      return false;
    }
    if (this.funnelProvider.isUIDExist()) {
      const uniqueIdentifier = this.urlsHelper.getParameterFromUrl({
        url: window.location.href,
        parameter: 'uid',
      });
      if (uniqueIdentifier) {
        const FUNNEL_CONFS = (window as any)['funnel_confs'] ? (window as any)['funnel_confs'].funnels : null;
        const mainFunnel = Object.keys(FUNNEL_CONFS)
          .map((key) => {
            return FUNNEL_CONFS[key];
          })
          .find((e) => e.uniqueIdentifier === uniqueIdentifier);
        console.log("FUNNEL_CONFS FOR UID " + uniqueIdentifier, mainFunnel);
        if (mainFunnel.orderForm.isSubscriptionAvailable && this.braintreeSubscriptionSupported) {
          console.log('[GH] subscription detected, running braintree initial subscription prototype');
        } else if (mainFunnel.orderForm.isSubscriptionAvailable && !this.braintreeSubscriptionSupported) {
          console.log('[GH] subscription detected, not running braintree');
          return false;
        }
      }
    }

    if(this.isSubscription) {
      console.log('[GH] subscription detected, not running braintree');
      return false;
    }
    if ( (window as any)['process_env'] &&  (window as any)['process_env']['BRAINTREE_ENABLED'] &&  (window as any)['process_env']['BRAINTREE_ENABLED'] == 'true') {
      console.log('[GH] braintree enabled');
      return true;
    }
    console.log('[GH] braintree not enabled');
    return false;
  }
  async setupBraintreeClient() {
    // TOUC-14710
    if ( this.braintreeCurrencyConversion && !this.isSubscription) { // MVP no subscription
/*       try {
        sessionStorage.setItem('timer_checkout_braintree_start', new Date().getTime().toString());
      } catch (e) {
        console.log('error setting up '+e);
      } */
      this.showPayPalPayButton = false; // use paypal from braintree
      (window as any)['jwtCallback'] = (data:any) => {
/* 
        try {
          sessionStorage.setItem('timer_checkout_braintree_jwt_callback_complete', new Date().getTime().toString());

          setTimeout(() => {
            sessionStorage.setItem('timer_totaltime', (parseInt((sessionStorage as any).getItem('timer_checkout_braintree_jwt_callback_complete')) - parseInt((sessionStorage as any).getItem('timer_start'))).toString());
            sessionStorage.setItem('timer_braintreesdktime', (parseInt((sessionStorage as any).getItem('timer_checkout_braintree_jwt_callback_complete')) - parseInt((sessionStorage as any).getItem('timer_checkout_braintree_start'))).toString());
            sessionStorage.setItem('timer_braintreetime', (parseInt((sessionStorage as any).getItem('timer_checkout_braintree_setup_complete')) - parseInt((sessionStorage as any).getItem('timer_checkout_braintree_start'))).toString());
            // DEV-19354
            let trackingEvent = 'Braintree Tracking';
            if (parseInt((sessionStorage as any).getItem('timer_checkout_braintree_jwt_callback_complete')) - parseInt((sessionStorage as any).getItem('timer_start')) < 0) {
              trackingEvent = 'Braintree Tracking Issue';
            }
            if(parseInt((sessionStorage as any).getItem('timer_checkout_braintree_jwt_callback_complete')) - parseInt((sessionStorage as any).getItem('timer_checkout_braintree_start')) < 0) {
              trackingEvent = 'Braintree Tracking Issue';
            }
            if(parseInt((sessionStorage as any).getItem('timer_checkout_braintree_setup_complete')) - parseInt((sessionStorage as any).getItem('timer_checkout_braintree_start')) < 0) {
              trackingEvent = 'Braintree Tracking Issue';
            }
            if (parseInt((sessionStorage as any).getItem('timer_checkout_braintree_jwt_callback_complete')) - parseInt((sessionStorage as any).getItem('timer_start')) > 100000) {
              trackingEvent = 'Braintree Tracking Issue';
            }
            if(parseInt((sessionStorage as any).getItem('timer_checkout_braintree_jwt_callback_complete')) - parseInt((sessionStorage as any).getItem('timer_checkout_braintree_start')) > 100000) {
              trackingEvent = 'Braintree Tracking Issue';
            }
            if(parseInt((sessionStorage as any).getItem('timer_checkout_braintree_setup_complete')) - parseInt((sessionStorage as any).getItem('timer_checkout_braintree_start')) > 100000) {
              trackingEvent = 'Braintree Tracking Issue';
            }
            this.funnelProvider.sendCustomStats(trackingEvent, {
              start: sessionStorage.getItem('timer_start'),
              confs_load: sessionStorage.getItem('timer_funnel_confs_loaded'),
              framework_step1: sessionStorage.getItem('timer_framework_initial_loaded'),
              framework_step2: sessionStorage.getItem('timer_framework_step1_loaded'),
              ionview: sessionStorage.getItem('timer_checkout_ionviewwillenter'),
              braintreestart: sessionStorage.getItem('timer_checkout_braintree_start'),
              jwt_load: sessionStorage.getItem('timer_checkout_braintree_jwt_callback_complete'),
              paypal_load: sessionStorage.getItem('timer_checkout_braintree_paypal_button_loaded'),
              braintree_complete: sessionStorage.getItem('timer_checkout_braintree_setup_complete'),
              totaltime: parseInt((sessionStorage as any).getItem('timer_checkout_braintree_jwt_callback_complete')) - parseInt((sessionStorage as any).getItem('timer_start')),
              braintreesdktime: parseInt((sessionStorage as any).getItem('timer_checkout_braintree_jwt_callback_complete')) - parseInt((sessionStorage as any).getItem('timer_checkout_braintree_start')),
              braintreetime: parseInt((sessionStorage as any).getItem('timer_checkout_braintree_setup_complete')) - parseInt((sessionStorage as any).getItem('timer_checkout_braintree_start'))
            });
          }, 7000);
        } catch (e) {
          console.log('error setting up '+e);
        }
         */
        console.log('[GH] jwt callback ', data);
        this.braintreeClientToken = data['jwt'];
        if (this.braintreeClientToken && this.braintreeClientToken.trim().length > 0) {
          this.showPayPalPayButton = false; // use paypal from braintree
          sessionStorage.setItem('braintreeDefaultCountry', this.braintreeDefaultCountry);
          sessionStorage.setItem('braintreeLanguageTranslation', this.braintreeLanguageTranslation.toString());
          console.log('[GH] this.currency', this.currency);
          console.log('[GH] this.currencySymbol', this.currencySymbol);
          sessionStorage.setItem('currency', this.currency);
          sessionStorage.setItem('currencySymbol', this.currencySymbol);
          this.braintreeEnabled = true;
          this.setupBrainTree(this.braintreeClientToken);
          this.showPayPalPayButton = false;

        } else {
          this.showPayPalPayButton = true; // use paypal legacy
        }
      }
      let jwtscript = document.createElement('script');
      jwtscript.src = '/checkout-jwt/?callback=jwtCallback';
      jwtscript.type = 'application/javascript';
      document.body.appendChild(jwtscript);
    }
  }
  paypalButtonLoaded() {
    try {
      if((<HTMLDivElement>document.querySelector('#paypal-button')).childNodes.length > 0) {
/*         try {
          sessionStorage.setItem('timer_checkout_braintree_paypal_button_loaded', new Date().getTime().toString());
        } catch (e) {
          console.log('error setting up '+e);
        } */
       return true;
      }
    } catch (e) {
      return true;
    }
    return false;
  }
  braintreeCountryConfig() {
    console.log('[GH] braintreeCountryConfig',this.braintreeDefaultCountry);
    if (!this.isRememberInfoChecked && typeof this.braintreeDefaultCountry === 'string') {
      if ((document.querySelector('#country') as HTMLSelectElement).innerHTML.toString().indexOf(this.braintreeDefaultCountry) > -1) {
        this.guest.country = this.braintreeDefaultCountry;
        const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.guest.country);
        this.shippingCost = country === SHIP_FROM_COUNTRY ? (this.funnel.shippingCost as any) + this.calculatedShippingCost :
          (this.funnel.internationalShippingCost as any) + this.calculatedShippingCost;
        this.accountForm.form.controls.country.patchValue(this.braintreeDefaultCountry);
        this.accountForm.form.controls.country.updateValueAndValidity();
        this.statesList = this.getStates((this.countries as any)[this.braintreeDefaultCountry] as Country);
        this.ngZone.run(() => {
        });
        this.address.country = this.guest.country;
        console.log('[GH] this.address', this.address);
        this.validateAddressAndCalculateTaxes('billing');
      }
    }
  }
  handleBraintreeDefaultSetup() {
    setTimeout(() => {
      console.log('[GH] handleBraintreeDefaultSetup', this.diffAddress);
      if (this.braintreeEnabled && this.diffAddress && this.isSelectedCB2 && typeof this.braintreeDefaultCountry === 'string' &&
        typeof this.diffAddress.form.controls.addShipAddress.value == 'undefined' &&
        (typeof this.diffAddress.form.controls.nameToShip.value == 'undefined' || this.diffAddress.form.controls.nameToShip.value.trim().length == 0) &&
        typeof this.diffAddress.form.controls.shipAddress.value == 'undefined' &&
        typeof this.diffAddress.form.controls.shipCity.value == 'undefined' &&
        typeof this.diffAddress.form.controls.shipCountry.value == 'string' &&
        this.diffAddress.form.controls.shipCountry.value == 'United States' &&
        typeof this.diffAddress.form.controls.shipPostal.value == 'undefined' &&
        (typeof this.diffAddress.form.controls.shipState.value == 'undefined' || (typeof this.diffAddress.form.controls.shipState.value == 'string' && this.diffAddress.form.controls.shipState.value.trim().length == 0))
      ) {
        if ((document.querySelector('#country') as HTMLSelectElement).innerHTML.toString().indexOf(this.braintreeDefaultCountry) > -1) {
          this.shippingAddress.country = this.braintreeDefaultCountry;
          const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.shippingAddress.country);
          this.shippingCost = country === SHIP_FROM_COUNTRY ? (this.funnel.shippingCost as any) + this.calculatedShippingCost :
            (this.funnel.internationalShippingCost as any) + this.calculatedShippingCost;
          this.diffAddress.form.controls.shipCountry.patchValue(this.braintreeDefaultCountry);
          this.diffAddress.form.controls.shipCountry.updateValueAndValidity();
          this.differentAddressStatesList = this.getStates((this.countries as any)[this.braintreeDefaultCountry] as Country);
          this.ngZone.run(() => {
          });
          this.validateAddressAndCalculateTaxes('shipping');
        }
      }
      console.log('[GH] handleBraintreeDefaultSetup', this.diffAddress);
    }, 333);
  }
  tearDownBraintree() {
    this.braintreeCurrencyConversion = 1;
    this.braintreeSplitTesting = false;
    this.currency = 'USD';
    this.currencySymbol = '$'; // TODO get from constants
    this.showPayPalPayButton = true; 
    this.braintreeClientToken = null;
    this.braintreeInstance = null;
    this.braintreeEnabled = false;
    this.ngZone.run(() => {
      this.braintreeDisplayHandler();
    });
    sessionStorage.removeItem('currencySymbol');
    sessionStorage.removeItem('currency');
  }
  braintreeDisplayHandler() {
    setTimeout(() => {
      this.isBraintreeLoading = false;
    }, 333);
  }
  /* Braintree get new single payment method nonce */
  async braintreeGetNonce() {
    return this.paypalCheckoutInstance.tokenizePayment(this.braintreeTokenizePaymentData, (err:any, payload:any) => {
      console.log('[GH] braintree paypal tokenized payment', payload);
      // TOUC-14710 https://developer.paypal.com/braintree/docs/guides/payment-method-nonces
      console.log('[GH] braintree payload', payload);
      console.log('[GH] braintree payload this.accountForm ', this.accountForm);
      console.log('[GH] braintree payload this.diffAddress ', this.diffAddress);
      this.payPalAddressHandler(payload);
      this.braintreeNonce = payload.nonce;
      console.log('[GH] braintreeNonce ', this.braintreeNonce);
      this.continuePayingWithBraintree();
    });
  }
  /* Braintree get new single payment method nonce */
  async braintreeHostedFieldsGetNonce() { // TOUC-10882
    console.log('[GH] braintreeHostedFieldsGetNonce() here');
    return this.braintreeHostedFieldsInstance.tokenize(this.braintreeTokenizePaymentData, (tokenizeErr:any, payload:any) => {
      if (tokenizeErr) {
        console.error('Error tokenizing payment method:', tokenizeErr);
      }else{
        console.log('[GH] braintreeHostedFieldsGetNonce() braintree paypal tokenized payment', payload);
        // TOUC-10882 maybe add doc url
        console.log('[GH] a braintree payload', payload);
        console.log('[GH] braintree payload this.accountForm ', this.accountForm);
        console.log('[GH] braintree payload this.shippingForm ', this.diffAddress);
        //this.payPalAddressHandler(payload); // here we should have the right address and patched the payment already so no need to run this again??? maybe test various scenarious like changing address after creating a billig agreement
        this.braintreeNonce = payload.nonce;
        console.log('[GH] braintreeHostedFieldsGetNonce() braintreeNonce ', this.braintreeNonce);
        this.continuePayingWithBraintree();
      }
    });

  }
  async setupBrainTree(braintreeClientToken: any) {
    if(this.braintreeDropInEnabled) {
      await this.setupBraintreeDropIn(braintreeClientToken);
    } else {      
      this.braintreepaypalSetup();
      await this.setupBraintreeHostedFields(braintreeClientToken);
      this.braintreeCountryConfig();
    }
    (document.querySelector('.loader') as HTMLElement).classList.toggle('hidden');

  }
  
  setupBraintreeHostedFields(braintreeClientToken: any) {
    (window as any)['braintree'].client.create({
      authorization: braintreeClientToken,
    }, (clientErr:any, clientInstance:any) => {

      if (clientErr) {
        console.error('[GH] error creating braintree client instance:', clientErr);
        return;
      }
      console.log('[GH] braintree client instance:', clientInstance);
      let font_size= '14px';
      if(this.general.isMobile()) font_size = '22px';
      // Initialize Hosted Fields

      (window as any)['braintree'].hostedFields.create({
        client: clientInstance,
        selector: '#dropin-container-hosted-fields',  // Use a different container ID
        styles: {
          input: {
            'font-size': font_size,
            'font-family': 'Arial, sans-serif',
          },
        },
        fields: {
          cardholderName: {
            container: '#nameOnCardBT',
            placeholder: '',
            classname: 'braintree_item_input'
          },
          number: {
            container: '#cardNumberBT',
            placeholder: '',
          },
          expirationDate: {
            container: '#expirationDateBT',
            placeholder: '',
          },
          cvv: {
            container: '#cvcCodeBT',
            placeholder: '',
          },
        },
      }, (hostedFieldsErr:any, hostedFieldsInstance:any) => {

        if (hostedFieldsErr) {
          console.error('[GH] error creating braintree hosted fields instance:', hostedFieldsErr);
          return;
        }


        console.log('[GH] braintree hosted fields instance:', hostedFieldsInstance);

        hostedFieldsInstance.on('focus', (event:any) =>{
          console.log('[GH] Braintree focus event', event);
          if(!this.braintreeCardFocusSent) {
            this.braintreeCardFocusSent = true;
            this.emitCustomEvent('PaymentFormTouched', 'BrainTree');
          }
        });

        hostedFieldsInstance.on('blur', (event:any) => {
          console.log('[GH] Braintree blur event:' + typeof event, event);
          if(event.cards && event.cards.length == 1) {
            if(event.cards[0].niceType == 'Visa') (document.getElementById("typeOfCardBT") as HTMLSelectElement).options[0].selected = true;
            else if(event.cards[0].niceType == 'Mastercard') (document.getElementById("typeOfCardBT") as HTMLSelectElement).options[1].selected = true;
            else if(event.cards[0].niceType == 'American Express') (document.getElementById("typeOfCardBT") as HTMLSelectElement).options[2].selected = true;
          }
          try {
            (document.querySelector('#nameOnCardBTerror') as HTMLElement).classList.add('hidden');
            (document.querySelector('#cardNumberBTerror') as HTMLElement).classList.add('hidden');
            (document.querySelector('#expirationDateBTerror') as HTMLElement).classList.add('hidden');
            (document.querySelector('#cvcCodeBTerror') as HTMLElement).classList.add('hidden');

            if (event) {
              if (!event.fields.cardholderName.isValid && !event.fields.cardholderName.isEmpty) (document.querySelector('#nameOnCardBTerror') as HTMLElement).classList.remove('hidden');
              if (!event.fields.number.isValid && !event.fields.number.isEmpty) (document.querySelector('#cardNumberBTerror') as HTMLElement).classList.remove('hidden');
              if (!event.fields.expirationDate.isValid && !event.fields.expirationDate.isEmpty) (document.querySelector('#expirationDateBTerror') as HTMLElement).classList.remove('hidden');
              if (!event.fields.cvv.isValid && !event.fields.cvv.isEmpty) (document.querySelector('#cvcCodeBTerror') as HTMLElement).classList.remove('hidden');
            }

          } catch (e) {
            console.log('[GH] error hiding BTerror', e);
          }
        });

        hostedFieldsInstance.on('validityChange', (event:any) => {
          console.log('[GH] Braintree validityChange event', event)
          this.braintreeHostedFieldsValid = false;
          this.braintreeeHostedFieldsEvent = event;
          try {
            setTimeout(() => {
              (<HTMLDivElement>document.querySelector('#paypal-button')).style.display = 'block';
            }, 1000);
          } catch (e) {
            // TODO handle error
          }
          if (event.fields.number.isValid && event.fields.cvv.isValid && event.fields.cardholderName.isValid && event.fields.expirationDate.isValid) {
            this.emitCustomEvent( 'PaymentFormValid', 'BrainTree');
            this.braintreeHostedFieldsValid = true;
            console.log('[GH] Braintree validityChange valid event', event);
            try {
              setTimeout(() => {
                (<HTMLDivElement>document.querySelector('#paypal-button')).style.display = 'none';
              }, 1000);
            } catch (e) {
              // TODO handle error
            }
          }
        });

        hostedFieldsInstance.on('paymentOptionSelected', (event:any) => {
          this.emitCustomEvent('PaymentOptionSelected', 'BrainTree');
          console.log('[GH] Braintree paymentOptionSelected event', event);
        });

        this.braintreeInstance = clientInstance;
        console.log('[GH] braintree client instance created ', clientInstance);
        if (clientErr) console.log('[GH] braintree client err', clientErr); // TODO error handling
        (window as any)['braintreeinstance'] = clientInstance;

        this.braintreeHostedFieldsInstance = hostedFieldsInstance;
        console.log('[GH] braintree hosted fields instance created ', hostedFieldsInstance);
        if (clientErr) console.log('[GH] braintree hosted fields err', hostedFieldsErr); // TODO error handling
        (window as any)['braintreehostedfieldsinstance'] = hostedFieldsInstance;

        if (sessionStorage.getItem('currency')) this.currency = sessionStorage.getItem('currency')+'';
        if (sessionStorage.getItem('currencySymbol')) this.currencySymbol = sessionStorage.getItem('currencySymbol')+'';
        console.log('[GH] braintree this currency symbol', this.currencySymbol);

  
      });
    });

  }

  setupBraintreeDropIn(braintreeClientToken: any) {

    let dis = document.createElement('script');
    dis.src = 'https://js.braintreegateway.com/web/dropin/1.43.0/js/dropin.min.js';
    dis.onload = () => {
      console.log('[GH] braintree dropin loaded');
      let dropinConfig: any = {
        authorization: braintreeClientToken,
        selector: '#dropin-container',
        dataCollector: true, // request from BT
        card: {
          cardholderName: {
            required: true
          }
        },
      };

      if (this.useNewBraintreeStyles) {
        (dropinConfig.card as any)['overrides'] = {
          fields: {
            postalCode: null,
            number: {
              placeholder: '',
              supportedCardBrands: {
                'discover': false,
                'jcb': false,
                'unionpay': false,
              }
            },
            cardholderName: {
              placeholder: ''
            },
            expirationDate: {
              placeholder: ''
            },
            cvv: {
              placeholder: ''
            },
            styles: {
              input: {
                'font-size': '14px',
                'font-family': 'Arial, sans-serif'
              }
            }
          },
        };
      } else {
        dropinConfig['overrides'] = {
          fields: {
            postalCode: null,
            number: {
              supportedCardBrands: {
                'discover': false,
                'jcb': false,
                'unionpay': false,
              }
            }
          }
        };
      }

      if ((window as any)['process_env']['BRAINTREE_PAYPAL_DROPIN_ENABLED'] === 'true') {
        dropinConfig['paypal'] = {
          enableShippingAddress: true,
          flow: 'vault'
        }
      }

      console.log('[GH] braintree dropin config', dropinConfig);

      (window as any)['braintree'].dropin.create(dropinConfig, (err: any, instance: any) => {

        instance.on('card:focus', (event: any) => {
          console.log('[GH] Braintree card:focus event', event);
          if (!this.braintreeCardFocusSent) {
            this.braintreeCardFocusSent = true;
            this.emitCustomEvent('PaymentFormTouched', 'BrainTree');
          }
        });
        instance.on('card:blur', (event: any) => {
          console.log('[GH] Braintree card:blur event', event);
        });
        instance.on('card:validityChange', (event: any) => {
          this.braintreeDropinValid = false;
          if (event.fields.number.isValid && event.fields.cvv.isValid && event.fields.cardholderName.isValid && event.fields.expirationDate.isValid) {
            this.emitCustomEvent('PaymentFormValid', 'BrainTree');
            this.braintreeDropinValid = true;
            console.log('[GH] Braintree card:validityChange valid event', event);
            try {
              setTimeout(() => {
                (<HTMLDivElement>document.querySelector('#paypal-button')).style.display = 'none';
                (<HTMLSpanElement>document.querySelector('.braintree-toggle > span')).innerHTML = 'Remember my information and choose another way to pay';
                (<HTMLSpanElement>document.querySelector('.braintree-toggle > span')).addEventListener('click', () => {
                  this.braintreeChooseAnotherWayToPay(null);
                });
              }, 1000);
            } catch (e) {
              // TODO handle error
            }
          }
          // TODO funnel event???
        });
        instance.on('paymentOptionSelected', (event: any) => {
          this.emitCustomEvent('PaymentOptionSelected', 'BrainTree');
          console.log('[GH] Braintree paymentOptionSelected event', event);
          if (event.paymentOption === 'paypal') {
            this.isSelectedCB2 = true;
            (document.getElementById('dropin-container') as any).scrollIntoView({ behavior: 'smooth' });
          }
        });
        instance.on('paymentMethodRequestable', async (event: any) => {
          // hide the paypal button as the drop event fired
          try {
            (<HTMLDivElement>document.querySelector('#dropin-container-paypal')).style.display = 'none';
          } catch (e) {
            // TODO handle error
          }
          console.log('[GH] Braintree paymentMethodRequestable event', event);
          this.braintreeDropinValid = true;
/*           if (event.type === 'PayPalAccount' && event.paymentMethodIsSelected && !this.isSelectedCB2) {
            this.isSelectedCB2 = true;
          } */
          if (!this.braintreePayPalTokenizedPayment) {
            this.braintreeInstance.requestPaymentMethod((err: any, payload: any) => {
              if (err || !payload.nonce) {
                console.log('[GH] braintree error1', err);
                this.braintreeMessageToUser = 'Please check the payment information you entered and try again.';
                this.toastCtrl.create({ message: this.braintreeMessageToUser, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
                return;
              }

              console.log('[GH] braintree dropin payload', payload);
              console.log('[GH] braintree dropin payload this.accountForm ', this.accountForm);
              console.log('[GH] braintree dropin payload this.diffAddress ', this.diffAddress);
              this.payPalAddressHandler(payload);
              this.braintreeDropinPayload = payload;

            });
          } else {
            // paying with paypal checkout vault flow 
          }
        });

        instance.on('noPaymentMethodRequestable', (event: any) => {
          console.log('[GH] Braintree noPaymentMethodRequestable event', event);
          this.braintreeDropinValid = false;
        });

        this.braintreeInstance = instance;
        console.log('[GH] braintree instance created ', instance);
        if (err) console.log('[GH] braintree err', err); // TODO error handling
        (window as any)['braintreeinstance'] = instance;

        if ((sessionStorage as any).getItem('braintreeCurrencyConversion')) this.braintreeCurrencyConversion = parseFloat((sessionStorage as any).getItem('braintreeCurrencyConversion'));
        if ((sessionStorage as any).getItem('currency')) (this.currency as any) = sessionStorage.getItem('currency');
        if ((sessionStorage as any).getItem('currencySymbol')) (this.currencySymbol as any) = sessionStorage.getItem('currencySymbol');
        console.log('[GH] braintree this currency symbol', this.currencySymbol);
      });

      this.braintreeCountryConfig();
      this.braintreepaypalSetup();

      return true;

    };
    document.body.appendChild(dis);






  }

  braintreepaypalSetup() {
    // if ((window as any)['process_env']['BRAINTREE_PAYPAL_ENABLED'] === 'true') {
    // Create a client.
    (window as any)['braintree'].client.create({
      authorization: this.braintreeClientToken,
    }, (clientErr: any, clientInstance: any) => {
      if (clientErr) {
        this.braintreeDisplayHandler();
        console.error('Error creating client:', clientErr);
        return;
      }
      // Create a PayPal Checkout component.
      (window as any)['braintree'].paypalCheckout.create({
        client: clientInstance
      }, (paypalCheckoutErr: any, paypalCheckoutInstance: any) => {
        this.paypalCheckoutInstance = paypalCheckoutInstance;
        let paypalCheckoutInstancePayload = {
          commit: false, // Per Braintree, this makes the Blue PayPal button show 'Continue'
          vault: true,
          currency: this.officialCurrency,
          intent: 'capture'
        };
        if ((window as any)['process_env']['BRAINTREE_AUTOCOMPLETE_CHECKOUT'] == 'true' || document.location.search.indexOf('braintreeautocomplete=true') > -1) {
          paypalCheckoutInstancePayload['commit'] = true;
        }
        paypalCheckoutInstance.loadPayPalSDK(paypalCheckoutInstancePayload, () => {
          console.log('[GH] braintree paypal sdk loaded');
          this.payPalLoader();
          this.braintreeDisplayHandler();
        });
      });
    });
    // }

  }

  whatispaypal() {
    let u = 'https://www.paypal.com/webapps/mpp/paypal-popup';
    let w = 400;
    let h = 500;
    let l = (window['screen'].width - w) / 2;
    let t = (window['screen'].height - h) / 2;
    window.open(u, "whatispaypal", 'resizable=yes, width=' + w + ', height=' + h + ', top=' + t + ', left=' + l);
    return false;
  }

  async braintreeChooseAnotherWayToPay(event:any) {
    try {
      if(event) event.stopPropagation();
      this.braintreePayPalTokenizedPayment = null;
      if(! this.isRememberInfoChecked) await this.changeStatusIsRemembered();
      this.spinner.enable('Please Wait...');
      setTimeout(() => {
        this.spinner.disable();
        document.location.reload(); // TODO handle this better
      }, 1000);
    } catch (e) {
    }
    return false;
  }

  async actionHandler(data:any, actions:any) {
    console.log('[GH] braintree paypal shipping change', data);
    if(typeof  data.paymentID == 'string' ) this.paypalpaymentId = data.paymentID;
    console.log('[GH] braintree paypal shipping change actions', actions);
    // initial offer price loaded here and updated on recalculations
    console.log('[GH] braintree paypal sdk loaded with totalPrice', this.totalPrice);
    console.log('[GH] braintree paypal sdk loaded with funnel.offerPrice',this.funnel.offerPrice);
    console.log('[GH] braintree paypal sdk loaded with braintreeCurrencyConversion', this.braintreeCurrencyConversion);

    if (typeof data.shipping_address == 'object') {
      let validCountry = false;
      for (const key in this.countries) {
        //console.log('[GH] checking country ', key);
        if (this.countries.hasOwnProperty(key)) {
          //console.log('[GH] hasownproperty country ', key);
          if ((this.countries as any)[key].value == data.shipping_address.country_code) {
            //console.log('[GH] valid country ', (this.countries as any)[key].value);
            validCountry = true;
          }
        }
      }
      if(!validCountry) {
        console.log('[GH] invalid country ', data.shipping_address.country_code);
        return false;
      }
    } 

    await this.payPalAddressHandler(data);
    await this.payPalCallUpdatePayment();
    return true;
  }

  payPalCallUpdatePayment() {
    if (!this.braintreeTokenizePaymentData || !this.braintreeEnabled) {
      console.log('[GH] payPalCallUpdatePayment called but not set or braintree not enabled '+ this.braintreeEnabled, this.braintreeTokenizePaymentData);
      return;
    }

    setTimeout(() => {
      let ccnt = 0;
      let cint2 = setInterval(() => {
        if (this.braintreeUpdatingCalculation == false || ccnt++ > 20) {
          console.log('[GH] braintree updating payment on ccnt ', ccnt);
          clearInterval(cint2);
          let amount = ((this.totalPrice > 0 ? this.totalPrice : (this.funnel.offerPrice as any)) * this.braintreeCurrencyConversion).toFixed(2);
          let subtotal = (1 * (this.funnel.offerPrice as any) * this.braintreeCurrencyConversion).toFixed(2);
          if (this.totalPrice <= 0) {
            amount = ((1 * (this.funnel.offerPrice as any) * this.braintreeCurrencyConversion) + ((this.funnel.isFreeFirstShipping &&
              this.selectedDeliveryDay == 'Free') ? 0 : (1 * this.shippingCost * this.braintreeCurrencyConversion)) + (1 * this.couponDiscount * this.braintreeCurrencyConversion)).toFixed(2);
          }
          if(this.currency == 'JPY')  amount = (parseFloat(amount)).toFixed(0); // JPY is a zero decimal currency TODO change our angular templates to define how many decimal places to show
          this.payPalUpdatePayment(amount, subtotal);
        }
      }, 100);
    }, 2000);
  }

  payPalUpdatePayment(amount:any, subtotal:any) {
    console.log('[GH] payPalUpdatePayment', amount, subtotal, this.state);
    let updatedPayment = {
      amount: amount,
      currency: this.officialCurrency,
      lineItems: [
        {
          name: "SubTotal",
          quantity: "1",
          kind: "debit",
          description: (this.state && this.state.taxData && this.state.taxData.productDetails) ? (this.state.taxData.productDetails as any)[0]['productDescription'] : document.title,
          unitAmount: subtotal,
          totalAmount: subtotal
        },
        {
          name: "Tax",
          quantity: "1",
          kind: "debit",
          description: 'Tax Amount (will recalculate on the next screen based on shipping address)',
          unitAmount: (this.totalTax * this.braintreeCurrencyConversion).toFixed(2).toString(),
          totalAmount: (this.totalTax * this.braintreeCurrencyConversion).toFixed(2).toString(),
        },
        {
          name: "Shipping",
          quantity: "1",
          kind: "debit",
          description: 'Shipping Amount (will recalculate on the next screen based on shipping address)',
          unitAmount: (this.funnel.isFreeFirstShipping && this.selectedDeliveryDay == 'Free') ? 0 : (1 * this.shippingCost * this.braintreeCurrencyConversion).toFixed(2).toString(),
          totalAmount: (this.funnel.isFreeFirstShipping && this.selectedDeliveryDay == 'Free') ? 0 : (1 * this.shippingCost * this.braintreeCurrencyConversion).toFixed(2).toString()
        },
        {
          name: "Discount",
          quantity: "1",
          kind: "credit",
          description: 'Discount Amount (will recalculate on the next screen based on coupon code)',
          unitAmount: (this.couponDiscount * this.braintreeCurrencyConversion).toFixed(2).toString().replace('-', ''),
          totalAmount: (this.couponDiscount * this.braintreeCurrencyConversion).toFixed(2).toString().replace('-', '')
        }
      ],
      paymentId: this.paypalpaymentId,
  //    shippingOptions: shippingOptions
    }
    let checksum = parseFloat(amount) - ((parseFloat(subtotal) + parseFloat((this.totalTax * this.braintreeCurrencyConversion).toFixed(2).toString()) + parseFloat( (this.shippingCost * this.braintreeCurrencyConversion).toFixed(2).toString())) + parseFloat( (this.couponDiscount * this.braintreeCurrencyConversion).toFixed(2).toString()));
    console.log('[GH] braintree 1 checksum', checksum);
    if (parseFloat(checksum.toFixed(2)) != 0) {
      let kind = 'credit';
      if (checksum > 0) kind = 'debit';
      let correction =
      {
        name: "Currency Conversion Rounding Correction",
        quantity: "1",
        kind: kind,
        description: 'Currency Conversion Rounding Correction',
        unitAmount: checksum.toFixed(2).replace('-', ''),
        totalAmount: checksum.toFixed(2).replace('-', '')
      }
      updatedPayment.lineItems.push(correction);
    }
    if (this.paypalCheckoutInstance && updatedPayment.paymentId) {
      this.paypalCheckoutInstance.updatePayment(updatedPayment);
      console.log('[GH] updated payment ', updatedPayment);
    }
  }

  payPalLoader() {
    let styles = {
      shape: 'pill',
      label: '',
      height: 32,
    };
    if (this.useNewBraintreeStyles) {
      styles = {
        shape: 'pill',
        label: 'checkout',
        height: 40,
      };
    }
    (window as any)['paypal'].Buttons({
      style: styles,
      fundingSource: (window as any)['paypal'].FUNDING.PAYPAL,
      createOrder: () => {
        this.braintreeCustomLoadingMessage();
        // initial offer price loaded here and updated on recalculations
        console.log('[GH] braintree paypal sdk loaded with totalPrice', this.totalPrice);
        console.log('[GH] braintree paypal sdk loaded with shippingCost', this.shippingCost);
        console.log('[GH] braintree paypal sdk loaded with funnel.offerPrice',this.funnel.offerPrice);
        console.log('[GH] braintree paypal sdk loaded with braintreeCurrencyConversion', this.braintreeCurrencyConversion);
        let amount = ((this.totalPrice > 0 ? this.totalPrice : (this.funnel.offerPrice as any)) * this.braintreeCurrencyConversion).toFixed(2);
        let subtotal = (1 * (this.funnel.offerPrice as any) * this.braintreeCurrencyConversion).toFixed(2);
        if (this.totalPrice <= 0) {
          amount = ((1 * (this.funnel.offerPrice as any) * this.braintreeCurrencyConversion) + ((this.funnel.isFreeFirstShipping &&
            this.selectedDeliveryDay == 'Free') ? 0 : (1 * this.shippingCost * this.braintreeCurrencyConversion)) + (1 * this.couponDiscount * this.braintreeCurrencyConversion)).toFixed(2);
        }
        this.braintreeSubtotalAmount = parseFloat(subtotal);

        // https://www.paypal.com/ph/brc/article/psd2-payment-requirements-make-sure-you-are-ready 
        let estimate = null;
        let estimateAmt = '0';
        try {
          if (this.totalTax == 0 &&  this.braintreeTaxEstimate > 0) {
            let newamt = 0;
            newamt = parseFloat(amount);
            estimateAmt =((newamt * (1+this.braintreeTaxEstimate)) - newamt).toFixed(2) ;
            estimate = {
              name: "Estimated Tax (calculated at checkout)",
              quantity: "1",
              kind: "debit",
              description: 'Estimated Tax Amount',
              unitAmount: estimateAmt,
              totalAmount: estimateAmt
            };
            amount = (newamt + parseFloat(estimateAmt)).toFixed(2);
          }
        } catch (e) {
          console.log('[GH] error calculating tax estimate', e);
        }

        let paypalOptions:any = {
          commit: false,
          requestBillingAgreement: true,
          flow: 'checkout',
          amount: amount,
          currency: this.officialCurrency,
          intent: 'capture', // Must match the intent passed in with loadPayPalSDK
          enableShippingAddress: true,
          shippingAddressEditable: true
        };
        if(this.state && this.state.taxData) {
          let lineItems = [
            {
              name: "SubTotal",
              quantity: "1",
              kind: "debit",
              description: (this.state && this.state.taxData && this.state.taxData.productDetails) ? (this.state.taxData.productDetails as any)[0]['productDescription'] : document.title,
              unitAmount: subtotal,
              totalAmount: subtotal
            },
            {
              name: "Tax (calculated at checkout)",
              quantity: "1",
              kind: "debit",
              description: 'Tax Amount',
              unitAmount: (this.totalTax * this.braintreeCurrencyConversion).toFixed(2).toString(),
              totalAmount: (this.totalTax * this.braintreeCurrencyConversion).toFixed(2).toString(),
            },
            {
              name: "Shipping (calculated at checkout)",
              quantity: "1",
              kind: "debit",
              description: 'Shipping Amount',
              unitAmount: (this.funnel.isFreeFirstShipping && this.selectedDeliveryDay == 'Free') ? 0 : (1 * this.shippingCost * this.braintreeCurrencyConversion).toFixed(2).toString(),
              totalAmount: (this.funnel.isFreeFirstShipping && this.selectedDeliveryDay == 'Free') ? 0 : (1 * this.shippingCost * this.braintreeCurrencyConversion).toFixed(2).toString()
            },
            {
              name: "Discount (calculated at checkout)",
              quantity: "1",
              kind: "credit",
              description: 'Discount Amount',
              unitAmount: (this.couponDiscount * this.braintreeCurrencyConversion).toFixed(2).toString().replace('-', ''),
              totalAmount: (this.couponDiscount * this.braintreeCurrencyConversion).toFixed(2).toString().replace('-', '')
            }
          ];
          paypalOptions['lineItems'] = lineItems;
          let checksum = parseFloat(amount) - ( parseFloat(estimateAmt) + (parseFloat(subtotal) + parseFloat((this.totalTax * this.braintreeCurrencyConversion).toFixed(2).toString()) + parseFloat( (this.shippingCost * this.braintreeCurrencyConversion).toFixed(2).toString())) + parseFloat( (this.couponDiscount * this.braintreeCurrencyConversion).toFixed(2).toString()));
          console.log('[GH] braintree 2 checksum', checksum);
          if (parseFloat(checksum.toFixed(2)) != 0) {
            let kind = 'credit';
            if (checksum > 0) kind = 'debit';
            let correction = {
              name: "Currency Conversion Rounding Correction",
              quantity: "1",
              kind: kind,
              description: 'Currency Conversion Rounding Correction',
              unitAmount: checksum.toFixed(2).replace('-', ''),  // TODO deal with our number always being negative and braintree not liking that
              totalAmount: checksum.toFixed(2).replace('-', '')
            };
            paypalOptions['lineItems'].push(correction);
          }  
        }
        if (estimate && paypalOptions['lineItems']) {
          paypalOptions['lineItems'].push(estimate);
        }
        console.log('[GH] braintree paypal options', paypalOptions);
        this.emitCustomEvent('PayPalButtonClick', 'BrainTree');
        return this.paypalCheckoutInstance.createPayment(paypalOptions);
      },

      onShippingChange: async (data:any, actions:any) => {
/*         this.isSelectedCB2 = true;
 */        return this.actionHandler(data, actions).then((ret) => {
          console.log('[GH] braintree paypal shipping change response: ' + ret, data);
          if (!ret) return actions.reject();
          return actions.resolve();
        });
      },

      onApprove: (data:any, actions:any) => {
        this.braintreeCustomLoadingMessage();
/*         this.isSelectedCB2 = true;
 */        console.log('[GH] braintree paypal approve', data);
        this.braintreeTokenizePaymentData = data;
        if (typeof data.paymentID == 'string') this.paypalpaymentId = data.paymentID;
        console.log('[GH] braintree paypal approve actions', actions);
        this.braintreeTokenizePayment();
        this.emitCustomEvent('PayPalApproved', 'Braintree');
      },

      onCancel: (data:any) => {
        console.log('[GH] handle cancel');
        (window as any)['facebookAutofillDisabled']=false;
        console.log('PayPal payment cancelled', JSON.stringify(data));
        console.log('PayPal payment cancelled for guest', this.guest);
        (<HTMLDivElement> document.querySelector('#dropin-container')).style.display = 'block'; 
        this.emitCustomEvent('PayPalCancelled', 'BrainTree')  ;
        this.braintreePayPalTokenizedPayment = null; 
        this.braintreeSubtotalAmount = 0;
      },

      onError: (err:any) => {
        console.log('todo handle error')
        console.error('PayPal error', err);
        this.spinner.disable();
        this.braintreeMessageToUser = '1Please check the payment information you entered and try again.  Enter a valid Amex, Visa, Master Card or use PayPal.';
        this.toastCtrl.create({ message: this.braintreeMessageToUser, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
        this.emitCustomEvent('PayPalError', 'BrainTree');
      }
    }).render('#paypal-button').then(() => {
      (<HTMLDivElement>document.querySelector('#paypal-button')).style.display = 'block';

    });
  }

  braintreeTokenizePayment() {
    this.spinner.enable('Please Wait...');
    return this.paypalCheckoutInstance.tokenizePayment(this.braintreeTokenizePaymentData, (err:any, payload:any) => {
      console.log('[GH] braintree paypal tokenized payment', payload);
      this.braintreePayPalEmail = payload.details.email;
      this.payPalAddressHandler(payload);
      this.braintreeDropinValid = true;
      this.braintreePayPalTokenizedPayment = payload;
      try {
        if ( (window as any)['process_env']['BRAINTREE_AUTOCOMPLETE_CHECKOUT'] !== 'true' && document.location.search.indexOf('braintreeautocomplete=true') == -1) {
          this.braintreeHidePayPalButton = true;
          if(this.isSelectedCB2) this.changeStateCB2();
          //this.braintreeToast = this.toastCtrl.create({ message: 'Please review your order and click "Submit Order" below.', position: 'top', showCloseButton: true, cssClass: 'successToast' });
          this.spinner.disable();
          setTimeout(() => {
            this.payPalCallUpdatePayment();
          }, 2000);
          setTimeout(() => {
            this.brantreeScreenControl();
          }, 2100);
        } else {    
          this.braintreeCustomLoadingMessage();
          setTimeout(async () => {
            this.brantreeScreenControl();
            setTimeout(async () => {
              let cint3cnt = 0;
              let cint3 = setInterval(async () => {
                if (this.braintreeUpdatingCalculation == false || cint3cnt++ > 10) {
                  clearInterval(cint3);
                  if ( (window as any)['process_env']['BRAINTREE_AUTOCOMPLETE_CHECKOUT'] === 'true' || document.location.search.indexOf('braintreeautocomplete=true') > -1) {
                    this.spinner.enable('Processing Order, Please Wait...');
                    this.payWithBrainTree(this.accountForm, this.diffAddress);
                  }
                }
              }, 300);
            }, 1000);
          }, 4000);
        }
      } catch (e) {
        console.log('[GH] error in braintree paypal approve', e);
      } 
    });
  }

  brantreeScreenControl() {
    console.log('[GH] braintreeScreenControl', this.braintreeUpdatingCalculation);
    try {
      (<HTMLDivElement>document.querySelector('#paymentForm')).style.display = 'none';

      (<HTMLDivElement>document.querySelector('#dropin-container')).style.display = 'none';
      this.braintreePayPalEmail = this.guest.email;
      (<HTMLDivElement>document.querySelector('#dropin-container-paypal')).style.display = 'block';
      console.log('[GH] braintreeUpdatingCalculation', this.braintreeUpdatingCalculation);
      
      (<HTMLSpanElement>document.querySelector('#toggle_addres')).addEventListener('click', () => {
        this.checkboxClickHandler(); 
      });

    } catch (e) {
    }
    this.payPalCallUpdatePayment();

  }

  toProperCase(str:any) {
    return str.replace(/\w\S*/g, function (txt:any) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  };

  pushNewSelectOption(id:any, value:any) {
    var ele = document.getElementById(id);
    var option = document.createElement("option");
    option.text = value;
    option.selected = true;
    (<HTMLSelectElement>ele).add(option);
  }
  
  async payPalAddressHandler(payload: any) {
    console.log('[GH] braintree paypal address handler', payload);
    console.log('[GH] braintree paypal address this.accountForm', this.accountForm);
    console.log('[GH] braintree paypal address this.paymentForm', this.paymentForm);
    console.log('[GH] braintree paypal address this.diffAddress', this.diffAddress);
    if (payload.type === 'PayPalAccount') {

      this.braintreeUpdatingCalculation = true;

      this.accountForm.form.controls.additionalBillingAddressLine.patchValue(''); // blank out this field in case it was previously entered
      this.accountForm.form.controls.additionalBillingAddressLine.updateValueAndValidity();


      this.accountForm.form.controls.city.patchValue(payload.details.shippingAddress.city);
      this.accountForm.form.controls.city.updateValueAndValidity();

      this.accountForm.form.controls.email.patchValue(payload.details.email);
      this.accountForm.form.controls.email.updateValueAndValidity();

      this.accountForm.form.controls.confirmEmail.patchValue(payload.details.email);
      this.accountForm.form.controls.confirmEmail.updateValueAndValidity();

      this.accountForm.form.controls.firstName.patchValue(payload.details.firstName);
      this.accountForm.form.controls.firstName.updateValueAndValidity();

      this.accountForm.form.controls.lastName.patchValue(payload.details.lastName);
      this.accountForm.form.controls.lastName.updateValueAndValidity();

      this.accountForm.form.controls.phone.patchValue(payload.details.phone);
      this.accountForm.form.controls.phone.updateValueAndValidity();

      this.accountForm.form.controls.street.patchValue(payload.details.shippingAddress.line1);
      this.accountForm.form.controls.street.updateValueAndValidity();

      if (typeof payload.details.shippingAddress.line2 == 'string' && payload.details.shippingAddress.line2.length > 0) {
        this.accountForm.form.controls.additionalBillingAddressLine.patchValue(payload.details.shippingAddress.line2);
        this.accountForm.form.controls.additionalBillingAddressLine.updateValueAndValidity();
      }

      this.accountForm.form.controls.zipPostalCode.patchValue(payload.details.shippingAddress.postalCode);
      this.accountForm.form.controls.zipPostalCode.updateValueAndValidity();

      (this.address.postal as any) = this.guest.zipPostalCode;
      (this.address.country as any) = this.guest.country;
      (this.address.city as any) = this.guest.city;
      this.address.state = this.guest.state;
      (this.address.street as any) = this.guest.street;

      this.checkCountry(false);

      for (const key in this.countries) {
        if (this.countries.hasOwnProperty(key)) {
          console.log(`[GH] country 0 ${payload.details.shippingAddress.countryCode}`, (this.countries as any)[key]);
          if ((this.countries as any)[key].value == payload.details.shippingAddress.countryCode) {
            console.log(`[GH] country 1 ${key}`, (document.querySelector('#country') as HTMLSelectElement).innerHTML.toString().indexOf(key));
            if ((document.querySelector('#country') as HTMLSelectElement).innerHTML.toString().indexOf(key) > -1) {
              this.accountForm.form.controls.country.patchValue(key);
              this.accountForm.form.controls.country.markAsDirty();
              this.accountForm.form.controls.country.updateValueAndValidity();
              this.accountForm.form.controls.state.patchValue(''); // set the state to blank in the cases where no valid state is coming from PayPal.
              this.accountForm.form.controls.state.markAsDirty();
              this.accountForm.form.controls.state.updateValueAndValidity();
              this.ngZone.run(() => {
                this.calculate(false);
              });
              this.statesList = this.getStates((this.countries as any)[key] as Country);
              let st = (this.countries as any)[key].states;
              console.log('[GH] braintree paypal address st 3 ', st);
              if (typeof st == 'object' && typeof st[payload.details.shippingAddress.state] == 'string') {
                setTimeout(() => {
                  let st = (this.countries as any)[key].states;
                  console.log('[GH] braintree paypal address st 4 ', st);
                  console.log('[GH] braintree paypal address payload.details.shippingAddress.state', payload.details.shippingAddress.state);
                  this.accountForm.form.controls.state.patchValue(st[payload.details.shippingAddress.state]);
                  this.accountForm.form.controls.state.markAsDirty();
                  this.accountForm.form.controls.state.updateValueAndValidity();
                  this.ngZone.run(() => {
                    this.calculate(false);
                  });
                }, 1100);
              } else {
                let st = (this.countries as any)[key].states;
                let sts = this.swapJsonKeyValues(st);
                if (typeof sts == 'object' && typeof (sts as any)[payload.details.shippingAddress.state] == 'string') {
                  setTimeout(() => {
                    console.log('[GH] braintree paypal address st 4 ', sts);
                    console.log('[GH] braintree paypal address payload.details.shippingAddress.state', payload.details.shippingAddress.state);
                    this.accountForm.form.controls.state.patchValue(payload.details.shippingAddress.state);
                    this.accountForm.form.controls.state.markAsDirty();
                    this.accountForm.form.controls.state.updateValueAndValidity();
                    this.ngZone.run(() => {
                      this.calculate(false);
                    });
                  }, 1100);
                } else {
                  this.braintreePayPalBillingState = payload.details.shippingAddress.state;
                }
              }
            }
          }
        }
      }

      setTimeout(() => {
        console.log('[GH] braintree paypal address this.accountForm after', this.accountForm);
        console.log('[GH] braintree paypal address this.paymentForm after', this.paymentForm);
        console.log('[GH] braintree paypal address this.diffAddress after', this.diffAddress);
        (this.address.postal as any) = this.guest.zipPostalCode;
        (this.address.country as any) = this.guest.country;
        (this.address.city as any) = this.guest.city;
        this.address.state = this.guest.state;
        (this.address.street as any) = this.guest.street;
        console.log('[GH] this.address', this.address);
        if (this.isSelectedCB2) {
          this.shippingAddress = {
            country: this.diffAddress.form.controls.shipCountry.value,
            state: this.diffAddress.form.controls.shipState.value,
            postal: this.diffAddress.form.controls.shipPostal.value,
            city: this.diffAddress.form.controls.shipCity.value,
            street: this.diffAddress.form.controls.shipAddress.value
          };
        } else {
          this.shippingAddress = {
            country: this.guest.country + '',
            state: this.guest.state + '',
            postal: this.guest.zipPostalCode + '',
            city: this.guest.city + '',
            street: this.guest.street + ''
          };
        }
        console.log('[GH] about to calculate with this.shippingAddress', this.shippingAddress);
        this.validateAddressAndCalculateTaxes('shipping');
      }, 1000);
    }
  }

  swapJsonKeyValues(input:any) {
    var one, output:any = {};
    for (one in input) {
        if (input.hasOwnProperty(one)) {
            output[input[one]] = one;
        }
    }
    return output;
  }

  public getBraintreeTaxEstimate() {
    if(!this.accountForm) return 0;
    if(this.accountForm.valid) return 0;
    else if(this.totalTax > 0) return 0;
    else return ((+this.totalPrice) + +this.transactionFeeAmount) * this.braintreeTaxEstimate;
  }

  async payWithBrainTree(accountForm: any, diffAddress:any) {  // TOUC-14710
    this.braintreeCustomCardLoadingMessage();
    // revalidate the forms
    console.log('[GH] payWithBraintree updating totalPrice in local currency: ' + (this.totalPrice * this.braintreeCurrencyConversion).toFixed(2));
    // if (this.braintreeToast && typeof this.braintreeToast.dismiss === 'function') this.braintreeToast.dismiss();
    try {
      if(  Object.keys((window as any)['VanillaToasts'].toasts).length > 0) {
        (window as any)['VanillaToasts'].hide(document.querySelector('#'+Object.keys((window as any)['VanillaToasts'].toasts)[0]))
      }
    } catch (e) {
    }
    this.spinner.enable('Please Wait');
    this.emitCustomEvent('submitOrder', 'payWithBrainTree');
    console.log('[GH] braintree paymentForm', this.paymentForm);
    this.errorMessage = '';
    this.rejectOrder = false;
    this.submittedAccountForm = true;
    this.submittedPaymentForm = false; // disable existing payment form validation rules
    this.couponValidation = true;
    console.log('[GH] this.diffAddress', this.diffAddress);
    if (this.isSelectedCB2) {
      this.shippingAddress = {
        country: this.diffAddress.form.controls.shipCountry.value,
        state: this.diffAddress.form.controls.shipState.value,
        postal: this.diffAddress.form.controls.shipPostal.value,
        city: this.diffAddress.form.controls.shipCity.value,
        street: this.diffAddress.form.controls.shipAddress.value
      };
    } else {
      this.shippingAddress = {
        country: this.guest.country ? this.guest.country : '',
        state: this.guest.state ? this.guest.state : '',
        postal: this.guest.zipPostalCode ? this.guest.zipPostalCode : '',
        city: this.guest.city ? this.guest.city : '',
        street: this.guest.street ? this.guest.street : ''
      };
    }
    console.log('[GH] braintree this.diffAddress', this.diffAddress);
    if (!this.diffAddress || !this.diffAddress.state || this.diffAddress.state.length == 0) {
      let shippingAddress = {
        country: this.guest.country,
        state: this.guest.state,
        postal: this.guest.zipPostalCode,
        city: this.guest.city,
        street: this.guest.street
      };
      if (this.isSelectedCB2) {
        shippingAddress = {
          country: this.diffAddress.form.controls.shipCountry.value,
          state: this.diffAddress.form.controls.shipState.value,
          postal: this.diffAddress.form.controls.shipPostal.value,
          city: this.diffAddress.form.controls.shipCity.value,
          street: this.diffAddress.form.controls.shipAddress.value
        };
      }
    }
  
    console.log('[GH] braintree this.shippingAddress', this.shippingAddress);

    if (accountForm.invalid || (this.guest?.state?.length === 0 && this.statesList.length > 0) || (this.isSelectedCB2 && diffAddress.invalid)) {
      console.log('guest', this.guest);
      console.log('accountform invalid', accountForm.invalid);
      console.log('this.guest.state.length', this.guest?.state?.length);
      console.log('this.statesList.length', this.statesList.length);
      console.log('this.isSelectedCB2', this.isSelectedCB2);
      if(diffAddress) console.log('diffAddress.invalid', diffAddress.invalid);
      console.log('this.differentAddressStatesList.length', this.differentAddressStatesList.length);
      this.spinner.disable();
      this.scrollToErrorBox();
      return;
    }

    if(this.braintreeDropInEnabled && !this.braintreeDropinValid) { // TOUC-14710
      this.spinner.disable();
      this.braintreeMessageToUser = 'Please check the payment information you entered and try again.  Enter a valid Visa, Master Card or use PayPal.';
      this.toastCtrl.create({ message: this.braintreeMessageToUser, position: 'top', showCloseButton: true, cssClass: 'errorToast' });//.present();
      return;
    } 


    if (!this.isTaxCalculated) {
      this.spinner.disable();
      this.showTaxErrorToast(TAX_ERROR_MESSAGE);
      try { // TOUC-891
        const taxAddressMessage =
          this.guest.street + ' ' +
          this.guest.city + ' ' +
          this.guest.state + ' ' +
          this.guest.zipPostalCode + ' ' +
          this.guest.country;
        this.sendCustomEvent('Checkout Tax 0', window.location.href.substring(0, 200), taxAddressMessage.substring(0, 200)); //TOUC-860
      } catch (e) {
        console.log("error logging tax message event: " + e);
      }
      return;
    }

    try {
      console.log('[GH] accountForm States: ', (this.countries as any)[ this.accountForm.form.controls.country.value ].states)
      if (this.accountForm.form.controls.state.value == '' && JSON.stringify((this.countries as any)[this.accountForm.form.controls.country.value].states).length > 2) {
        this.spinner.disable();
        this.toastCtrl.create({ message: 'Please check the billing address information you entered and try again.', position: 'top', showCloseButton: true, cssClass: 'errorToast' });//.present();
        return;
      }
    } catch (e) {
      console.log('error getting states for country', e);
    }

    if (this.isSelectedCB2 && this.diffAddress) {
      try {
        console.log('[GH] diffAddress States: ', (this.countries as any)[this.diffAddress.form.controls.shipCountry.value].states);
        if (this.diffAddress.form && this.diffAddress.form.controls && this.diffAddress.form.controls.shippingState && this.diffAddress.form.controls.shippingState.value == '' && JSON.stringify((this.countries as any)[this.diffAddress.form.controls.shipCountry.value].states).length > 2) {
          this.spinner.disable();
          this.toastCtrl.create({ message: 'Please check the shipping address information you entered and try again.', position: 'top', showCloseButton: true, cssClass: 'errorToast' });//.present();
          return;
        }
      } catch (e) {
        console.log('error getting states for country', e);
      }

      console.log('[GH] setting shipToName', this.shipToName);
      console.log('[GH] setting shippingAddress', this.shippingAddress);

      this.guest.shipToName = this.shipToName;
      this.guest.shippingAddress = this.shippingAddress;
    } else {
      console.log('[GH] not setting shipToName', this.shipToName);
      console.log('[GH] not setting shippingAddress', this.shippingAddress);
    }

    if(this.braintreeDropInEnabled && this.braintreeDropinValid ) {
      return this.continuePayingWithBraintree();
    } else if(this.braintreeDropInEnabled && !this.braintreeDropinValid ) {
      this.braintreeCustomLoadingMessage();
      console.log('[GH] braintreeDropinValid is not valid so this is BT with PayPal and needs an update', this.braintreeDropinValid);
      await this.payPalUpdatePayment((this.totalPrice * this.braintreeCurrencyConversion).toFixed(2), (this.funnel.offerPrice ? this.funnel.offerPrice : 1 * this.braintreeCurrencyConversion).toFixed(2));  //  getting error 2073 sometimes from BT, try to update the amount to latest before checking out
      return this.braintreeGetNonce();
    } else if(!this.braintreeDropInEnabled && !this.braintreeHostedFieldsValid && !this.braintreePayPalTokenizedPayment) {
      this.spinner.disable();
      this.scrollToErrorBox();
      return;
    } else if(!this.braintreeDropinValid && !this.braintreeHostedFieldsValid && !this.braintreePayPalTokenizedPayment) {
        console.log('[GH] braintreeHostedFieldsValid', this.braintreeHostedFieldsValid);
        console.log('[GH] braintreeInstance', this.braintreeInstance);
        this.spinner.disable();
        this.scrollToErrorBox();
        return;
    } else if(this.braintreePayPalTokenizedPayment) {
      console.log('[GH] braintreePayPalTokenizedPayment', this.braintreePayPalTokenizedPayment);
      return this.continuePayingWithBraintree();
    } else if(this.braintreeHostedFieldsValid) {
      console.log('[GH] braintreeHostedFieldsValid', this.braintreeHostedFieldsValid);
      return this.braintreeHostedFieldsGetNonce();
    }
    this.spinner.disable();

  }

  continuePayingWithBraintree() {
    this.spinner.enable('Processing Order');

    const country = this.calculateHelper.getCountryISOCode(this.countries as any, this.address.country);
    let body: any = this.orderHelperExtender.createBody({

      coupon: this.coupon,
      couponDiscount: +(+this.couponDiscount).toFixed(2),
      totalPrice: +(+this.totalPrice).toFixed(2),
      guest: this.guest,
      totalTaxforBody: +(+this.totalTaxforBody).toFixed(2),
      dataForTax: this.taxData.dataForTax,
      isSubscription: this.isSubscription,
      shippingCost: +(+this.shippingCost).toFixed(2),
      calculatedShippingCost: +(+this.calculatedShippingCost).toFixed(2),
      customerIp: (window as any)['gh']['ip'],
      bumpOffers: this.bumpOffersInCart,
      funnelOffersData: this.funnelOffersData,
      paymentMethod: 'Braintree ' + this.officialCurrency,
      orderFormProduct: this.returnSelectedFunnel(
        this.orderFormProduct,
        this.selectedProduct
      ),
      subscriptionTax: +(+this.subscriptionTax).toFixed(2),   // TODO find out what this is used for at GH if anything
      shippingType: this.shippingProvider.isShippingAvailable()
        ? this.selectedDeliveryDay
        : '',
      totalSubscriptionPrice: +(+this.totalSubscriptionPrice).toFixed(2),
      country: country,
    });

    body.statsInfo = this.funnelProvider.getStats();
    body['namedAgent'] = 'PayPal';
    console.log('[GH] braintree body 1', body);

    return this.tcrApiOrder.setOrderGuest(body).toPromise()
      .then((result: any) => {
        console.log("[GH] setOrderGuest result", result);
        this.spinner.enable('Processing Payment');
        result.order.paymentMethod = 'Braintree ' + this.officialCurrency;
        result.order.crv = this.braintreeCurrencyConversion;
        (window as any)['braintreeReference'] = result.order.name;
        console.log("[GH] createTransactionBraintree", result);
        if (this.braintreePayPalTokenizedPayment) {
          console.log('[GH] braintreePayPalTokenizedPayment', this.braintreePayPalTokenizedPayment);
          let payload = this.braintreePayPalTokenizedPayment;
          this.braintreeResonseHandler(null, payload, body, result);
        } else {
          console.log('[GH] braintreeInstance.requestPaymentMethod , use this.braintreeDropinPayload', this.braintreeDropinPayload);
          this.braintreeResonseHandler(null, this.braintreeDropinPayload, body, result);
        }

      })
      .catch((error) => {
        this.spinner.disable();
        console.log('[GH] error', error);
        this.toastCtrl.create({ message: this.getLabelCheckout('problem'), position: 'top', showCloseButton: true, cssClass: 'errorToast' });//.present();
        this.emitCustomEvent('Braintree Error', error); // TOUC-812
      });


  }

  braintreeResonseHandler(err:any, payload:any, body:any, result:any) {
    console.log('[GH] braintree payload', payload);
    console.log('[GH] braintree body 2', body);
    console.log('[GH] braintree result', result);
    console.log('[GH] braintree err', err);

    if (err || (!this.braintreeNonce && !payload.nonce )) {
       console.log('[GH] braintree error2', err);
       this.braintreeMessageToUser = 'Please check the payment information you entered and try again.  Enter a valid Amex, Visa, Master Card or use PayPal.';
       this.toastCtrl.create({ message: this.braintreeMessageToUser, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
       return;
    }
    
    let xhr = new XMLHttpRequest();
    let asy = true;
    xhr.open('POST', '/checkout-braintree/', asy);
    xhr.withCredentials = true;
    xhr.timeout = 30000;
    if (typeof (window as any)['getCookie'] === 'function') xhr.setRequestHeader('XSRF-TOKEN', (window as any)['getCookie']('XSRF-TOKEN')); // [TOUC-5682]
    xhr.onload = () => {
      console.log("[GH] braintree response data", JSON.parse(xhr.response));
      try {
        let response = JSON.parse(xhr.response);
        if (document.location.search.indexOf('braintreeforcecheckoutdeclined=true') > -1) {
          console.log('[GH] braintreeforcecheckoutdeclined=true');
          response.result.success = false;
        }

        if (response.result && response.result.success)
          (window as any)['braintreeStatus'] = 'Approved';
        else
          (window as any)['braintreeStatus'] = 'Declined';
      
        response['payload'] = payload;
        response['currencyData'] = { code: this.officialCurrency, braintreeCurrencyConversion: this.braintreeCurrencyConversion }; // send them the amount in GBP
        this.readBrainTreeResponse(result, response);
      } catch (e) {
        console.log('[GH] braintree error', e); 
        // TODO catch and handle error
      }
    }
    (xhr as any)['dataType'] = 'json';
    xhr.setRequestHeader('X-Brand',  (window as any)['process_env'].BRAND);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    body['nonce'] = this.braintreeNonce ? this.braintreeNonce : payload.nonce;  
    console.log('this.countries', this.countries);
    console.log('this.guest', this.guest);
    let bstates = (this.countries as any)[this.guest.country as any].states;
    let sstates = (this.countries as any)[this.guest.shippingAddress ? this.guest.shippingAddress.country : (this.guest.country as any)].states;
    console.log('sstates', sstates);
    console.log('bstates', bstates);
    let rsstates = this.swapJsonKeyValues(sstates);
    let rbstates = this.swapJsonKeyValues(bstates);
    console.log('rsstates', rsstates);
    console.log('rbstates', rbstates);
    // format data for paypal
    body['braintreePayPalBillingState'] = this.braintreePayPalBillingState;
    body['braintreePayPalShippingState'] = this.braintreePayPalShippingState;

    body['billingCountryISOCode'] = this.calculateHelper.getCountryISOCode(this.countries as any, this.guest.country as any);
    body['shippingCountryISOCode'] = this.calculateHelper.getCountryISOCode(this.countries as any, (this.guest.shippingAddress && this.guest.shippingAddress.country) ? this.guest.shippingAddress.country : (this.guest.country as any));
    body['billingStateISOCode'] = rbstates[this.guest.state as any];
    body['shippingStateISOCode'] = rsstates[this.guest.shippingAddress ? this.guest.shippingAddress.state : (this.guest.state as any)];
    body['submittedForSetlement'] = true;
    body['amount'] = (parseFloat(body.orderTotal) * this.braintreeCurrencyConversion).toFixed(2);
    if(this.currency == 'JPY')  body['amount'] = (parseFloat(body.orderTotal) * this.braintreeCurrencyConversion).toFixed(0); // JPY is a zero decimal currency TODO change our angular templates to define how many decimal places to show
    body['currency'] = this.officialCurrency; // send them the amount in GBP or other currency
    body['offersData'] = this.funnelOffersData[0];
    if(this.braintreeSubtotalAmount > 0 ) {
      body['braintreeMethod'] = 'paypal_checkout_vault';
    } else {
      body['braintreeMethod'] = 'card';
    }
    try {
      body['productDescription'] = ((this.state.taxData as any).productDetails as any)[0]['productDescription'];
    } catch(e) {
      body['productDescription'] = document.title
    }
    body['subTotalAmount'] = this.braintreeSubtotalAmount > 0 ? this.braintreeSubtotalAmount.toString() : (body.subTotal * this.braintreeCurrencyConversion).toFixed(2);
    body['shippingAmount'] = (body.shippingCost * this.braintreeCurrencyConversion ).toFixed(2);
    body['taxAmount'] = (body.totalTax * this.braintreeCurrencyConversion ).toFixed(2);
    body['discountAmount'] = (body.couponDiscount * this.braintreeCurrencyConversion).toFixed(2);
    console.log('[GH] creating braintree client', this.braintreeClientToken );

    (window as any)['braintree'].client.create({
      authorization: this.braintreeClientToken,
    }, (err:any, clientInstance:any) => {
      console.log('[GH] created braintree client', clientInstance);

      (window as any)['braintree'].dataCollector.create({
        client: clientInstance
      }, (err:any, dataCollectorInstance:any) => {
        if (err) {
          console.log('[GH] error creating braintree client', err);
          // TODO Handle error in creation of data collector
          return;
        }
        console.log('[GH] success creating braintree dataCollectorInstance', dataCollectorInstance);
        sessionStorage.setItem('description', (window as any)['braintreeReference']);
        let description = (window as any)['braintreeReference'] + ' currencyConversion ' + this.braintreeCurrencyConversion + ' Total: $' + (body.orderTotal) + '  Tax: $' + body.totalTax; 
        body['description'] = description;
        body['deviceData'] = dataCollectorInstance.deviceData;
        try {
          sessionStorage.setItem('deviceData', dataCollectorInstance.deviceData);
        } catch (e) {
          console.log('[GH] error setting deviceData', e);
        }
        console.log('[GH] sending braintree request', body);
        xhr.send(JSON.stringify(body));
      });
    });

  }

  readBrainTreeResponse(result:any, response:any) {
    if ((window as any)['braintreeStatus'] !== 'Approved') {
      this.spinner.disable();
      this.createTransactionBraintree(result, response, 'Failed');
      console.log('[GH] braintreeStatus Failed', response);
      if(response.result.message.indexOf("Payment instrument type is not accepted by this merchant account.") > -1) {
        this.toastCtrl.create({ message: 'Please enter a Amex, Master Card or Visa or use PayPal and retry the order.', position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
      } else if (response.result.message.indexOf("Address Verification Failed") > -1) {
        this.toastCtrl.create({ message: 'Sorry, your payment has been declined. A match of the Shipping Address City, State, and Postal Code failed. Please update the payment information and wait a minute before trying again. ' + response.result.message, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
      } else {
        this.toastCtrl.create({ message: 'The processor declined the order. Please update the payment information and wait a minute before trying again. ' + response.result.message, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
      }       
     // this.braintreeHidePayPalButton = false;
     // this.showPayPalPayButton = true;
     // this.braintreePayPalTokenizedPayment = null;
      this.scrollToErrorBox();
      this.sendCustomEvent('Braintree Checkout Error', window.location.href.substring(0, 200), 'Braintree status ' + (window as any)['braintreeStatus']); //TOUC-860
      console.log('[GH] braintreeStatus', 'Failed');
      return;
    }
    console.log('[GH] braintreeStatus', (window as any)['braintreeStatus']);
    delete (window as any)['braintreeStatus'];
    this.createTransactionBraintree(result, response, 'Approved');
    return this.stateProvider.setOrderDetails({ order: result.order })
      .then(async (state) => {
        this.state = state;
        // Save shipping information to storage
        if (this.shippingProvider.isShippingAvailable()) {
          await this.saveShippingInformation();
        }
        this.spinner.disable();
        await this.stateProvider.setTaxData(this.taxData.dataForTax);
        this.cart.cleanCart();
        try {
          localStorage.setItem('Greeting', this.accountForm.form.value.firstName);
        } catch (e) {
          console.log('warning' + e);
        }
        this.toastCtrl.create({ message: `SUCCESS!`, duration: 2000, position: 'top' })//.present();
        this.sendCustomEvent('Checkout Success', window.location.href.substring(0, 200), `SUCCESS!`); //TOUC-1243
        this.spinner.enable('Loading page.<br> Please wait');
        result.order.subscribed = this.isSubscription;
        result.order.authorized = false;
        this.eventStreamer.streamEvent('purchase', {
          customTitle: 'Braintree purchase',
          data: result.order,
        });
        let orderDetailsInfowCopy = JSON.parse(JSON.stringify(this.state.orderDetails)) || {};
        orderDetailsInfowCopy.order = result.order;
        orderDetailsInfowCopy.paymentMethod = 'Braintree ' + this.officialCurrency;
        orderDetailsInfowCopy.cr = this.braintreeCurrencyConversion;

        return this.stateProvider.setPageNameAndOrder(this.stateProvider.UPSELL, orderDetailsInfowCopy)
        .then(async () => {
          console.log('Created Order ' + result.order.name)

          await this.stateProvider.clearPurchasedUpsells();
          if(document.location.search.length > 1 && this.urlsHelper.getQueryParams().length == 0 ) {
            this.urlsHelper.fixQueryParams(document.location.search.substring(1));
          }
          const params: any = await this.funnelProvider.getNextPage();
          if (!params) {
            this.loggingProvider.logEvent('Page not found');
            this.sendCustomEvent('Checkout Error', window.location.href.substring(0,200) , `Page not found`); //TOUC-860
            console.log('navigating to component ' + this.stateProvider.ORDER_SUCCESS) ;
            return this.navCtrl.setRootExt(this.stateProvider.ORDER_SUCCESS, { name: 'Order Success' }, {currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 8 }); //TOUC-1202
          //  return this.toastCtrl.create({ message: `Page not found`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
          }

          const { component, orderForm, type } = params;
          if (type === 'Upsell' || type === 'Downsell') {
            await this.stateProvider.setUpsells([orderForm]);
          }
          console.log('[GH] navigating to component ' + component.name)
          return setTimeout(() => {
            return this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true}, {currentStep: this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'}), nextStep: 2 }); //TOUC-1202
          }, 33);
        });

      });
  }

  createTransactionBraintree(result:any, response:any, captureId:any) {
    console.log('[GH] createTransactionBraintree result', result);
    console.log('[GH] createTransactionBraintree response', response);
    let orderId = result.orderId;
    return new Promise((resolve, reject) => {
      let braintreeinterval = setInterval(async () => {
        if (typeof (window as any)['braintreeReference'] == 'string' && response.result.transaction) {
          clearInterval(braintreeinterval);
          let token = response.result.transaction.creditCard.token;
          if(!token && typeof response.result.transaction.paypalAccount !== 'undefined') {
            token = response.result.transaction.paypalAccount.implicitlyVaultedPaymentMethodToken;
          }
          sessionStorage.setItem('braintree-payment-token', token);
          response.result.transaction['TCROrderType'] = this.isSubscription ? 'Subscription' : 'Standard';  // SFB-214
          this.tcrApiOrder.createTransaction({
            orderId: orderId, status: response.result.success ? 'Approved' : 'Failed',
            method: 'Braintree ' + response.result.transaction.currencyIsoCode, message: response.result.transaction.creditCard.maskedNumber.replace('null******null', 'PayPal') +
              ' token ' + token + ' ' 
              + response.result.transaction.status + ' ' + response.result.transaction.currencyIsoCode +  ' currencyConversion ' + this.braintreeCurrencyConversion +
              ' ' +  this.currencySymbol + (response.result.transaction.amount) ,
            original: JSON.stringify(response.result.transaction),
            paymentAttempt: result.paymentAttempt,
            subscriptionId: result.subscriptionId
          })
            .subscribe((result:any) => {
              if (result.message === 'The transaction has been created') {
                resolve({ message: 'success' });
              } else {
                reject('Transaction was not created!');
              }
            }, (error:any) => {
              reject(error);
            });
        }
      }, 300);
    });
  }

}