import { Component, Input } from "@angular/core";
import { FunnelSettingsProvider } from "../../providers/funnel-settings";
import { UrlsProvider } from "../../providers/urls";

@Component({
  selector: "footer-common",
  styleUrls: ['./footer-common.scss'],
  templateUrl: "./footer-common.html"
})
export class FooterCommonComponent {
  // public currentYear: number = new Date().getFullYear();

  @Input() brand: string = '';
  @Input() address: string = '9465 Wilshire Boulevard, Suite 300 Beverly Hills, California, 90212';
  @Input() mainText: string = `<p>The information on this website has not been evaluated by the Food and Drug Administration. These products are not intended to diagnose, treat, cure or prevent any disease.</p>`;
  @Input() mainTextMobile: string = '';
  @Input() footerAdditionalText: string = ``;
  @Input() footerCopyrightContent: string = `© 2021 ${this.brand}. All Rights Reserved.`;
  @Input() footerContentInsideWithLinks: string = '';


  /**
   * @apiDescription Used to render links
   * @apiParam {links} Array containing link objects
   * @apiParamExample {links} Example input Array
   *  [{
   *    label: 'Privacy Policy',
   *    target: '_blank',
   *    page: 'policy',
   *    options: 'width=400,height=400',
   *    show: true,
   *    classes: 'hiddenMobile',
   *  }]
   */
  @Input() links: Array<any> = [];

  readonly copyrightSymbol: string = "© Gundry MD";
  readonly allRightsReserved: string = ". All Rights Reserved ";

  window: any = window;
  currentYear: number = new Date().getFullYear();

  constructor(public funnelProvider: FunnelSettingsProvider, public urls: UrlsProvider) {}
  public getSearch () {
    return window.location.search ; // [TOUC-5682]
  }
  public navigateToPageByGenericUrl(url: string, linkTarget: string, linkOptions: string): void {

    if(url.startsWith("http")) {
      window.open(url, linkTarget, linkOptions);
    } else {
      const currentSessionId = this.funnelProvider.getSessionId();
      const pathName = url;
      let newQueryParams = this.urls.addParamToQuery(
        `${this.getSearch()}`,
        'sessionid',
        currentSessionId
      );
      const nextUrl = `${window.location.origin}/${
        pathName
      }${newQueryParams}`;
      window.open(nextUrl, linkTarget, linkOptions);
    }
    
  }
}
