import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import { Country } from '../../models/country';
import { Category } from '../../models/category';
import { TouchcrApiGeneralProvider } from "./touchcr-api-general";

const API_URL = (window as any)["process_env"].API_URL;

const UTILS_COUNTRIES = 'utils/countries/';

@Injectable({providedIn: 'root'})
export class TouchcrApiGeneralExtenderProvider extends TouchcrApiGeneralProvider {
  override categories!: Category[];

  constructor(
    public override http: HttpClient,
  ) {
    super(http);
  }

  getCountriesDefault(): Observable<Country[]> { // TOUC-492
    let countriesURL;
    if (window.location.protocol === 'https:' ) countriesURL = '/countries.json/';
    else countriesURL = 'http://' + window.location.hostname + ':8080/countries.json'; // for ionic dev sever mode, to get used the nodejs proxy
    return this.http.get<Country[]>(countriesURL)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }
}
