import { Component, Output, Input , EventEmitter, Inject, Injectable} from '@angular/core';

/**
 * Generated class for the UpsellProductBoxComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Injectable({ providedIn: 'root' })
@Component({
  selector: 'upsell-product-box',
  templateUrl: 'upsell-product-box.html',
  styleUrls: ['upsell-product-box.scss'],
})
export class UpsellProductBoxComponent {
  @Input() title: string = '';
  @Input() showTitle: boolean = true;
  @Input() image: string = 'assets/img/No-Image-Available.jpg';
  @Input() imageAlt: string = '';
  @Input() auxImg: any = null;
  @Input() auxImgAlt: string = '';
  @Input() subTitle: string = '';
  @Input() regularPrice: number = 0;
  @Input() instantSavings: number = 0;
  @Input() yourPrice: number = 0;
  @Input() currency: string = 'USD';  // TOUC-14710
  @Input() braintreeCurrencyConversion: number = 1;

  yesButtonText: string = 'YES I WANT THIS SPECIAL ADDED TO MY ORDER'; // TOUC-1243
  noButtonText: string = 'NO THANKS, I UNDERSTAND THAT I WON\'T SEE THIS OFFER AGAIN'; // TOUC-1243
  yesButtonMobileText: string = 'YES I WANT THIS SPECIAL ADDED TO MY ORDER'; // TOUC-1243
  noButtonMobileText: string = 'NO THANKS, I UNDERSTAND THAT I WON\'T SEE THIS OFFER AGAIN'; // TOUC-1243

  @Input() isAmazon: boolean = false;
  @Input() isPlaceOrder: boolean = true;
  @Input() showImage: boolean = true;

  @Input() main_title: string = "Title";
  @Input() subtitle: string = "Subtitle";
  @Input() product_image: string =
    "https://images.thenewgutfix.com/Total_Restore.png";
  @Input() reg_price: number = 100.0;
  @Input() product_savings: number = 10.0;
  @Input() special_price: number = 114.0;
  @Input() buttonId: string = '';
  @Input() walletId: string = '';
  @Input() regularText:string = 'Regular Price';
  @Input() instantSavingsText: string = 'Instant Savings';
  @Input() yourPriceText: string = 'Your Price';
  component_type: number = 1;
  @Output() button_emitter: EventEmitter<boolean> = new EventEmitter();
  @Output() amazonPaymentEmitter: EventEmitter<void> = new EventEmitter();
  button_value: boolean;

  text: string;

  constructor() {
    this.text = "Hello World";
  }

  nextPage(btn_val: boolean) {
    this.button_value = btn_val ? true : false;
    this.button_emitter.emit(this.button_value);
  }
  payWithAmazon() {
    this.amazonPaymentEmitter.emit();
  }
}
