<div style="background-color:#fff;">
  <div *ngIf="!containsRawHTML" class="upsellDownsell">
      <header-with-menu [showLogo]="showHeaderLogo" [icon]="icon" [showContent]="showHeaderContent"
      [displayPhone]="displayPhone" [phone]="phone" [phoneIcon]="phoneIcon" [showOnUpsell]="showHeaderOnUpsell"
      [cartQuantity]="originalPurchaseQuantity"></header-with-menu> 

    <!-- INSERT HEADER HERE -->
    <div class="upDownPage paddedforEmpty" no-padding>
      <ng-container *ngFor="let content of pageContents; let i = index;">
          <div *ngIf="content.type == 'html'">
            <div class="content{{ i + 1 }}col {{ content.name }}Content" [innerHTML]="content.html">
            </div>
          </div>

          <ng-container *ngIf="content.type == 'component'">
            <ng-container>
              <ng-container *ngIf="content.componentName == 'progress-bar'">
                 <progress-bar *ngIf="totalProductsSummary" [total]="minForFreeShipping" [current]="totalProductsSummary"
                  [title]="progressBarTitle" [logo]="progressBarLogo" [subTitle]="progressBarSubTitle"
                  [customText]="progressBarCustomText"></progress-bar> 
              </ng-container>

              <ng-container *ngIf="content.componentName == 'upsell-sticky-bar'">
                 <upsell-sticky-bar [brand]="content.options.productBrand || productBrand"
                  [name]="content.options.productName || productName" [image]="productImage"
                  [quantity]="productQuantity" [total]="productYourPrice" [productUnitType]="productUnitType"
                  [addToCartText]="content.options.addToCartText" (itemAdded)="nextPage($event)"
                  [showButton]="showButton" [buttonYes]="garantee_01_buttonYes"
                  [showButtonYes]="garantee_01_showButtonYes" [buttonNo]="garantee_01_buttonNo"
                  [showButtonNo]="garantee_01_showButtonNo" [description]="garantee_01_description"
                  [showDescription]="garantee_01_showDescription"
                  [upsellCtaTextButtonsClass]="garantee_01_upsellCtaTextButtonsClass"
                  [showImage]="garantee_01_showImage"></upsell-sticky-bar> 
              </ng-container>
              <ng-container *ngIf="content.componentName == 'upsell-steps'">
                    <upsell-steps *ngIf="steps_show" [steps]="steps_items" [stepLabel]="step_label"></upsell-steps> 
              </ng-container>
              <ng-container *ngIf="content.componentName == 'upsell-wait'">
                 <upsell-wait *ngIf="wait_show" [isFull]="wait_isFull" [title]="wait_title"
                  [description]="wait_description" [iconText]="wait_text" [iconWait]="iconImgWait">
                </upsell-wait> 
              </ng-container>
              <ng-container *ngIf="content.componentName == 'simple-video-player'">
                 <simple-video-player [videoID]="content.options.videoID"
                  [videoMessageTitle]="content.options.videoMessageTitle" [videoMessage]="content.options.videoMessage"
                  [autoplay]="content.options.autoplay">
                </simple-video-player> 
              </ng-container>
              <ng-container *ngIf="content.componentName == 'video-player'">
                 <video-player [videoID]="content.options.videoID"
                  [videoMessageTitle]="content.options.videoMessageTitle" [videoMessage]="content.options.videoMessage"
                  [videoPlayType]="content.options.videoPlayType">

                </video-player> 
              </ng-container>
              <ng-container *ngIf="content.componentName == 'upsell-section'">
                 <upsell-section [content]="content.options"></upsell-section> 
              </ng-container>
              
              <ng-container *ngIf="content.componentName == 'upsell-product-box'">
                
                <upsell-product-box *ngIf="product_box_show" [currency]="currency" [braintreeCurrencyConversion]="braintreeCurrencyConversion" [title]="product_box_title"
                  [showTitle]="product_box_showTitle" [image]="productImage" [imageAlt]="product_title"
                  [subTitle]="product_box_subTitle" [regularPrice]="productRegularPrice"
                  [instantSavings]="productInstantSavings" [yourPriceText]="product_box_yourPriceText"
                  [instantSavingsText]="product_box_instantSavingsText" [regularText]="product_box_regularText"
                  [yourPrice]="productYourPrice" [auxImg]="auxImg" [auxImgAlt]="auxImgAlt">
                </upsell-product-box>
              
              </ng-container>
              <ng-container *ngIf="content.componentName == 'upsell-product-box-full'">

                <upsell-product-box-full *ngIf="product_box_full_show" [currency]="currency" [braintreeCurrencyConversion]="braintreeCurrencyConversion" [title]="product_box_full_title"
                  [image]="productImage" [imageAlt]="product_title" [additionalImage]="product_box_full_additionalImage"
                  [yourPriceText]="product_box_yourPriceText" [instantSavingsText]="product_box_instantSavingsText"
                  [regularText]="product_box_regularText" [regularPrice]="productRegularPrice"
                  [instantSavings]="productInstantSavings" [yourPrice]="productYourPrice"></upsell-product-box-full>

              </ng-container>
              <ng-container *ngIf="content.componentName == 'upsell-guarantee'">
                 <upsell-guarantee *ngIf="garantee_01_show" [buttonYes]="garantee_01_buttonYes"
                  [showButtonYes]="garantee_01_showButtonYes" [buttonNo]="garantee_01_buttonNo"
                  [showButtonNo]="garantee_01_showButtonNo" [description]="garantee_01_description"
                  [showDescription]="garantee_01_showDescription" [image]="garantee_01_image"
                  [imageAlt]="garantee_01_imageAlt" [showImage]="garantee_01_showImage"
                  [upsellCtaTextButtonsClass]="garantee_01_upsellCtaTextButtonsClass"
                  (eventEmitter)="nextPage($event)"></upsell-guarantee> 
              </ng-container>
              <ng-container *ngIf="content.componentName == 'upsell-guarantee-2'">
                 <upsell-guarantee *ngIf="garantee_02_show" [buttonYes]="garantee_02_buttonYes"
                  [showButtonYes]="garantee_02_showButtonYes" [buttonNo]="garantee_02_buttonNo"
                  [showButtonNo]="garantee_02_showButtonNo" [description]="garantee_02_description"
                  [showDescription]="garantee_02_showDescription" [image]="garantee_02_image"
                  [imageAlt]="garantee_02_imageAlt" [showImage]="garantee_02_showImage"
                  [upsellCtaTextButtonsClass]="garantee_02_upsellCtaTextButtonsClass"
                  (eventEmitter)="nextPage($event)"></upsell-guarantee> 
              </ng-container>
              <ng-container *ngIf="content.componentName == 'brand-reviews'">
                 <brand-reviews [reviewsTitle]="reviewsTitle" [reviews]="reviews"
                  [perReviewAddOn]="perReviewAddOn"></brand-reviews> 
              </ng-container>
              <ng-container *ngIf="content.componentName == 'upsell-downsell-product-box'">
                 <upsell-downsell-product-box [product]="content.options" (nextPageEvent)="nextPage($event)"
                  [amazonButton]="buttonList[0]" [isAmazon]="isAmazon" 
                  (amazonPaymentEmitter)="payWithAmazon()"> <!-- [AmazonPayAuthorized]="isAmazonPayAuthorized"-->
                </upsell-downsell-product-box> 
              </ng-container>
              <ng-container *ngIf="content.componentName == 'upsell-buttons'">
                <upsell-buttons [buttonYesText]="garantee_01_buttonYes" [buttonNoText]="garantee_01_buttonNo"
                  [showButtonYes]="garantee_01_showButtonYes" [showButtonNo]="garantee_01_showButtonNo"
                  (onClickYesNoButton)="nextPage($event)">
                </upsell-buttons> 
              </ng-container>
            </ng-container>
          </ng-container>
      </ng-container>
    </div>
    <!-- INSERT FOOTER HERE -->
    
    <upsell-footer [links]="footerLinks" [text]="footerText" [brand]="productBrand"
      [address]="footerAddress"></upsell-footer>
  </div>

  <div *ngIf="containsRawHTML" class="rawHTML"></div>
</div>
