import { Injectable } from '@angular/core';
import { TouchcrApiRemoteServicesProvider } from '../touchcr-api-remoteservices';
import { TouchcrApiShippingProvider } from '../TouchcrApiShippingProvider';
const SHIP_FROM_COUNTRY = (window as any)["process_env"].SHIP_FROM_COUNTRY;
@Injectable({providedIn: 'root'})
export class CalculateProvider {

  constructor(
    public tcrApiRemoteServices: TouchcrApiRemoteServicesProvider,
    public shippingProvider: TouchcrApiShippingProvider,
  ) {

  }

  // Calculate all Amounts
  amountsRecalculation({
                         funnelOffersData,
                         bumpOffers,
                         coupon,
                         calculateTax,
                         shippingAddress,
                         countries,
                         calculatedShippingCost,
                         accInfoForShipping,
                         productsForShipping,
                         getShipping,
                         isSubscription,
                       }: any) {
    const country = countries[shippingAddress.country] ? countries[shippingAddress.country].value : shippingAddress.country;
    let totalPrice = 0;
    let funnelPrice = 0;
    let bumpPrice = 0;
    let shippingCost = 0;
    let couponDiscount = 0;
    let totalSubscriptionPrice = 0;

    let taxCost = 0;
    let subscriptionTaxCost = 0;
    let taxData = {};
    let taxError = false;
    let shippingRates:any = [];
    let shippingError = '';

    let error = '';

    // Calculate funnel data
    if (funnelOffersData) {
      const response = this.calculateFunnelData(funnelOffersData, country);
      funnelPrice = response.funnelPrice;
      if(country) {
        shippingCost = country === SHIP_FROM_COUNTRY ? response.shippingCost : response.internationalShippingCost;
      } else {
        shippingCost = 0;
      }
      totalSubscriptionPrice = response.totalSubscriptionPrice;
    }

    // Calculate BumpOffers
    if (bumpOffers ) {
      const response = this.calculateBumpOffers(bumpOffers);
      bumpPrice = response.bumpPrice;
      if(country) {
        shippingCost += country === SHIP_FROM_COUNTRY ? response.shippingCost : response.internationalShippingCost;
      } else {
        shippingCost = 0;
      }
    }

    // Calculate Coupon Discount
    if (coupon && coupon.couponId) {
      const response = this.calculateCouponDiscount(coupon, funnelPrice, shippingCost);
      couponDiscount = response.coupon.couponDiscount || 0;
      coupon = response.coupon;
      error = response.error;
    }

    return Promise.resolve()
      .then(() => {
        if (this.shippingProvider.isShippingAvailable() && getShipping) {
          return this.shippingProvider.calculateShippingCost({
            accountInfo: accInfoForShipping,
            products: productsForShipping,
          }).toPromise();
        }
        return Promise.resolve();
      })
      .then(result => {
        if (result) {
          if (result.error && result.error === 'ADDRESS_VERIFY_FAILURE') {
            shippingError = result.error;
          } else {
            shippingRates = result.rates;
          }
        }
      })
      .then(() => {
        // Tax calculation logic
        // check if not enough data for tax calculation
        if (calculateTax) {
          //console.log('[GH] calculateTax shippingAddress', shippingAddress);
          if(typeof shippingAddress.city == 'undefined' && typeof shippingAddress.street == 'undefined' && typeof shippingAddress.postal == 'undefined' ) {
            //console.log('[GH] calculateTax shippingAddress incomplete address', shippingAddress);
            return Promise.resolve([{
              dataForTax: {},
              totalTax: 0,
              taxError: false,
            }]);
          }
          let promArray = [];
          if (funnelOffersData[0].isFreeFirstShipping) {
            promArray.push(this.calculateTaxRate(0, 0, coupon, shippingAddress, funnelOffersData, bumpOffers, funnelPrice, countries, false));
          } else {
            promArray.push(this.calculateTaxRate(shippingCost + calculatedShippingCost, 0, coupon, shippingAddress, funnelOffersData, bumpOffers, funnelPrice, countries, false));
          }
          if (isSubscription) {
            promArray.push(this.calculateTaxRate(shippingCost + calculatedShippingCost, 0, coupon, shippingAddress, funnelOffersData, bumpOffers, totalSubscriptionPrice, countries, true));
          }
          return Promise.all(promArray);
        }
        else {
          return Promise.resolve([{
            dataForTax: {},
            totalTax: 0,
            taxError: false,
          }]);
        }
      })
      .then((taxArray: any) => {
        ({ totalTax: taxCost, dataForTax: taxData, taxError } = taxArray[0]);
        if (isSubscription && taxArray[1]) {
          ({ totalTax: subscriptionTaxCost } = taxArray[1]);
          if (!funnelOffersData[0].isPurchaseAvailable && funnelOffersData[0].isSubscriptionAvailable && !funnelOffersData[0].isFreeFirstShipping) {
            ({ dataForTax: taxData } = taxArray[1]);
          } else if (!funnelOffersData[0].isPurchaseAvailable && funnelOffersData[0].isSubscriptionAvailable && funnelOffersData[0].isFreeFirstShipping) {
            ({ dataForTax: taxData } = taxArray[0]);
          }
        }
      })
      .then(() => {
        //calculate total price with coupon discount and shipping
        if (!funnelOffersData[0].isFreeFirstShipping) {
          totalPrice = couponDiscount + bumpPrice + shippingCost + funnelPrice + taxCost + calculatedShippingCost;
          //totalSubscriptionPrice += subscriptionTaxCost + calculatedShippingCost;
          // TOUC-5830
          totalSubscriptionPrice += couponDiscount + subscriptionTaxCost + calculatedShippingCost;
        } else {
          totalPrice = couponDiscount + bumpPrice + funnelPrice + taxCost;
          //totalSubscriptionPrice += subscriptionTaxCost; 
          // TOUC-5830         
          totalSubscriptionPrice += couponDiscount + subscriptionTaxCost;

        }
        return Promise.resolve({
          totalPrice: totalPrice,
          funnelPrice: funnelPrice,
          bumpPrice: bumpPrice,
          shippingCost: shippingCost,
          couponDiscount: couponDiscount,
          totalSubscriptionPrice: totalSubscriptionPrice,
          coupon: coupon,
          taxCost: taxCost,
          taxData: taxData,
          taxError: taxError,
          error: error,
          shippingRates: shippingRates,
          shippingError: shippingError,
          subscriptionTax: subscriptionTaxCost,
        });
      });
  }


  // Calculate Funnel Product Price
  calculateFunnelData(funnelData:any, country:any) {
    let funnelPrice = 0;
    let shippingCost = 0;
    let totalSubscriptionPrice = 0;
    let internationalShippingCost = 0;

    for (let i = 0; i < funnelData.length; i++) {
      // Set OrderForm Product price
      funnelPrice +=
        (funnelData[i].isSubscriptionAvailable && !funnelData[i].isPurchaseAvailable
            ? (funnelData[i].subscriptionPrice)
            : (funnelData[i].offerPrice)
        );
      shippingCost += funnelData[i].shippingCost * 1;
      internationalShippingCost += funnelData[i].internationalShippingCost * 1;

      // Set "Subscription Price" for current orderForm Product
      if (funnelData[i].isFreeFirstShipping || !country) {
        totalSubscriptionPrice += (funnelData[i].subscriptionPrice || 0)
      } else {
        if (country === SHIP_FROM_COUNTRY) {
          totalSubscriptionPrice += (funnelData[i].subscriptionPrice || 0) + funnelData[i].shippingCost * 1;
        } else {
          totalSubscriptionPrice += (funnelData[i].subscriptionPrice || 0) + funnelData[i].internationalShippingCost * 1;
        }
      }
    }

    return {
      funnelPrice: funnelPrice || 0,
      shippingCost: shippingCost || 0,
      totalSubscriptionPrice: totalSubscriptionPrice || 0,
      internationalShippingCost: internationalShippingCost || 0,
    };
  }

  // Calculate BumpOffer products
  calculateBumpOffers(bumpOffers:any) {
    let bumpPrice = 0;
    let shippingCost = 0;
    let internationalShippingCost = 0;

    for (let i = 0; i < bumpOffers.length; i++) {
      if (bumpOffers[i].isInCart) {
        bumpPrice += bumpOffers[i].offerPrice * 1;
        shippingCost += bumpOffers[i].shippingCost * 1;
        internationalShippingCost += bumpOffers[i].internationalShippingCost * 1;
      }
    }

    return {
      bumpPrice: bumpPrice || 0,
      shippingCost: shippingCost || 0,
      internationalShippingCost: internationalShippingCost || 0,
    };
  }

  // Calculate Discount if applied coupon
  calculateCouponDiscount(coupon: any, subtotal:any, shippingCost:any) {

    let error = '';

    //if cartOverValue > than price of the products then reset coupon
    if (coupon.cartOverValue > subtotal || (coupon.discountType === '$ Off' && coupon.discountAmount >= subtotal)) {
      error = 'Not reached the minimum amount limit for this coupon';
      coupon.couponDiscount = 0;
    }
    //calculate discount for $ discount coupon
    else if (coupon.discountType === '$ Off') {
      coupon.couponDiscount = coupon.discountAmount > subtotal ? -subtotal : -coupon.discountAmount;
    }
    //calculate discount for % discount coupon
    else if (coupon.discountType === 'Discount %') {
      if (typeof (window as any)["roundHalfEven"] === 'function') {
          coupon.couponDiscount = -((window as any)["roundHalfEven"](subtotal * (coupon.discountAmount / 100)));
          console.log("[GH] coupon.couponDiscount roundHalfEven:",  coupon.couponDiscount);
      } else {
        coupon.couponDiscount = -(Math.round(subtotal * (coupon.discountAmount / 100) * Math.pow(10, 2)) / Math.pow(10, 2));
        console.log("[GH] coupon.couponDiscount Math.round:",  coupon.couponDiscount);
      }
    }
    //error and reset coupon if a "free shipping" coupon was used but shipping'd been already free
    else if (coupon.discountType === 'Free Shipping' && shippingCost === 0) {
      error = 'Shipping is already free';
      coupon.couponDiscount = 0;
    }
    //coupon discount is 0 for "Free Shipping" type of coupon
    else if (coupon.discountType === 'Free Shipping') {
      coupon.couponDiscount = 0;
    }

    return {
      coupon: coupon,
      error: error,
    };

  }

  calculateTaxRate(shippingCost: number, calculatedShippingCost: number, coupon: any, shippingAddress: any, funnelOffersData: any, bumpOffers: any, subtotal: number, countries: any, forSubscription: boolean) {
    let dataForTax = {};
    let totalTax = 0;
    if (!funnelOffersData[0].isPurchaseAvailable && funnelOffersData[0].isFreeFirstShipping && !forSubscription) {
      funnelOffersData[0].offerPrice = funnelOffersData[0].subscriptionPrice;
    }
    return new Promise((resolve) => {
      this.tcrApiRemoteServices.requestDataForTaxes({
        cartData: null,
        funnelOffersData: funnelOffersData,
        bumpOffersData: bumpOffers,
        account: this.generateAccDataForTax(shippingAddress, countries),
        subtotal: subtotal,
        coupon: (coupon && coupon.discountType) ? coupon : null,
        shipping: +shippingCost + +calculatedShippingCost,
        forSubscription: forSubscription,
      }).subscribe((res:any) => {
        dataForTax = res.taxRequest;
        totalTax = res.taxRate.totalTax;
        return resolve({
          dataForTax: dataForTax,
          totalTax: totalTax || 0,
          taxError: false,
        });
      }, () => {
        return resolve({
          dataForTax: dataForTax,
          totalTax: totalTax || 0,
          taxError: true,
        });
      });
    });
  }

  //generate account data for calculate tax
  generateAccDataForTax(shippingAddress: any, countries: any) {
    let statesList = [];
    if (countries[shippingAddress.country]) {
      for (let state in countries[shippingAddress.country].states) {
        statesList.push({ value: state, label: countries[shippingAddress.country].states[state] });
      }
      statesList.reverse();
    }

    let to_state = '';
    for (let i = 0; i < statesList.length; i++) {
      if (statesList[i].label === shippingAddress.state) {
        to_state = statesList[i].value
      }
    }
    let accountDetails = {
      shipTo: {
        line1: shippingAddress.street,
        city: shippingAddress.city,
        region: (to_state && to_state.length > 0) ? to_state : shippingAddress.state,
        country: countries[shippingAddress.country] ? countries[shippingAddress.country].value : shippingAddress.country,
        postalCode: shippingAddress.postal
      }
    };
    return accountDetails;
  }

  getCountryISOCode(countries: { [x: string]: { value: any; }; }, country: string | number) {
    if (countries && countries[country] && countries[country].value) {
      return countries[country].value
    } else {
      return country;
    }
  }
}
