import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralProvider } from './general';
import { Storage } from '@ionic/storage-angular';
import { deepLinkConfig } from '../app.module';
import { UrlsProvider } from './urls';
import { TouchcrApiContentProvider } from './touchcr-api-content';
import { LoggingProvider } from './logging';
import { FunnelSettingsProvider } from "./funnel-settings";
import { StateExtenderProvider } from "./state-extender";
import { NavControllerExt } from '../extensions/nav-controller-extension';

const FUNNEL_CONFS = (window as any)['funnel_confs'] ? (window as any)['funnel_confs'].funnels : null;
export const UNIQUE_IDENTIFIER = 'uid';
export const DESTINATION_ID = 'dsid';
export const CONTENT_ID = 'pcid';


@Injectable({providedIn: 'root'})
export class FunnelSettingsExtenderProvider extends FunnelSettingsProvider {
  private mSTATS: string = 'stats';

  isStorageAvailable: boolean = true;

  constructor(
    private mHttp: HttpClient,
    private mGeneralProvider: GeneralProvider,
    public override storage: Storage,
    public override stateProvider: StateExtenderProvider,
    public override navCtrl: NavControllerExt,
    public override urls: UrlsProvider,
    public override contentProvider: TouchcrApiContentProvider,
    public loggingProvider: LoggingProvider,
  ) {
    super(
      mHttp,
      mGeneralProvider,
      storage,
      stateProvider,
      navCtrl,
      urls,
      contentProvider,
    );

    //Check if storage is unavailable - TCR
    /**TODO */
    /* if (this.storage) {
      this.storage.set('StorageTest', '')
        .then(() => {
          this.storage.remove('StorageTest');
        })
        .catch(() => {
          this.isStorageAvailable = false;
        });
    } */

    this.isStorageAvailable = false


    try { // TOUC-1243 expose the deepLinkConfig for the UAT and Development efforts
      var cntr = 0;
      if (localStorage.getItem("zzxyz") === 'true' || localStorage.getItem("debugMode") === 'true') {
        //deepLinkConfig.links.forEach((item) => {
        //  console.log(cntr++ + " " + window.location.origin + '/' + item.segment);
        //});
        console.log("[GH] ALL THE FUNNEL_CONFS", FUNNEL_CONFS);
        console.log("[GH] deepLinkConfig", deepLinkConfig);
      }
    } catch (e) {
      console.log("can't log deepLinkConfig", e);
    }

  }


 override async saveUserStats(stats: any = {}) {
    if (!stats) return;
    try {
    stats.deviceInfo = await this.mGeneralProvider.getDeviceInfo();
    } catch (e) {
      console.log("observed error in console at runtime on some ios devices in browser stack. ");  //TOUC-1036 observed error ' reference Error Can't find variable Intl getDeviceInfo
    }
    if (this.isStorageAvailable) {
      await this.storage.set(this.mSTATS, stats);
    } else {
      (window as any)['gh'][this.mSTATS] = JSON.stringify(stats);
    }
  }

  override getUserStats() {
    if (this.isStorageAvailable) {
      return this.storage.get(this.mSTATS);
    } else {
      return new Promise(resolve => {
        setTimeout(() => {
          let stats = (window as any)['gh'][this.mSTATS];
          let parsedStats = JSON.parse(stats ? stats : "{}");
          resolve(parsedStats);
        }, 0);
      });
    }
  }

  override getComponent(url: any = '') {
    if (!url) return;

    const component: any = deepLinkConfig.links.find((e) => e.segment === url);
    if (!component) {
      // TOUC-480 log when deep link config derives no component and the user is about to redirect to the default ROUTE
      // remove the uuid from the query string to prevent the default landing page from being broken
      this.loggingProvider.logEvent('component not found');

      let newQuery: any = window.location.search;
      if (this.urls.getParameterFromUrl({ url: newQuery, parameter: UNIQUE_IDENTIFIER })) {
        newQuery =  this.urls.removeParamFromQuery(newQuery, UNIQUE_IDENTIFIER);
      }
      this.urls.setQueryParams(newQuery);

      return;
    }
    return component;
  }

  isFunnelSetupCorrectly(genericUrls: string) {
    try {
      let strArr = genericUrls.split(',');
      let genericUrl = '';
      for (let i = 0; i < strArr.length; i++) {
        genericUrl = strArr[i];
        const mainFunnel = this.getMainFunnelByGenericUrl(genericUrl);
        if (!mainFunnel) {
          console.log('funnel not setup correctly: ' + genericUrl, FUNNEL_CONFS);
          this.loggingProvider.logEvent('funnel not setup correctly: ');
          return false;
        }
      }
    } catch (e) {}
    return true;
  }

  // TOUC-3096 TODO: improve this to only fire initially and when visitorId returns from the api
  override async sendStatsToAPIDest(data: any) {
    super.sendStatsToAPIDest(data);
    if(data && data.eventType && data.eventType.indexOf('Explorium') === -1)
      this.ghtracking(data.eventType);
   }

  override async sendStatsToAPI(data: any) {
    super.sendStatsToAPI(data);
    if(data && data.eventType && data.eventType.indexOf('Explorium') === -1)
      this.ghtracking(data.eventType);
  }

  getCookie(cname: any) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  getss =  () => { // [TOUC-5682]
    if (this.isStorageAvailable)
      return sessionStorage.getItem("affiliateInfo");
    else return {};
  }
  async ghtracking (eventType: any) {

    setTimeout(async () => {
      try {

        //console.log('[GH] tracking event type: ' + eventType);
        let explorium1Payload: any = {};  
        explorium1Payload['alternaiGuestId'] = this.getCookie('alternaiGuestId');
        explorium1Payload['alternaiVisitorId'] = this.getCookie('alternaiVisitorId');
        if (!explorium1Payload['ip'] && (window as any)['gh'] && (window as any)['gh']['ip']) explorium1Payload['ip'] = (window as any)['gh']['ip'];
        if(navigator.userAgent.length > 128)
          explorium1Payload['ua'] = navigator.userAgent.substring(0,128);
        else
          explorium1Payload['ua'] = navigator.userAgent;
          explorium1Payload['gpc'] = (navigator as any)['globalPrivacyControl'];
        //console.log("[GH] sending signal explorium new session", explorium1Payload);
        if((eventType === 'Page View' || eventType === 'New Session') && typeof (window as any)['ExploriumNewSessionSent'] == 'undefined') {
          this.sendCustomStats("Explorium-NewSession", explorium1Payload);
          (window as any)['ExploriumNewSessionSent'] = true;
        }

      } catch (e) {
        console.log("TOUC-3096 - error with new logging on destination : " + e);
      } 
    },1000);
  }
  getHref = function() {
    return window.location.href ; // [TOUC-5682]
  }

}