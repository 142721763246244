import { Injectable } from '@angular/core';
import { Coupon } from '../../models/coupon';
import { OrderForm } from '../../models/orderForm';
import { OrderFormProduct } from '../../models/orderFormProduct';
import { User } from '../../models/user';
const SHIP_FROM_COUNTRY = (window as any)['process_env'].SHIP_FROM_COUNTRY;
@Injectable({ providedIn: 'root' })
export class OrderProvider {

  constructor(
  ) {
  }

  createBody({
    token,
    paymentMethod,
    coupon,
    couponDiscount,
    totalPrice,
    accDetails,
    guest,
    cardDetails,
    totalTaxforBody,
    dataForTax,
    isSubscription,
    shippingCost,
    calculatedShippingCost,
    customerIp,
    funnelOffersData,
    bumpOffers,
    orderFormProduct,
    subscriptionTax,
    shippingType,
    totalSubscriptionPrice,
    country,
  }: {
      token?: any,
      paymentMethod?: string,
      coupon: Coupon,
      couponDiscount: number,
      totalPrice: number,
      accDetails?: any,
      guest: any,
      cardDetails?: any,
      totalTaxforBody: any,
      dataForTax: any,
      isSubscription: boolean,
      shippingCost: number,
      calculatedShippingCost: number,
      customerIp: string,
      funnelOffersData: Array<{
        isSubscriptionAvailable: boolean,
        subscriptionDaysPostpone: boolean,
        isPurchaseAvailable: boolean,
        isFreeFirstShipping: boolean,
        sfid: string,
      }>,
      bumpOffers: Array<OrderForm>,
      orderFormProduct?: Array<OrderFormProduct>,
      subscriptionTax: number,
      shippingType: any,
      totalSubscriptionPrice: number,
      country?: string,
    }) {
    let body = {
      products: [],
      funnels: [],
      bumpoffers: [],
      orderFormProduct: [],
      variants: [],
      orderTotal: (!funnelOffersData[0].isPurchaseAvailable && funnelOffersData[0].isSubscriptionAvailable) ? (!funnelOffersData[0].isFreeFirstShipping ? totalSubscriptionPrice.toFixed(2) : totalPrice.toFixed(2)) : totalPrice.toFixed(2),
      authorize: false,
      coupons: coupon && coupon.couponId && [coupon],
      couponDiscount: couponDiscount,
      paymentMethod: paymentMethod,
      namedAgent: paymentMethod,
      orderSource: 'Funnel',
      accDetails: accDetails,
      cardDetails: !token ? {
        CCV: guest.cardccv,
        exp_year: guest.expyear,
        exp_month: guest.expmonth,
        Number: '' + guest.card1 + guest.card2 + guest.card3 + guest.card4,
      } : { cardId: token },
      totalTax: (!funnelOffersData[0].isPurchaseAvailable && funnelOffersData[0].isSubscriptionAvailable) ? (!funnelOffersData[0].isFreeFirstShipping ? subscriptionTax : totalTaxforBody) : totalTaxforBody,
      dataForTax: dataForTax,
      orderType: isSubscription ? 'Subscription' : 'Standard',
      customerIp: customerIp,
      shippingCost: shippingCost,
      definedShippingCost: calculatedShippingCost,
      subscriptionTax: subscriptionTax,
      shippingType: shippingType,
      isInternationalShipping: country != SHIP_FROM_COUNTRY,
    };

    if (!accDetails) {
      body['accDetails'] = this.getAccountDetailsFromGuest(guest);
    }

    if (cardDetails) {
      body['cardDetails'] = !token ? {
        CCV: cardDetails.cardccv,
        exp_year: cardDetails.expyear,
        exp_month: cardDetails.expmonth,
        Number: '' + (cardDetails.card && cardDetails.card.split(' ').join('')),
      } : { cardId: token };
    } else {
      body['cardDetails'] = !token ? {
        CCV: guest.cardccv,
        exp_year: guest.expyear,
        exp_month: guest.expmonth,
        Number: '' + guest.card1 + guest.card2 + guest.card3 + guest.card4,
      } : { cardId: token };
    }

    if (orderFormProduct) {
      // Set Authorize using information from OrderForm
      body.authorize = funnelOffersData[0].isSubscriptionAvailable && !!funnelOffersData[0].subscriptionDaysPostpone;
      body.orderFormProduct.push(...orderFormProduct.map((elem) => {
        return {
          orderFormProductId: elem.sfid,
          count: 1,
        } as never
      }));
    }

    if (!body.orderFormProduct.length) {
      body.funnels.push(...funnelOffersData.map((elem) => {
        // Set Authorize using information from OrderForm
        body.authorize = elem.isSubscriptionAvailable && !!elem.subscriptionDaysPostpone;
        return {
          orderFormId: elem.sfid,
          count: 1,
        } as never;
      }));
    }

    body.bumpoffers.push(...bumpOffers.map((elem) => {
      return {
        orderFormId: elem.sfid,
        count: 1,
      } as never;
    }));

    return body;
  }

  getAccountDetailsFromGuest(guest: User) {
    return {
      firstName: guest.firstName,
      lastName: guest.lastName,
      email: guest.email,
      phone: guest.phone,
      shippingAddress: this.getShippingAddressFromGuest(guest),
      billingAddress: {},
      shippingApartment: guest.apartment,
      additionalShippingAddressLine:  guest.additionalAddressLine,
      billingApartment: guest.apartment,
      additionalBillingAddressLine: guest.additionalAddressLine
    };
  }

  private getShippingAddressFromGuest(guest: User) {
    return {
      country: guest.country,
      state: guest.state,
      city: guest.city,
      street: guest.street,
      postal: guest.zipPostalCode,
    };
  }

}
function useState(arg0: any): never {
  throw new Error('Function not implemented.');
}

