<div class="simpleVideoPlayer video-player-div">
  <div class="simpleVideoPlayer_wrapper">
    <div class="simpleVideoPlayer_col simpleVideoPlayer_title"[innerHTML]="videoMessageTitle"></div>
      <div class="simpleVideoPlayer_col simpleVideoPlayer_content" [innerHTML]="videoMessage"></div>
        <div class="simpleVideoPlayer_videoCol">
          <!-- data-player="DPUqvBFAwn"  -->
          <video-js 
            id="myPlayerID player_{{ randomScrumble }}"
            data-account="6058004204001" 
            [attr.data-player]="videoPlayType" 
            data-embed="default" 
            controls="" 
            [attr.data-video-id]="videoID"
            data-playlist-id="" 
            data-application-id="" 
            class="vjs-fluid innerWrapper"
        ></video-js>
      </div>
  </div>
</div>
