<div class="scroll-content">
  <header-with-menu [showLogo]="showHeaderLogo" [icon]="gundryLogo" [showContent]="showHeaderContent"
    ></header-with-menu>
    <div class="gdpr-container">
      <h1 class=header>
        CALIFORNIA PRIVACY POLICY
      </h1>
      <p>
        This privacy policy is only applicable to residents of the State of California. We respect your privacy and appreciate the importance of your trust. As part of our efforts to help you understand how we handle the personal information you share with us on our websites and related platforms (including, without limitation, social media pages, and mobile sites (collective, the "Website")), this Privacy Policy sets forth the categories of information that we may collect from you, how such information is collected, our business purposes for collecting such information, and how certain information may be shared with our business partners.
      </p>
      <ol>
        <li>
          <strong>Types of Information that We Collect</strong>
          <ol class="latin-list">
            <li>
              <p>Information Provided by You:</p>
              <p>	We and our third-party service providers may collect Personal Information that you provide to us through your interactions with the Website. "Personal Information" is information that can be used to identify you individually, and includes items such as your name, home address, e-mail address, telephone number, and/or payment information, such as your account or card number, if used to make a purchase from our Website. If you maintain an account on our website, Personal Information may also include a user name, password and answers to our security questions.</p>
              <p>The type of Personal Information that we collect depends on your use of the Website and what information you provide to us. For example:</p>
              <ul class="bullet-list">
                <li>
                  If you purchase any of the Products offered from our Website, you will be required to provide us with your name, billing address, shipping address, e-mail address and credit card number.
                </li>
                <li>
                  If you sign up to be a part of our mailing list, you will be asked to provide us with your e-mail address.
                </li>
                <li>
                  If you receive and fill out a survey, you may be asked for certain demographic information, such as your gender and age range.
                </li>
                <li>
                  If you participate on any of our social networking platforms, we may access any photographs, messages or other data that you have posted on our social network pages.
                </li>
                <li>
                  If you contact our Support team, we will store information about your communication, such as the date and time of your communication, your phone number or email address, and the content of your communication.
                </li>
              </ul>
            </li>
            <li>
              <p>Information We Automatically Receive</p>
              <p>We and our third-party service providers may also use a variety of technologies that automatically or passively collect information about how our Website is accessed and used (known as "Usage Information") by you. “Usage Information” may include the type of browser and device you used to access our Website, your operating system and application version, the web pages accessed by you, the time you accessed these web pages, preceding web page views, and your use of any features or applications on our Website. Statistical data like this helps us understand what is interesting and relevant to our customers, so we can best adapt our content for our customers’ benefit.</p>
              <p>We and our third-party service providers may also automatically collect an IP address or other unique identifier information from the computer, mobile device, or other device you use to access our Website. We may use this information to, amongst other things, administer our Website, help diagnose server problems, analyze trends, track web page movements, help identify you and your shopping cart, and gather broad demographic information for collected use.</p>
            </li>
            <li>
              <p>Cookies and Other Technology:</p>
              <p>Like many sites, our Website may employ cookies, JavaScript tags, web beacons or pixels (also known as clear GIF technology or "action tags") to speed your navigation of our Website, recognize you and your access privileges, and track usage of our Website. Cookies are small pieces of information that are stored as text files by your Internet browser on your computer's hard drive. We use both persistent cookies and third-party cookies. Persistent and third-party cookies enable us to track and target the interests of our customers to enhance your experience on our Website. Persistent cookies are used to make it easier for you to navigate our Website and remain on your hard drive for an extended period of time. Third-party cookies are only used for short-term tracking. We also work with outside service providers to manage cookies in order to analyze your activities across our Website over time. This allows our providers to adapt certain advertisements and offers for you based on your online activity.</p>
              <p>Most Internet browsers are initially set up to accept cookies. You may download an opt-out cookie <a (click)="navigateToPageByGenericUrl('/optout')">here</a> to disable any and all cookies we employ to track and enhance your user experience– however, your ability to use the website will be then limited and/or unavailable.</p>
              <p>Please note that this opt-out is cookie-based (i.e., a cookie must be on your computer to tell our systems that you have opted-out). If you delete, block or otherwise restrict all cookies, this opt-out may not be effective. Further, because different computers, mobile devices and different Internet browsers all require their own version of the opt-out cookie, you must perform this opt-out process on all computers and browsers for which you wish to be opted-out.</p>
            </li>
          </ol>
        </li>
        <li>
          <strong>Our Children’s Policy.</strong>
          <p>Our Website is not directed to children under the age of sixteen (16). Children may not use our Website or purchase our products, and we do not knowingly collect any personal information from children under the age of sixteen (16). We have no way of distinguishing the age of individuals who access our Website. If a child has provided us with personal information, the parent or guardian should contact us to remove the information and opt out of promotional opportunities using the contact link provided below.</p>
        </li>
        <li>
          <strong>Our Use and Sharing of Your Information.</strong>
          <p>We use the information that we collect about you for a variety reasons, including the following:</p>
          <ul class="bullet-list">
            <li>Verifying your identity;</li>
            <li>Fulfilling your product orders;</li>
            <li>Processing any product returns, exchanges or subscriptions;</li>
            <li>Responding to your communications;</li>
            <li>Communicating with you about your purchases and activities on the Website;</li>
            <li>Providing you with access to certain areas and features on the Website;</li>
            <li>Inviting you to participate in a survey regarding your health, our products or websites;</li>
            <li>Adapting content, advertisements and offers that we provide you directly;</li>
            <li>Monitor and analyze trends, usage and activities in connection with our Website;</li>
            <li>Improving the Website and our customers’ experience;</li>
            <li>Sending you emails and other marketing communications;</li>
            <li>Sending notices or information; and</li>
            <li>Carrying out any other purpose described to you at the time your information was collected.</li>
          </ul>
          <p>We may also use and disclose any information that is aggregated or de-identified so that it cannot identify you personally.</p>
          <p>	If you provide us with a payment card when placing an order on our website, we use third-parties to validate your card, authenticate your identity and “tokenize” your payment credentials to make your payments more secure. Your “tokenized” credentials will be stored in your account in order to help make future purchases more convenient. You may delete or edit the stored payment methods in your account at any time by visiting our website or contacting customer support.</p>
          <p>We may share your Personal Information with certain third parties, as follows:</p>
          <ul class="bullet-list">
            <li>With our vendors, consultants, agents, contractors, and other service providers or sub-processors (discussed more fully below) that we use to support our business;</li>
            <li>To provide you with any information or services that you request;</li>
            <li>To respond to subpoenas, court orders, and other legal process, or as otherwise required by law;</li>
            <li>To exercise our legal rights or to defend ourselves against legal claims, to enforce our contracts, to investigate, respond to and resolve problems or inquiries (including governmental inquiries), or to permit us to pursue available remedies or limit the damages that we may sustain;</li>
            <li>In connection with an actual or potential merger, sale, acquisition, assignment, or transfer of all or part of our assets, affiliates, lines of business, or products and services, including at bankruptcy;</li>
            <li>With our affiliates, subsidiaries, or parent companies; and</li>
            <li>With your consent.</li>
          </ul>
          <p>We contract with certain service providers to assist us in providing services to our customers. These service providers use your personal information in order to perform the following tasks:</p>
          <ul class="bullet-list">
            <li>Order processing at checkout;</li>
            <li>Credit card authorization, tokenization and identity verification;</li>
            <li>Order shipping and handling;</li>
            <li>Emailing newsletters and emailing and/or texting other promotional materials for those customers on our mailing and/or text message lists;</li>
            <li>Storing and “backing up” our customer records;</li>
            <li>Inviting you to participate in a survey regarding your health, our products or websites;</li>
            <li>Adapting content, advertisements and offers;</li>
            <li>Monitor and analyze trends, usage and activities in connection with our Website</li>
          </ul>
        </li>
        <li>
          <strong>Security and Your Information.</strong>
          <p>No data transmission over the Internet or website can be guaranteed to be secure from intrusion. However, we and our service providers maintain commercially reasonable physical, electronic and procedural safeguards to protect your Personal Information in accordance with data protection legislative requirements. We follow the Payment Card Industry Data Security Standards (PCI DSS) when handling credit card data. We also recommend that you take steps to protect against unauthorized access to your account through shared or public computers, devices, and applications.</p>
        </li>
        <li>
          <strong>How Long We Will Retain Your Information.</strong>
          <p>We will not retain your Personal Information for longer than necessary. Your e-mail address will be retained on our mailing list until you request that we stop sending you advertising communications. We will retain your other Personal Information in order to provide you with the ability to act on our Product warranties, to fulfill any Product subscriptions and/or for business record and legal purposes.</p>
          <p>For example, we may keep just enough of your personal information to ensure that we comply with your request to erase your personal information, or to exercise or defend our legal claims.</p>
        </li>
        <li>
          <strong>Third Party Links and Services.</strong>
          <p>Our Website may contain links to third-party websites and products and services, such as paying for product orders through PayPal®. We do not control such linked websites and this Privacy Policy does not apply to those websites. We encourage you to read the privacy policy of any third-party website that you visit before you provide any information to that website’s owner.</p>
        </li>
        <li>
          <strong>Changes and Updates to our Privacy Policy.</strong>
          <p>We may change this Privacy Policy at any time without prior notice to you. Any changes that we make will be effective immediately upon our posting of the revised Privacy Policy on our Website. Your continued use of our Website constitutes your consent to any changes made. We will use your Personal Information in a manner consistent with the Privacy Policy in effect at the time you submitted your Personal Information, unless you later consent to a revised policy. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
        </li>
        <li>
          <strong>Your Personal Information Rights pursuant to CCPA.</strong>
          <p>
            As you may know, the California Consumer Privacy Act of 2018, as provided by California Civil Code Section 1798.83 (the “CCPA”), provides California residents certain rights with respect to the access and control of their personal data. For your convenience, we have summarized these rights here. In the event you wish to contact us regarding any of these rights, or for any other reason, please refer to the instructions provided in Section 10 below.
          </p>
          <ol>
            <li>
              <u>Access your personal data</u>: You have the right to request that we disclose what categories of personal information we collect, use, disclose and sell. However, we are not obligated to provide you with this information more than twice in a twelve (12) month period.
            </li>
            <li>
              <u>Delete your personal data</u>: You have the right to request that we delete your personal information that we have collected and/or maintained.
            </li>
            <li>
              <u>Opt-Out of Sale of Personal Information</u>: You have the right to opt-out of the sale of your personal information by a business. <b>Please note, we have not and do not sell or rent any of our customer’s personal information.</b>
            </li>
            <li>
              <u>Non-Discriminatory Treatment for the Exercise of These Rights.</u> You have the right not to receive discriminatory treatment by us in response to your exercise of your privacy rights under CCPA.
            </li>
            <li>
              <u>Authorized Agent.</u> You have the right to designate an authorized agent to make a request under the CCPA on your behalf. Please note that you must provide your authorized agent written permission to so act on your behalf, and your authorized agent must provide us with a copy of this document.
            </li>
          </ol>
          <p>Please note that while we will try to accommodate your requests, there may be times when we might not be able to help. For example, if you have recently purchased a product from us and this product is still within its applicable Money Back Guarantee period, we will not be able to honor a request to erase your personal data unless you first agree to waive your right to the Money Back Guarantee.</p>
        </li>
        <li>
          <strong>California “Shine the Light” Inquiries.</strong>
          <p>In addition to Section 8 above, Section 1798.83 of the California Civil Code permits California residents who have provided their personal information to us to request data regarding our disclosure of their information to third parties for direct marketing purposes.</p>
          <p>In the event you wish to contact us regarding the foregoing, please refer to the instructions provided in Section 10 below. Please specify in your communication that you are making a “California Shine the Light” inquiry.</p>
        </li>
        <li>
          <strong>Questions or Requests?</strong>
          <p>In the event that you have any questions regarding our use of your personal information or your wish to submit a request to view or delete the categories of personal information we have collected from you, please email or call our <a href="https://gundrymd.com/contact/">Support team</a> to assist you. In order to help us verify your identity, we will ask you to provide us with one or more items of personal information (such as your billing or shipping address) to match with our records.</p>
        </li>
      </ol>
      <p><strong>Last Revised: 7/23/2021</strong></p>
      
    </div>
    <footer-common [links]="footerLinks"></footer-common>
</div>
