<div>
  <div class="container mt-3">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="spinnerProvider">
  <div class="spinnerProvider-label">{{ spinnerMessage }}</div>
  <div class="spinnerProvider-spinner"></div>
</div>
<div class="fadeIn spinnerProvider-cover hidden"></div>
