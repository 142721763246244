import { Component } from "@angular/core";
import { CookiesProvider } from "../providers/cookies";
import { TouchcrApiAffiliateComponent } from "../src/services/touchcr-api-affiliate/touchcr-api-affiliate.component";
import { Storage } from '@ionic/storage-angular';
import { UrlsProvider } from 'src/app/providers/urls';
import { SpinnerProvider } from 'src/app/providers/spinner/spinner';
import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { StateExtenderProvider } from 'src/app/providers/state-extender';
import { FunnelSettingsExtenderProvider } from "src/app/providers/funnel-settings-extender";
import { GundrySecureCartComponent } from "../gundry-secure-cart/gundry-secure-cart.component";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"],
})
export class TermsComponent extends GundrySecureCartComponent {
  footerLinks: Array<any> = [];
  public genericUrlToOpenNewTab: string = '';
  public innerWidth: number;
  public innerHeight: number;

  constructor(
    public override urls: UrlsProvider,
    public override spinner: SpinnerProvider,
    public override navCtrl: NavControllerExt,
    public override stateProvider: StateExtenderProvider,
    public cookiesProvider: CookiesProvider,
    public override tcrApiAffiliate: TouchcrApiAffiliateComponent,
    public storage: Storage,
    public override funnelProvider: FunnelSettingsExtenderProvider,

  ) {
    super( urls, tcrApiAffiliate, funnelProvider, spinner, navCtrl, stateProvider );
 
    this.populatePageData();
    this.footerLinks = [];
  }

  async populatePageData() {
    this.noticeOn = false;
    this.noticeText = `<p><span class="bold">UPDATE:</span> Due to extreme weather conditions, some customers may experience shipping delays.<p>`;
    this.showOnOrderForm = true;
    this.brandFooterObj = { link: "http://gundrymd.com", label: "GundryMD" };
  }

     // Use for navigation to specific page by its Generic Url
     public navigateToPageByGenericUrl(url: string): void {

      if(url.startsWith("http")) {
        window.open(url, '_top', `width=${this.innerWidth},height=${this.innerHeight}`);
      } else {
        const currentSessionId = this.funnelProvider.getSessionId();
        const pathName = url;
        let newQueryParams = this.urls.addParamToQuery(
          `${this.getSearch()}`,
          'sessionid',
          currentSessionId
        );
        const nextUrl = `${window.location.origin}/${
          pathName
        }${newQueryParams}`;
        window.open(nextUrl, '_top', `width=${this.innerWidth},height=${this.innerHeight}`); 
      }
    }
}
