import { Component, Output, Input, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'upsell-buttons',
  templateUrl: 'upsell-buttons.html',
  styleUrls: ['upsell-buttons.scss'],
})
export class UpsellButtonsComponent {
  @Input() buttonYesText = '';
  @Input() showButtonYes = true;
  @Input() buttonNoText = '';
  @Input() showButtonNo = true;

  // Provide external method to navigate to next page over funnel
  @Output() onClickYesNoButton = new EventEmitter<boolean>();

  public buttonValue = false;

  constructor(FormsModule: FormsModule
     ) {}

  nextPage(buttonValue: boolean) {
    this.buttonValue = buttonValue;
    this.onClickYesNoButton.emit(this.buttonValue);
  }
}
