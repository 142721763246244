import { Injectable, ApplicationRef } from '@angular/core';
import { EventStreamerProvider } from "../providers/event-stream";
import { NavigationEnd, Router } from '@angular/router';
import { UrlsProvider } from '../providers/urls';
import { GeneralProvider } from '../providers/general';

@Injectable({providedIn: 'root'})
export class NavControllerExt{
  pushExt(name: any, arg1: { name: any; }) {
    return new Promise(resolve => {
      console.log('TODO pushExt', name, arg1);
      resolve(null);
    });
  }
  clearStack() {
    throw new Error('Method not implemented.');
  }
  getConfig() {
    return this.router.config
  }
  animate: boolean;

  constructor(
    public eventStreamer: EventStreamerProvider,
    public router: Router,
    public url: UrlsProvider,
    public generalProvider: GeneralProvider,
  ) {
  }
  public getSearch() {
    return document.location.search;
  }
  setRootExt(page: string, params: any, opts: any = {}) {
    // Gundry B code to handle animation???
    let animation_sets = ["wp-transition", "md-transition"];
    animation_sets = ["md-transition"]; // use only md to reduce size of css

    if((window as any).safari) animation_sets = ['ios-transition'];
    let random_annimation =
      animation_sets[Math.floor(Math.random() * animation_sets.length)];

    opts.animate = true;
    opts.animation = random_annimation;
    opts.duration = 1000;
    opts.easing = "cubic-bezier(0.25,0.1,0.25,1))";  // TODO implement this

    return new Promise(resolve => {
      console.log('extension setRootExt 2', page, params, opts);
      let uri = `${this.getSearch()}`;
      let stp = this.url.getParameterFromUrl({url: location.search, parameter: 'step'});
      if(!stp) stp = '1';
      let nxt:any = stp;
      try {
        nxt = parseInt(stp) + 1;
        if(!location.pathname.startsWith('/DS_') && opts && opts.taken && !opts.alternate) {
          nxt++;
        }
      } catch(e) {
        console.log('step Error in setRootExt', e);
      }
      console.log('changing step from ', stp, 'to ', nxt);
      uri = `${this.getSearch()}`.replace('&step='+stp, '&step=' +nxt);
      this.url.changeState(uri);

      //(document.querySelector("body") as any).classList.add('fadeIn');  // TODO or implement something like this to make smoother transitions
      this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
      let sid = this.url.getParameterFromUrl({url: location.search, parameter: 'sessionid'});

      this.router.navigateByUrl('/upsellrd' + uri, opts).then(
        () => {
          window.location.hash = 'upsellrd';
          setTimeout(() => {
            this.router.navigateByUrl("/" + page + uri, opts).then(
              () => {
                try {
                  window.location.hash = page;

                  this.streamEvent(page, params);
                  this.changeState(params.isUpsell);

                  setTimeout(() => {
                     (document.querySelector("html") as any).scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });  
                  }, 600);

                } catch (e) {
                  console.log('Error in setRootExt', e);
                }
                resolve(null);
              },
              (err: any) => {
                console.log("other extenion setRootExt 3", err);
                resolve(null);
              }
            );
          }, 10);
        });
    });
  }



  streamEvent(page: string, params: any, path?: string) {
    console.log('TODO streamEvent', page, params, path); 
  }
/*
  pushExt(page: string, params: any, opts?: any) {
    return this.app.getRootNavs()[0].push(page, params, opts, () => {
      this.streamEvent(page, params);
      this.changeState(params.isUpsell);
    });
  }

  setRootExt(page: string, params: any, opts: any = {}) {
    return this.app.getRootNavs()[0].setRoot(page, params, opts, () => {
      this.streamEvent(page, params);
      this.changeState(params.isUpsell);
    });
  }

  clearStack() {
    const startIndex = 0;
    const removeCount = this.app.getRootNavs()[0].getViews().length - 1;
    return this.app.getRootNavs()[0].remove(startIndex, removeCount);
  }

  streamEvent(page: string, params: any, path?: string) {
    this.eventStreamer.streamEvent("appScreenView", {
      screenPath: path ? path : this.trimSlashes(window.location.pathname),
      screenName: page,
      customTitle: (params) ? params.name : page,
      fullUrl: window.location.href,
    });
  }
*/
  trimSlashes(path: string | any[]) {
    if (path[0] === '/') {
      path = path.slice(1);
    }
    if (path[path.length - 1] === '/') {
      path = path.slice(0, -1);
    }
    return path;
  }

  changeState(isUpsell: any) {
    this.url.changeState(this.url.getQueryParams());
    if (isUpsell) {
      this.generalProvider.disableBackButton();
    }
  }
}
