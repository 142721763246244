<div class="upsell-wait-div">
  <div margin-bottom justify-content-center>
    <div class="wait_wrapper" col-12 no-padding>
      <div class="row" [ngClass]="{'color_background' : isFull }">
        <div col-12 col-md-4 padding class="wait_icon" [ngClass]="{'color_background' : !isFull }">
          <img src="{{ iconWait }}" alt="">
          <span text-uppercase >{{ iconText }}</span>
        </div>
        <div class="wait_description" col-12 col-md-8>
          <h3 text-center text-md-left [innerHTML]="title"></h3>
          <p no-margin text-center text-md-left [innerHTML]="description"></p>
        </div>
      </div>
    </div>
  </div>
</div>