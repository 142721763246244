import { Component, Input } from '@angular/core';
import * as constants from '../../constants/constants';
import { ToastController } from 'src/app/components/ionic-replacements/ToastController';

declare let OO: any;

@Component( {
  selector: 'simple-video-player',
  templateUrl: 'simple-video-player.html'
} )

export class SimpleVideoPlayerComponent {

  @Input() videoID: string;
  @Input() autoplay: boolean = true;
  @Input() videoPCode: string = 'dwdGgyOvm_d2UoFvVsOW3UK4Un4R';
  @Input() videoPlayerBrandId: string = 'b644c02543ad45638afcad87e8d1292a';
  @Input() videoMessageTitle: string = `Congratulations`;
  @Input() videoMessage: string = `Once you to see the amazing benefits of Vital Reds, you'll be grateful you stocked up.`;

  constructor(
    public toastCtrl: ToastController
  ) {
  }

  player: any;
  window: any = window;
  randomScrumble: string = this.create_UUID();

  async ngAfterViewInit() {
    console.log('begin: ', this.randomScrumble);
    await this.insertVideoScripts();
    console.log('end: ', this.randomScrumble);
    this.loadVideo();
  }

  ngOnDestroy() {
    try {
      if ( this.player ) {
        this.player.destroy();
      }
    } catch ( e ) {
      console.log( e );
    }
  }

  async insertVideoScripts() {
    const scripts = document.getElementsByClassName( constants.OOYALA_CLASS );

    console.log('scripts', scripts);

    if ( scripts.length === 0 ) {
      // tslint:disable-next-line:prefer-for-of
      for ( let i = 0; i < constants.VIDEO_SCRIPTS.length; i++ ) {
        await this.loadVideoScript( constants.VIDEO_SCRIPTS[ i ] )
          .catch( e => {
            return this.toastCtrl
              .create( {
                message: e.message,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast'
              } )
           //   .present();
          } );
      }

      const link = document.createElement( 'link' );
      link.href = constants.VIDEO_STYLE_HREF;
      link.rel = constants.VIDEO_STYLE_REL;
      link.setAttribute( 'class', constants.OOYALA_CLASS );
      document.head.appendChild( link );
    }
  }

  loadVideoScript( scriptCode:any ) {
    return new Promise( ( resolve, reject ) => {
      const script = document.createElement( 'script' );
      script.src = scriptCode;
      script.setAttribute( 'class', constants.OOYALA_CLASS );
      document.head.appendChild( script );

      script.addEventListener( 'load', () => {
        resolve( script );
      } );

      script.addEventListener( 'error', () => {
        reject( new Error( `${ scriptCode } failed to load.` ) );
      } );
    } );
  }


  loadVideo() {
    const playerPrams: any = {
      autoplay: this.autoplay,
      pcode: this.videoPCode,
      playerBrandingId: this.videoPlayerBrandId,
      debug: false
    };
    const playerID = `player_${ this.randomScrumble }`;
    console.log('player id:', playerID);
    console.log('window object:', (window as any)['OO']);

    if( (window as any)['OO'] ) {
      (window as any)['OO'].ready( () => {
        this.player = (window as any)['OO'].Player.create( playerID, this.videoID, playerPrams );
      } );
    } else {
      console.log('retrying', (window as any)['OO']);
      setTimeout( this.loadVideo.bind(this), 0);
    }
  }

  // Borrowed from https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
  create_UUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, ( c ) => {
      const r = ( dt + Math.random() * 16 ) % 16 | 0;
      dt = Math.floor( dt / 16 );
      return ( c === 'x' ? r : ( r & 0x3 | 0x8 ) ).toString( 16 );
    } );
    return uuid;
  }

}
