<div class="upsellButtons upsell-buttons-div">
  <button class="upsellButtons__button upsellButtons__button--yes"
    tappable
    *ngIf="showButtonYes"
    (click)="nextPage(true)">
    <div class="upsellButtons__button__text upsellButtons__button__text--yes">{{ buttonYesText }}</div>
  </button>
  <button class="upsellButtons__button upsellButtons__button--no"
    tappable
    *ngIf="showButtonNo"
    (click)="nextPage(false)">
    <div class="upsellButtons__button__text upsellButtons__button__text--no">{{ buttonNoText }}</div>
  </button>
</div>
