import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

import { EventStreamerProvider } from '../event-stream';

@Injectable({ providedIn: 'root' })
export class OrderDetailsProvider {
  constructor(
    public storage: Storage,
    public eventStreamer: EventStreamerProvider,
  ) {
    this.storage = new Storage({
      name: '__ghdb'
      });
    this.storage.create();
  }

  handleEvent(eventType: any, item: any, cart: any) {
    let eventData = {
      customTitle: item.name,
      fullUrl: window.location.href,
      data: cart,
    }
    this.eventStreamer.streamEvent(eventType, eventData);
  }
}
