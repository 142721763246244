<div class="footerCommon">
    <div [innerHTML]="mainText" [ngClass]="{ 'footerCommon__hiddenMobile' : mainTextMobile.length }"></div>
    <div [innerHTML]="mainTextMobile" class="footerCommon__hidden" *ngIf="mainTextMobile.length"></div>
    <div class="footerCommon__copyright">
      <p>{{ copyrightSymbol }} {{ brand }} {{ currentYear }}{{ allRightsReserved }}</p>
      <ng-container *ngFor="let link of links">
        <ng-container *ngIf="link.show">
          <span class="{{ 'footerCommon__' + link.classes }}"><a (click)="navigateToPageByGenericUrl(link.page, link.target, link.options)">{{ link.label }}</a> <span class="footerCommon__pipe">|</span></span>
        </ng-container>
      </ng-container>
      <p [innerHTML]="address"></p>
    </div>
</div>
