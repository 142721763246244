import { Injectable, NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { OopsComponent } from './oops/oops.component';
import { CheckoutCommon } from './pages/_brand-pages-base/base-controllers/checkout/checkout-common';
import { WhatIsThisPage } from '../pages/what-is-this/what-is-this';
import { SafeOrderPage } from '../pages/safe-order/safe-order';
import { DestinationComponent } from './destination/destination.component';
import { UpsellDynamic } from './upsell/upsell-dynamic';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { CcpaComponent } from './ccpa/ccpa.component';
import { TermsComponent } from './terms/terms.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: OopsComponent},
    { path: 'PrivacyPolicy', component: PrivacyPolicyComponent},
    { path: 'gdpr', component: GdprComponent},
    { path: 'ccpa', component: CcpaComponent},
    { path: 'terms', component: TermsComponent},
    { path: 'fst/:generidUrl', component: DestinationComponent},
    { path: 'upDownDyn', component: UpsellDynamic, data: { animation: 'enterLeavePage' } },
    { path: 'what-is-this', component: WhatIsThisPage },  // TODO make a module to lazy load this page
    { path: 'safe-order', component: SafeOrderPage }, // TODO make a module to lazy load this page
    { path: 'OrderSuccess', loadChildren: () => import('./order-success/order-success.module').then(m => m.OrderSuccessModule) },
    { path: 'what-is-this', component: WhatIsThisPage },  // TODO make a module to lazy load this page
    { path: 'safe-order', component: SafeOrderPage }, // TODO make a module to lazy load this page
    { path: 'upsellrd', loadChildren: () => import('../upsellrd/upsellrd.module').then(m => m.UpsellrdModule) },

  // Braintree checkout variants
  { path: 'Gundry_SecureCart_GBP', component: CheckoutCommon, data: { braintreeTaxEstimate: 0.2, braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'GBP', braintreeCurrencyConversion: 0.83, braintreeDefaultCountry: 'United Kingdom',  officialCurrency: 'GBP', currencySymbol: '£', braintreeCountryLabel: true, checkoutFeature: 'showProductBox' } },
  { path: 'Gundry_SecureCart_GBP2', component: CheckoutCommon, data: { braintreeTaxEstimate: 0.2,  braintreeEnabled: true, braintreeDropInEnabled: false, euSpecified: true,  currency: 'GBP', braintreeCurrencyConversion: 0.83, braintreeDefaultCountry: 'United Kingdom',  officialCurrency: 'GBP', currencySymbol: '£', braintreeCountryLabel: true, checkoutFeature: 'showProductBox', showSubtotal: true } },

  
  // upsell variants for POC
  { path: 'US_3-Unit-ActiveAdvantage-GEN_AP', data: { animation: 'enterLeavePage' },loadChildren: () => import('./us3-unit-active-advantage-gen-ap/us3-unit-active-advantage-gen-ap.module').then(m => m.US3UnitActiveAdvantageGENAPModule) },


  ];

@Injectable({ providedIn: "root" })
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(public router: Router) {
    console.log("[GH] AppRoutingModule constructor " + document.location.origin  +'/assets/html/a18tests.html');
    let content = {};
    let logic = (content:any) => {
      if (Object.keys(content).length === 0) {
        console.log("[GH] Error getting upsell dynamic content");
        this.routeHandlerFinally();
        return;
      } else {
        console.log("[GH] Got upsell dynamic content", content);
      }
      for (let prop in content) {
        if (prop == "fileListStream") {
          for (const key in (content as any)[prop]) {
            if ((content as any)[prop].hasOwnProperty(key)) {
             /*  console.log(
                `[GH] push this key? ${key}`,
                (content as any)[prop][key]
              ); */
              //console.log('this.router.config', ...this.router.config);

              let alreayAdded = false;
              for(let i = 0; i < this.router.config.length; i++) {
                if(this.router.config[i].path == (content as any)[prop][key].url) {
                  //console.log('route already added ' + (content as any)[prop][key].url);
                  alreayAdded = true;
                  break;
                }
              }
              if(alreayAdded) {
                continue;
              }

              if (typeof (window as any)['upsellDynamicCMSControl'].dynamicPageNamesWithContent == 'object') {
                let isvalid = false;
                for(let i = 0; i < (window as any)['upsellDynamicCMSControl'].dynamicPageNamesWithContent.length; i++) {
                  //console.log('checking '+(content as any)[prop][key].url, (window as any)['upsellDynamicCMSControl'].dynamicPageNamesWithContent[i])
                  if((window as any)['upsellDynamicCMSControl'].dynamicPageNamesWithContent[i] == 'upDownDyn-' + (content as any)[prop][key].url + '.json' ) {
                    isvalid = true;
                    break;
                  }
                }
                if(!isvalid) {
                  continue;
                }
                this.router.config.push({
                  path: (content as any)[prop][key].url,
                  component: UpsellDynamic,
                  data: { animation: "enterLeavePage" },
                });
              } else {
                console.log('upsellDynamicCMSControl.dynamicPageNamesWithContent is not an array');
              }
            }
          }
        }
      }
      this.routeHandlerFinally();
    }
    if((window as any)['upsellDynamicContent']) {
      logic((window as any)['upsellDynamicContent']);
      return;
    }
    let xhr = new XMLHttpRequest();
    xhr.open("GET", "/assets/content/store_dynamic_upsell_control.json", false);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    xhr.onload = () => {
      if (typeof xhr.response == "string") content = JSON.parse(xhr.response);
      else if (typeof xhr.response == "object") content = xhr.response;
      logic(content);
    };
    xhr.onerror = (error) => {
      console.log(`XHR Error: ${error}`);
      this.routeHandlerFinally();
    };

    xhr.send();
  }

  routeHandlerFinally() {
    // Wildcard route TODO add a not found handler
    this.router.config.push({
      path: "**",
      component: OopsComponent,
      data: { animation: "enterLeavePage" },
    });
  }
}
