import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import { Country } from '../../models/country';
import { Category } from '../../models/category';
import { Product } from '../../models/product';

import { COUNTRIES_BACKUP } from '../../../constants/constants';

const API_URL = (window as any)["process_env"].API_URL;

const CATEGORIES_GET_ALL = 'categories/all';
const PRODUCT_GET_ID = 'products/bysfid/';
const PRODUCT_GET_IDS = 'products/bysfids/';
const ACCOUNTS_REGISTER = 'accounts/registration/';


@Injectable({providedIn: 'root'})
export class TouchcrApiGeneralProvider {
  categories: Category[] | undefined;

  constructor(
    public http: HttpClient,
  ) {
  }

  duplicateregistration(signup:any) {
    let body = {
      firstName: signup.firstName,
      lastName: signup.lastName,
      personEmail: signup.email,
      source: 'FunnelSite',
    };
    return this.http.post(`/account/signup/duplicatefusionauth`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }
  register(signup:any) {
    let body = {
      firstName: signup.firstName,
      lastName: signup.lastName,
      personEmail: signup.email,
      source: 'FunnelSite',
    };
    return this.http.post(`${API_URL}${ACCOUNTS_REGISTER}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  getCountries(): Observable<Country[]> {
    //If for some reason we did not get countries from server or countries list is empty we will provide back up country list
    if ((window as any)['countries'] && (window as any)['countries'].length) {
      return Observable.of((window as any)['countries']);
    } else {
      return Observable.of(COUNTRIES_BACKUP);
    }
  }

  getCategories(): Observable<Category[]> {
    if (this.categories) {
      return Observable.of(this.categories);
    } else {
      return this.http.get<Category[]>(`${API_URL}${CATEGORIES_GET_ALL}`)
        .catch((error) => {
          return Observable.throw(error && error.error && error.error.error);
        });
    }
  }

  getProductById(id:any): Observable<Product> {
    return this.http.get<Product>(`${API_URL}${PRODUCT_GET_ID}${id}`)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  getProductByIds(ids:any): Observable<Product[]> {
    return this.http.post<Product[]>(`${API_URL}${PRODUCT_GET_IDS}`, ids)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }
}
