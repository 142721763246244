import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'upsell-sticky-bar',
  templateUrl: 'upsell-sticky-bar.html',
  styleUrls: ['upsell-sticky-bar.scss']
})
export class UpsellStickyBar {
  @Input() brand: string;
  @Input() name: string;
  @Input() image: string;
  @Input() quantity: any;
  @Input() productUnitType: string;
  @Input() total: number;
  @Input() addToCartText: string;

  @Input() showButton: boolean = false;
  @Input() buttonYes: string = '';
  @Input() showButtonYes: boolean = true;
  @Input() buttonNo: string = '';
  @Input() showButtonNo: boolean = true;
  @Input() description: string = '';
  @Input() showDescription: boolean = false;
  @Input() imageAlt: string = '';
  @Input() showImage: boolean = true;
  @Input() upsellCtaTextButtonsClass: string;

  @Output() itemAdded = new EventEmitter<boolean>();

  addToCart() {
    this.itemAdded.emit(true);
  }
  nextPage(value: boolean | undefined) {
    this.itemAdded.emit(value);
  }
  constructor() {}
}