import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppComponent } from '../app.component';

import { UrlsProvider } from 'src/app/providers/urls';

import { SpinnerProvider } from 'src/app/providers/spinner/spinner';
import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { StateExtenderProvider } from 'src/app/providers/state-extender';
import { FunnelSettingsExtenderProvider } from "src/app/providers/funnel-settings-extender";
import { TouchcrApiAffiliateComponent } from '../src/services/touchcr-api-affiliate/touchcr-api-affiliate.component';

const ROUTE = (window as any)['process_env'].ROUTE;
const IS_PAYPAL_NVP = (window as any)['process_env'].PAYPAL_NVP_ENABLED;
const TAX_ERROR_MESSAGE = 'Sorry, but we couldn\'t calculate the tax. Please correct your address.';
const ADDRESS_ERROR_MESSAGE = 'Entered address is not valid.';
const COUNTRY_ERROR = 'Sorry, but we don\'t ship to this address';
const SHIP_FROM_COUNTRY = (window as any)['process_env'].SHIP_FROM_COUNTRY;
const BRAND = (window as any)['process_env'].BRAND;

@Component({
  selector: 'app-gundry-secure-cart',
  templateUrl: './gundry-secure-cart.component.html',
  styleUrls: ['./gundry-secure-cart.component.css']
})
export class GundrySecureCartComponent extends AppComponent {
  accountForm: NgForm | undefined;
  diffAddress: NgForm | undefined;
  paymentForm: NgForm | undefined;

  iconArrow: string = '';
  iconPadlock: string = '';
  iconComodo: string = '';
  linkComodo: string = '';
  iconPaypal: string = '';


  isPayPalExecuted: boolean = false;
  isCouponChecked: boolean = false;
  shouldCreateLeadOnFulfillment: boolean = false;
  isRememberInfoChecked: boolean = false;
  isSelectedMainCheckBox: boolean = false;
  isSelectedMainCheckBoxKey: string = '';

  rejectOrder: boolean = false;
  rejectOrderMessage: any = {
    header: '',
    body: '',
  };


  errorBoxId: string = 'orderForm-validation-error-box';

  textPattern: RegExp = /[,.[\/A-Za-z0-9 -]*/ ; // TOUC-1036
  addressPattern: RegExp = /[*#\+,.%()^:;=?[\/A-Za-z0-9 -]*/ ; // TOUC-1036

  buttonList: any = [
    {
      buttonId: 'AmazonPayButton',
      walletId: 'walletWidgetDiv',
      addressId: 'addressBookWidgetDiv',
      isPlaceOrder: true,
    }
  ];

  
  // TOUC-14710
  braintreeEnabled:boolean = false;
  braintreeClientToken: string | undefined;
  braintreeInstance: any = null;
  braintreeDropinValid: boolean = false;
  braintreeToast: any;
  paypalCheckoutInstance: any = null;
  paypalpaymentId: string | undefined;
  braintreeCurrencyConversion: number = 1; // TOUC-14710
  currencySymbol: string = "$"; // TOUC-14710
  currency: string = 'USD'; // TOUC-14710
  officialCurrency: string = 'USD'; // TOUC-18106
  braintreeSplitTesting: boolean = false; // TOUC-14710
  braintreeUpdatingCalculation: boolean = false; // TOUC-14710
  braintreePayPalTokenizedPayment: string | undefined; // TOUC-14710
  braintreeHidePayPalButton: boolean = false; // TOUC-14710
  braintreePayPalEmail: string | undefined; // TOUC-14710
  braintreeNonce: string | undefined; // TOUC-14710
  braintreeTokenizePaymentData: any = null; // TOUC-14710
  braintreeSubtotalAmount: number = 0; // TOUC-14710
  braintreeDropinPayload: any = null; // TOUC-14710
  braintreePayPalBillingState: string | undefined; // TOUC-14710
  braintreePayPalShippingState: string | undefined; // TOUC-14710
  braintreeDefaultCountry: string | undefined;
  isBraintreeLoading: boolean = false;
  braintreeCardFocusSent: boolean = false;
  braintreeMessageToUser: string | undefined;
  useNewBraintreeStyles: boolean = false; // DEV-20535 - after getting the request to style each BT page differently

  constructor(
    public override urls: UrlsProvider,
    public override tcrApiAffiliate: TouchcrApiAffiliateComponent,
    //public override storage: Storage,
    public override funnelProvider: FunnelSettingsExtenderProvider,
    public spinner: SpinnerProvider,
    public override navCtrl: NavControllerExt,
    public override stateProvider: StateExtenderProvider,
    

    
  ) {

    super(urls, tcrApiAffiliate, funnelProvider, navCtrl, stateProvider);
    //Check if storage is unavailable - TCR
    try {
      sessionStorage.setItem('StorageTest', '');
      sessionStorage.removeItem('StorageTest');
    } catch (e) {
      this.isStorageAvailable = false;
    }
  }
  
  ionViewWillLeave() {
    // TOUC-2763-DrMarty
  }
  public getPath () {
    return window.location.pathname ; // [TOUC-5682]
  }
  public getHost () {
    return window.location.hostname ; // [TOUC-5682]
  }
  public override getSearch () {
    return window.location.search ; // [TOUC-5682]
  }
  /* Use 300ms timeout to ensure query parameters are available
     and to provide correct funnel search
  */

}
