<div>
    <div justify-content-center>
        <div col-12 padding class="footer_wrapper">
            <div class="footer_line"></div>
            <div margin-vertical text-center class="footer_links">
                <a href="{{ link.link }}" target="_blank" *ngFor="let link of links" title="{{ link.label }}" (click)="navigateToPageByGenericUrl(link.page, link.target, link.options)">{{ link.label }}</a>
            </div>
            <div justify-content-center>
                <div col-12 col-xl-10 no-padding>
                    <p  margin-bottom text-center>{{ text }}</p>
                    <p no-margin text-center>© {{ year }} {{ brand }}. All Rights Reserved</p>
                    <p no-margin text-center>{{ address }}</p>
                </div>
            </div>
        </div>
    </div>
</div>