import { OrderForm } from './orderForm';
import { User } from './user';
import { TaxData } from './taxData';
import {Coupon} from "./coupon";

export class State {
  pageName?: string;
  navParams?: any;
  orderDetails?: any;
  funnel?: OrderForm;
  coupon?: Coupon;
  date?: number;
  accountDetails?: User;
  taxData?: TaxData;
  bumpExpireList?: Array<{
    sfid: string,
    viewDate?: any,
  }>;
  upsells?: any;
  shippingInfo?: {
    accountInfoForShipping: User,
    productsForShipping: any,
    selectedDeliveryDay?: any,
    isValid: boolean,
  }
}
