//import { NavParams, ToastController, LoadingController, Content } from 'ionic-angular';
import { NavParams } from 'src/app/components/ionic-replacements/NavParams';
import { ToastController } from 'src/app/components/ionic-replacements/ToastController';
import { LoadingController } from 'src/app/components/ionic-replacements/LoadingController';

import { TouchcrApiOrderProvider } from 'src/app/providers/touchcr-api-order/touchcr-api-order'
import { TouchcrApiGeneralProvider } from 'src/app/providers/touchcr-api-general/touchcr-api-general';
import { EventStreamerProvider } from 'src/app/providers/event-stream';
import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { OrderDetailsProvider } from 'src/app/providers/order-details/order-details';
import { PaypalPaymentProvider } from 'src/app/providers/paypal/paypal';
import { Component, HostListener, NgZone, ViewChild } from '@angular/core';

import { TouchcrApiShippingProvider } from 'src/app/providers/TouchcrApiShippingProvider';
import { TouchcrApiRemoteServicesProvider } from 'src/app/providers/touchcr-api-remoteservices';
import { CalculateProvider } from 'src/app/providers/calculate/calculate';
import { UrlsProvider } from 'src/app/providers/urls';
import { StateProvider } from 'src/app/providers/state';
import { State } from 'src/app/models/state';
import { TouchcrApiOrderformProvider } from 'src/app/providers/touchcr-api-orderform/touchcr-api-orderform';
import { GeneralProvider } from 'src/app/providers/general';
import { SpinnerProvider } from 'src/app/providers/spinner/spinner'

const BRAND = (window as any)['process_env'].BRAND;
const ROUTE = (window as any)['process_env'].ROUTE;

@Component({
  selector: 'upsell-base',
  templateUrl: './upsell-base.html'
})
export class UpsellBase {
  //@ViewChild(Content) mContent: Content;

  orderDetailsInfo: any = {};
  order: any = {};
  upsell: any = {};
  paymentMethod: {
    standard: boolean,
    googlePay: boolean,
    applePay: boolean,
    paypal: boolean,
    braintreeMethod: boolean
  } = {
    standard: false,
    googlePay: false,
    applePay: false,
    paypal: false,
    braintreeMethod: false
  };
  currencySymbol: string = '$';
  braintreeCurrencyConversion: number = 1; // TOUC-14710
  urlParameters: any = {};

  // country list variables
  countries: any = {};
  statesList: any = [];

  // tax service variables
  dataForTax: any = {};
  taxRate: any = {};
  taxAmount: number = 0;
  taxServiceEnabled: boolean = (window as any)['process_env'].IS_ENABLED_SALESTAX;
  isErrorTax: boolean = false;
  isTaxValid: boolean = true;

  upsellIndex: number = -1;
  upsellsArray: Array<any> = [{}];
  purchasedUpsells: Array<any> = [];
  isAmazonEnabled: boolean = (window as any)['process_env'].AMAZON_IS_ENABLED;
  isAmazon: boolean = false;
  isAmazonScriptLoaded: boolean = false;
  buttonList: any = [
    {
      buttonId: 'AmazonPayButton',
      walletId: 'walletWidgetDiv',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton1',
      walletId: 'walletWidgetDiv1',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton2',
      walletId: 'walletWidgetDiv2',
      isPlaceOrder: true,
    }
  ];

  // Need for creating invoice in shipping service
  productsForShipping: Array<any> = [];
  accountInfoForShipping: any = {};
  selectedDeliveryDay: any;
  plannedLeavePage: boolean = false;
  isShippingAvailable: boolean = (window as any)['process_env'].SHIPPING;
  isShippingValid: boolean = true;

  // transaction fee
  transactionFeeAmount: number = 0;
  transactionFeeEnabled: boolean = (window as any)['process_env'].IS_ENABLED_TRANSACTION_FEE;
  isCalculatingTransactionFee: boolean = false;
  disableCardPaymentDueToTransactionFee: boolean = false;
  orderSTxId: string = '';  // order Id in surchx

  state: State;
  currentURL: string = "";

  constructor(
    public navCtrl: NavControllerExt,
    public navParams: NavParams,
    public tcrApi: TouchcrApiGeneralProvider,
    public tcrApiOrder: TouchcrApiOrderProvider,
    public loadingCtrl: LoadingController,
    public eventStreamer: EventStreamerProvider,
    public orderDetails: OrderDetailsProvider,
    public paypalPayment: PaypalPaymentProvider,
    public toastCtrl: ToastController,
    public ngZone: NgZone,
    public shippingProvider: TouchcrApiShippingProvider,
    public tcrApiRemoteServices: TouchcrApiRemoteServicesProvider,
    public calculateHelper: CalculateProvider,
    public urlsHelper: UrlsProvider,
    public stateProvider: StateProvider,
    public tcrApiOrderform: TouchcrApiOrderformProvider,
    public generalProvider: GeneralProvider,
    public spinner: SpinnerProvider

  ) {
    this.urlParameters = urlsHelper.getDataFromUrl(window.location.search);
    this.urlParameters.brand = BRAND;
  }

  ngOnInit() {
  //  this.generalProvider.enableJSScroll(this.mContent);

    try {
      if (sessionStorage.getItem('currencySymbol')) (this.currencySymbol as any) = sessionStorage.getItem('currencySymbol');
      if (this.orderDetailsInfo.cr && this.orderDetailsInfo.cr.order && this.orderDetailsInfo.cr.order.upsells) {
        this.braintreeCurrencyConversion = this.orderDetailsInfo.cr;
      }
    } catch (e) {
      console.log('[GH] Error getting currency conversion from session storage');
    }
  }

  // To control if the page closes
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler() {
    if (this.isShippingAvailable && !this.plannedLeavePage) this.createShippingInvoice(true);
  }

  appendPixel() {
    // Pixel
    if (this.orderDetailsInfo.navParams.order &&
      this.orderDetailsInfo.navParams.order.affParameters &&
      this.orderDetailsInfo.navParams.order.affParameters.pixelsText) {
      const scriptElements = [];
      const pixelVariables = document.createElement('script');
      pixelVariables.innerHTML = `
        var pixelOrderTotal = ${this.orderDetailsInfo.navParams.order.total};
        var pixelaffId = '${this.orderDetailsInfo.navParams.order.affParameters.affId}';
        var pixeloffId = '${this.orderDetailsInfo.navParams.order.affParameters.offId}';
        var pixelSubId1 = '${this.orderDetailsInfo.navParams.order.affParameters.subId1 || ''}';
        var pixelSubId2 = '${this.orderDetailsInfo.navParams.order.affParameters.subId2 || ''}';
        var pixelSubId3 = '${this.orderDetailsInfo.navParams.order.affParameters.subId3 || ''}';
        var pixelSubId4 = '${this.orderDetailsInfo.navParams.order.affParameters.subId4 || ''}';
        var pixelSubId5 = '${this.orderDetailsInfo.navParams.order.affParameters.subId5 || ''}';
      `;
      scriptElements.push(pixelVariables);

      const div = document.createElement('div');
      div.innerHTML = this.orderDetailsInfo.navParams.order.affParameters.pixelsText;

      const scriptNodes = div.querySelectorAll('script');
      for (let i = 0; i < scriptNodes.length; i++) {
        const scriptNode:any = scriptNodes[i];
        const scriptElement = document.createElement('script');
        for (let aI = 0; aI < scriptNode.attributes.length; aI++) {
          scriptElement.attributes.setNamedItem(<Attr>scriptNode.attributes[aI].cloneNode());
        }
        const scriptContent = document.createTextNode(scriptNode.textContent);
        scriptElement.appendChild(scriptContent);
        scriptNode.remove();
        scriptElements.push(scriptElement);
      }
      document.body.appendChild(div);
      for (let i = 0; i < scriptElements.length; i++) {
        document.body.appendChild(scriptElements[i]);
      }
    }
  }


 
  showNextBase(skip:any) {
    for (let index = 0; index < this.buttonList.length; index++){
      this.buttonList[index].isPlaceOrder = true;
    }
    this.upsellIndex++;
    if (this.upsellIndex > this.upsellsArray.length - 1) {
      this.stateProvider.setNameOrderUpsell(this.stateProvider.ORDER_SUCCESS, this.order, this.purchasedUpsells)
        .then(() => {
          this.spinner.enable('Loading page <br> Please wait...');
          this.navCtrl.setRootExt(this.stateProvider.ORDER_SUCCESS, { name: 'Order Success', isUpsell: true }, { taken: true})
            .then(() => {
              this.spinner.disable();
            })
            .catch((error) => {
              this.toastCtrl.create({
                message: `Couldn't navigate to the next page`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              });
              console.log('Error on showNextBase()', error);
              this.spinner.disable();
            });
        })
    } else {
      this.disableCardPaymentDueToTransactionFee = true;
      this.dataForTax = this.getTaxRateForUpsells({ detailsInfo: this.orderDetailsInfo, upsellIndex: this.upsellIndex});
      return this.tcrApiRemoteServices.getTaxRateForUpsell(this.dataForTax, this.state).toPromise()
        .then(taxRate => {
          this.taxRate = taxRate;
          if (this.upsellsArray[this.upsellIndex] && this.upsellsArray[this.upsellIndex].sfid) {
            if (skip && this.upsellsArray[this.upsellIndex] &&
              this.upsellsArray[this.upsellIndex].type === 'Downsell') {
              this.showNextBase(false);
            } else {
              if (this.upsellsArray[this.upsellIndex].type === 'Downsell' &&
                (this.upsellIndex < 1 ||
                  this.upsellsArray[this.upsellIndex].orderNumber !== this.upsellsArray[this.upsellIndex - 1].orderNumber)) {
                this.showNextBase(false);
              } else {
                this.upsell = this.upsellsArray[this.upsellIndex];
                this.calculateTransactionFee();
              }
            }
          } else {
            this.showNextBase(false);
          }
          if (taxRate === 'error') {
            this.isErrorTax = true;
            return Promise.reject({
              message: `Sorry, we are couldn't calculate the tax. Your tax amount is 0$.`,
            })
          }
          return Promise.resolve();
        });
    }
    return;
  }

  noThanks() {
    this.showNextBase(false);
  }




  upgradeOrderWithGooglePay() {

  }

  upgradeOrderWithApplePay() {

  }

  async insertScripts(scriptsToLoad: Array<string>, className: string = '') {
    let scripts = document.getElementsByClassName(className);
    if (scripts.length === 0) {
      for (let i = 0; i < scriptsToLoad.length; i++) {
        await this.loadScript(scriptsToLoad[i], className).catch(e => {
          return this.toastCtrl
            .create({
              message: e.message,
              position: "top",
              showCloseButton: true,
              cssClass: "errorToast"
            })
            //.present();
        });
      }
    }
  }

  loadScript(scriptCode:any, className: string = '') {
    return new Promise((resolve, reject) => {
      let script = document.createElement("script");
      script.src = scriptCode;
      if (className) {
        script.setAttribute("class", className);
      }

      document.head.appendChild(script);

      script.addEventListener('load', () => {
        resolve(script);
      });

      script.addEventListener('error', () => {
        reject(new Error(`${scriptCode} failed to load.`));
      });
    });
  }

  getTaxRateForUpsells(data:any) { // generate data for get tax amount for upsels
    let dataForTax: any = {};
    let shipCountry = data.detailsInfo.order.shippingCountry;
    let shipState = data.detailsInfo.order.shippingState;

    if (data.upsellIndex === -1) {
      data.upsellIndex = 0;
    }

    if (this.countries && this.countries[shipCountry] && this.countries[shipCountry].states) {
      for (let state in this.countries[shipCountry].states) {
        this.statesList.push({ value: state, label: this.countries[shipCountry].states[state] });
      }
    }

    let to_state = '';
    for (let i = 0; i < this.statesList.length; i++) {
      if (this.statesList[i].label === shipState) {
        to_state = this.statesList[i].value;
      }
    }

    let accountDetails = {
      shipTo: {
        line1: data.detailsInfo.order.shippingAddress,
        city: data.detailsInfo.order.shippingCity,
        region: to_state || shipState,
        country: this.countries[shipCountry] ? this.countries[shipCountry].value : shipCountry,
        postalCode: data.detailsInfo.order.shippingZipPostal
      }
    };

    dataForTax['accountDetails'] = accountDetails;
    let productDetails = [];
    let item = {};
    item = {
      number: data.detailsInfo.upsells[data.upsellIndex].sfid,
      quantity: 1,
      amount: data.detailsInfo.upsells[data.upsellIndex].offerPrice,
      taxCode: data.detailsInfo.upsells[data.upsellIndex].productTaxCode,
      description: data.detailsInfo.upsells[data.upsellIndex].name,
      productDescription: data.detailsInfo.upsells[data.upsellIndex].productDescription
    };
    productDetails.push(item);
    dataForTax.productDetails = productDetails;
    dataForTax.shipping = data.detailsInfo.upsells[data.upsellIndex].shippingCost;
    dataForTax.total =  data.detailsInfo.upsells[data.upsellIndex].offerPrice;
    return dataForTax;
  }


  // Create shipping invoice with our API
  createShippingInvoice(closePage: boolean) {
    if (this.state.shippingInfo && this.state.shippingInfo.isValid) {
      this.stateProvider.clearShippingInfo();
      let ordersIds = this.order.id;
      if (this.purchasedUpsells) this.purchasedUpsells.forEach((e1) => {
        // ordersIds.push({ orderId: e1.id });
        ordersIds += ',' + e1.id;
        e1.orderItems.forEach((e2:any) => {
          this.productsForShipping.push({count : e2.quantity, shippingCost: 0, productId : e2.productId});
        });
      });
      const invoiceBody = {
        products: this.productsForShipping,
        accountInfo: this.accountInfoForShipping,
        orderIds: ordersIds, selectedDeliveryDay:
        this.selectedDeliveryDay
      };
      if (closePage) {
        this.shippingProvider.createInvoiceViaXHR(invoiceBody);
      } else {
        this.shippingProvider.createInvoice(invoiceBody).subscribe();
      }
    }
  }

  calculateTransactionFee() {
    const orderCardNicn = this.orderDetailsInfo.order.nicn ? this.orderDetailsInfo.order.nicn : '';
    if (!this.transactionFeeEnabled || orderCardNicn.length < 8 || !this.paymentMethod.standard) {
      this.disableCardPaymentDueToTransactionFee = false;
      this.transactionFeeAmount = 0;
      return;
    }

    this.isCalculatingTransactionFee = true;
    const accountInfo = {
      billingPostalCode: this.orderDetailsInfo.order.billingZip,
      billingCountryCode: this.orderDetailsInfo.order.billingCountry,
    };

    const orderTotal = +(this.upsell.offerPrice + this.upsell.shippingCost + (this.taxRate.totalTax || 0)).toFixed(2);

    this.tcrApiRemoteServices.requestDataForTrFee({
        brand: (window as any)['process_env'].BRAND,
        account: accountInfo,
        orderTotalWithoutFee: orderTotal,
        orderCardNicn: orderCardNicn,
        orderIdTemp: 'upsellOrder',
        orderSTxId: this.orderSTxId,
        orderTotalWithoutFeeSubscription: 0,
        orderIdTempSubscription: '',
        orderSTxIdSubscription: '',
      }
    ).subscribe((res) => {
      this.isCalculatingTransactionFee = false;
      const calculationResponse = res.trFeeResponse.upsellOrder ? res.trFeeResponse.upsellOrder : {};
      this.transactionFeeAmount = calculationResponse.transactionFee ? calculationResponse.transactionFee : 0;
      this.orderSTxId = calculationResponse.sTxId ? calculationResponse.sTxId : '';
      // response also contains: are calculation is valid in 'calculationResponse.isSuccess'
      this.disableCardPaymentDueToTransactionFee = false;
    }, (err) => {
      this.transactionFeeAmount = 0;
      this.isCalculatingTransactionFee = false;
      this.disableCardPaymentDueToTransactionFee = false;
      this.orderSTxId = '';

      console.log('Error on transaction fee in Сalculate Transaction Fee: ', err);
      this.toastCtrl.create({ message: `Error transaction fee calculating!`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
    })
  }

  // Transaction Fee 'capture' callout to API
  captureTransactionFee(orderId:any) {
    this.tcrApiRemoteServices.captureTransactionFeeRequest({
        orderBrand: (window as any)['process_env'].BRAND,
        orderMTxId: orderId,
        orderSTxId: this.orderSTxId,
        orderId: orderId,
        subscriptionSFId: '',
        subscriptionSTxId: '',
      }
    ).subscribe(() => {}, (err) => {
      console.log('Error on capture transaction fee for upsell: ', err);
    })
  }

  changePlaceOrder(index:any) {
    for (let i = 0; i < this.buttonList.length; i++){
      if (i === index) this.buttonList[i].isPlaceOrder = false;
      else this.buttonList[i].isPlaceOrder = true;
    } 
  }

}
